package policies

import components.DisclosureText
import components.keyspotDisclosure
import dev.fritz2.core.RenderContext
import koin
import localization.TranslationStore
import localization.policies.UiAup


fun RenderContext.aup(styleClass: String) {
    val translationStore by koin.inject<TranslationStore>()

    article(styleClass) {
        h1("text-3xl font-semibold") {
            translationStore[UiAup.Heading].renderText(this)
        }
        div {
            translationStore[UiAup.Description].renderText(this)
        }

        val sections = listOf(
            Triple(
                UiAup.CredentialsHeading,
                UiAup.CredentialsDescription,
                listOf(
                    UiAup.CredentialsFirst,
                    UiAup.CredentialsSecond,
                    UiAup.CredentialsThird,
                    UiAup.CredentialsFourth,
                    UiAup.CredentialsFifth
                )
            ),
            Triple(
                UiAup.ContentHeading,
                UiAup.ContentDescription,
                listOf(UiAup.ContentFirst, UiAup.ContentSecond, UiAup.ContentThird, UiAup.ContentFourth)
            ),
            Triple(
                UiAup.RestrictionHeading,
                UiAup.RestrictionDescription,
                listOf(UiAup.RestrictionFirst, UiAup.RestrictionSecond, UiAup.RestrictionThird)
            ),
            Triple(
                UiAup.AbusiveHeading,
                UiAup.AbusiveDescription,
                listOf(UiAup.AbusiveFirst, UiAup.AbusiveSecond, UiAup.AbusiveThird, UiAup.AbusiveFourth)
            ),
            Triple(
                UiAup.SecurityHeading,
                UiAup.SecurityDescription,
                listOf(UiAup.SecurityFirst, UiAup.SecuritySecond, UiAup.SecurityThird)
            ),
            Triple(UiAup.MonitoringHeading, UiAup.MonitoringDescription, emptyList()),
            Triple(UiAup.CopyrightHeading, UiAup.CopyrightDescription, emptyList())
        )

        sections.forEach { (heading, description, items) ->
            addDisclosureSection(heading, description, items)
        }
    }
}
private fun RenderContext.addDisclosureSection(heading: UiAup, description: UiAup, items: List<UiAup>) {
    keyspotDisclosure(DisclosureText.TranslatableContent(heading), DisclosureText.TranslatableContent(description), additionalContent(items))
}

private fun additionalContent(items: List<UiAup>): RenderContext.() -> Unit = {
    val translationStore by koin.inject<TranslationStore>()
    if (items.isNotEmpty()) {
        ul("list-disc px-6") {
            items.forEach { item ->
                li { translationStore[item].renderText(this) }
            }
        }
    }
}