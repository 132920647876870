package components

import dev.fritz2.core.*
import dev.fritz2.routing.MapRouter
import homepage.navigation.Pages
import homepage.navigation.aLink
import koin
import localization.TranslationStore
import localization.components.UiRouting
import localization.homepage.UiTrial
import org.w3c.dom.HTMLDivElement

fun HtmlTag<HTMLDivElement>.policyCheckbox(
    policyCheckboxStore: Store<Boolean>,
    router: MapRouter,
    id: String
) {
    val translationStore by koin.inject<TranslationStore>()

    div("checkboxContainer") {
        input("checkbox", id) {
            type("checkbox")
            checked(policyCheckboxStore.data)
            changes.states() handledBy policyCheckboxStore.update
        }
        label("checkboxLabel") {
            `for`(id)
            translationStore[UiTrial.Checkbox].renderText(this)
        }
        aLink("", Pages.privacyPolicy, router, translationStore[UiRouting.PrivacyPolicy])
        +","
        aLink("", Pages.cookiePolicy, router, translationStore[UiRouting.CookiePolicy])
        +","
        aLink("", Pages.tou, router, translationStore[UiRouting.Tou])
        +","
        aLink("", Pages.aup, router, translationStore[UiRouting.Aup])
    }
}