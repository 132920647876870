// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.repository

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlinx.datetime.LocalDateTime

public fun Document.Companion.id(): Lens<Document, Int> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v)}
  )

public fun <PARENT> Lens<PARENT, Document>.id(): Lens<PARENT, Int> = this + Document.id()

public fun Document.Companion.name(): Lens<Document, String> = lensOf(
    "name",
    { it.name },
    { p, v -> p.copy(name = v)}
  )

public fun <PARENT> Lens<PARENT, Document>.name(): Lens<PARENT, String> = this + Document.name()

public fun Document.Companion.type(): Lens<Document, DocumentType> = lensOf(
    "type",
    { it.type },
    { p, v -> p.copy(type = v)}
  )

public fun <PARENT> Lens<PARENT, Document>.type(): Lens<PARENT, DocumentType> = this +
    Document.type()

public fun Document.Companion.folderType(): Lens<Document, SingletonFolderType?> = lensOf(
    "folderType",
    { it.folderType },
    { p, v -> p.copy(folderType = v)}
  )

public fun <PARENT> Lens<PARENT, Document>.folderType(): Lens<PARENT, SingletonFolderType?> = this +
    Document.folderType()

public fun Document.Companion.uri(): Lens<Document, String> = lensOf(
    "uri",
    { it.uri },
    { p, v -> p.copy(uri = v)}
  )

public fun <PARENT> Lens<PARENT, Document>.uri(): Lens<PARENT, String> = this + Document.uri()

public fun Document.Companion.creationDateTime(): Lens<Document, LocalDateTime> = lensOf(
    "creationDateTime",
    { it.creationDateTime },
    { p, v -> p.copy(creationDateTime = v)}
  )

public fun <PARENT> Lens<PARENT, Document>.creationDateTime(): Lens<PARENT, LocalDateTime> = this +
    Document.creationDateTime()

public fun Document.Companion.updateDateTime(): Lens<Document, LocalDateTime> = lensOf(
    "updateDateTime",
    { it.updateDateTime },
    { p, v -> p.copy(updateDateTime = v)}
  )

public fun <PARENT> Lens<PARENT, Document>.updateDateTime(): Lens<PARENT, LocalDateTime> = this +
    Document.updateDateTime()

public fun Document.Companion.parent(): Lens<Document, Document?> = lensOf(
    "parent",
    { it.parent },
    { p, v -> p.copy(parent = v)}
  )

public fun <PARENT> Lens<PARENT, Document>.parent(): Lens<PARENT, Document?> = this +
    Document.parent()

public fun Document.Companion.children(): Lens<Document, List<Document>> = lensOf(
    "children",
    { it.children },
    { p, v -> p.copy(children = v)}
  )

public fun <PARENT> Lens<PARENT, Document>.children(): Lens<PARENT, List<Document>> = this +
    Document.children()
