package components

import domain.util.FileType
import kotlinx.coroutines.await
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLProgressElement
import org.w3c.files.File
import utils.createProgressBar

suspend fun extractZip(file: File, allowedFileTypes: List<FileType>, progressBarContainer: HTMLDivElement?): List<File> {

    val progressBar = createProgressBar(file.name)

    if (progressBarContainer != null) {
        progressBarContainer.hidden = false
        progressBarContainer.appendChild(progressBar)
    }

    val extractedFiles = mutableListOf<File>()
    try {
        val zip = JSZip.loadAsync(file).await()
        val fileNames = js("Object").keys(zip.files) as Array<String>
        val totalFiles = fileNames.size

        for ((processedFiles, name) in fileNames.withIndex()) {
            val jsZipFile = zip.files[name].unsafeCast<JSZipObject>()
            val blob = jsZipFile.async("blob").await()
            val extractedFile = File(arrayOf(blob), name)

            if (extractedFile.name.endsWith(FileType.ZIP.fileEnding) || extractedFile.name.endsWith(FileType.X_ZIP.fileEnding)) {
                extractedFiles.addAll(extractZip(extractedFile, allowedFileTypes, progressBarContainer))
            } else if (!extractedFile.name.endsWith("/")
                && (allowedFileTypes.contains(FileType.ALL)
                        || allowedFileTypes.map { fileType -> fileType.fileEnding }.contains(extractedFile.name.substringAfterLast(".")))) {
                extractedFiles.add(extractedFile)
            }

            if(progressBarContainer != null) {
                val progress = (processedFiles + 1).toDouble() / totalFiles.toDouble() * 100.0
                updateProgress(progressBar, progress)
            }
        }
        if(progressBarContainer != null) progressBar.remove()
    } catch (e: Exception) {
        console.error("Error occurred while extracting zip file", e)
    }
    return extractedFiles.toList()
}

private fun updateProgress(progressBar: HTMLDivElement, value: Double) {
    (progressBar.querySelector(".zip-file-progress-bar") as? HTMLProgressElement)?.value = value
}

