// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.authentication.token

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.String

public fun Pin.Companion.pin(): Lens<Pin, String> = lensOf(
    "pin",
    { it.pin },
    { p, v -> p.copy(pin = v)}
  )

public fun <PARENT> Lens<PARENT, Pin>.pin(): Lens<PARENT, String> = this + Pin.pin()

public fun Pin.Companion.expirationTime(): Lens<Pin, String> = lensOf(
    "expirationTime",
    { it.expirationTime },
    { p, v -> p.copy(expirationTime = v)}
  )

public fun <PARENT> Lens<PARENT, Pin>.expirationTime(): Lens<PARENT, String> = this +
    Pin.expirationTime()
