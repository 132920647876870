package domain.model

import dev.fritz2.core.Id
import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import domain.userManagement.User
import kotlinx.serialization.Serializable
import localization.platform.UiModel
import localization.platform.UiModelGroup
import util.Message
import util.Severity

@Lenses
@Serializable
data class Group(
    val id: Int,
    val name: String,
    val color: String,
    val rules: Set<Rule>?,
    val owner: User?,
    val uniqueIdentifier: String = Id.next(16)
) {
    constructor() : this(0, "", "", null, null)
    constructor(id: Int, name: String, color: String, rules: Set<Rule>) : this(id, name, color, rules, null)

    fun isContentEqual(other: Group): Boolean {
        if (this === other) return true
        if (id != other.id) return false
        if (name != other.name) return false
        if (color != other.color) return false
        if (!rules.isContentEqual(other.rules)) return false
        if (owner != other.owner) return false
        return true
    }

    private fun Set<Rule>?.isContentEqual(other: Set<Rule>?): Boolean {
        if (this == null && other == null) return true
        if (this == null || other == null) return false
        if (this.size != other.size) return false

        val otherRulesById = other.associateBy { it.id }
        return this.all { rule ->
            val otherRule = otherRulesById[rule.id] ?: return false
            rule.isContentEqual(otherRule)
        }
    }

    companion object {
        const val PATH = "/modelGroup"

        val validation: Validation<Group, Unit, Message> = dev.fritz2.validation.validation { inspector ->
            val name = inspector.map(Group.name())
            if (!isValidName(name.data)) {
                add(Message(name.path, Severity.Error, UiModelGroup.NameMessage))
            }

            val color = inspector.map(Group.color())
            if (!isValidColor(color.data)) {
                add(Message(color.path, Severity.Error, UiModelGroup.ColorMessage))
            }

            val rules = inspector.map(Group.rules())
            if (!areValidRules(rules.data)) {
                add(Message(rules.path, Severity.Error, UiModelGroup.RulesMessage))
            }
        }

        fun isValid(group: Group): Boolean =
            isValidName(group.name) && isValidColor(group.color) && areValidRules(group.rules)

        private fun isValidName(name: String): Boolean = name.length < 50

        private fun isValidColor(color: String): Boolean {
            val colorSplit = color.split(",")
            if (colorSplit.size != 3) return false
            colorSplit.forEach { rgbValue ->
                return try {
                    rgbValue.toInt() in 0..255
                } catch (e: Exception) {
                    false
                }
            }
            return true
        }

        private fun areValidRules(rules: Set<Rule>?): Boolean {
            if (rules.isNullOrEmpty()) {
                return false
            }
            rules.forEach { rule: Rule -> if (!Rule.isValid(rule)) {
                return false
            } }
            return true
        }
    }
}