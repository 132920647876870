// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.authentication.login

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.String

public fun Register.Companion.email(): Lens<Register, String> = lensOf(
    "email",
    { it.email },
    { p, v -> p.copy(email = v)}
  )

public fun <PARENT> Lens<PARENT, Register>.email(): Lens<PARENT, String> = this + Register.email()

public fun Register.Companion.username(): Lens<Register, String> = lensOf(
    "username",
    { it.username },
    { p, v -> p.copy(username = v)}
  )

public fun <PARENT> Lens<PARENT, Register>.username(): Lens<PARENT, String> = this +
    Register.username()

public fun Register.Companion.password(): Lens<Register, String> = lensOf(
    "password",
    { it.password },
    { p, v -> p.copy(password = v)}
  )

public fun <PARENT> Lens<PARENT, Register>.password(): Lens<PARENT, String> = this +
    Register.password()

public fun Register.Companion.passwordConfirmation(): Lens<Register, String> = lensOf(
    "passwordConfirmation",
    { it.passwordConfirmation },
    { p, v -> p.copy(passwordConfirmation = v)}
  )

public fun <PARENT> Lens<PARENT, Register>.passwordConfirmation(): Lens<PARENT, String> = this +
    Register.passwordConfirmation()
