package homepage.sides

import dev.fritz2.core.RenderContext
import dev.fritz2.routing.MapRouter
import homepage.sides.indexComponents.benefits
import homepage.sides.indexComponents.heroSection
import homepage.sides.indexComponents.solution
import homepage.sides.indexComponents.successStories

fun RenderContext.index(router: MapRouter) {
    heroSection(router)
    benefits()
    successStories()
    solution()
}
