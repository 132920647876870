package domain.model

import domain.repository.DocumentType
import kotlinx.serialization.Serializable

@Serializable
data class ModelTree(
    val id: Int,
    val name: String,
    val path: String,
    val type: DocumentType,

    val children: MutableList<ModelTree>?
) {
    companion object {
        const val PATH = "/modelTree"
    }
}