package platform.sides

import admin.sides.stores.GroupsStore
import api.*
import components.*
import components.modal.modalAnimationOverlay
import csstype.Rules
import dev.fritz2.core.*
import dev.fritz2.headless.components.disclosure
import dev.fritz2.headless.components.modal
import dev.fritz2.headless.components.toast
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.InitialFocus
import dev.fritz2.routing.MapRouter
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.valid
import domain.model.*
import koin
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import localization.platform.UiCustomizationEdit
import localization.platform.UiCustomizationGroupEdit
import localization.platform.UiModelSettings
import org.w3c.dom.HTMLElement
import platform.navigation.AdminStore
import platform.navigation.Pages
import platform.navigation.PersonalIdStore
import util.Message
import utils.*

private val defaultModelMetadata = ModelMetadata()
private val defaultModelSettings = ModelSetting()
private val defaultModelGroup = Group(0, "MyGroup-${Id.next(16)}", "160,82,45", null, null)

private val defaultModelCriteria = Rule(
    0, "MyRule-${Id.next(16)}", null,
    listOf(
        Combination(
            0,
            type = CombinationType.TERM,
            words = listOf(
                Word(
                    0, "", Language.NONE,
                    caseSensitive = false,
                    subStringSearch = false,
                    type = WordType.TERM
                )
            )
        )
    ), emptyList()
)

val defaultModelPhrase = Rule(
    0, "MyRule-${Id.next(16)}", null,
    listOf(
        Combination(
            0,
            type = CombinationType.TERM,
            words = listOf(
                Word(
                    0,
                    generateRandomString(),
                    Language.ENGLISH,
                    caseSensitive = false,
                    subStringSearch = false
                )
            )
        ),
        Combination(
            0,
            type = CombinationType.PHRASE,
            words = listOf(
                Word(
                    0, "", Language.NONE,
                    caseSensitive = false,
                    subStringSearch = false,
                    type = WordType.PHRASE
                )
            )
        )
    ), emptyList()
)

val defaultModel =
    Model(0, "MyModel-${Id.next(16)}", "", defaultModelMetadata, defaultModelSettings, setOf(defaultModelGroup), true)

object ModelValidationStore : ValidatingStore<Model, Unit, Message>(
    defaultModel,
    Model.validation,
    Unit,
    job = Job(),
    validateAfterUpdate = false
) {
    val initialize: Handler<Int> = ModelValidationStore.handle { _, id ->
        if (id == 0) return@handle defaultModel

        try {
            val model = getModel(id)
            toastSuccess("Model retrieval successful")
            return@handle model
        } catch (e: Exception) {
            toastError(e.message ?: "Model retrieval failed")
            return@handle defaultModel
        }
    }

    val deleteModel: Handler<MapRouter> = ModelValidationStore.handle { model, router ->
        try {
            deleteModel(model.id)
            toastSuccess("Model deletion successful")
            router.navTo(
                mapOf(
                    "page" to Pages.customization
                )
            )
            model
        } catch (e: Exception) {
            toastError(e.message ?: "Model delete failed")
            model
        }
    }

    val saveModel: Handler<MapRouter> = ModelValidationStore.handle { model, router ->
        try {
            val storedModel = createModel(model)
            toastSuccess("Model save successful")
            router.navTo(
                mapOf(
                    "page" to Pages.customization
                )
            )
            storedModel
        } catch (e: Exception) {
            toastError(e.message ?: "Model save failed")
            model
        }
    }

    val updateModel: Handler<MapRouter> = ModelValidationStore.handle { model, router ->
        try {
            val storedModel = updateModel(model.id, model)
            toastSuccess("Model update successful")
            router.navTo(
                mapOf(
                    "page" to Pages.customization
                )
            )
            storedModel
        } catch (e: Exception) {
            toastError(e.message ?: "Model update failed")
            model
        }
    }

    val validateModel: Handler<MapRouter> = ModelValidationStore.handle { model, router ->
        try {
            validateModel(model.id, model)
            toastSuccess("Model successfully validated against persisted Model")
            router.navTo(
                mapOf(
                    "page" to Pages.customization
                )
            )
            model
        } catch (e: Exception) {
            toastError(e.message ?: "Model validation against persisted model failed")
            model
        }
    }

    val addNewGroup: Handler<MapRouter> = ModelValidationStore.handle { model, router ->
        val newGroup = defaultModelGroup
        router.navTo(
            mapOf(
                "page" to Pages.groupCustomization,
                "modelId" to model.id.toString(),
                "groupUniqueIdentifier" to newGroup.uniqueIdentifier
            )
        )
        model.copy(groups = model.groups + newGroup)
    }

    val addNewRule: Handler<Triple<String, Boolean, MapRouter>> =
        ModelValidationStore.handle { model, (groupUniqueIdentifier, isPhrase, router) ->
            val newRule = if (isPhrase) defaultModelPhrase else defaultModelCriteria

            router.navTo(
                mapOf(
                    if (isPhrase) "page" to Pages.phraseCustomization else "page" to Pages.criteriumCustomization,
                    "modelId" to model.id.toString(),
                    "groupUniqueIdentifier" to groupUniqueIdentifier,
                    "ruleUniqueIdentifier" to newRule.uniqueIdentifier
                )
            )

            val updatedGroups = model.groups.map { group ->
                if (group.uniqueIdentifier == groupUniqueIdentifier) {
                    val updatedRules = (group.rules ?: emptySet()) + newRule
                    group.copy(rules = updatedRules)
                } else {
                    group
                }
            }.toSet()
            model.copy(groups = updatedGroups)
        }

    private fun toastSuccess(message: String) {
        toast("success", 1000L, "successToast") { +message }
    }

    private val groupStores = mutableMapOf<String, ModelGroupValidationStore>()

    fun getGroupStore(uniqueIdentifier: String): ModelGroupValidationStore {
        return groupStores.getOrPut(uniqueIdentifier) {
            current.groups.find { it.uniqueIdentifier == uniqueIdentifier }
                ?: error("Group with uniqueIdentifier $uniqueIdentifier not found")
            ModelGroupValidationStore(this, uniqueIdentifier)
        }
    }

    fun isValid(): Boolean {
        return validate(this.current).valid
    }
}

class ModelGroupValidationStore(
    val modelValidationStore: ValidatingStore<Model, Unit, Message>,
    private val groupUniqueIdentifier: String
) : ValidatingStore<Group, Unit, Message>(
    defaultModelGroup,
    Group.validation,
    Unit,
    job = Job(),
    validateAfterUpdate = false
) {
    private var initialGroup: Group? = null

    private val _initialized = CompletableDeferred<Unit>()
    val initialized: CompletableDeferred<Unit> get() = _initialized

    init {
        modelValidationStore.data handledBy { model ->
            val updatedGroup = model.groups.find { it.uniqueIdentifier == groupUniqueIdentifier }
            if (updatedGroup != null) {
                initialGroup = updatedGroup

                val updateCompletion = CompletableDeferred<Unit>()
                update(updatedGroup)
                updateCompletion.complete(Unit)

                updateCompletion.invokeOnCompletion {
                    _initialized.complete(Unit)
                }
            }
        }
    }

    private val ruleStores = mutableMapOf<String, ModelRuleValidationStore>()

    fun getRuleStore(ruleUniqueIdentifier: String): ModelRuleValidationStore {
        return ruleStores.getOrPut(ruleUniqueIdentifier) {
            val rule = current.rules?.find { it.uniqueIdentifier == ruleUniqueIdentifier }
                ?: error("Rule with uniqueIdentifier $ruleUniqueIdentifier not found")
            ModelRuleValidationStore(this, ruleUniqueIdentifier, rule)
        }
    }

    val resetChanges: Handler<MapRouter> = this.handle { currentGroup, router ->
        if (currentGroup.id == 0) {
            val updatedGroups =
                modelValidationStore.current.groups.filterNot { it.uniqueIdentifier == groupUniqueIdentifier }.toSet()
            modelValidationStore.update(modelValidationStore.current.copy(groups = updatedGroups))
            router.navTo(
                mapOf(
                    "page" to Pages.modelCustomization,
                    "id" to modelValidationStore.current.id.toString(),
                    "init" to "false"
                )
            )
            currentGroup
        } else {
            initialGroup?.let {
                router.navTo(
                    mapOf(
                        "page" to Pages.modelCustomization,
                        "id" to modelValidationStore.current.id.toString(),
                        "init" to "false"
                    )
                )
                it
            } ?: initialGroup!!
        }
    }

    val deleteGroup: Handler<MapRouter> = this.handle { currentGroup, router ->
        if (modelValidationStore.current.id != 0 && currentGroup.id != 0) {
            try {
                deleteGroup(modelValidationStore.current.id, currentGroup.id)
                val updatedGroups = modelValidationStore.current.groups.filterNot { it.id == currentGroup.id }.toSet()
                modelValidationStore.update(modelValidationStore.current.copy(groups = updatedGroups))
                router.navTo(
                    mapOf(
                        "page" to Pages.modelCustomization,
                        "id" to modelValidationStore.current.id.toString(),
                        "init" to "false"
                    )
                )
            } catch (e: Exception) {
                toastError(e.message ?: "Delete group failed")
            }
            currentGroup
        } else {
            val updatedGroups =
                modelValidationStore.current.groups.filterNot { it.uniqueIdentifier == groupUniqueIdentifier }.toSet()
            modelValidationStore.update(modelValidationStore.current.copy(groups = updatedGroups))
            router.navTo(
                mapOf(
                    "page" to Pages.modelCustomization,
                    "id" to modelValidationStore.current.id.toString(),
                    "init" to "false"
                )
            )
            currentGroup
        }
    }

    val saveGroup: Handler<MapRouter> = this.handle { currentGroup, router ->
        if (modelValidationStore.current.id != 0 && validate(currentGroup).valid) {
            saveOrUpdateGroup(
                currentGroup,
                modelValidationStore.current.id,
                initialGroup
            ).let { updatedGroup ->
                update(updatedGroup)
                syncWithModelStore(updatedGroup)
                router.navTo(
                    mapOf(
                        "page" to Pages.modelCustomization,
                        "id" to modelValidationStore.current.id.toString(),
                        "init" to "false"
                    )
                )
                updatedGroup
            }
        } else {
            syncWithModelStore(currentGroup)
            router.navTo(
                mapOf(
                    "page" to Pages.modelCustomization,
                    "id" to modelValidationStore.current.id.toString(),
                    "init" to "false"
                )
            )
            currentGroup
        }
    }

    private suspend fun saveOrUpdateGroup(currentGroup: Group, modelId: Int, initialGroup: Group?): Group {
        return if (currentGroup.id != 0) {
            initialGroup?.let {
                getGroup(modelId, currentGroup.id).let { storedGroup ->
                    if (!initialGroup.isContentEqual(storedGroup)) {
                        toast("error", classes = "errorToast") { +"Group changed in between" }
                        return currentGroup
                    }
                }
            }
            updateGroup(modelId, currentGroup.id, currentGroup)
        } else {
            createGroup(modelId, currentGroup)
        }
    }

    private fun syncWithModelStore(updatedGroup: Group) {
        val currentModel = modelValidationStore.current
        val updatedGroups = currentModel.groups.map { group ->
            if (group.uniqueIdentifier == groupUniqueIdentifier) updatedGroup else group
        }.toSet()
        modelValidationStore.update(currentModel.copy(groups = updatedGroups))
    }
}

class ModelRuleValidationStore(
    private val groupValidationStore: ModelGroupValidationStore,
    val ruleUniqueIdentifier: String,
    rule: Rule
) : ValidatingStore<Rule, Unit, Message>(
    rule,
    Rule.validation,
    Unit,
    job = Job(),
    validateAfterUpdate = false
) {
    private var initialRule: Rule? = null

    init {
        groupValidationStore.data handledBy { group ->
            val updatedRule = group.rules?.find { it.uniqueIdentifier == ruleUniqueIdentifier }
            if (updatedRule != null) {
                initialRule = updatedRule
                update(updatedRule)
            } else {
                Rule(0, "", null, emptyList(), emptyList())
            }
        }
    }

    fun initialize(groupValidationStore: ModelGroupValidationStore, ruleUniqueIdentifier: String) {
        groupValidationStore.data handledBy { group ->
            val updatedRule = group.rules?.find { it.uniqueIdentifier == ruleUniqueIdentifier }
            if (updatedRule != null) {
                initialRule = updatedRule
                update(updatedRule)
            } else {
                Rule(0, "", null, emptyList(), emptyList())
            }
        }
    }

    val resetChanges: Handler<MapRouter> = this.handle { currentRule, router ->
        if (currentRule.id == 0) {
            val updatedRules =
                groupValidationStore.current.rules?.filterNot { it.uniqueIdentifier == ruleUniqueIdentifier }?.toSet()
            groupValidationStore.update(groupValidationStore.current.copy(rules = updatedRules))
            router.navTo(
                mapOf(
                    "page" to Pages.groupCustomization,
                    "modelId" to ModelValidationStore.current.id.toString(),
                    "groupUniqueIdentifier" to groupValidationStore.current.uniqueIdentifier
                )
            )
            currentRule
        } else {
            initialRule?.let {
                router.navTo(
                    mapOf(
                        "page" to Pages.groupCustomization,
                        "modelId" to ModelValidationStore.current.id.toString(),
                        "groupUniqueIdentifier" to groupValidationStore.current.uniqueIdentifier
                    )
                )
                it
            } ?: initialRule!!
        }
    }

    val deleteRule: Handler<MapRouter> = this.handle { currentRule, router ->
        val modelId = ModelValidationStore.current.id
        val groupId = groupValidationStore.current.id
        val ruleId = currentRule.id

        if (modelId != 0 && groupId != 0 && ruleId != 0) {
            try {
                deleteRule(modelId, groupId, ruleId)
                val updatedRule = groupValidationStore.current.rules?.filterNot { it.id == ruleId }?.toSet()
                groupValidationStore.update(groupValidationStore.current.copy(rules = updatedRule))
                router.navTo(
                    mapOf(
                        "page" to Pages.groupCustomization,
                        "modelId" to modelId.toString(),
                        "groupUniqueIdentifier" to groupValidationStore.current.uniqueIdentifier
                    )
                )
            } catch (e: Exception) {
                toastError(e.message ?: "Delete rule failed")
            }
            currentRule
        } else {
            val updatedRule = groupValidationStore.current.rules?.filterNot { it.uniqueIdentifier == ruleUniqueIdentifier }?.toSet()
            groupValidationStore.update(groupValidationStore.current.copy(rules = updatedRule))
            router.navTo(
                mapOf(
                    "page" to Pages.groupCustomization,
                    "modelId" to modelId.toString(),
                    "groupUniqueIdentifier" to groupValidationStore.current.uniqueIdentifier
                )
            )
            currentRule
        }
    }

    val deleteRequirementFromPhrase: Handler<String> = this.handle { currentRule, combinationUniqueIdentifier ->
        val combinations = currentRule.combinations.toMutableList()
        val filteredCombinations =
            combinations.filter { combination -> combination.uniqueIdentifier != combinationUniqueIdentifier }
        val removedRule = currentRule.copy(combinations = filteredCombinations)
        removedRule
    }

    val addRequirementToPhrase: Handler<Unit> = this.handle { currentRule, _ ->
        val combinations = currentRule.combinations.toMutableList()

        if (combinations.filter { combination -> combination.type == CombinationType.TERM }.size == 1) {
            if (combinations.filter { combination -> combination.type == CombinationType.PHRASE }.size in 0..4) {
                combinations.add(
                    Combination(
                        0,
                        type = CombinationType.PHRASE,
                        words = listOf(Word(0, "", Language.NONE, caseSensitive = false, subStringSearch = false))
                    )
                )
                val copiedRule =
                    currentRule.copy(combinations = combinations, orderSensitivity = !currentRule.orderSensitivity)
                copiedRule
            } else {
                throw Exception("Invalid phrase. Phrase count not in range between 1 and 5")
            }
        } else {
            throw Exception("Invalid phrase. Missing single random term ")
        }
    }

    val saveRule: Handler<MapRouter> = this.handle { currentRule, router ->
        if (groupValidationStore.current.id != 0 && groupValidationStore.modelValidationStore.current.id != 0
            && validate(currentRule).valid
        ) {
            saveOrUpdateRule(
                currentRule,
                groupValidationStore.modelValidationStore.current.id,
                groupValidationStore.current.id,
                initialRule
            ).let { updatedRule ->
                update(updatedRule)
                syncWithGroupStore(updatedRule, router)
                router.navTo(
                    mapOf(
                        "page" to Pages.groupCustomization,
                        "modelId" to groupValidationStore.modelValidationStore.current.id.toString(),
                        "groupUniqueIdentifier" to groupValidationStore.current.uniqueIdentifier
                    )
                )
                updatedRule
            }
        } else {
            syncWithGroupStore(currentRule, router)
            router.navTo(
                mapOf(
                    "page" to Pages.groupCustomization,
                    "modelId" to groupValidationStore.modelValidationStore.current.id.toString(),
                    "groupUniqueIdentifier" to groupValidationStore.current.uniqueIdentifier
                )
            )
            currentRule
        }
    }

    private suspend fun saveOrUpdateRule(currentRule: Rule, modelId: Int, groupId: Int, initialRule: Rule?): Rule {
        return if (currentRule.id != 0) {
            initialRule?.let {
                getRule(modelId, groupId, currentRule.id).let { storedRule ->
                    if (!initialRule.isContentEqual(storedRule)) {
                        toast("error", classes = "errorToast") { +"Rule changed in between" }
                        return currentRule
                    }
                }
            }
            updateRule(modelId, groupId, currentRule.id, currentRule)
        } else {
            createRule(modelId, groupId, currentRule)
        }
    }

    private fun syncWithGroupStore(updatedRule: Rule, router: MapRouter) {
        val currentGroup = groupValidationStore.current
        val updatedRules = currentGroup.rules?.map { rule ->
            if (rule.uniqueIdentifier == ruleUniqueIdentifier) updatedRule else rule
        }?.toSet()
        groupValidationStore.update(currentGroup.copy(rules = updatedRules))
    }
}

fun RenderContext.modelCustomizationEdit(id: String, initialize: String, router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()

    val idInt = try {
        id.toInt()
    } catch (e: Exception) {
        0
    }

    val initBoolean = try {
        initialize.toBoolean()
    } catch (e: Exception) {
        false
    }

    if (initBoolean) ModelValidationStore.initialize(idInt)

    div("text-primary-10") {
        headingBanner(
            translationStore[UiCustomizationEdit.Heading],
            translationStore[UiCustomizationEdit.Description],
            "bg-heading-banner-4"
        )

        6
        val allEditable =
            AdminStore.data.combine(ModelValidationStore.data.map { it.isCustom }) { admin, isCustom ->
                admin || isCustom
            }

        val advancedSettings = storeOf(AdminStore.current)

        div {
            allEditable.render { isAllEditable ->
                div("flex justify-end gap-4 p-4 items-center") {
                    renderDeleteButton(isAllEditable, router)
                    renderAdvancedCheckbox(advancedSettings)
                }
                renderModelName(isAllEditable)
                renderGroups(isAllEditable, router)

                advancedSettings.data.renderIf({ it }) {
                    renderModelSettings()
                }

                renderSaveButton(isAllEditable, router)
            }
        }

    }
}

private fun RenderContext.renderSaveButton(allEditable: Boolean, router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()

    ModelValidationStore.data.render { model ->
        val disabled = !ModelValidationStore.isValid()
        div("flex justify-center items-center") {
            button(
                "bg-gradient-to-r from-darkest-0 to-primary-10 text-greyscale-100 font-semibold text-lg rounded-lg mb-4 px-10 py-3 " +
                        "hover:shadow-hover hover:bg-none hover:bg-darkest-0 focus-visible:bg-darkest-0 focus-visible:shadow-hover " +
                        "focus-visible:outline-none disabled:text-disabled disabled:cursor-none disabled:pointer-events-none " +
                        "disabled:bg-none disabled:bg-disabled-12 disabled:text-disabled mt-2"
            ) {
                disabled(disabled)
                if (allEditable && model.id == 0) {
                    translationStore[UiCustomizationEdit.SaveModel].renderText(this)
                } else if (allEditable && model.id != 0) {
                    translationStore[UiCustomizationEdit.UpdateModel].renderText(this)
                } else {
                    translationStore[UiCustomizationEdit.ValidateModel].renderText(this)
                }

                attr("aria-label", "save, update or validate model")
                if (allEditable && model.id == 0) {
                    clicks.map { router } handledBy ModelValidationStore.saveModel
                } else if (allEditable && model.id != 0) {
                    clicks.map { router } handledBy ModelValidationStore.updateModel
                } else {
                    clicks.map { router } handledBy ModelValidationStore.validateModel
                }
            }
        }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
            hidden(!disabled)
            translationStore[UiCustomizationEdit.DisabledMessage].renderText(this)
            arrow()
            placement = "top"
        }
    }
}

private fun RenderContext.renderGroups(allEditable: Boolean, router: MapRouter) {

    val groups = ModelValidationStore.map(Model.groups())
    val groupsLens: Lens<Set<Group>, List<Group>> = lensOf(
        "groupListCast",
        getter = { it.toList() },
        setter = { _, groupList -> groupList.toSet() }
    )
    val groupList = groups.map(groupsLens)

    div("bg-greyscale-70 rounded-lg p-4") {
        div("flex flex-col gap-4") {
            val groupIdProvider: (Group) -> String = { group -> "${group.id}-${group.uniqueIdentifier}" }
            groupList.data.renderEach(into = this, idProvider = groupIdProvider) { group: Group ->
                div("w-full") {
                    renderGroup(group, allEditable, router)
                }
            }

            renderAddGroupLink(router)
        }
    }
}

private fun RenderContext.renderAddGroupLink(router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()
    div {
        a(
            "flex items-center cursor-pointer " +
                    "hover:bg-greyscale-80 hover:rounded hover:shadow-[rgba(0,0,15,0.3)_0px_2px_2px_0px]"
        ) {
            attr("role", "link")
            tabIndex(0)
            img(
                "h-8 p-1 rounded aspect-square " +
                        "hover:bg-tertiary-50 hover:border hover:border-primary-10 focus-visible:bg-tertiary-50 " +
                        "focus-visible:border focus-visible:border-primary-10 focus-visible:outline-none"
            ) {
                src("../images/addGradient.svg")
                alt("add new group and switch to editing")
            }
            span("pl-2") {
                translationStore[UiCustomizationEdit.AddGroupLabel].renderText(this)
            }
            keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }
                .map { router } handledBy ModelValidationStore.addNewGroup
            clicks.map { router } handledBy ModelValidationStore.addNewGroup
        }
    }
}

private fun RenderContext.renderGroup(group: Group, allEditable: Boolean, router: MapRouter) {
    disclosure {
        disclosureButton(
            "flex justify-between gap-4 p-4 rounded w-full bg-primary-100 shadow-lg font-semibold text-lg " +
                    "items-center hover:shadow-hover focus-visible:shadow-hover focus-visible:outline-none"
        ) {
            div("flex gap-2 items-center") {
                div {
                    +group.name
                }
                div("rounded aspect-square w-8 bg-dynamic") {
                    this.domNode.unsafeCast<HTMLElement>().style.setProperty("--bg-color", "rgb(${group.color})")
                }
            }

            opened.render {

                div("flex gap-2 items-center") {
                    if (allEditable) {
                        editLink(group, router)
                    }
                    chevron(it)
                }
            }
        }
        disclosurePanel("text-left p-4 rounded-lg bg-greyscale-90 shadow-lg mt-2") {
            renderRules(group, router)
        }
    }
}

fun RenderContext.renderRules(group: Group, router: MapRouter) {
    div("flex justify-between gap-2 mb-2") {
        renderAddCriteriaLink(group, router)
        renderAddPhraseLink(group, router)
    }

    div("flex flex-wrap gap-2") {
        val groupedRules = if (AdminStore.current) {
            group.rules?.groupBy(Rule::label)
        } else {
            group.rules?.filter { it.owner?.id == PersonalIdStore.current }?.groupBy(Rule::label)
        }
        val sortedGroupedRules = groupedRules?.entries
            ?.sortedBy { it.key.uppercase() }
            ?.associate { it.toPair() }
        sortedGroupedRules?.forEach { (label, ruleList) -> renderLabelGroup(label, ruleList, group, router) }
    }
}

private fun RenderContext.renderAddCriteriaLink(group: Group, router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()
    div {
        a(
            "flex items-center cursor-pointer " +
                    "hover:bg-greyscale-80 hover:rounded hover:shadow-[rgba(0,0,15,0.3)_0px_2px_2px_0px]"
        ) {
            attr("role", "link")
            tabIndex(0)
            img(
                "h-8 p-1 rounded aspect-square " +
                        "hover:bg-tertiary-50 hover:border hover:border-primary-10 focus-visible:bg-tertiary-50 " +
                        "focus-visible:border focus-visible:border-primary-10 focus-visible:outline-none"
            ) {
                src("../images/addGradient.svg")
                alt("add new criteria and switch to editing")
            }
            span("pl-2") {
                translationStore[UiCustomizationEdit.AddCriteriaLabel].renderText(this)
            }
            keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }
                .map { Triple(group.uniqueIdentifier, false, router) } handledBy ModelValidationStore.addNewRule
            clicks.map { Triple(group.uniqueIdentifier, false, router) } handledBy ModelValidationStore.addNewRule
        }
    }
}

private fun RenderContext.renderAddPhraseLink(group: Group, router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()
    div {
        a(
            "flex items-center cursor-pointer " +
                    "hover:bg-greyscale-80 hover:rounded hover:shadow-[rgba(0,0,15,0.3)_0px_2px_2px_0px]"
        ) {
            attr("role", "link")
            tabIndex(0)
            span("pl-2") {
                translationStore[UiCustomizationEdit.AddPhraseLabel].renderText(this)
            }
            img(
                "h-8 p-1 rounded aspect-square " +
                        "hover:bg-tertiary-50 hover:border hover:border-primary-10 focus-visible:bg-tertiary-50 " +
                        "focus-visible:border focus-visible:border-primary-10 focus-visible:outline-none"
            ) {
                src("../images/addGradient.svg")
                alt("add new phrase and switch to editing")
            }
            keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }
                .map { Triple(group.uniqueIdentifier, true, router) } handledBy ModelValidationStore.addNewRule
            clicks.map { Triple(group.uniqueIdentifier, true, router) } handledBy ModelValidationStore.addNewRule
        }
    }
}

private fun RenderContext.renderLabelGroup(label: String, ruleList: List<Rule>, group: Group, router: MapRouter) {
    div("p-2 bg-greyscale-70 rounded-lg flex flex-col flex-1 shadow-md basis-auto") {
        div("font-semibold") { +label }
        div("flex gap-2 flex-wrap items-stretch flex-1") {
            ruleList.forEach { rule -> renderRule(rule, group, router) }
        }
    }
}

private fun RenderContext.renderRule(rule: Rule, group: Group, router: MapRouter) {
    div("p-1 bg-greyscale-90 shadow rounded-lg items-stretch flex-1 flex flex-col") {
        div("flex-1") {
            val ruleSplits = rule.getPreview().split("<br>")
            ruleSplits.forEach {
                div("mb-1") { +it }
            }
        }
        div("mt-2 flex justify-end") {
            ruleEditLink(rule, group, router)
        }
    }
}

private fun RenderContext.ruleEditLink(rule: Rule, group: Group, router: MapRouter) {
    val isPhrase =
        rule.combinations.firstOrNull { combination: Combination -> combination.type == CombinationType.PHRASE } != null

    a("p-6 contents cursor-pointer") {
        attr("role", "link")
        tabIndex(0)
        img(
            "h-8 p-1 rounded aspect-square " +
                    "hover:bg-tertiary-50 hover:border hover:border-primary-10 focus-visible:bg-tertiary-50 " +
                    "focus-visible:border focus-visible:border-primary-10 focus-visible:outline-none"
        ) {
            src("../images/edit.svg")
            alt("editLink to rule customization")
        }
        keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }.map {
            mapOf(
                if (isPhrase) "page" to Pages.phraseCustomization else "page" to Pages.criteriumCustomization,
                "modelId" to ModelValidationStore.current.id.toString(),
                "groupUniqueIdentifier" to group.uniqueIdentifier,
                "ruleUniqueIdentifier" to rule.uniqueIdentifier
            )
        } handledBy router.navTo
        clicks.map {
            mapOf(
                if (isPhrase) "page" to Pages.phraseCustomization else "page" to Pages.criteriumCustomization,
                "modelId" to ModelValidationStore.current.id.toString(),
                "groupUniqueIdentifier" to group.uniqueIdentifier,
                "ruleUniqueIdentifier" to rule.uniqueIdentifier
            )
        } handledBy router.navTo
    }
}

private fun RenderContext.editLink(group: Group, router: MapRouter) {
    a("p-6 contents cursor-pointer") {
        attr("role", "link")
        tabIndex(0)
        img(
            "h-8 p-1 rounded aspect-square " +
                    "hover:bg-tertiary-50 hover:border hover:border-primary-10 focus-visible:bg-tertiary-50 " +
                    "focus-visible:border focus-visible:border-primary-10 focus-visible:outline-none"
        ) {
            src("../images/edit.svg")
            alt("editLink to model group customization")
        }
        keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }.map {
            mapOf(
                "page" to Pages.groupCustomization,
                "modelId" to ModelValidationStore.current.id.toString(),
                "groupUniqueIdentifier" to group.uniqueIdentifier
            )
        } handledBy router.navTo
        clicks.map {
            mapOf(
                "page" to Pages.groupCustomization,
                "modelId" to ModelValidationStore.current.id.toString(),
                "groupUniqueIdentifier" to group.uniqueIdentifier
            )
        } handledBy router.navTo
    }
}

private fun RenderContext.renderAdvancedCheckbox(advancedSettings: Store<Boolean>) {
    val translationStore by koin.inject<TranslationStore>()

    div("flex justify-end gap-4") {
        div { translationStore[UiCustomizationEdit.AdvancedLabel].renderText(this) }
        input(
            "relative aspect-square lowercase border border-primary-10 w-6 " +
                    "hover:bg-tertiary-50 hover:shadow-checkbox-inset " +
                    "focus-visible:bg-tertiary-50 focus-visible:shadow-checkbox-inset focus-visible:outline-none " +
                    "aria-checked:bg-tertiary-50 aria-checked:shadow-checkbox-inset " +
                    "aria-checked:after:content-[''] aria-checked:after:absolute aria-checked:after:h-8px " +
                    "aria-checked:after:w-14px aria-checked:after:border-l-2 aria-checked:after:border-b-2 " +
                    "aria-checked:after:top-1 aria-checked:after:left-1 aria-checked:after:text-primary-10 " +
                    "aria-checked:after:-rotate-45 aria-checked-hover-bg-transparent aria-checked-focus-visible-bg-transparent"
        ) {
            type("checkbox")
            checked(advancedSettings.data)
            attr("aria-checked", advancedSettings.data.map { it.toString() })
            changes.states() handledBy advancedSettings.update
        }
    }
}

private fun RenderContext.renderModelName(allEditable: Boolean) {
    val translationStore by koin.inject<TranslationStore>()

    val name = ModelValidationStore.map(Model.name())
    val messages: Flow<List<Message>> = ModelValidationStore.messages

    div {
        if (allEditable) {
            textInput(translationStore[UiCustomizationEdit.NameLabel], name, messages)
        } else {
            textDisplay(translationStore[UiCustomizationEdit.NameLabel], name)
        }
    }
}

private fun RenderContext.renderModelSettings() {
    val translationStore by koin.inject<TranslationStore>()

    val compoundCombinatorics = ModelValidationStore.map(Model.modelSetting().compoundCombinatorics())
    val spanPriority = ModelValidationStore.map(Model.modelSetting().spanPriority())
    val screeningLanguage = ModelValidationStore.map(Model.modelSetting().screeningLanguage())

    val compoundCombinatoricsString: Store<String> = compoundCombinatorics.map(
        lensOf(
            "compoundCombinatoricsLens",
            getter = { combinatorics -> combinatorics.representation },
            setter = { _, representation -> CompoundCombinatorics.fromRepresentation(representation) }
        )
    )

    val spanPriorityString: Store<String> = spanPriority.map(
        lensOf(
            "spanPriorityLens",
            getter = { span -> span.representation },
            setter = { _, representation -> Span.fromRepresentation(representation) }
        )
    )

    disclosure("m-4") {
        disclosureButton(
            "flex justify-between p-4 rounded-lg w-full bg-primary-100 shadow-lg font-semibold text-lg " +
                    "items-center hover:shadow-hover focus-visible:shadow-hover focus-visible:outline-none"
        ) {
            translationStore[UiModelSettings.Heading].renderText()
            opened.render {
                chevron(it)
            }
        }
        disclosurePanel("text-left p-4 rounded-lg bg-greyscale-90 shadow-lg mt-2") {
            div("grid grid-cols-[1fr_1fr_1fr] gap-1") {
                dropdownSelector(
                    translationStore[UiModelSettings.CombinatoricsLabel],
                    compoundCombinatoricsString,
                    CompoundCombinatorics.entries.map { it.representation }.sorted()
                )

                dropdownSelector(
                    translationStore[UiModelSettings.SpanLabel],
                    spanPriorityString,
                    Span.entries.map { it.representation }
                )

                dropdownSelector(
                    translationStore[UiModelSettings.LanguageLabel],
                    screeningLanguage,
                    Language.entries.map { it.representation }.sorted()
                )
            }
        }
    }
}

private fun RenderContext.renderDeleteButton(
    allEditable: Boolean,
    router: MapRouter
) {
    val translationStore by koin.inject<TranslationStore>()

    ModelValidationStore.data.render { model ->
        val disabled = model.id == 0 || !allEditable

        val showModal = storeOf(false, Job())
        modal {
            router.data handledBy {
                if (it["page"] != Pages.modelCustomization) {
                    showModal.update(false)
                }
            }
            this.openState(showModal)
            modalPanel(modalDark) {
                modalAnimationOverlay()
                modalTitle(modalDarkTitle) { translationStore[UiCustomizationEdit.DeleteModalTitle].renderText(this) }
                modalDescription(modalDarkDescription) {
                    translationStore[UiCustomizationEdit.DeleteModalDescription].renderText(
                        this
                    )
                }
                div(modalDarkButtonContainer) {
                    button(modalDarkOk) {
                        setInitialFocus = InitialFocus.TryToSet
                        type("button")
                        translationStore[UiCustomizationEdit.DeleteModalOk].renderText(this)
                        clicks.map { router } handledBy ModelValidationStore.deleteModel
                        clicks.map { false } handledBy showModal.update
                    }

                    button(modalDarkCancel) {
                        type("button")
                        translationStore[UiCustomizationEdit.DeleteModalCancel].renderText(this)
                        clicks.map { false } handledBy showModal.update
                    }
                }
            }
        }

        div("flex justify-end mr-4") {
            div {
                button(iconTextButtonUnderlined) {
                    disabled(disabled)
                    attr("aria-disabled", disabled.toString())
                    trashCanSvg(disabled)
                    clicks.map { true } handledBy showModal.update
                    translationStore[UiCustomizationEdit.DeleteModel].renderText()
                }
            }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
                hidden(!disabled)
                translationStore[UiCustomizationEdit.DeleteDisabledMessage].renderText(this)
                arrow()
                placement = "left"
            }
        }
    }
}