package api

import dev.fritz2.headless.components.toast
import js.buffer.ArrayBuffer
import js.typedarrays.Int8Array
import kotlinx.coroutines.*
import org.w3c.files.File
import org.w3c.files.FileReader

suspend fun readFile(file: File): ByteArray {
    return CoroutineScope(Dispatchers.Default).promise {
        val completableFuture = CompletableDeferred<ByteArray>()
        val reader = FileReader()
        reader.onloadend = {
            val arrayBuffer = reader.result as ArrayBuffer
            val int8Array = Int8Array(arrayBuffer)
            completableFuture.complete(int8Array.toByteArray())
        }
        reader.onerror = {
            completableFuture.completeExceptionally(Exception((reader.error?.message.toBuilder() ?: "Unknown error") as? String))
        }
        reader.readAsArrayBuffer(file)
        completableFuture.await()
    }.await()
}

fun Int8Array.toByteArray(): ByteArray {
    return ByteArray(length) { index -> this[index] }
}