package api

import domain.repository.Feature
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*

suspend fun getFeatures(): List<Feature> {
    val response = bearerJsonClient.get(Feature.PATH)
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Retrieving features failed ${response.status}")
    }
}

suspend fun getFeature(id: String): Feature {
    val response = bearerJsonClient.get(Feature.PATH + "/$id")
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Retrieving feature failed ${response.status}")
    }
}

suspend fun postFeature(feature: Feature): Feature {
    val response = bearerJsonClient.post(Feature.PATH + "/${feature.id}") {
        contentType(ContentType.Application.Json)
        setBody(feature)
    }
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Deletion of feature ${feature.display} failed ${response.status}")
    }
}

suspend fun putFeature(feature: Feature): Feature {
    val response = bearerJsonClient.put(Feature.PATH + "/${feature.id}") {
        contentType(ContentType.Application.Json)
        setBody(feature)
    }
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Deletion of feature ${feature.display} failed ${response.status}")
    }
}

suspend fun duplicateFeature(feature: Feature): Boolean {
    val response = bearerJsonClient.get(Feature.PATH + "/${feature.id}/duplicate") {
        url {
            parameters.append("name", feature.name)
        }
    }
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Deletion of feature ${feature.display} failed ${response.status}")
    }
}

suspend fun deleteFeature(feature: Feature): Boolean {
    val response = bearerJsonClient.delete(Feature.PATH + "/${feature.id}") {
        contentType(ContentType.Application.Json)
        setBody(feature)
    }
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Deletion of feature ${feature.display} failed ${response.status}")
    }
}