package domain.contact


import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import dev.fritz2.validation.validation
import kotlinx.serialization.Serializable
import localization.homepage.UiTrial
import util.EmailValidator
import util.Message
import util.Severity
import util.StringValidator

@Lenses
@Serializable
data class ContactTrial(
    val firstname: String,
    val surname: String,
    val email: String,
    val company: String,
    val attentiveBy: String
) {
    companion object {
        const val PATH = "/contactTrial"

        val validation: Validation<ContactTrial, Unit, Message> = validation { inspector ->
            val firstname = inspector.map(ContactTrial.firstname())
            if (!validateFirstname(firstname.data)) {
                add(Message(firstname.path, Severity.Error, UiTrial.NameInvalid))
            }

            val surname = inspector.map(ContactTrial.surname())
            if (!validateSurname(surname.data)) {
                add(Message(surname.path, Severity.Error, UiTrial.SurnameInvalid))
            }

            val company = inspector.map(ContactTrial.company())
            if (!validateCompany(company.data)) {
                add(Message(company.path, Severity.Error, UiTrial.CompanyInvalid))
            }

            val email = inspector.map(ContactTrial.email())
            if (!validateEmail(email.data)) {
                add(Message(email.path, Severity.Error, UiTrial.EmailInvalid))
            }

            val attentiveBy = inspector.map(ContactTrial.attentiveBy())
            if(attentiveBy.data.trim().isBlank()) {
                add(Message(attentiveBy.path, Severity.Error, UiTrial.AttentiveByMissing))
            }
        }

        fun validate(contactTrial: ContactTrial): Boolean =
            validateFirstname(contactTrial.firstname) && validateSurname(contactTrial.surname)
                    && validateCompany(contactTrial.company) && validateEmail(contactTrial.email)
                    && validateAttentiveBy(contactTrial.attentiveBy)

        private fun validateFirstname(firstname: String): Boolean =
            firstname.isNotBlank() && StringValidator.isStringValid(firstname, 1, 100)

        private fun validateSurname(surname: String): Boolean =
            surname.isNotBlank() && StringValidator.isStringValid(surname, 1, 100)

        private fun validateCompany(company: String): Boolean =
            StringValidator.isStringValid(company, 0, 100)

        private fun validateEmail(email: String): Boolean = EmailValidator.isEmailValid(email.trim())

        private fun validateAttentiveBy(attentiveBy: String): Boolean =
            StringValidator.isStringValid(attentiveBy, 0, 100)

    }
}