package admin.navigation

import admin.sides.*
import api.navigateToPlatform
import dev.fritz2.core.*
import dev.fritz2.headless.components.toastContainer
import dev.fritz2.headless.foundation.portalRoot
import dev.fritz2.routing.MapRouter
import dev.fritz2.routing.routerOf
import icons.*
import koin
import kotlinx.browser.document
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import localization.components.UiRouting
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions
import react.FC
import react.Props

external interface RouterProps : Props

object Pages {
    const val home = "Home"
    const val userManagement = "User management"
    const val userEdit = "User"
    const val groupManagement = "Group management"
    const val groupEdit = "Group"
    const val directoryManagement = "Directory management"
    const val directoryEdit = "Directory"
    const val featureManagement = "Feature management"
    const val featureEdit = "Feature"
    const val screeningMonitor = "Screening monitor"
}

val AdminRouter = FC<RouterProps> {
    val router = routerOf(mapOf("page" to Pages.home, "id" to ""))

    val floaterInScrollStore: Store<Boolean> = storeOf(false, Job())
    val platformLinkStore: Store<Boolean> = storeOf(false, Job())

    val translationStore by koin.inject<TranslationStore>()

    render("#root") {
        div("flex flex-col-reverse min-h-screen sm:flex-row") {
            nav(
                "flex bg-darkest-0 text-greyscale-100 px-8 py-2 items-center sm:items-stretch sm:py-4 sm:px-4 justify-between " +
                        "sm:justify-start sm:gap-2 sm:w-20 xl:w-64 flex-row sm:flex-col"
            ) {
                attr("aria-expanded", "true")


                a(
                    "hidden sm:block xl:p-2 sm:cursor-pointer rounded-lg " +
                            "hover:outline hover:outline-2 hover:outline-primary-100" +
                            "focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary-100"
                ) {
                    attr("role", "link")
                    attr("aria-label", "navigation to ${Pages.home}")
                    tabIndex(0)

                    img("hidden w-18 h-18 sm:block xl:hidden") {
                        src("../images/logo_k.svg")
                        alt("keyspot Logo")
                    }
                    img("hidden w-48 h-20 xl:block") {
                        src("../images/logo_white.svg")
                        alt("keyspot Logo")
                    }

                    keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }.map {
                        mapOf("page" to Pages.home)
                    } handledBy router.navTo
                    clicks.map {
                        mapOf("page" to Pages.home)
                    } handledBy router.navTo
                }

                val linkClass =
                    "flex flex-col justify-center xl:justify-start sm:flex-row cursor-pointer items-center gap-1 sm:gap-4 text-xl rounded-lg " +
                            "focus:outline-none focus-visible:bg-primary-10 " +
                            "focus:visible:duration-500 hover:bg-primary-10 hover:duration-500 " +
                            "py-2 xl:px-4 xl:py-6"
                val spanClass = "block text-sm sm:hidden xl:inline-block xl:text-xl tracking-wide"
                a(linkClass) {
                    attr("role", "link")
                    attr("aria-label", "navigation to platform")
                    tabIndex(0)

                    platformIcon("w-6 h-6", "currentColor")

                    span(spanClass) {
                        +"Platform"
                    }

                    keydownsCaptured.filter { shortcutOf(it) == Keys.Enter } handledBy platformLinkStore.handle {
                        navigateToPlatform()
                        false
                    }
                    clicks handledBy platformLinkStore.handle {
                        navigateToPlatform()
                        false
                    }
                }
                aLink(
                    linkClass,
                    spanClass,
                    Pages.userManagement,
                    router,
                    translationStore[UiRouting.UserManagement],
                    RenderContext::userIcon
                )

                a("sm:hidden $linkClass") {
                    attr("role", "link")
                    attr("aria-label", "navigation to ${Pages.home}")
                    tabIndex(0)

                    img("w-12 aspect-square") {
                        src("../images/logo_k.svg")
                        alt("keyspot Logo")
                    }

                    keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }.map {
                        mapOf("page" to Pages.home)
                    } handledBy router.navTo
                    clicks.map {
                        mapOf("page" to Pages.home)
                    } handledBy router.navTo
                }

                aLink(
                    linkClass,
                    spanClass,
                    Pages.groupManagement,
                    router,
                    translationStore[UiRouting.GroupManagement],
                    RenderContext::groupIcon
                )
                aLink(
                    linkClass,
                    spanClass,
                    Pages.directoryManagement,
                    router,
                    translationStore[UiRouting.DirectoryManagement],
                    RenderContext::directoryIcon
                )
                aLink(
                    linkClass,
                    spanClass,
                    Pages.featureManagement,
                    router,
                    translationStore[UiRouting.FeatureManagement],
                    RenderContext::featureIcon
                )
                aLink(
                    linkClass,
                    spanClass,
                    Pages.screeningMonitor,
                    router,
                    translationStore[UiRouting.Monitoring],
                    RenderContext::featureIcon
                )
            }

            main("w-full p-4 grow", id = "main") {
                router.select("page").render(into = this) { (page, other) ->
                    when (page) {
                        Pages.home -> monitoring(router)
                        Pages.userManagement -> userManagement(router)
                        Pages.userEdit -> userEdit(other["id"])
                        Pages.groupManagement -> groupManagement(router)
                        Pages.groupEdit -> groupEdit(other["id"])
                        Pages.directoryManagement -> directoryManagement(router, other["id"])
                        Pages.directoryEdit -> directoryEdit(router, other["id"], other["parentId"])
                        Pages.featureManagement -> featureManagement(router)
                        Pages.featureEdit -> featureEdit(router, other["id"])
                        Pages.screeningMonitor -> monitoring(router)
                        else -> section {
                            h5 {
                                +"Page not found"
                            }
                        }
                    }
                }
            }
            button(
                "fixed right-4 bottom-24 rounded-full cursor-pointer h-12 w-12 text-primary-100 opacity-90 " +
                        "border border-darkest-0 bg-gradient-to-r from-darkest-0 to-primary-10 sm:hidden"
            ) {
                clicks handledBy floaterInScrollStore.handle {
                    val rootElement = document.documentElement
                    rootElement?.scrollTo(
                        ScrollToOptions(
                            top = 0.0,
                            behavior = ScrollBehavior.SMOOTH
                        )
                    )
                    false
                }
                toTopIcon("h-full w-full p-2", "#ADFF5A")
            }

            toastContainer("success", "toastContainer")
            toastContainer("progress", "toastContainer")
            toastContainer("error", "toastContainer")
            portalRoot()
        }
    }

}

fun RenderContext.aLink(
    className: String, spanClassStyle: String, routeTo: String, router: MapRouter, name: Flow<String>?,
    svgFunction: RenderContext.(String, String) -> SvgTag
) {
    a(className) {
        attr("role", "link")
        attr("aria-label", "navigation to $routeTo")
        tabIndex(0)

        svgFunction("w-6 h-6", "currentColor")

        span(spanClassStyle) {
            if (name != null) {
                name.renderText(this)
            } else {
                +routeTo
            }
        }

        keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }.map {
            mapOf("page" to routeTo)
        } handledBy router.navTo
        clicks.map {
            mapOf("page" to routeTo)
        } handledBy router.navTo
    }
}