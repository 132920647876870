// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.Boolean
import kotlin.Int
import kotlin.String

public fun ModelSetting.Companion.id(): Lens<ModelSetting, Int> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v)}
  )

public fun <PARENT> Lens<PARENT, ModelSetting>.id(): Lens<PARENT, Int> = this + ModelSetting.id()

public fun ModelSetting.Companion.compoundCombinatorics(): Lens<ModelSetting, CompoundCombinatorics>
    = lensOf(
    "compoundCombinatorics",
    { it.compoundCombinatorics },
    { p, v -> p.copy(compoundCombinatorics = v)}
  )

public fun <PARENT> Lens<PARENT, ModelSetting>.compoundCombinatorics():
    Lens<PARENT, CompoundCombinatorics> = this + ModelSetting.compoundCombinatorics()

public fun ModelSetting.Companion.spanPriority(): Lens<ModelSetting, Span> = lensOf(
    "spanPriority",
    { it.spanPriority },
    { p, v -> p.copy(spanPriority = v)}
  )

public fun <PARENT> Lens<PARENT, ModelSetting>.spanPriority(): Lens<PARENT, Span> = this +
    ModelSetting.spanPriority()

public fun ModelSetting.Companion.blockHighlighting(): Lens<ModelSetting, Boolean> = lensOf(
    "blockHighlighting",
    { it.blockHighlighting },
    { p, v -> p.copy(blockHighlighting = v)}
  )

public fun <PARENT> Lens<PARENT, ModelSetting>.blockHighlighting(): Lens<PARENT, Boolean> = this +
    ModelSetting.blockHighlighting()

public fun ModelSetting.Companion.screeningLanguage(): Lens<ModelSetting, String> = lensOf(
    "screeningLanguage",
    { it.screeningLanguage },
    { p, v -> p.copy(screeningLanguage = v)}
  )

public fun <PARENT> Lens<PARENT, ModelSetting>.screeningLanguage(): Lens<PARENT, String> = this +
    ModelSetting.screeningLanguage()

public fun ModelSetting.Companion.highlightingSetting(): Lens<ModelSetting, HighlightingSetting> =
    lensOf(
    "highlightingSetting",
    { it.highlightingSetting },
    { p, v -> p.copy(highlightingSetting = v)}
  )

public fun <PARENT> Lens<PARENT, ModelSetting>.highlightingSetting():
    Lens<PARENT, HighlightingSetting> = this + ModelSetting.highlightingSetting()
