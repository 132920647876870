package util

import com.tryformation.localization.Translatable
import dev.fritz2.validation.ValidationMessage

data class Message(override val path: String, val severity: Severity, val text: Translatable): ValidationMessage {
    override val isError: Boolean = severity > Severity.Warning
}

enum class Severity {
    Info,
    Warning,
    Error
}