package api

import domain.repository.Screening
import domain.repository.ScreeningDocument
import domain.repository.ScreeningHistory
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.utils.io.errors.*
import kotlinx.browser.window
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag

/*
Creates a new screening and returns it.
When creation of new screening failed exception is thrown.
Creation fails when the Auth Token is modified and userId is changed.
 */
suspend fun createScreening(screening: Screening): Screening {
    val response = bearerJsonClient.post(Screening.PATH) {
        contentType(ContentType.Application.Json)
        setBody(screening)
    }
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Creating screening failed ${response.status}")
    }
}

suspend fun getScreening(id: String): Screening? {
    val response = bearerJsonClient.get(Screening.PATH + "/$id") {
        contentType(ContentType.Application.Json)
    }
    return if (response.status == HttpStatusCode.OK) {
        response.body()
    } else {
        null
    }
}

/*
Updates a screening and returns the updated screening.
When the screening does not exist or update failed exception is thrown.
The update fails when the screening id exists in the database but is not associated with the user who sends the request.
 */
suspend fun updateScreening(screening: Screening): Screening {
    val response = bearerJsonClient.put(Screening.PATH + "/${screening.id}") {
        contentType(ContentType.Application.Json)
        setBody(screening)
    }
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Updating screening failed ${response.status}")
    }
}

suspend fun deleteScreening(screening: Screening): Boolean {
    val response = bearerJsonClient.delete(Screening.PATH + "/${screening.id}") {
        contentType(ContentType.Application.Json)
        setBody(screening)
    }
    if (response.status == HttpStatusCode.OK) {
        return true
    } else {
        throw Exception("Deleting screening failed ${response.status}")
    }
}

/*
Returns list of all screenings associated with a User. Should return the empty list when user has no screenings and the associated screening when he does have some.
 */
suspend fun getScreenings(): List<Screening> {
    val response = bearerJsonClient.get(Screening.PATH)
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Getting all screenings failed ${response.status}")
    }
}

/*
Returns all active screenings if admin
 */
suspend fun getActiveScreenings(): List<ScreeningHistory> {
    val response = bearerJsonClient.get(Screening.PATH + "/active")
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Getting all screenings failed ${response.status}")
    }
}

/**
 * Returns all screening
 */
suspend fun getScreeningHistory() {
    val response: HttpResponse = bearerJsonClient.get(Screening.PATH + "/history") {
        accept(ContentType.Application.Xlsx)
    }

    if (response.status == HttpStatusCode.OK) {
        val bytes = response.readBytes()
        val blob = Blob(arrayOf(bytes), BlobPropertyBag("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))

        val url = URL.createObjectURL(blob)
        val a = window.document.createElement("a") as HTMLAnchorElement
        a.href = url
        a.download = "screeningHistory.xlsx"
        a.click()
        URL.revokeObjectURL(url)
    } else {
        // Handle error response
        console.log("Error: ${response.status}")
    }
}

/*
Start screening given all files have been uploaded.
Expects list of uploaded files. These will be compared to files that have uploaded complete in backend.
 */
suspend fun startScreening(screening: Screening, screeningDocuments: List<Int>, modelsIds: List<Int>) {
    val response = bearerJsonClient.post(Screening.PATH + "/${screening.id}") {
        contentType(ContentType.Application.Json)
        setBody(Pair(screeningDocuments, modelsIds))
    }
    if (response.status == HttpStatusCode.NotAcceptable) {
        throw IOException("Model validation failed")
    }
    if (response.status != HttpStatusCode.OK) {
        throw Exception("Starting screening failed.")
    }
}