package localization.platform

import com.tryformation.localization.Translatable

enum class UiCreate : Translatable {
    Heading,
    Description,
    ProcessName,
    ProcessUpload,
    ProcessModel,
    Name,
    StateVirus,
    StateDuplicate,
    StateFinished,
    StateInvalid,
    StateReplaced,
    StateFailure,
    StateInProgress,
    QuickRules;

    override val prefix: String = "create"
}