package admin.sides

import admin.navigation.Pages
import admin.sides.stores.UsersFilterStore
import admin.sides.stores.UsersStore
import admin.sides.stores.userFiltering
import admin.sides.stores.userLoadMoreButton
import components.headingBanner
import dev.fritz2.core.*
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import dev.fritz2.routing.MapRouter
import domain.userManagement.UserFilter
import domain.userManagement.filter
import koin
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import localization.admin.UiUserManagement
import localization.platform.UiCustomizationEdit
import localization.platform.UiScreening
import util.Message


fun RenderContext.userManagement(router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()

    val msgs: Flow<List<Message>> = UsersFilterStore.messages
    val filter = UsersFilterStore.map(UserFilter.filter())


    headingBanner(
        translationStore[UiUserManagement.Heading],
        translationStore[UiUserManagement.Description],
        "bg-heading-banner-admin-1"
    )

    userFiltering(filter, msgs, UiUserManagement.SearchLabel, UiUserManagement.SearchButton, translationStore)

    div("uc-grouping") {
        div("uc-container") {
            UsersStore.data.renderEach(into = this) { user ->
                div("uc") {
                    div("uc-content") {
                        +user.username
                    }
                    div("uc-buttons") {

                        input(
                            "relative mx-4 aspect-square lowercase border border-primary-10 w-6 " +
                                    "hover:bg-tertiary-50 hover:shadow-checkbox-inset " +
                                    "focus-visible:bg-tertiary-50 focus-visible:shadow-checkbox-inset focus-visible:outline-none " +
                                    "aria-checked:bg-tertiary-50 aria-checked:shadow-checkbox-inset " +
                                    "aria-checked:after:content-[''] aria-checked:after:absolute aria-checked:after:h-8px " +
                                    "aria-checked:after:w-14px aria-checked:after:border-l-2 aria-checked:after:border-b-2 " +
                                    "aria-checked:after:top-1 aria-checked:after:left-1 aria-checked:after:text-primary-10 " +
                                    "aria-checked:after:-rotate-45 aria-checked-hover-bg-transparent aria-checked-focus-visible-bg-transparent"
                        ) {
                            type("checkbox")
                            checked(user.approved)
                            attr("aria-checked", user.approved.toString())

                            changes.states().map { isSelected ->
                                Pair(user.id, isSelected)
                            } handledBy {
                                UsersStore.approveUser(it)
                            }
                        }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
                            placement = PlacementValues.right
                            +"Approve user to login on keyspot"
                            arrow()
                        }

                        a("uc-contact-button") {
                            attr("role", "link")
                            tabIndex(0)
                            href("mailto:${user.email}")
                            +user.email
                        }
                        //link because of page switch
                        a("uc-edit-button") {
                            attr("role", "link")
                            tabIndex(0)
                            translationStore[UiUserManagement.EditButton].renderText(this)
                            clicks.map {
                                mapOf("page" to Pages.userEdit, "id" to user.id.toString())
                            } handledBy router.navTo
                        }
                    }
                }
            }

            userLoadMoreButton(UiUserManagement.LoadButton, "uc-load-more-button", translationStore)
        }
    }
}

