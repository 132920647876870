package domain.repository

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import domain.userManagement.Group
import kotlinx.serialization.Serializable
import localization.admin.UiFeatureEdit
import localization.admin.UiGroupEdit
import util.Message
import util.Severity

@Lenses
@Serializable
data class Feature(
    val id: Int,
    val name: String,
    val display: String
) {
    companion object {
        const val PATH = "/features"

        val validation: Validation<Feature, Unit, Message> = dev.fritz2.validation.validation { inspector ->
            val name = inspector.map(Feature.name())
            if (!validateName(name.data)) {
                add(Message(name.path, Severity.Error, UiFeatureEdit.NameValidation))
            }

            val display = inspector.map(Feature.display())
            if (!validateDisplay(display.data)) {
                add(Message(display.path, Severity.Error, UiFeatureEdit.DisplayValidation))
            }
        }

        fun validate(feature: Feature): Boolean = validateName(feature.name) && validateDisplay(feature.display)

        private fun validateName(name: String): Boolean = true

        private fun validateDisplay(name: String): Boolean = true
    }


}
