package domain.model

import dev.fritz2.core.Id
import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import kotlinx.serialization.Serializable
import util.Message

@Lenses
@Serializable
data class Word(
    val id: Int,
    val word: String,
    val stemming: Language,
    val caseSensitive: Boolean,
    val subStringSearch: Boolean,
    val type: WordType = WordType.TERM,

    val uniqueIdentifier: String = Id.next(16)
) {
    fun isContentEqual(other: Word): Boolean {
        if (this === other) return true
        if (word != other.word) return false
        if (stemming != other.stemming) return false
        if (caseSensitive != other.caseSensitive) return false
        if (subStringSearch != other.subStringSearch) return false
        if (type != other.type) return false
        return true
    }

    companion object {
        val validation: Validation<Word, Unit, Message> = dev.fritz2.validation.validation { inspector ->
        }
    }
}