package homepage.sides.indexComponents

import dev.fritz2.core.RenderContext
import dev.fritz2.core.alt
import dev.fritz2.core.src
import koin
import kotlinx.coroutines.flow.Flow
import localization.TranslationStore
import localization.homepage.UiBenefit

fun RenderContext.benefits() {
    val translationStore by koin.inject<TranslationStore>()

    article{

        div("headingArticle") {
            attr("aria-hidden", "true")
            h1 {
                translationStore[UiBenefit.Heading].renderText(this)
            }
            div("headingArticleUnderline") {
                attr("aria-hidden", "true")
            }
        }

        div("gap_2 content benefits") {
            benefit(
                "../images/money.svg", translationStore[UiBenefit.TimeHeading],
                translationStore[UiBenefit.TimeDesc]
            )
            benefit(
                "../images/warning.svg", translationStore[UiBenefit.RiskHeading],
                translationStore[UiBenefit.RiskDesc]
            )
            benefit(
                "../images/network.svg", translationStore[UiBenefit.CompetitivenessHeading],
                translationStore[UiBenefit.CompetitivenessDesc]
            )
        }
    }
}


fun RenderContext.benefit(iconUrl: String, header: Flow<String>, desc: Flow<String>) {
    div("benefit") {
        div("benefitIcon") {
            attr("aria-hidden", "true")
            img("icon") {
                attr("aria-hidden", "true")
                attr("role", "img")
                src(iconUrl)
                alt("")
            }
        }
        div("benefitContent") {
            h2("benefitHeader") {
                header.renderText(this)
            }
            p("benefitDescription") {
                desc.renderText(this)
            }
        }
    }
}