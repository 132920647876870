// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.authentication.login

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.String

public fun Login.Companion.email(): Lens<Login, String> = lensOf(
    "email",
    { it.email },
    { p, v -> p.copy(email = v)}
  )

public fun <PARENT> Lens<PARENT, Login>.email(): Lens<PARENT, String> = this + Login.email()

public fun Login.Companion.password(): Lens<Login, String> = lensOf(
    "password",
    { it.password },
    { p, v -> p.copy(password = v)}
  )

public fun <PARENT> Lens<PARENT, Login>.password(): Lens<PARENT, String> = this + Login.password()
