package domain.userManagement

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import dev.fritz2.validation.validation
import domain.model.ModelPermission
import domain.repository.Feature
import domain.repository.Permission
import kotlinx.serialization.Serializable
import localization.admin.UiGroupEdit
import util.Message
import util.Severity

@Lenses
@Serializable
data class Group(
    val id: Int,
    val name: String,
    val users: List<User> = emptyList(),
    val permissions: List<Permission> = emptyList(),
    val modelPermissions: List<ModelPermission> = emptyList(),
    val features: List<Feature> = emptyList()
) {
    companion object {
        const val PATH = "/groups"

        val validation: Validation<Group, Unit, Message> = validation { inspector ->
            val name = inspector.map(Group.name())
            if (validateName(name.data)) {
                add(Message(name.path, Severity.Error, UiGroupEdit.NameValidation))
            }
        }

        fun validate(group: Group): Boolean = validateName(group.name)

        private fun validateName(name: String): Boolean = false
    }
}