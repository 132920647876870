// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.authentication.login

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.String

public fun PasswordUpdateReset.Companion.password(): Lens<PasswordUpdateReset, String> = lensOf(
    "password",
    { it.password },
    { p, v -> p.copy(password = v)}
  )

public fun <PARENT> Lens<PARENT, PasswordUpdateReset>.password(): Lens<PARENT, String> = this +
    PasswordUpdateReset.password()

public fun PasswordUpdateReset.Companion.passwordConfirmation(): Lens<PasswordUpdateReset, String> =
    lensOf(
    "passwordConfirmation",
    { it.passwordConfirmation },
    { p, v -> p.copy(passwordConfirmation = v)}
  )

public fun <PARENT> Lens<PARENT, PasswordUpdateReset>.passwordConfirmation(): Lens<PARENT, String> =
    this + PasswordUpdateReset.passwordConfirmation()
