package domain.userManagement

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import dev.fritz2.validation.validation
import domain.model.ModelPermission
import domain.repository.Permission
import kotlinx.serialization.Serializable
import localization.admin.UiUserEdit
import util.Message
import util.Severity

@Lenses
@Serializable
data class UserApproval(
    var id: Int,
    val approval: Boolean,
) {
    companion object {
        const val PATH = "/approval"
    }
}
