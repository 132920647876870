// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.collections.List

public fun Combination.Companion.id(): Lens<Combination, Int> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v)}
  )

public fun <PARENT> Lens<PARENT, Combination>.id(): Lens<PARENT, Int> = this + Combination.id()

public fun Combination.Companion.connector(): Lens<Combination, Connector> = lensOf(
    "connector",
    { it.connector },
    { p, v -> p.copy(connector = v)}
  )

public fun <PARENT> Lens<PARENT, Combination>.connector(): Lens<PARENT, Connector> = this +
    Combination.connector()

public fun Combination.Companion.orderSensitivity(): Lens<Combination, Boolean> = lensOf(
    "orderSensitivity",
    { it.orderSensitivity },
    { p, v -> p.copy(orderSensitivity = v)}
  )

public fun <PARENT> Lens<PARENT, Combination>.orderSensitivity(): Lens<PARENT, Boolean> = this +
    Combination.orderSensitivity()

public fun Combination.Companion.type(): Lens<Combination, CombinationType> = lensOf(
    "type",
    { it.type },
    { p, v -> p.copy(type = v)}
  )

public fun <PARENT> Lens<PARENT, Combination>.type(): Lens<PARENT, CombinationType> = this +
    Combination.type()

public fun Combination.Companion.words(): Lens<Combination, List<Word>> = lensOf(
    "words",
    { it.words },
    { p, v -> p.copy(words = v)}
  )

public fun <PARENT> Lens<PARENT, Combination>.words(): Lens<PARENT, List<Word>> = this +
    Combination.words()

public fun Combination.Companion.uniqueIdentifier(): Lens<Combination, String> = lensOf(
    "uniqueIdentifier",
    { it.uniqueIdentifier },
    { p, v -> p.copy(uniqueIdentifier = v)}
  )

public fun <PARENT> Lens<PARENT, Combination>.uniqueIdentifier(): Lens<PARENT, String> = this +
    Combination.uniqueIdentifier()
