package localization.platform

import com.tryformation.localization.Translatable

enum class UiCustomizationPhraseEdit : Translatable {
    Heading,
    Description,
    NameLabel,
    PhraseLabel,
    DisabledMessage,
    SavePhrase,
    UpdatePhrase,
    BackPhrase,
    DonePhrase,
    DeletePhrase,
    DeleteModalTitle,
    DeleteModalDescription,
    DeleteModalOk,
    DeleteModalCancel,
    DeleteDisabledMessage,
    AddRequirement,
    DeleteRequirement;

    override val prefix: String = "customization-phrase-edit"

}