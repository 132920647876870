package localization.platform

import com.tryformation.localization.Translatable

enum class UiScreening : Translatable {
    Heading,
    Description,
    NameValidation,
    HeaderId,
    HeaderName,
    HeaderCreationDate,
    HeaderDeletionDate,
    HeaderState,
    HeaderAction,
    EditDescription,
    DownloadDescription,
    DeleteDescription,
    DeleteModalTitle,
    DeleteModalDescription,
    DeleteModalOk,
    DeleteModalCancel;

    override val prefix: String = "screening"
}