package api

import domain.wpn.PushSubscription
import domain.wpn.VapidKeys
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import js.buffer.ArrayBuffer
import kotlinx.browser.window
import org.khronos.webgl.Uint8Array

suspend fun getVapidKey(): VapidKeys {
    val response: HttpResponse = bearerJsonClient.get(PushSubscription.PATH)
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Getting vapid key failed ${response.status}")
    }
}

suspend fun postPush() {
    val response: HttpResponse = bearerJsonClient.post(PushSubscription.PATH)
    if (response.status != HttpStatusCode.OK) {
        throw Exception("Pushing notification failed ${response.status}")
    }
}

suspend fun subscribeToPush(pushSubscription: web.push.PushSubscription) {
    val dtoPushSubscription = fromWebPushSubscription(pushSubscription)
    val response: HttpResponse = bearerJsonClient.post(PushSubscription.PATH + PushSubscription.SUBSCRIBE) {
        contentType(ContentType.Application.Json)
        setBody(dtoPushSubscription)
    }
    if (response.status != HttpStatusCode.OK) {
        throw Exception("Subscribing to notification failed ${response.status}")
    }
}

suspend fun unsubscribeFromPush() {
    val response: HttpResponse = bearerJsonClient.post(PushSubscription.PATH + PushSubscription.UNSUBSCRIBE)
    if (response.status != HttpStatusCode.OK) {
        throw Exception("Unsubscribing from notification failed ${response.status}")
    }
}

suspend fun isSubscribed(pushSubscription: web.push.PushSubscription): Boolean {
    val dtoPushSubscription = fromWebPushSubscription(pushSubscription)
    val response: HttpResponse = bearerJsonClient.post(PushSubscription.PATH + PushSubscription.IS_SUBSCRIBE){
        contentType(ContentType.Application.Json)
        setBody(dtoPushSubscription)
    }
    return response.status == HttpStatusCode.OK
}

fun convertArrayBufferToBase64(arrayBuffer: ArrayBuffer): String {
    val byteArray = js.typedarrays.Uint8Array(arrayBuffer)
    var binary = ""
    byteArray.forEach { binary += it }
    return window.btoa(binary)
}

fun fromWebPushSubscription(webPushSubscription: dynamic): PushSubscription {
    val endpoint: String = webPushSubscription.endpoint as String
    val pushJson = webPushSubscription.toJSON()
    val p256dh = pushJson.keys.p256dh
    val auth = pushJson.keys.auth
    val keys = mapOf(
        "auth" to auth,
        "p256dh" to p256dh
    )
    return PushSubscription(endpoint, keys)
}

