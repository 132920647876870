package domain.model

enum class Connector(val representation: String) {
    AND("and"),
    OR("or"),
    NOR("nor"),
    POR("por"),
    NPOR("npor");

    companion object {
        fun fromRepresentation(representation: String): Connector {
            return Connector.values()
                .find { it.representation == representation } ?: OR
        }
    }
}