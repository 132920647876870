package platform.sides

import components.ModelSelector
import components.headingBanner
import dev.fritz2.core.RenderContext
import dev.fritz2.routing.MapRouter
import koin
import localization.TranslationStore
import localization.platform.UiCustomizationManagement
import util.Mode

fun RenderContext.modelCustomizationManagement(router: MapRouter) {

    val translationStore by koin.inject<TranslationStore>()
    val modelSelector = ModelSelector(mode = Mode.EDIT)

    div("w-full text-primary-10") {

        headingBanner(
            translationStore[UiCustomizationManagement.Heading],
            translationStore[UiCustomizationManagement.Description],
            "bg-heading-banner-3"
        )

        div("w-full mt-4") {
            div("bg-greyscale-90 rounded-lg py-4 px-8") {
                processLabel(translationStore[UiCustomizationManagement.ModelLabel])
                modelSelector.renderModelSelector(this, router)
            }
        }
    }
}
