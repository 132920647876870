package api

import domain.model.ModelPermission
import domain.repository.Permission
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*

suspend fun getPermissions(): List<Permission> {
    val response = bearerJsonClient.get(Permission.PATH)
    if(response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Retrieving permissions failed ${response.status}")
    }
}

suspend fun getModelPermissions(): List<ModelPermission> {
    val response = bearerJsonClient.get(ModelPermission.PATH)
    if(response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Retrieving permissions failed ${response.status}")
    }
}