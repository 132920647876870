package platform.sides

import components.*
import components.modal.modalAnimationOverlay
import dev.fritz2.core.*
import dev.fritz2.headless.components.modal
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.InitialFocus
import dev.fritz2.headless.foundation.utils.floatingui.core.middleware.offset
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import dev.fritz2.routing.MapRouter
import domain.model.*
import koin
import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import localization.TranslationStore
import localization.admin.UiDirectoryManagement
import localization.platform.UiCustomizationEdit
import localization.platform.UiCustomizationGroupEdit
import localization.platform.UiCustomizationPhraseEdit
import platform.navigation.AdminStore
import platform.navigation.Pages
import platform.navigation.PersonalIdStore
import utils.*

fun RenderContext.modelCustomizationPhraseEdit(
    modelId: String,
    groupUniqueIdentifier: String,
    ruleUniqueIdentifier: String,
    router: MapRouter
) {
    val translationStore by koin.inject<TranslationStore>()

    val groupStore = ModelValidationStore.getGroupStore(groupUniqueIdentifier)
    val ruleStore = ModelRuleValidationStore(groupStore, ruleUniqueIdentifier, defaultModelPhrase)
    MainScope().launch {
        groupStore.initialized.await()
        groupStore.data.first { it.rules != null }
        val newRuleStore = groupStore.getRuleStore(ruleUniqueIdentifier)
        ruleStore.update(newRuleStore.current)
        ruleStore.initialize(groupStore, newRuleStore.ruleUniqueIdentifier)
    }

    val label = ruleStore.map(Rule.label())
    val combinations = ruleStore.map(Rule.combinations())
    val msg = ruleStore.messages

    div("text-primary-10") {
        headingBanner(
            translationStore[UiCustomizationPhraseEdit.Heading],
            translationStore[UiCustomizationPhraseEdit.Description],
            "bg-heading-banner-4"
        )

        val allEditable =
            AdminStore.data.combine(ruleStore.data.map { (it.owner != null && it.owner.id == PersonalIdStore.current) }) { admin, isOwnRule ->
                admin || isOwnRule
            }

        val combinationIdProvider: (Combination) -> String =
            { combination -> combination.uniqueIdentifier + combination.words.size }
        val wordIdProvider: (Word) -> String = { word -> word.uniqueIdentifier }

        val phraseLens = lensOf<List<Combination>, List<Combination>>(
            "phraseLens",
            getter = { combinations -> combinations.filter { combination: Combination -> combination.type == CombinationType.PHRASE } },
            setter = { combinations, phrases ->
                combinations.map { original ->
                    phrases.find { it.uniqueIdentifier == original.uniqueIdentifier } ?: original
                }
            }
        )

        val phraseSizeLength = lensOf<List<Combination>, Int>(
            "phraseSizeLens",
            getter = { combinations -> combinations.size },
            setter = { combinations, _ -> combinations }
        )

        val phrasesStore = combinations.map(phraseLens)
        val phraseSizeStore = phrasesStore.map(phraseSizeLength)

        allEditable.render { isAllEditable ->
            renderDeleteButton(ruleStore, groupStore, isAllEditable, router)
            div {
                if (isAllEditable) {
                    textInput(translationStore[UiCustomizationPhraseEdit.NameLabel], label, msg)
                } else {
                    textDisplay(translationStore[UiCustomizationPhraseEdit.NameLabel], label)
                }

                div("bg-greyscale-70 rounded-lg pt-4 pb-4") {
                    phrasesStore.renderEach(combinationIdProvider) { phrase ->
                        val words = phrase.map(Combination.words())
                        val uniqueIdentifier = phrase.map((Combination.uniqueIdentifier()))
                        div {
                            words.renderEach(wordIdProvider, into = this) { word ->
                                val value = word.map(Word.word())
                                div {
                                    renderRemovePhrase(ruleStore, uniqueIdentifier.current)
                                    if (isAllEditable) {
                                        textareaInput(
                                            translationStore[UiCustomizationPhraseEdit.PhraseLabel],
                                            value,
                                            msg
                                        )
                                    } else {
                                        textDisplay(translationStore[UiCustomizationPhraseEdit.PhraseLabel], value)
                                    }
                                }
                            }
                        }
                    }

                    if (isAllEditable) {
                        phraseSizeStore.data.render {
                            if (it in 0..4) {
                                renderAddButton(ruleStore)
                            }
                        }
                    }

                }

                div("flex gap-4 justify-center") {
                    renderBackButton(ruleStore, router)
                    renderSaveButton(ruleStore, groupStore, modelId, isAllEditable, router)
                }
            }
        }
    }
}

private fun RenderContext.renderAddButton(ruleStore: ModelRuleValidationStore) {
    val translationStore by koin.inject<TranslationStore>()

    div( "px-4 w-full") {
        button(
            "flex items-center cursor-pointer " +
                    "hover:bg-greyscale-100 hover:rounded hover:shadow-[rgba(0,0,15,0.3)_0px_2px_2px_0px] " +
                    "focus-visible:outline-none focus-visible:bg-greyscale-100"
        ) {
            img(
                "h-8 p-1 rounded aspect-square " +
                        "hover:bg-tertiary-50 hover:border hover:border-primary-10 focus-visible:bg-tertiary-50 " +
                        "focus-visible:border focus-visible:border-primary-10 focus-visible:outline-none"
            ) {
                src("../images/addGradient.svg")
                alt("add new group and switch to editing")
            }
            span("pl-2") {
                translationStore[UiCustomizationPhraseEdit.AddRequirement].renderText(this)
            }
            keydownsCaptured.filter { shortcutOf(it) == Keys.Enter } handledBy ruleStore.addRequirementToPhrase
            clicks handledBy ruleStore.addRequirementToPhrase
        }
    }
}

private fun RenderContext.renderBackButton(
    ruleStore: ModelRuleValidationStore,
    router: MapRouter
) {
    val translationStore by koin.inject<TranslationStore>()

    ruleStore.data.render {
        div("flex justify-center items-center") {
            button(mainButton) {
                translationStore[UiCustomizationPhraseEdit.BackPhrase].renderText(this)
                attr("aria-label", "return to group without saving")
                clicks.map { router } handledBy ruleStore.resetChanges
            }
        }
    }

}

private fun RenderContext.renderSaveButton(
    ruleStore: ModelRuleValidationStore,
    groupStore: ModelGroupValidationStore,
    modelId: String,
    allEditable: Boolean,
    router: MapRouter
) {
    val translationStore by koin.inject<TranslationStore>()

    ruleStore.data.render { rule ->
        val disabled = !Rule.isValid(rule) || rule.combinations.find { it.type == CombinationType.PHRASE } == null ||
                (rule.combinations.find { it.type == CombinationType.PHRASE } != null && rule.combinations.none {
                    it.type == CombinationType.PHRASE && it.words.size == 1 && it.words[0].word != ""
                })

        div("flex justify-center items-center") {
            button(mainButton) {
                disabled(disabled)


                if (allEditable && modelId.toInt() != 0 && groupStore.current.id != 0 && ruleStore.current.id == 0) {
                    translationStore[UiCustomizationPhraseEdit.SavePhrase].renderText(this)
                } else if (allEditable && modelId.toInt() != 0 && groupStore.current.id != 0 && ruleStore.current.id != 0) {
                    translationStore[UiCustomizationPhraseEdit.UpdatePhrase].renderText(this)
                } else {
                    translationStore[UiCustomizationPhraseEdit.DonePhrase].renderText(this)
                }

                attr("aria-label", "save, update or validate rule")
                clicks.map { router } handledBy ruleStore.saveRule
            }
        }.tooltip(tooltip) {
            hidden(!disabled)
            translationStore[UiCustomizationPhraseEdit.DisabledMessage].renderText(this)
            arrow()
            placement = "top"
        }
    }
}

private fun RenderContext.renderRemovePhrase(ruleStore: ModelRuleValidationStore, combinationUniqueIdentifier: String) {
    val translationStore by koin.inject<TranslationStore>()
    div("flex justify-end mr-5 -mb-3") {
        div {
            button(iconTextButtonUnderlined) {
                trashCanSvg(false)
                clicks.map { combinationUniqueIdentifier } handledBy ruleStore.deleteRequirementFromPhrase
                translationStore[UiCustomizationPhraseEdit.DeleteRequirement].renderText()
            }
        }
    }
}

private fun RenderContext.renderDeleteButton(
    ruleStore: ModelRuleValidationStore,
    groupStore: ModelGroupValidationStore,
    allEditable: Boolean,
    router: MapRouter
) {
    val translationStore by koin.inject<TranslationStore>()

    ruleStore.data.render { rule ->
        //groups with only one rule cannot delete the rule and have to be removed completely
        val ruleSize = groupStore.current.rules?.filter { it.id != 0 }?.size
        val disabled = rule.id == 0 || !allEditable || ruleSize?.let { it <= 1 } ?: false

        val showModal = storeOf(false, Job())
        modal {
            router.data handledBy {
                if (it["page"] != Pages.phraseCustomization) {
                    showModal.update(false)
                }
            }
            this.openState(showModal)
            modalPanel(modalDark) {
                modalAnimationOverlay()
                modalTitle(modalDarkTitle) {
                    translationStore[UiCustomizationPhraseEdit.DeleteModalTitle].renderText(
                        this
                    )
                }
                modalDescription("my-2") {
                    translationStore[UiCustomizationPhraseEdit.DeleteModalDescription].renderText(
                        this
                    )
                }
                div(modalDarkButtonContainer) {
                    button(modalDarkOk) {
                        setInitialFocus = InitialFocus.TryToSet
                        type("button")
                        translationStore[UiCustomizationPhraseEdit.DeleteModalOk].renderText(this)
                        clicks.map { router } handledBy ruleStore.deleteRule
                        clicks.map { false } handledBy showModal.update
                    }

                    button(modalDarkCancel) {
                        type("button")
                        translationStore[UiCustomizationPhraseEdit.DeleteModalCancel].renderText(this)
                        clicks.map { false } handledBy showModal.update
                    }
                }
            }
        }

        div("flex justify-end mr-4") {
            div {
                button(iconTextButtonUnderlined) {
                    disabled(disabled)
                    attr("aria-disabled", disabled.toString())
                    trashCanSvg(disabled)
                    clicks.map { true } handledBy showModal.update
                    translationStore[UiCustomizationPhraseEdit.DeletePhrase].renderText()
                }
            }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
                hidden(!disabled)
                translationStore[UiCustomizationPhraseEdit.DeleteDisabledMessage].renderText(this)
                arrow()
                placement = "left"
            }
        }
    }
}



