package api

import domain.repository.ScreeningDocument
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.http.Headers
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.File

/*
TODO: return document with state to handle duplicate
Creates a ScreeningDocument. For a new Screening, where no ScreeningDocument directory exists, it creates the
directory and puts the ScreeningDocument there.
Documents are saved to the file system, metadata is written to the database (hybrid approach).
 */
suspend fun postScreeningDocument(screeningId: Int, file: File): Pair<HttpStatusCode, List<ScreeningDocument>> {
    val fileBytes = readFile(file)
    val response = bearerJsonClient.post(ScreeningDocument.PATH + "/" + screeningId) {
        contentType(ContentType.Application.Json)
        setBody(MultiPartFormDataContent(
            formData {
                append("screeningId", screeningId)
                append("fileName", file.name)
                append("file", fileBytes, Headers.build {
                    append(HttpHeaders.ContentType, ContentType.Application.OctetStream)
                    append(HttpHeaders.ContentDisposition, "filename=\"${file.name}\"")
                })
            }
        ))
    }
    return if (response.status == HttpStatusCode.OK) {
        Pair(response.status, response.body<List<ScreeningDocument>>())
    } else {
        Pair(response.status, emptyList())
    }
}

/*
Gets all Screening Documents associated with the provided screeningId.
 */
suspend fun getAllScreeningDocuments(screeningId: Int): List<ScreeningDocument> {
    val response = bearerJsonClient.get(ScreeningDocument.PATH + "/" + screeningId)
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Retrieving screening document failed ${response.status}")
    }
}

suspend fun getDocuments(screeningIds: List<Int>): Map<Int, List<ScreeningDocument>> {
    val response = bearerJsonClient.get(ScreeningDocument.PATH + ScreeningDocument.MULTIPLE_PATH) {
        contentType(ContentType.Application.Json)
        parameter("screeningIds", screeningIds.joinToString(","))
    }
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Retrieving screening documents failed ${response.status}")
    }
}

/*
Deletes a Screening Document given a screeningId and a fileName.
 */
suspend fun deleteScreeningDocument(screeningId: Int, fileName: String) {
    bearerJsonClient.delete(ScreeningDocument.PATH + "/" + screeningId) {
        contentType(ContentType.Application.Json)
        parameter("fileName", fileName)
    }
}

suspend fun getResultsZip(screeningId: Int) {
    val response: HttpResponse = bearerJsonClient.get {
        url(ScreeningDocument.PATH + "/" + screeningId + ScreeningDocument.RESULTS_PATH)
        accept(ContentType.Application.Zip)
    }

    if (response.status == HttpStatusCode.OK) {
        val bytes = response.readBytes()
        val blob = Blob(arrayOf(bytes), BlobPropertyBag("application/zip"))

        val url = URL.createObjectURL(blob)
        val a = window.document.createElement("a") as HTMLAnchorElement
        a.href = url
        a.download = "results.zip"
        a.click()
        URL.revokeObjectURL(url)
    } else {
        // Handle error response
        console.log("Error: ${response.status}")
    }
}
