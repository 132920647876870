package localization.platform

import com.tryformation.localization.Translatable

enum class UiModelSettings : Translatable {

    Heading,
    CombinatoricsLabel,
    SpanLabel,
    BlockHighlightingLabel,
    LanguageLabel,
    HighlightingLabel,
    LanguageMessage;

    override val prefix: String = "model-settings"
}