package platform.sides

import dev.fritz2.core.RenderContext
import koin
import localization.TranslationStore
import localization.platform.UiHome

fun RenderContext.index() {

    val translationStore by koin.inject<TranslationStore>()

    div("w-full flex flex-col py-4 px-4 md:py-20 md:px-10 rounded-lg bg-primary-100") {
        div ("text-5xl font-semibold"){
               translationStore[UiHome.Greeting].renderText(this)
        }
        div ("text-5xl font-semibold") {
            translationStore[UiHome.GreetingLocation].renderText(this)
        }
        div ("text-xl py-4") {
            div {
                translationStore[UiHome.Manual].renderText()
                a("px-2 text-primary-10 font-medium hover:underline focus-visible:underline focus-visible:outline-none") {
                    attr("href", "../files/keyspotManualBASE.pdf")
                    attr("target", "_blank")
                    translationStore[UiHome.ManualLink].renderText()
                }
            }
            div {
                translationStore[UiHome.Question].renderText()
                a("px-2 text-primary-10 font-medium hover:underline focus-visible:underline focus-visible:outline-none") {
                    attr("href", "mailto:info@keyspot.ai")
                    attr("target", "_blank")
                    translationStore[UiHome.QuestionLink].renderText()
                }
            }
            div {
                translationStore[UiHome.Homepage].renderText()
                a("px-2 text-primary-10 font-medium hover:underline focus-visible:underline focus-visible:outline-none") {
                    attr("href", "https://www.keyspot.ai")
                    attr("target", "_blank")
                    translationStore[UiHome.HomepageLink].renderText()
                }
            }
        }
        div {
            translationStore[UiHome.Bye].renderText(this)
        }
    }
}