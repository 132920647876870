package localization.homepage

import com.tryformation.localization.Translatable

enum class UiSolution : Translatable {
    Heading,
    InputHeading,
    InputWebserviceHeading,
    InputWebserviceDesc,
    InputFormatHeading,
    InputFormatDesc,
    InputEngineeringHeading,
    InputEngineeringDesc,
    PipelineHeading,
    PipelineAdvancedHeading,
    PipelineAdvancedDesc,
    PipelineRepositoryHeading,
    PipelineRepositoryDesc,
    PipelineModelsHeading,
    PipelineModelsDesc,
    OutputHeading,
    OutputHighlightedHeading,
    OutputHighlightedDesc,
    OutputRequirementHeading,
    OutputRequirementDesc,
    OutputCommentingHeading,
    OutputCommentingDesc;

    override val prefix: String = "solution"
}