package domain.authentication.token

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import kotlinx.serialization.Serializable
import localization.login.UiLogin
import util.Message
import util.Severity

@Lenses
@Serializable
data class Pin(
    val pin: String,
    val expirationTime: String
) {
    companion object {
        const val PATH = "/pin"


        val validation: Validation<Pin, Unit, Message> = dev.fritz2.validation.validation { inspector ->
            val pin = inspector.map(Pin.pin())
            if (!validatePin(pin.data)) {
                add(Message(pin.path, Severity.Error, UiLogin.PinInvalid))
            }
        }

        fun validate(pin: Pin): Boolean = validatePin(pin.pin)

        private fun validatePin(pin: String): Boolean {
            val pinRegex = "^[0-9]{6}$".toRegex()
            return pinRegex.matches(pin)
        }
    }
}
