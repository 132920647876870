package domain.util

enum class FileType(val mimeType: String, val fileEnding: String) {

    //Application
    PDF("application/pdf", "pdf"),
    XML("application/xml", "xml"),
    MSG("application/vnd.ms-outlook", "msg"),
    EML("message/rfc822", "eml"),
    DOC("application/msword", "doc"),
    DOCX("application/vnd.openxmlformats-officedocument.wordprocessingml.document", "docx"),
    XLS("application/vnd.ms-excel", "xls"),
    XLSX("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "xlsx"),
    TXT("text/plain", "txt"),

    //ADD EML format

    //Compressed
    X_ZIP("application/x-zip-compressed", "zip"),
    ZIP("application/zip", "zip"),

    ALL("*/*", "*");

    companion object {
        fun mimeTypes(fileTypes: List<FileType>): String {
            return fileTypes.joinToString(",") { it.mimeType }
        }

        fun fileEndings(fileTypes: List<FileType>): String {
            return fileTypes.joinToString(",") { it.fileEnding }
        }

        fun screeningFileTypes(): List<FileType> = listOf(PDF, MSG, EML, DOC, DOCX, XLS, XLSX, TXT, ZIP, X_ZIP)

        fun modelFileTypes(): List<FileType> = listOf(XML)

        fun fromMimeType(mimeType: String): FileType? {
            return entries.firstOrNull { it.mimeType == mimeType }
        }
    }
}
