package localization

import com.tryformation.localization.Locale

enum class Locales (
    override val languageCode: String,
    override val countryCode: String?,
    override val aliases: Array<String>,
    ) : Locale {
    EN_US("en", "US", arrayOf("en-US", "en")),
    DE_DE("de", "DE", arrayOf("de-DE","de"));

    override val prefix = "locales"

    companion object {
        fun getByIdOrNull(id: String): Locales? {
            val values = values()
            return values.firstOrNull { it.id == id} ?: values.firstOrNull { id in it.aliases }
        }
    }
}