package localization.login

import com.tryformation.localization.Translatable

enum class UiPasswordReset :Translatable {

    Heading,
    Email,
    EmailInvalid,
    Button,
    Unsuccessful,
    Successful;

    override val prefix: String = "password-reset"
}