package api

import domain.userManagement.Role
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*

suspend fun getRoles(): List<Role> {

    val response = bearerJsonClient.get(Role.PATH) {}
    if(response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Retrieving groups failed ${response.status}")
    }
}