package localization.platform

import com.tryformation.localization.Translatable

enum class UiHome : Translatable {
    Greeting,
    GreetingLocation,
    Manual,
    ManualLink,
    Question,
    QuestionLink,
    Homepage,
    HomepageLink,
    Bye;

    override val prefix: String = "home"
}