package localization.platform

import com.tryformation.localization.Translatable

enum class UiCustomizationManagement : Translatable {
    Heading,
    Description,
    ModelLabel;

    override val prefix: String = "customization-management"
}