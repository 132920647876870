package policies

import dev.fritz2.core.RenderContext
import dev.fritz2.core.href
import koin
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.launch
import localization.TranslationStore
import localization.policies.UiCookie
import localization.policies.UiCorporateInformation

fun RenderContext.corporateInformation(styleClass: String, emailFlexClass: String) {
    val translationStore by koin.inject<TranslationStore>()

    article(styleClass) {
        h1("text-3xl font-semibold") {
            translationStore[UiCorporateInformation.Heading].renderText(this)
        }


        div("flex flex-col") {
            div { translationStore[UiCorporateInformation.Street].renderText(this) }
            div { translationStore[UiCorporateInformation.Location].renderText(this) }
            div { translationStore[UiCorporateInformation.Country].renderText(this) }
            div (emailFlexClass) {
                div { +"✉" }
                a(
                    "text-primary-20 font-bold underline visited:text-primary-10 " +
                            "hover:text-primary-20 hover:no-underline hover:outline-offset-2 hover:outline-primary-50"
                ) {
                    val contactEmail: Flow<String> = translationStore[UiCorporateInformation.ContactEmail]
                    MainScope().launch {
                        contactEmail.collect {
                            href("mailto:${it}")
                        }
                    }
                    translationStore[UiCorporateInformation.ContactEmail].renderText(this)
                }
            }
        }

        h2("text-xl font-semibold mt-8 mb-2") {
            translationStore[UiCorporateInformation.CeoHeading].renderText(this)
        }
        div("flex flex-col mt-2"){
            div { translationStore[UiCorporateInformation.CeoName].renderText(this) }
        }
        div("flex flex-col mt-2") {
            div { translationStore[UiCorporateInformation.CooName].renderText(this) }
            div { translationStore[UiCorporateInformation.CooPhone].renderText(this) }
            div(emailFlexClass) {
                div { +"✉" }
                a(
                    "text-primary-20 font-bold underline visited:text-primary-10 " +
                            "hover:text-primary-30 hover:no-underline hover:outline-offset-2 hover:outline-primary-50"
                ) {
                    val ceoEmail: Flow<String> = translationStore[UiCorporateInformation.CooEmail]
                    MainScope().launch {
                        ceoEmail.collect {
                            href("mailto:${it}")
                        }
                    }
                    translationStore[UiCorporateInformation.CooEmail].renderText(this)
                }
            }
        }
        div("flex flex-col mt-2"){
            div { translationStore[UiCorporateInformation.CtoName].renderText(this) }
        }

        h2("text-xl font-semibold mt-8 mb-2") {
            translationStore[UiCorporateInformation.TradeRegisterHeading].renderText(this)
        }
        div("flex flex-col") {
            div { translationStore[UiCorporateInformation.OfficeName].renderText(this) }
            div { translationStore[UiCorporateInformation.OfficeStreet].renderText(this) }
            div { translationStore[UiCorporateInformation.OfficeBlz].renderText(this) }
            div { translationStore[UiCorporateInformation.OfficeRoom].renderText(this) }
        }
        div("flex flex-col mt-2") {
            div { translationStore[UiCorporateInformation.TradeRegisterTaxNumber].renderText(this) }
            div { translationStore[UiCorporateInformation.TradeRegister].renderText(this) }
        }
    }
}