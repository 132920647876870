package domain.model

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import kotlinx.serialization.Serializable
import localization.platform.UiModel
import util.Message
import util.Severity

@Lenses
@Serializable
data class Model(
    val id: Int,
    val name: String,
    val path: String,

    val modelMetadata: ModelMetadata,
    val modelSetting: ModelSetting,

    val groups: Set<Group>,
    val isCustom: Boolean
) {
    fun isContentEqual(other: Model): Boolean {
        if(this === other) return true
        if(id != other.id) return false
        if(name!= other.name) return false
        if(path != other.path) return false
        if(modelMetadata != other.modelMetadata) return false
        if(modelSetting != other.modelSetting) return false
        if(!groups.isContentEqual(other.groups)) return false
        return true
    }

    private fun Set<Group>?.isContentEqual(other: Set<Group>): Boolean {
        if (this == null) return false
        if (this.size != other.size) return false

        val otherGroupsById = other.associateBy { it.id }
        return this.all { group ->
            val otherGroup = otherGroupsById[group.id] ?: return false
            group.isContentEqual(otherGroup)
        }
    }

    companion object {
        const val PATH = "/model"

        val validation: Validation<Model, Unit, Message> = dev.fritz2.validation.validation { inspector ->
            val name = inspector.map(Model.name())
            if (!isValidName(name.data)) {
                add(Message(name.path, Severity.Error, UiModel.NameMessage))
            }

            val groups = inspector.map(Model.groups())
            if (!areValidGroups(groups.data)) {
                add(Message(groups.path, Severity.Error, UiModel.GroupsMessage))
            }
        }

        fun isValid(model: Model): Boolean = isValidName(model.name) && areValidGroups(model.groups)

        private fun isValidName(name: String): Boolean = name.length < 50

        private fun areValidGroups(groups: Set<Group>): Boolean {
            if (groups.isEmpty()) {
                return false
            }
            groups.forEach { group: Group -> if (!Group.isValid(group)) {
                return false
            } }
            return true
        }
    }
}
