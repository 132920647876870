// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import domain.userManagement.User
import kotlin.Int
import kotlin.String
import kotlin.collections.Set

public fun Group.Companion.id(): Lens<Group, Int> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v)}
  )

public fun <PARENT> Lens<PARENT, Group>.id(): Lens<PARENT, Int> = this + Group.id()

public fun Group.Companion.name(): Lens<Group, String> = lensOf(
    "name",
    { it.name },
    { p, v -> p.copy(name = v)}
  )

public fun <PARENT> Lens<PARENT, Group>.name(): Lens<PARENT, String> = this + Group.name()

public fun Group.Companion.color(): Lens<Group, String> = lensOf(
    "color",
    { it.color },
    { p, v -> p.copy(color = v)}
  )

public fun <PARENT> Lens<PARENT, Group>.color(): Lens<PARENT, String> = this + Group.color()

public fun Group.Companion.rules(): Lens<Group, Set<Rule>?> = lensOf(
    "rules",
    { it.rules },
    { p, v -> p.copy(rules = v)}
  )

public fun <PARENT> Lens<PARENT, Group>.rules(): Lens<PARENT, Set<Rule>?> = this + Group.rules()

public fun Group.Companion.owner(): Lens<Group, User?> = lensOf(
    "owner",
    { it.owner },
    { p, v -> p.copy(owner = v)}
  )

public fun <PARENT> Lens<PARENT, Group>.owner(): Lens<PARENT, User?> = this + Group.owner()

public fun Group.Companion.uniqueIdentifier(): Lens<Group, String> = lensOf(
    "uniqueIdentifier",
    { it.uniqueIdentifier },
    { p, v -> p.copy(uniqueIdentifier = v)}
  )

public fun <PARENT> Lens<PARENT, Group>.uniqueIdentifier(): Lens<PARENT, String> = this +
    Group.uniqueIdentifier()
