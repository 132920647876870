package api

import dev.fritz2.headless.components.toast
import domain.model.ModelTree
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import util.Mode
import utils.toastError

suspend fun getModelTree(mode: Mode): ModelTree {
    val response = bearerJsonClient.get(ModelTree.PATH) {
        parameter("mode", mode.name)
    }
    if (response.status == HttpStatusCode.OK) {
        toast("success", 1000L, "successToast") { +"Document operation successful" }
        return response.body()
    } else {
        val errorMessage = "Error retrieving model tree: ${response.status.description}"
        toastError(errorMessage)
        throw Exception(errorMessage)
    }
}