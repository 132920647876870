package components

import org.w3c.files.File
import kotlin.js.Promise

external interface DataTransferItem {
    fun webkitGetAsEntry(): FileSystemEntry?
}

external interface FileSystemFileEntry : FileSystemEntry {
    fun file(successCallback: (File) -> Unit)
}

external interface FileSystemDirectoryEntry : FileSystemEntry {
    fun createReader(): FileSystemDirectoryReader
}

external interface FileSystemDirectoryReader {
    fun readEntries(successCallback: (Array<FileSystemEntry>) -> Unit)
}

external interface FileSystemEntry {
    val isFile: Boolean
    val isDirectory: Boolean
    val name: String
}

fun FileSystemFileEntry.filePromise(): Promise<File> {
    return Promise { resolve, _ ->
        this.file { file ->
            resolve(file)
        }
    }
}