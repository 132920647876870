package localization.login

import com.tryformation.localization.Translatable

enum class UiPasswordUpdate :Translatable {

    Heading,
    OldPassword,
    OldPasswordInvalid,
    Password,
    PasswordInvalid,
    PasswordConfirmation,
    PasswordConfirmationInvalid,
    PasswordEquality,
    Button,
    Unsuccessful,
    Successful,
    MissingToken;

    override val prefix: String = "password-update"
}