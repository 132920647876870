package domain.model

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import kotlinx.serialization.Serializable
import localization.platform.UiModelSettings
import util.Message
import util.Severity

@Lenses
@Serializable
data class ModelSetting(
    val id: Int,
    val compoundCombinatorics: CompoundCombinatorics = CompoundCombinatorics.NO_COVERAGE,
    val spanPriority: Span = Span.NARROW,
    val blockHighlighting: Boolean = false,
    val screeningLanguage: String = "none",
    val highlightingSetting: HighlightingSetting = HighlightingSetting.TRANSPARENT
) {
    constructor() : this(
        0,
        CompoundCombinatorics.NO_COVERAGE,
        Span.NARROW,
        false,
        "none",
        HighlightingSetting.TRANSPARENT
    )

    companion object {
        val validation: Validation<ModelSetting, Unit, Message> = dev.fritz2.validation.validation { inspector ->

            val screeningLanguage = inspector.map(ModelSetting.screeningLanguage())
            if (validateScreeningLanguage(screeningLanguage.data)) {
                add(Message(screeningLanguage.path, Severity.Error, UiModelSettings.LanguageMessage))
            }
        }

        fun validate(modelSetting: ModelSetting): Boolean = validateScreeningLanguage(modelSetting.screeningLanguage)

        private fun validateScreeningLanguage(screeningLanguage: String): Boolean {
            return !(screeningLanguage != "none" && Language.fromRepresentation(screeningLanguage) == Language.NONE)
        }

    }
}
