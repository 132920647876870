package login

import api.postPasswordReset
import components.defaultHeader
import components.textInput
import dev.fritz2.core.render
import dev.fritz2.core.storeOf
import dev.fritz2.headless.components.TabGroup
import dev.fritz2.headless.components.tabGroup
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.valid
import domain.authentication.login.PasswordReset
import domain.authentication.login.email
import koin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.first
import localization.TranslationStore
import localization.login.UiPasswordReset
import org.w3c.dom.HTMLDivElement
import react.FC
import react.Props
import util.Message
import utils.mainButton
import web.dom.document


external interface PasswordResetProps : Props

val PasswordResetPage = FC<PasswordResetProps> {
    val translationStore by koin.inject<TranslationStore>()

    render("#root") {
        defaultHeader()
        main(id = "main") {
            article("ta-ce") {
                tabGroup("tabGroup") {
                    tabList("tabList") {
                        tab("tabListBtn") { translationStore[UiPasswordReset.Heading].renderText(this) }
                    }
                    tabPanels("tabPanels") {
                        passwordResetPanel()
                    }
                }
            }
        }
    }
}

private val responseMsgStore = storeOf("", Job())

object PasswordResetStore : ValidatingStore<PasswordReset, Unit, Message>(
    PasswordReset(""),
    PasswordReset.validation,
    Unit, Job(),
    validateAfterUpdate = false
) {

    val translationStore by koin.inject<TranslationStore>()

    val save = PasswordResetStore.handle {
        if (PasswordResetStore.validate(it).valid) {
            PasswordResetStore.resetMessages()
            val response = postPasswordReset(PasswordReset(it.email))
            if(response.first().trim() == translationStore[UiPasswordReset.Successful].first().trim()) {
                document.getElementById("tabPanelResponse")?.classList?.add("tabPanelResponseInformation")
            }
            responseMsgStore.update(response.first())
            PasswordReset(it.email)
        } else it
    }
}

private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.passwordResetPanel() {
    val translationStore by koin.inject<TranslationStore>()

    val msgs: Flow<List<Message>> = PasswordResetStore.messages
    val email = PasswordResetStore.map(PasswordReset.email())

    panel("tabPanel") {
        div("tabPanelHeading") {
            attr("aria-hidden", "true")
            h1("tabHeading") {
                translationStore[UiPasswordReset.Heading].renderText(this)
            }
            div("tabPanelUnderline") {
                attr("aria-hidden", "true")
            }
        }

        div("tabPanelResponseError", "tabPanelResponse") {
            responseMsgStore.data.renderText()
        }

        div("tabPanelContent") {
            textInput(translationStore[UiPasswordReset.Email], email, msgs)

            button(mainButton, "loginButton") {
                translationStore[UiPasswordReset.Button].renderText(this)
                clicks handledBy PasswordResetStore.save
            }
        }
    }
}