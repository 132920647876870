package localization.policies

import com.tryformation.localization.Translatable

enum class UiAup  : Translatable {
    Heading,
    Description,
    CredentialsHeading,
    CredentialsDescription,
    CredentialsFirst,
    CredentialsSecond,
    CredentialsThird,
    CredentialsFourth,
    CredentialsFifth,
    ContentHeading,
    ContentDescription,
    ContentFirst,
    ContentSecond,
    ContentThird,
    ContentFourth,
    RestrictionHeading,
    RestrictionDescription,
    RestrictionFirst,
    RestrictionSecond,
    RestrictionThird,
    AbusiveHeading,
    AbusiveDescription,
    AbusiveFirst,
    AbusiveSecond,
    AbusiveThird,
    AbusiveFourth,
    SecurityHeading,
    SecurityDescription,
    SecurityFirst,
    SecuritySecond,
    SecurityThird,
    MonitoringHeading,
    MonitoringDescription,
    CopyrightHeading,
    CopyrightDescription;

    override val prefix: String = "aup"
}