package admin.sides

import admin.sides.stores.*
import api.getGroup
import api.groupNameDuplicateExists
import api.postGroup
import api.putGroup
import components.headingBanner
import components.textInput
import dev.fritz2.core.Handler
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.storeOf
import dev.fritz2.headless.components.TabGroup
import dev.fritz2.headless.components.checkboxGroup
import dev.fritz2.headless.components.tabGroup
import dev.fritz2.headless.components.toast
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.valid
import domain.model.ModelPermission
import domain.repository.Feature
import domain.repository.Permission
import domain.userManagement.*
import io.ktor.http.*
import koin
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flattenMerge
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import localization.admin.UiGroupEdit
import localization.admin.UiGroupManagement
import localization.admin.UiUserManagement
import org.w3c.dom.HTMLDivElement
import util.Message
import utils.groupIterableBy
import utils.mainButton

private val groupStore = object : ValidatingStore<Group, Unit, Message>(
    Group(0, "", emptyList(), emptyList()),
    Group.validation,
    Unit, Job(),
    validateAfterUpdate = false
) {

    val initialize: Handler<Long> = handle { _, id ->

        try {
            toast("success", 1000L, "successToast") {
                +"Retrieving of group successful"
            }
            if (id == 0L) {
                Group(0, "", emptyList(), emptyList())
            } else {
                getGroup(id)
            }
        } catch (e: Exception) {
            toast("error", classes = "errorToast") {
                e.message
            }
            Group(0, "", emptyList(), emptyList())
        }
    }

    val save: Handler<Long> = handle { group, id ->
        if (validate(group).valid) {
            resetMessages()

            try {
                val isDuplicateName = groupNameDuplicateExists(id, group.name)
                if (!isDuplicateName) {
                    val response = if (id == 0L) postGroup(id, group) else putGroup(id, group)
                    if (response.status == HttpStatusCode.OK) {
                        toast("success", 1000L, "successToast") {
                            +"Group operation successful"
                        }
                    } else {
                        toast("error", classes = "errorToast") {
                            +"Failed to update/create group: ${response.status}"
                        }
                    }
                } else {
                    toast("error", classes = "errorToast") {
                        +"Duplicate group name"
                    }
                }
            } catch (e: Exception) {
                toast("error", classes = "errorToast") {
                    +"Failed to check for duplicate"
                }
            }
            group
        } else group
    }
}

private val msgs: Flow<List<Message>> = groupStore.messages

private val translationStore by koin.inject<TranslationStore>()

fun RenderContext.groupEdit(id: String?) {

    if (id != null) {
        groupStore.initialize(id.toLong())
        PermissionsStore.initialize(true)
        ModelPermissionsStore.initialize(true)
        FeaturesStore.initialize(true)
        GroupsStore.initialize(true)
    }

    headingBanner(
        translationStore[UiGroupEdit.Heading],
        translationStore[UiGroupEdit.Description],
        "bg-heading-banner-admin-2"
    )


    tabGroup("mt-8") {
        tabList("bg-primary-100 rounded-lg flex justify-between") {
            tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                translationStore[UiGroupEdit.TabGroup].renderText(
                    this
                )
            }
            tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                translationStore[UiGroupEdit.TabUser].renderText(
                    this
                )
            }
            tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                translationStore[UiGroupEdit.TabPermission].renderText(
                    this
                )
            }
            tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                translationStore[UiGroupEdit.TabModelPermission].renderText(
                    this
                )
            }
            tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                translationStore[UiGroupEdit.TabFeature].renderText(
                    this
                )
            }
        }
        tabPanels("mt-8") {
            groupPanel()
            userPanel()
            permissionPanel()
            modelPermissionPanel()
            featurePanel()
        }

        div("w-full flex items-center justify-center") {
            button(mainButton) {
                clicks.map { id?.toLong() ?: 0 } handledBy groupStore.save
                translationStore[UiGroupEdit.SaveButton].renderText(this)
            }
        }
    }
}

private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.groupPanel() {
    val name = groupStore.map(Group.name())

    panel("tab-panel") {
        div("input-card") {
            textInput(translationStore[UiGroupEdit.NameLabel], name, msgs)
        }
    }
}


private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.userPanel() {
    val filter = UsersFilterStore.map(UserFilter.filter())
    val filterMsgs: Flow<List<Message>> = UsersFilterStore.messages

    val groupUsers: Store<List<User>> = groupStore.map(Group.users())

    panel("tab-panel") {
        userFiltering(filter, filterMsgs, UiUserManagement.SearchLabel, UiUserManagement.SearchButton, translationStore)
        div("card-container") {
            UsersStore.data.renderEach(into = this) { user ->
                div("user-card") {
                    div("user-card-name") {
                        +user.username
                    }
                    checkboxGroup("checkbox-group") {
                        value(groupUsers)
                        checkboxGroupLabel("checkbox-group-label") {}
                        checkboxGroupOption(user, classes = "checkbox-option") {
                            checkboxGroupOptionToggle("checkbox-option-toggle") {}
                        }
                    }
                }
            }
        }
        userLoadMoreButton(UiUserManagement.LoadButton, "uc-load-more-button", translationStore)
    }
}

private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.permissionPanel() {

    val groupedFlow = PermissionsStore.data.groupIterableBy { it.path }
    val groupPermissions: Store<List<Permission>> = groupStore.map(Group.permissions())

    panel("tab-panel") {
        div("card-container") {
            groupedFlow.render(into = this) { permissionMap ->
                permissionMap.forEach { (path, permissionList) ->
                    div("permission-card") {
                        div("permission-card-name") {
                            +path
                        }

                        checkboxGroup("checkbox-group") {
                            value(groupPermissions)
                            checkboxGroupLabel("checkbox-group-label") {}
                            permissionList.forEach { permission ->
                                checkboxGroupOption(permission, classes = "checkbox-option") {

                                    checkboxGroupOptionLabel("checkbox-option-label") {
                                        +permission.permissionType.name
                                    }
                                    checkboxGroupOptionToggle("checkbox-option-toggle") {}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.modelPermissionPanel() {

    val groupedFlow = ModelPermissionsStore.data.groupIterableBy { it.path }
    val groupModelPermissions: Store<List<ModelPermission>> = groupStore.map(Group.modelPermissions())

    panel("tab-panel") {
        div("card-container") {
            groupedFlow.render(into = this) { permissionMap ->
                permissionMap.forEach { (path, permissionList) ->
                    div("permission-card") {
                        div("permission-card-name") {
                            +path
                        }

                        checkboxGroup("checkbox-group") {
                            value(groupModelPermissions)
                            checkboxGroupLabel("checkbox-group-label") {}
                            permissionList.forEach { permission ->
                                checkboxGroupOption(permission, classes = "checkbox-option") {

                                    checkboxGroupOptionLabel("checkbox-option-label") {
                                        +permission.permissionType.name
                                    }
                                    checkboxGroupOptionToggle("checkbox-option-toggle") {}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.featurePanel() {

    val groupedFlow = FeaturesStore.data.groupIterableBy { it.display }
    val groupFeatures: Store<List<Feature>> = groupStore.map(Group.features())

    panel("tab-panel") {
        div("card-container") {
            groupedFlow.render(into = this) { featureMap ->
                featureMap.forEach { (display, featureList) ->
                    div("shadow-md border border-primary-10 rounded-lg bg-greyscale-100 text-left " +
                            "transition-all duration-300 hover:shadow-xl") {
                        div("permission-card-name") {
                            +display
                        }

                        checkboxGroup("flex justify-center items-center border-t border-primary-10 pt-1") {
                            value(groupFeatures)
                            checkboxGroupLabel("checkbox-group-label") {}
                            featureList.forEach { feature ->
                                checkboxGroupOption(feature, classes = "checkbox-option") {

                                    checkboxGroupOptionLabel("checkbox-option-label") {
                                        +feature.name
                                    }
                                    checkboxGroupOptionToggle("checkbox-option-toggle") {}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


