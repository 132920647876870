package localization.homepage

import com.tryformation.localization.Translatable

enum class UiTeam : Translatable {
    Heading,
    AboutUs,
    AboutUsDescriptionOne,
    AboutUsDescriptionTwo,
    OneName,
    OnePosition,
    TwoName,
    TwoPosition,
    ThreeName,
    ThreePosition;

    override val prefix: String = "team"
}