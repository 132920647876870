package homepage.sides

import dev.fritz2.core.RenderContext
import dev.fritz2.core.src
import koin
import localization.TranslationStore
import localization.homepage.UiContact
import localization.homepage.UiTeam

fun RenderContext.team() {
    val translationStore by koin.inject<TranslationStore>()

    article {
        h1("text-3xl font-semibold") {
            translationStore[UiTeam.Heading].renderText(this)
        }

        teamCore()
    }

    /*
    article ("mg-block-end-3") {
        h1("text-3xl font-semibold") {
            translationStore[UiTeam.AboutUs].renderText(this)
        }

        p ("about-us-desc"){
            translationStore[UiTeam.AboutUsDescriptionOne].renderText(this)
        }
        p ("about-us-desc"){
            translationStore[UiTeam.AboutUsDescriptionTwo].renderText(this)
        }
    }
     */
}

private fun RenderContext.teamCore() {
    div("teamContainer") {
        teamMember(UiTeam.ThreeName, UiTeam.ThreePosition, "../images/KoernerRene.webp")
        teamMember(UiTeam.OneName, UiTeam.OnePosition, "../images/FriedrichChristian.webp")
        teamMember(UiTeam.TwoName, UiTeam.TwoPosition, "../images/KeilMichael.webp")
    }
}

private fun RenderContext.teamMember(name: UiTeam, position: UiTeam, imageUrl: String) {
    val translationStore by koin.inject<TranslationStore>()

    div("px-4 flex flex-col items-center") {
        img("teamMemberImg") {
            src(imageUrl)
        }
        p("teamMemberName") {
            translationStore[name].renderText(this)
        }
        p("teamMemberPosition") {
            translationStore[position].renderText(this)
        }
    }
}