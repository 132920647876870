package policies

import dev.fritz2.core.RenderContext
import koin
import localization.TranslationStore
import localization.policies.UiLegal

fun RenderContext.legal(styleClass: String) {
    val translationStore by koin.inject<TranslationStore>()

    article(styleClass) {
        h1("text-3xl font-semibold") {
            translationStore[UiLegal.Heading].renderText(this)
        }

        div("my-8"){
            translationStore[UiLegal.First].renderText(this)
        }

        div("my-8"){
            translationStore[UiLegal.Second].renderText(this)
        }
    }
}