package components

import com.tryformation.localization.Translatable
import dev.fritz2.core.*
import dev.fritz2.headless.components.disclosure
import koin
import localization.TranslationStore
import utils.chevron

sealed class DisclosureText {
    data class TranslatableContent(val translatable: Translatable) : DisclosureText()
    data class StringContent(val string: String) : DisclosureText()
}

fun RenderContext.keyspotDisclosure(heading: DisclosureText, description: DisclosureText, content: (RenderContext.() -> Unit)? = null) {
    val translationStore by koin.inject<TranslationStore>()

    disclosure("m-4") {
        disclosureButton("flex justify-between p-4 rounded-lg w-full bg-primary-100 shadow-lg font-semibold text-lg " +
                "items-center hover:shadow-hover focus-visible:shadow-hover focus-visible:outline-none") {
            when(heading) {
                is DisclosureText.TranslatableContent -> translationStore[heading.translatable].renderText()
                is DisclosureText.StringContent -> + heading.string
            }
            opened.render {
                chevron(it)
            }
        }
        disclosurePanel("text-left p-4 rounded-lg bg-greyscale-90 shadow-lg mt-2") {
            div {
                when (description) {
                    is DisclosureText.TranslatableContent -> translationStore[description.translatable].renderText(this)
                    is DisclosureText.StringContent -> +description.string
                }
            }
            content?.invoke(this)
        }
    }
}

