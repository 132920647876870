package localization.admin

import com.tryformation.localization.Translatable

enum class UiFeatureEdit : Translatable {
    Heading,
    Description,
    NameLabel,
    NameValidation,
    NameDuplicate,
    DisplayLabel,
    DisplayValidation,
    SaveButton;

    override val prefix: String = "feature"
}