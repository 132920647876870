package domain.repository

enum class ScreeningState {
    DRAFT,
    PENDING,
    QUEUED,
    IN_PROGRESS,
    FINISHED,
    TIMEOUT,
    ERROR,
    DELETED
}