package util

class StringValidator {

    companion object {

        fun isStringValid(string: String, minLength: Int, maxLength: Int): Boolean {
            return string.length in minLength..maxLength
        }
    }
}