// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.repository

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.Int
import kotlin.String

public fun Feature.Companion.id(): Lens<Feature, Int> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v)}
  )

public fun <PARENT> Lens<PARENT, Feature>.id(): Lens<PARENT, Int> = this + Feature.id()

public fun Feature.Companion.name(): Lens<Feature, String> = lensOf(
    "name",
    { it.name },
    { p, v -> p.copy(name = v)}
  )

public fun <PARENT> Lens<PARENT, Feature>.name(): Lens<PARENT, String> = this + Feature.name()

public fun Feature.Companion.display(): Lens<Feature, String> = lensOf(
    "display",
    { it.display },
    { p, v -> p.copy(display = v)}
  )

public fun <PARENT> Lens<PARENT, Feature>.display(): Lens<PARENT, String> = this + Feature.display()
