package domain.model

import domain.model.CompoundCombinatorics.NO_COVERAGE

enum class Span(val representation: String) {
    NARROW("narrow"),
    MEDIUM("medium"),
    WIDE("wide"),
    ONE("1"),
    TWO("2"),
    FOUR("4"),
    FIVE("5"),
    SIX("6"),
    EIGHT("8"),
    NINE("9"),
    TEN("10"),
    ELEVEN("11"),
    TWELVE("12");

    companion object {
        fun fromRepresentation(representation: String): Span {
            return entries.find { it.representation == representation } ?: NARROW
        }
    }
}