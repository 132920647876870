package localization.admin

import com.tryformation.localization.Translatable

enum class UiGroupEdit : Translatable {
    Heading,
    Description,
    NameLabel,
    NameValidation,
    NameDuplicate,
    UserFilterLabel,
    UserFilterButton,
    TabUser,
    TabGroup,
    TabPermission,
    TabModelPermission,
    TabFeature,
    SaveButton;

    override val prefix: String = "group-edit"
}