package localization.components

import com.tryformation.localization.Translatable

enum class UiTextareaRuleConverter : Translatable {
    AddAllLabel,
    AddAllDescription,
    AddDescription,
    LanguageLabel;

    override val prefix: String = "textarea-rule-converter"
}