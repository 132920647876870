package admin.sides

import admin.navigation.Pages
import admin.sides.stores.FeaturesStore
import components.headingBanner
import components.modal.modalAnimationOverlay
import components.textInput
import dev.fritz2.core.*
import dev.fritz2.headless.components.dataCollection
import dev.fritz2.headless.components.modal
import dev.fritz2.headless.foundation.SortDirection
import dev.fritz2.headless.foundation.utils.scrollintoview.ScrollBehavior
import dev.fritz2.routing.MapRouter
import domain.repository.Document
import domain.repository.Feature
import koin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import localization.admin.UiFeatureManagement
import utils.*

fun RenderContext.featureManagement(router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()
    val filterStore = storeOf("")

    FeaturesStore.initialize(true)


    val showModal = RootStore(false, Job())
    val featureToDelete: Store<Feature?> = storeOf(null)
    val updateDeleteModalFeature: SimpleHandler<Feature> = showModal.handle { toggle, feature ->
        featureToDelete.update(feature)
        !toggle
    }

    deleteModal(showModal, featureToDelete, router)

    headingBanner(
        translationStore[UiFeatureManagement.Heading],
        translationStore[UiFeatureManagement.Description],
        "bg-heading-banner-admin-2"
    )

    dataCollection("mt-8") {
        data(FeaturesStore.data, Feature::id)

        div("grid grid-cols-[1fr_auto_auto]") {
            filterStore.data handledBy filterByText(Feature::display)

            div {
                textInput(translationStore[UiFeatureManagement.FilterLabel], filterStore, null)
            }
            dataCollectionSortButton(
                compareBy(Feature::display),
                compareByDescending(Feature::display),
                classes = "mt-4 mb-8 ml-4 mr-4 rounded-lg bg-gradient-to-r from-darkest-0 to-primary-10 " +
                        "text-greyscale-100 border border-primary-30 flex items-center " +
                        "hover:border-primary-10 hover:outline-none hover:cursor-pointer hover:shadow-hover " +
                        "focus-visible:border-primary-10 focus-visible:outline-none focus-visible:cursor-pointer focus-visible:shadow-lg"
            ) {
                direction.render {
                    when (it) {
                        SortDirection.NONE -> svg("w-8 aspect-square") {
                            content(
                                "<svg viewBox=\"0 0 64 64\" fill=\"currentColor\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xml:space=\"preserve\">\n" +
                                        "    <g transform=\"matrix(2.14725,0,0,2.14725,-36.709,-61.4031)\">\n" +
                                        "        <path d=\"M20.748,45.874L18.032,44.846L18.027,42.196L20.74,41.159C20.827,40.741 20.936,40.331 21.067,39.931L19.228,37.684L20.549,35.386L23.421,35.845C23.703,35.53 24.001,35.231 24.314,34.949L23.844,32.078L26.137,30.748L28.391,32.579C28.799,32.444 29.216,32.331 29.643,32.242L30.675,29.528L33.325,29.528L34.357,32.242C34.775,32.33 35.185,32.439 35.585,32.571L37.835,30.736L40.131,32.06L39.667,34.932C39.981,35.214 40.28,35.512 40.562,35.826L43.433,35.361L44.759,37.656L42.925,39.907C43.057,40.307 43.167,40.717 43.255,41.135L45.97,42.165L45.971,44.816L43.257,45.849C43.17,46.267 43.06,46.677 42.929,47.077L44.766,49.327L43.442,51.623L40.57,51.161C40.289,51.475 39.99,51.774 39.676,52.056L40.143,54.927L37.849,56.254L35.597,54.422C35.197,54.553 34.787,54.664 34.37,54.751L33.34,57.467L30.69,57.47L29.655,54.757C29.237,54.67 28.827,54.56 28.427,54.43L26.179,56.267L23.882,54.945L24.342,52.072C24.028,51.791 23.729,51.493 23.447,51.179L20.576,51.648L19.247,49.354L21.078,47.101C20.947,46.701 20.836,46.292 20.748,45.874ZM32,35.498C27.585,35.498 24,39.083 24,43.498C24,47.913 27.585,51.498 32,51.498C36.415,51.498 40,47.913 40,43.498C40,39.083 36.415,35.498 32,35.498Z\"/>\n" +
                                        "    </g>\n" +
                                        "</svg>\n"
                            )
                        }

                        SortDirection.ASC -> svg("w-8 aspect-square") {
                            content(
                                "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 24 24\" stroke-width=\"1.5\" stroke=\"currentColor\" class=\"w-6 h-6\">\n" +
                                        "    <path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M4.5 15.75l7.5-7.5 7.5 7.5\" />\n" +
                                        "</svg>"
                            )
                        }

                        SortDirection.DESC -> svg("w-8 aspect-square") {
                            content(
                                "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 24 24\" stroke-width=\"1.5\" stroke=\"currentColor\" class=\"w-6 h-6\">\n" +
                                        "    <path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M19.5 8.25l-7.5 7.5-7.5-7.5\" />\n" +
                                        "</svg>\n"
                            )
                        }
                    }
                }
            }

            a("mt-4 mb-8 ml-4 mr-4 px-4 rounded-lg bg-gradient-to-r from-darkest-0 to-primary-10 justify-center px-2" +
                    "text-greyscale-100 border border-primary-30 flex items-center text-greyscale-100 " +
                    "hover:border-primary-10 hover:outline-none hover:cursor-pointer hover:shadow-hover " +
                    "focus-visible:border-primary-10 focus-visible:outline-none focus-visible:cursor-pointer focus-visible:shadow-lg") {
                attr("role", "link")
                tabIndex(0)
                translationStore[UiFeatureManagement.NewButton].renderText(this)
                clicks.map {
                    mapOf("page" to Pages.featureEdit, "id" to "0")
                } handledBy router.navTo
            }
        }

        val featureIdProvider: (Feature) -> String = { feature -> "${feature.display}-${feature.name}-${feature.id}" }
        dataCollectionItems("p-4 bg-greyscale-70 rounded-lg") {
            scrollIntoView(ScrollBehavior.smooth)
            div("flex flex-row flex-wrap justify-between gap-8 pb-4") {
                items.renderEach(featureIdProvider, into = this) { feature: Feature ->
                    dataCollectionItem(item = feature, "flex-grow flex-shrink flex-basis-0 shadow-md " +
                            "transition-all text-left bg-greyscale-100 border border-primary-10 " +
                            "rounded-lg flex  items-center") {
                        div("px-4 py-1 text-lg font-semibold flex-grow") {
                            + feature.display
                            span("font-light text-sm") {
                                + " (${feature.name})"
                            }
                        }
                        a("trashButtonIcon border-l border-primary-10 cursor-pointer no-underline text-primary-10 " +
                                "items-center p-2 flex hover:outline-none hover:border-transparent hover:checkbox-inset hover:bg-tertiary-50 " +
                                "focus-visible:outline-none focus-visible:border-transparent focus-visible:checkbox-inset focus-visible:bg-tertiary-50") {
                            attr("role", "link")
                            tabIndex(0)
                            translationStore[UiFeatureManagement.DeleteButton].renderText(this)
                            clicks.map {
                                feature
                            } handledBy updateDeleteModalFeature
                        }
                        a("editButtonIcon border-l border-primary-10 cursor-pointer no-underline text-primary-10 " +
                                "items-center p-2 flex hover:outline-none hover:border-transparent hover:checkbox-inset hover:bg-tertiary-50 " +
                                "focus-visible:outline-none focus-visible:border-transparent focus-visible:checkbox-inset focus-visible:bg-tertiary-50") {
                            attr("role", "link")
                            tabIndex(0)
                            translationStore[UiFeatureManagement.EditButton].renderText(this)
                            clicks.map {
                                mapOf("page" to Pages.featureEdit, "id" to feature.id.toString())
                            } handledBy router.navTo
                        }
                    }
                }
            }
        }

    }
}

private fun deleteModal(toggle: Store<Boolean>, feature: Store<Feature?>, router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()

    modal {
        router.data handledBy {
            if(it["page"] != Pages.featureManagement) {
                toggle.update(false)
            }
        }

        this.openState(toggle)
        modalPanel(modalDark) {
            modalAnimationOverlay()

            modalTitle(modalDarkTitle) {
                translationStore[UiFeatureManagement.DeleteModalTitle].renderText(
                    this
                )
            }
            modalDescription(modalDarkDescription) {
                translationStore[UiFeatureManagement.DeleteModalDescription].renderText(this)
            }

            div(modalDarkButtonContainer) {
                button(modalDarkOk) {
                    type("button")
                    translationStore[UiFeatureManagement.DeleteModalDeleteButton].renderText(this)
                    clicks.map { feature.current!! } handledBy FeaturesStore.delete
                    clicks.map { false } handledBy toggle.update
                }

                button(modalDarkCancel) {
                    type("button")
                    translationStore[UiFeatureManagement.DeleteModalCancelButton].renderText(this)
                    clicks.map { false } handledBy toggle.update
                }
            }
        }
    }
}
