// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.repository

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlinx.datetime.LocalDateTime

public fun ScreeningHistory.Companion.id(): Lens<ScreeningHistory, Int> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v)}
  )

public fun <PARENT> Lens<PARENT, ScreeningHistory>.id(): Lens<PARENT, Int> = this +
    ScreeningHistory.id()

public fun ScreeningHistory.Companion.name(): Lens<ScreeningHistory, String> = lensOf(
    "name",
    { it.name },
    { p, v -> p.copy(name = v)}
  )

public fun <PARENT> Lens<PARENT, ScreeningHistory>.name(): Lens<PARENT, String> = this +
    ScreeningHistory.name()

public fun ScreeningHistory.Companion.state(): Lens<ScreeningHistory, ScreeningState> = lensOf(
    "state",
    { it.state },
    { p, v -> p.copy(state = v)}
  )

public fun <PARENT> Lens<PARENT, ScreeningHistory>.state(): Lens<PARENT, ScreeningState> = this +
    ScreeningHistory.state()

public fun ScreeningHistory.Companion.userMail(): Lens<ScreeningHistory, String> = lensOf(
    "userMail",
    { it.userMail },
    { p, v -> p.copy(userMail = v)}
  )

public fun <PARENT> Lens<PARENT, ScreeningHistory>.userMail(): Lens<PARENT, String> = this +
    ScreeningHistory.userMail()

public fun ScreeningHistory.Companion.creationDateTime(): Lens<ScreeningHistory, LocalDateTime?> =
    lensOf(
    "creationDateTime",
    { it.creationDateTime },
    { p, v -> p.copy(creationDateTime = v)}
  )

public fun <PARENT> Lens<PARENT, ScreeningHistory>.creationDateTime(): Lens<PARENT, LocalDateTime?>
    = this + ScreeningHistory.creationDateTime()

public fun ScreeningHistory.Companion.startDateTime(): Lens<ScreeningHistory, LocalDateTime?> =
    lensOf(
    "startDateTime",
    { it.startDateTime },
    { p, v -> p.copy(startDateTime = v)}
  )

public fun <PARENT> Lens<PARENT, ScreeningHistory>.startDateTime(): Lens<PARENT, LocalDateTime?> =
    this + ScreeningHistory.startDateTime()

public fun ScreeningHistory.Companion.deletionDateTime(): Lens<ScreeningHistory, LocalDateTime?> =
    lensOf(
    "deletionDateTime",
    { it.deletionDateTime },
    { p, v -> p.copy(deletionDateTime = v)}
  )

public fun <PARENT> Lens<PARENT, ScreeningHistory>.deletionDateTime(): Lens<PARENT, LocalDateTime?>
    = this + ScreeningHistory.deletionDateTime()

public fun ScreeningHistory.Companion.downloadDateTime(): Lens<ScreeningHistory, LocalDateTime?> =
    lensOf(
    "downloadDateTime",
    { it.downloadDateTime },
    { p, v -> p.copy(downloadDateTime = v)}
  )

public fun <PARENT> Lens<PARENT, ScreeningHistory>.downloadDateTime(): Lens<PARENT, LocalDateTime?>
    = this + ScreeningHistory.downloadDateTime()

public fun ScreeningHistory.Companion.numDocuments(): Lens<ScreeningHistory, Int?> = lensOf(
    "numDocuments",
    { it.numDocuments },
    { p, v -> p.copy(numDocuments = v)}
  )

public fun <PARENT> Lens<PARENT, ScreeningHistory>.numDocuments(): Lens<PARENT, Int?> = this +
    ScreeningHistory.numDocuments()

public fun ScreeningHistory.Companion.numPages(): Lens<ScreeningHistory, Int?> = lensOf(
    "numPages",
    { it.numPages },
    { p, v -> p.copy(numPages = v)}
  )

public fun <PARENT> Lens<PARENT, ScreeningHistory>.numPages(): Lens<PARENT, Int?> = this +
    ScreeningHistory.numPages()

public fun ScreeningHistory.Companion.numOcr(): Lens<ScreeningHistory, Int?> = lensOf(
    "numOcr",
    { it.numOcr },
    { p, v -> p.copy(numOcr = v)}
  )

public fun <PARENT> Lens<PARENT, ScreeningHistory>.numOcr(): Lens<PARENT, Int?> = this +
    ScreeningHistory.numOcr()

public fun ScreeningHistory.Companion.slot(): Lens<ScreeningHistory, Int?> = lensOf(
    "slot",
    { it.slot },
    { p, v -> p.copy(slot = v)}
  )

public fun <PARENT> Lens<PARENT, ScreeningHistory>.slot(): Lens<PARENT, Int?> = this +
    ScreeningHistory.slot()

public fun ScreeningHistory.Companion.models(): Lens<ScreeningHistory, List<String>> = lensOf(
    "models",
    { it.models },
    { p, v -> p.copy(models = v)}
  )

public fun <PARENT> Lens<PARENT, ScreeningHistory>.models(): Lens<PARENT, List<String>> = this +
    ScreeningHistory.models()
