package localization.policies

import com.tryformation.localization.Translatable

enum class UiPrivacy  : Translatable {
    Heading,
    Description,
    GeneralHeading,
    GeneralDescription,
    GeneralCompliance,
    GeneralResponsibleParty,
    GeneralCompany,
    GeneralCompanyStreet,
    GeneralCompanyBlz,
    GeneralCompanyCountry,
    GeneralCompanyEmail,
    GeneralDpo,
    GeneralDpoName,
    GeneralContactUs,
    CollectingHeading,
    CollectingDescription,
    CollectingDataCheck,
    CollectingInformationRequirement,
    CollectingInformationRequirementCreate,
    CollectingInformationRequirementDemo,
    CollectingInformationRequirementTrial,
    CollectingInformationRequirementContact,
    CollectingInformationRequirementEmail,
    CollectingCollected,
    CollectingCollectedName,
    CollectingCollectedAccountName,
    CollectingCollectedCompany,
    CollectingCollectedDepartment,
    CollectingCollectedEmail,
    CollectingUsage,
    CollectingUsageSecurity,
    CollectingUsageVerify,
    CollectingUsageResponse,
    CollectingUsageSupport,
    CollectingUsageManage,
    CollectingUsageEvaluate,
    CollectingUsageCommunication,
    CollectingProcess,
    CollectingProcessEnhance,
    CollectingProcessProtection,
    CollectingProcessAnalysis,
    CollectingDisclosure,
    Storage,
    StorageDescription,
    Rights,
    RightsDescription,
    RightsInformation,
    RightsRectification,
    RightsErasure,
    RightsRestriction,
    RightsObject,
    RightsData,
    RightsDisclosure,
    ControllerProcessor,
    ControllerProcessorDescription,
    DataProtection,
    DataProtectionDescription,
    Amendment,
    AmendmentDescription,
    AmendmentDisclosure,
    RightsSub;

    override val prefix: String = "privacy"
}