// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.userManagement

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import domain.model.ModelPermission
import domain.repository.Feature
import domain.repository.Permission
import kotlin.Int
import kotlin.String
import kotlin.collections.List

public fun Group.Companion.id(): Lens<Group, Int> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v)}
  )

public fun <PARENT> Lens<PARENT, Group>.id(): Lens<PARENT, Int> = this + Group.id()

public fun Group.Companion.name(): Lens<Group, String> = lensOf(
    "name",
    { it.name },
    { p, v -> p.copy(name = v)}
  )

public fun <PARENT> Lens<PARENT, Group>.name(): Lens<PARENT, String> = this + Group.name()

public fun Group.Companion.users(): Lens<Group, List<User>> = lensOf(
    "users",
    { it.users },
    { p, v -> p.copy(users = v)}
  )

public fun <PARENT> Lens<PARENT, Group>.users(): Lens<PARENT, List<User>> = this + Group.users()

public fun Group.Companion.permissions(): Lens<Group, List<Permission>> = lensOf(
    "permissions",
    { it.permissions },
    { p, v -> p.copy(permissions = v)}
  )

public fun <PARENT> Lens<PARENT, Group>.permissions(): Lens<PARENT, List<Permission>> = this +
    Group.permissions()

public fun Group.Companion.modelPermissions(): Lens<Group, List<ModelPermission>> = lensOf(
    "modelPermissions",
    { it.modelPermissions },
    { p, v -> p.copy(modelPermissions = v)}
  )

public fun <PARENT> Lens<PARENT, Group>.modelPermissions(): Lens<PARENT, List<ModelPermission>> =
    this + Group.modelPermissions()

public fun Group.Companion.features(): Lens<Group, List<Feature>> = lensOf(
    "features",
    { it.features },
    { p, v -> p.copy(features = v)}
  )

public fun <PARENT> Lens<PARENT, Group>.features(): Lens<PARENT, List<Feature>> = this +
    Group.features()
