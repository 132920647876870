package domain.authentication.login

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import dev.fritz2.validation.validation
import kotlinx.serialization.Serializable
import localization.login.UiPasswordUpdate
import util.Message
import util.Severity

@Lenses
@Serializable
data class PasswordUpdateReset(
    val password: String,
    val passwordConfirmation: String
) {
    companion object {

        const val PATH = "/confirm-reset-password"

        val validation: Validation<PasswordUpdateReset, Unit, Message> = validation { inspector ->
            val password = inspector.map(PasswordUpdateReset.password())
            if (validatePassword(password.data)) {
                add(Message(password.path, Severity.Error, UiPasswordUpdate.PasswordInvalid))
            }

            val passwordConfirmation = inspector.map(PasswordUpdateReset.passwordConfirmation())
            if (validatePasswordConfirmation(passwordConfirmation.data)) {
                add(Message(passwordConfirmation.path, Severity.Error, UiPasswordUpdate.PasswordConfirmationInvalid))
            }

            if (validateEquality(password.data, passwordConfirmation.data)) {
                add(Message(passwordConfirmation.path, Severity.Error, UiPasswordUpdate.PasswordEquality))
            }
        }

        fun validate(passwordUpdate: PasswordUpdateReset): Boolean = !validatePassword(passwordUpdate.password) &&
                !validatePasswordConfirmation(passwordUpdate.passwordConfirmation) &&
                !validateEquality(passwordUpdate.password, passwordUpdate.passwordConfirmation)


        private fun validatePassword(password: String): Boolean = password.trim().isBlank()
        private fun validatePasswordConfirmation(passwordConfirmation: String): Boolean =
            passwordConfirmation.trim().isBlank()

        private fun validateEquality(password: String, passwordConfirmation: String): Boolean =
            password != passwordConfirmation
    }
}
