package domain.contact


import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import dev.fritz2.validation.validation
import kotlinx.serialization.Serializable
import localization.homepage.UiContact
import util.EmailValidator
import util.Message
import util.Severity
import util.StringValidator

@Lenses
@Serializable
data class Contact(
    val firstname: String,
    val surname: String,
    val email: String,
    val company: String,
    val comment: String,
    val attentiveBy: String
) {
    companion object {
        const val PATH = "/contact"

        val validation: Validation<Contact, Unit, Message> = validation { inspector ->
            val firstname = inspector.map(Contact.firstname())
            if (!validateFirstname(firstname.data)) {
                add(Message(firstname.path, Severity.Error, UiContact.NameInvalid))
            }

            val surname = inspector.map(Contact.surname())
            if (!validateSurname(surname.data)) {
                add(Message(surname.path, Severity.Error, UiContact.SurnameInvalid))
            }

            val company = inspector.map(Contact.company())
            if (!validateCompany(company.data)) {
                add(Message(company.path, Severity.Error, UiContact.CompanyInvalid))
            }

            val email = inspector.map(Contact.email())
            if (!validateEmail(email.data)) {
                add(Message(email.path, Severity.Error, UiContact.EmailInvalid))
            }

            val comment = inspector.map(Contact.comment())
            if (!validateComment(comment.data)) {
                add(Message(comment.path, Severity.Error, UiContact.CommentInvalid))
            }

            val attentiveBy = inspector.map(Contact.attentiveBy())
            if (!validateAttentiveBy(attentiveBy.data)) {
                add(Message(attentiveBy.path, Severity.Error, UiContact.AttentiveByMissing))
            }
        }

        fun validate(contact: Contact): Boolean =
            validateFirstname(contact.firstname) && validateSurname(contact.surname)
                    && validateCompany(contact.company) && validateEmail(contact.email)
                    && validateComment(contact.comment) && validateAttentiveBy(contact.attentiveBy)

        private fun validateFirstname(firstname: String): Boolean =
            firstname.isNotBlank() && StringValidator.isStringValid(firstname, 1, 100)

        private fun validateSurname(surname: String): Boolean =
            surname.isNotBlank() && StringValidator.isStringValid(surname, 1, 100)

        private fun validateCompany(company: String): Boolean =
            StringValidator.isStringValid(company, 0, 100)

        private fun validateEmail(email: String): Boolean = EmailValidator.isEmailValid(email.trim())
        private fun validateComment(comment: String): Boolean =
            StringValidator.isStringValid(comment, 0, 1000)

        private fun validateAttentiveBy(attentiveBy: String): Boolean =
            StringValidator.isStringValid(attentiveBy, 0, 100)

    }
}