// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.collections.Set

public fun Model.Companion.id(): Lens<Model, Int> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v)}
  )

public fun <PARENT> Lens<PARENT, Model>.id(): Lens<PARENT, Int> = this + Model.id()

public fun Model.Companion.name(): Lens<Model, String> = lensOf(
    "name",
    { it.name },
    { p, v -> p.copy(name = v)}
  )

public fun <PARENT> Lens<PARENT, Model>.name(): Lens<PARENT, String> = this + Model.name()

public fun Model.Companion.path(): Lens<Model, String> = lensOf(
    "path",
    { it.path },
    { p, v -> p.copy(path = v)}
  )

public fun <PARENT> Lens<PARENT, Model>.path(): Lens<PARENT, String> = this + Model.path()

public fun Model.Companion.modelMetadata(): Lens<Model, ModelMetadata> = lensOf(
    "modelMetadata",
    { it.modelMetadata },
    { p, v -> p.copy(modelMetadata = v)}
  )

public fun <PARENT> Lens<PARENT, Model>.modelMetadata(): Lens<PARENT, ModelMetadata> = this +
    Model.modelMetadata()

public fun Model.Companion.modelSetting(): Lens<Model, ModelSetting> = lensOf(
    "modelSetting",
    { it.modelSetting },
    { p, v -> p.copy(modelSetting = v)}
  )

public fun <PARENT> Lens<PARENT, Model>.modelSetting(): Lens<PARENT, ModelSetting> = this +
    Model.modelSetting()

public fun Model.Companion.groups(): Lens<Model, Set<Group>> = lensOf(
    "groups",
    { it.groups },
    { p, v -> p.copy(groups = v)}
  )

public fun <PARENT> Lens<PARENT, Model>.groups(): Lens<PARENT, Set<Group>> = this + Model.groups()

public fun Model.Companion.isCustom(): Lens<Model, Boolean> = lensOf(
    "isCustom",
    { it.isCustom },
    { p, v -> p.copy(isCustom = v)}
  )

public fun <PARENT> Lens<PARENT, Model>.isCustom(): Lens<PARENT, Boolean> = this + Model.isCustom()
