package localization.platform

import com.tryformation.localization.Translatable

enum class UiCustomizationEdit : Translatable {
    Heading,
    Description,
    AdvancedLabel,
    NameLabel,
    GroupLabel,
    AddGroupLabel,
    AddCriteriaLabel,
    AddPhraseLabel,
    SaveModel,
    UpdateModel,
    ValidateModel,
    DisabledMessage,
    DeleteModel,
    DeleteModalTitle,
    DeleteModalDescription,
    DeleteModalOk,
    DeleteModalCancel,
    DeleteDisabledMessage;

    override val prefix: String = "customization-edit"

}