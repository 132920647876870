package localization.platform

import com.tryformation.localization.Translatable

enum class UiCustomizationGroupEdit : Translatable {
    Heading,
    Description,
    NameLabel,
    DisabledMessage,
    SaveGroup,
    UpdateGroup,
    BackGroup,
    DeleteGroup,
    DeleteModalTitle,
    DeleteModalDescription,
    DeleteModalOk,
    DeleteModalCancel,
    DeleteDisabledMessage,
    QuickRules;

    override val prefix: String = "customization-group-edit"

}