package localization.admin

import com.tryformation.localization.Translatable

enum class UiUserEdit : Translatable {

    Heading,
    Description,
    UsernameLabel,
    UsernameValidation,
    TabUser,
    TabGroup,
    TabPermission,
    TabModelPermission,
    TabRole,
    TabFeature,
    SaveButton;

    override val prefix: String = "user-edit"
}