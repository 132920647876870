import admin.navigation.AdminRouter
import api.postRefresh
import domain.authentication.login.PasswordReset
import domain.authentication.login.PasswordUpdateReset
import homepage.navigation.Router
import koinConfiguration.homepageModule
import kotlinx.browser.window
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import localization.TranslationStore
import login.LoginPage
import login.PasswordResetPage
import login.PasswordUpdateResetPage
import org.koin.core.context.GlobalContext
import org.koin.core.context.GlobalContext.startKoin
import platform.navigation.PlatformRouter
import react.create
import react.dom.client.createRoot
import web.dom.document

val koin get() = GlobalContext.get()

@OptIn(DelicateCoroutinesApi::class)
fun main() {
    GlobalScope.launch {
        startKoin {
            modules(homepageModule)
        }
        koin.declare(TranslationStore.load(fallback = "en-US"))

        when (window.location.pathname) {
            Site.HOMEPAGE.path -> accessHomepage()
            Site.PLATFORM.path -> accessPlatform()
            Site.ADMIN.path -> accessAdmin()
            Site.LOGIN.path -> accessLogin()
            Site.PASSWORD_RESET.path -> accessPasswordReset()
            Site.PASSWORD_RESET_CONFIRM.path -> accessPasswordResetConfirm()
            else -> accessHomepage()
        }
    }
}

enum class Site(val path: String) {
    HOMEPAGE("/"),
    PLATFORM("/platform"),
    ADMIN("/admin"),
    LOGIN("/login"),
    PASSWORD_RESET(PasswordReset.PATH),
    PASSWORD_RESET_CONFIRM(PasswordUpdateReset.PATH)
}

fun accessHomepage() {
    document.getElementById("root")?.let { createRoot(it).render(Router.create()) }
}

suspend fun accessPlatform() {
    postRefresh()
    document.getElementById("root")?.let { createRoot(it).render(PlatformRouter.create()) }
}

suspend fun accessAdmin() {
    postRefresh()
    document.getElementById("root")?.let { createRoot(it).render(AdminRouter.create()) }
}

fun accessLogin() {
    document.getElementById("root")?.let { createRoot(it).render(LoginPage.create()) }
}

fun accessPasswordReset() {
    document.getElementById("root")?.let { createRoot(it).render(PasswordResetPage.create()) }
}

fun accessPasswordResetConfirm() {
    document.getElementById("root")?.let { createRoot(it).render(PasswordUpdateResetPage.create()) }
}
