package localization.policies

import com.tryformation.localization.Translatable

enum class UiCorporateInformation  : Translatable {
    Heading,
    Street,
    Location,
    Country,
    ContactEmail,
    CeoHeading,
    CeoName,
    CooName,
    CooEmail,
    CooPhone,
    CtoName,
    OfficeName,
    OfficeStreet,
    OfficeBlz,
    OfficeRoom,
    TradeRegisterHeading,
    TradeRegisterTaxNumber,
    TradeRegister;

    override val prefix: String = "corporate"
}