package domain.model

enum class Language(val representation: String) {
    ENGLISH("english"),
    GERMAN("german"),
    FRENCH("french"),
    RUSSIAN("russian"),
    SPANISH("spanish"),
    DANISH("danish"),
    DUTCH("dutch"),
    FINNISH("finnish"),
    NORWEGIAN("norwegian"),
    SWEDISH("swedish"),
    PORTUGUESE("portuguese"),
    NONE("none");

    companion object {
        fun fromRepresentation(representation: String): Language {
            return Language.values()
                .find { it.representation == representation } ?: NONE
        }
    }
}