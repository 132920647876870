package koinConfiguration

import admin.sides.ScreeningHistoryService
import homepage.LanguageStore
import kotlinx.coroutines.MainScope
import org.koin.dsl.module
import platform.sides.ScreeningService
import utils.PollingUtility


val homepageModule = module {
    single { MainScope() }
    single { LanguageStore() }
    single { admin.LanguageStore() }

    factory { PollingUtility(get()) }

    single { ScreeningService(get(), get()) }
    single { ScreeningHistoryService(get(), get()) }

}