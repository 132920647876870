package components

import dev.fritz2.core.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.flow.map
import org.khronos.webgl.get
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLElement

data class RGB(val red: Int, val green: Int, val blue: Int) {
    override fun toString(): String = "$red,$green,$blue"
    fun toCssString(): String = "rgb($red, $green, $blue)"

    companion object {
        fun fromString(rgbString: String): RGB {
            val parts = rgbString.split(",").map { it.toInt() }
            return RGB(parts[0], parts[1], parts[2])
        }
    }
}

fun getColorFromImage(x: Int, y: Int, canvas: HTMLCanvasElement): RGB {
    val ctx = canvas.getContext("2d") as CanvasRenderingContext2D
    val imageData = ctx.getImageData(x.toDouble(), y.toDouble(), 1.0, 1.0).data
    return RGB(imageData[0].toInt(), imageData[1].toInt(), imageData[2].toInt())
}

enum class Placement {
    TOP, BOTTOM, LEFT, RIGHT
}

fun calculatePlacement(buttonRect: dynamic, popupRect: dynamic, preferredPlacement: Placement): Placement {
    val viewportHeight = window.innerHeight
    val viewportWidth = window.innerWidth

    return when (preferredPlacement) {
        Placement.BOTTOM -> if (buttonRect.bottom + popupRect.height <= viewportHeight) Placement.BOTTOM else Placement.TOP
        Placement.TOP -> if (buttonRect.top - popupRect.height >= 0) Placement.TOP else Placement.BOTTOM
        Placement.LEFT -> if (buttonRect.left - popupRect.width >= 0) Placement.LEFT else Placement.RIGHT
        Placement.RIGHT -> if (buttonRect.right + popupRect.width <= viewportWidth) Placement.RIGHT else Placement.LEFT
    }
}

fun RenderContext.renderColorPicker(colorStore: Store<String>, preferredPlacement: Placement = Placement.LEFT) {
    val popupOpenStore = storeOf(false)
    val tempColorStore = storeOf(colorStore.current)
    val rgbStore = tempColorStore.map(
        lensOf(
            "rgbLens",
            getter = { color -> RGB.fromString(color) },
            setter = { _, rgb -> rgb.toString() }
        )
    )

    div("relative") {
        val buttonId = Id.next()
        rgbStore.data.render { rgbValue ->
            button("w-14 rounded aspect-square bg-dynamic mb-4", id = buttonId) {
                this.domNode.unsafeCast<HTMLElement>().style.setProperty("--bg-color", rgbValue.toCssString())
                clicks.map { true } handledBy popupOpenStore.update
            }
        }
        popupOpenStore.data.render { isOpen ->
            if (isOpen) {
                div("absolute flex gap-4 border border-primary-10 rounded-lg bg-greyscale-100 p-2 z-50") {
                    afterMount { mountContext, _ ->
                        val domNode = mountContext.domNode as HTMLElement
                        val buttonNode = document.getElementById(buttonId) as HTMLElement
                        val buttonRect = buttonNode.getBoundingClientRect()
                        val popupRect = domNode.getBoundingClientRect()

                        val placement = calculatePlacement(buttonRect, popupRect, preferredPlacement)
                        when (placement) {
                            Placement.BOTTOM -> {
                                domNode.style.left = "${-(popupRect.height / 2)}px"
                                domNode.style.top = "${buttonRect.height + 16}px"
                            }

                            Placement.TOP -> {
                                domNode.style.left = "${-(popupRect.height / 2)}px"
                                domNode.style.top = "-16px"
                            }

                            Placement.LEFT -> {
                                domNode.style.left = "${-(popupRect.width + 16)}px"
                                domNode.style.right = "${(buttonRect.width + 16)}px"
                                domNode.style.top = "${-((popupRect.height/2) - (buttonRect.height/2))}px"
                            }

                            Placement.RIGHT -> {
                                domNode.style.left = "${(popupRect.width + 16)}px"
                                domNode.style.right = "${(buttonRect.width + 16)}px"
                                domNode.style.top = "${-((popupRect.height/2) - (buttonRect.height/2))}px"
                            }
                        }
                    }

                    canvas(id = "colorCanvas") {
                        height(256)
                        width(256)
                        val canvasElement = this.domNode

                        afterMount { _, _ ->
                            val ctx = canvasElement.getContext("2d") as CanvasRenderingContext2D
                            // Horizontal gradient for colors
                            val gradientH = ctx.createLinearGradient(0.0, 0.0, 256.0, 0.0)
                            gradientH.addColorStop(0.0, "rgb(255, 0, 0)")
                            gradientH.addColorStop(1.0 / 6.0, "rgb(255, 255, 0)")
                            gradientH.addColorStop(2.0 / 6.0, "rgb(0, 255, 0)")
                            gradientH.addColorStop(3.0 / 6.0, "rgb(0, 255, 255)")
                            gradientH.addColorStop(4.0 / 6.0, "rgb(0, 0, 255)")
                            gradientH.addColorStop(5.0 / 6.0, "rgb(255, 0, 255)")
                            gradientH.addColorStop(1.0, "rgb(255, 0, 0)")

                            ctx.fillStyle = gradientH
                            ctx.fillRect(0.0, 0.0, 256.0, 256.0)

                            // Vertical gradient for lightness and darkness
                            val gradientL = ctx.createLinearGradient(0.0, 0.0, 0.0, 256.0)
                            gradientL.addColorStop(0.0, "rgba(255, 255, 255, 1)")  // White at the top
                            gradientL.addColorStop(0.5, "rgba(255, 255, 255, 0)")  // Transparent in the middle
                            gradientL.addColorStop(0.5, "rgba(0, 0, 0, 0)")        // Transparent in the middle
                            gradientL.addColorStop(1.0, "rgba(0, 0, 0, 1)")        // Black at the bottom

                            ctx.fillStyle = gradientL
                            ctx.fillRect(0.0, 0.0, 256.0, 256.0)
                        }

                        clicks.map { event ->
                            val rect = canvasElement.getBoundingClientRect()
                            val x = event.clientX - rect.left.toInt()
                            val y = event.clientY - rect.top.toInt()
                            getColorFromImage(x, y, canvasElement).toString()
                        } handledBy tempColorStore.update
                    }

                    div("flex flex-col justify-between") {
                        div {
                            label { +"Red" }
                            input {
                                type("range")
                                min("0")
                                max("255")
                                value(rgbStore.data.map { it.red.toString() })
                                changes.values().map {
                                    RGB(
                                        it.toInt(),
                                        rgbStore.current.green,
                                        rgbStore.current.blue
                                    ).toString()
                                } handledBy tempColorStore.update
                            }
                            span { rgbStore.data.render(this) { it.red } }

                            label { +"Green" }
                            input {
                                type("range")
                                min("0")
                                max("255")
                                value(rgbStore.data.map { it.green.toString() })
                                changes.values().map {
                                    RGB(
                                        rgbStore.current.red,
                                        it.toInt(),
                                        rgbStore.current.blue
                                    ).toString()
                                } handledBy tempColorStore.update
                            }
                            span { rgbStore.data.render(this) { it.green } }

                            label { +"Blue" }
                            input {
                                type("range")
                                min("0")
                                max("255")
                                value(rgbStore.data.map { it.blue.toString() })
                                changes.values().map {
                                    RGB(
                                        rgbStore.current.red,
                                        rgbStore.current.green,
                                        it.toInt()
                                    ).toString()
                                } handledBy tempColorStore.update
                            }
                            span { rgbStore.data.render(this) { it.blue } }

                        }
                        div("flex justify-between gap-4") {
                            button("bg-gradient-to-r from-darkest-0 to-primary-10 text-greyscale-100 rounded-lg px-4 py-1" +
                                    "hover:shadow-hover hover:bg-none hover:bg-darkest-0 focus-visible:bg-darkest-0 focus-visible:shadow-hover " +
                                    "focus-visible:outline-none") {
                                +"Select"
                                clicks.map { tempColorStore.current } handledBy colorStore.update
                                clicks.map { false } handledBy popupOpenStore.update
                            }
                            button("bg-gradient-to-r from-greyscale-40 to-greyscale-60 text-greyscale-10 rounded-lg px-4 py-1 " +
                                    "hover:shadow-hover hover:bg-none hover:bg-greyscale-10 hover:text-greyscale-100 " +
                                    "focus-visible:bg-greyscale-70 focus-visible:shadow-hover focus-visible:text-greyscale-100" +
                                    "focus-visible:outline-none") {
                                +"Cancel"
                                clicks handledBy {
                                    tempColorStore.update(colorStore.current)
                                    popupOpenStore.update(false)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}