package localization.components

import com.tryformation.localization.Translatable

enum class UiFileInput : Translatable {
    Label,
    DragoverDefault,
    DragoverExtract,
    DragoverSize,
    DragoverType;

    override val prefix: String = "file-input"
}