package localization.components

import com.tryformation.localization.Translatable

enum class UiRouting : Translatable {
    Home,
    Faq,
    Contact,
    Team,
    Trial,
    TrialNav,
    TrialHero,
    ContactEmail,
    CorporateInformation,
    PrivacyPolicy,
    CookiePolicy,
    Tou,
    Aup,
    Login,
    Legal,
    UserManagement,
    GroupManagement,
    ModelManagement,
    DirectoryManagement,
    FeatureManagement,
    Monitoring;

    override val prefix: String = "routing"
}