package domain.model

import domain.repository.PermissionType
import kotlinx.serialization.Serializable

@Serializable
data class ModelPermission(
    val id: Int,
    val name: String,
    val path: String,
    val permissionType: PermissionType
) {
    companion object {
        const val PATH = "/modelPermissions"
    }
}
