package admin.sides.stores

import api.getModelPermissions
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.headless.components.toast
import domain.model.ModelPermission
import kotlinx.coroutines.Job

object ModelPermissionsStore: RootStore<List<ModelPermission>>(emptyList(), Job()) {
    val initialize: SimpleHandler<Boolean> = handle {_, _ ->
        try {
            toast("success", 1000L, "successToast") {
                +"Retrieving of permissions successful"
            }
            getModelPermissions()
        } catch (e: Exception) {
            toast("error", classes = "errorToast") {
                e.message
            }
            emptyList()
        }
    }
}