package localization.admin

import com.tryformation.localization.Translatable

enum class UiUserManagement : Translatable {

    Heading,
    Description,
    SearchLabel,
    SearchButton,
    LoadButton,
    EditButton,
    FilterValidation;

    override val prefix: String = "user-management"
}