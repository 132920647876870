package api

import domain.model.Group
import domain.model.Model
import domain.model.Rule
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getModel(id: Int): Model {
    val response: HttpResponse = bearerJsonClient.get("${Model.PATH}/$id")
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Getting model failed ${response.status}")
    }
}

suspend fun createModel(model: Model): Model {
    val response: HttpResponse = bearerJsonClient.post(Model.PATH) {
        contentType(ContentType.Application.Json)
        setBody(model)
    }
    if (response.status != HttpStatusCode.Created) {
        throw Exception("Creating model failed ${response.status}")
    } else {
        return response.body()
    }
}

suspend fun updateModel(id: Int, model: Model): Model {
    val response: HttpResponse = bearerJsonClient.put("${Model.PATH}/$id") {
        contentType(ContentType.Application.Json)
        setBody(model)
    }
    if (response.status != HttpStatusCode.OK) {
        throw Exception("Updating model failed ${response.status}")
    } else {
        return response.body()
    }
}

suspend fun validateModel(id: Int, model: Model) {
    val response: HttpResponse = bearerJsonClient.get("${Model.PATH}/$id/validate") {
        contentType(ContentType.Application.Json)
        setBody(model)
    }
    if (response.status != HttpStatusCode.OK) {
        if(response.status == HttpStatusCode.Conflict) {
            throw Exception("Model validation failed ${response.status} against persisted model")
        }
        throw Exception("Model validation failed ${response.status}")
    }
}



suspend fun deleteModel(id: Int) {
    val response: HttpResponse = bearerJsonClient.delete("${Model.PATH}/$id")
    if (response.status != HttpStatusCode.OK) {
        throw Exception("Deleting model failed ${response.status}")
    }
}

suspend fun createGroup(modelId: Int, group: Group): Group {
    val response: HttpResponse = bearerJsonClient.post("${Model.PATH}/$modelId${Group.PATH}") {
        contentType(ContentType.Application.Json)
        setBody(group)
    }
    if (response.status != HttpStatusCode.Created) {
        throw Exception("Creating group failed ${response.status}")
    } else {
        return response.body()
    }
}

suspend fun getGroup(modelId: Int, groupId: Int): Group {
    val response: HttpResponse = bearerJsonClient.get("${Model.PATH}/$modelId${Group.PATH}/$groupId")
    if (response.status != HttpStatusCode.OK) {
        throw Exception("Getting group failed ${response.status}")
    } else {
        return response.body()
    }
}

suspend fun updateGroup(modelId: Int, groupId: Int, group: Group): Group {
    val response: HttpResponse = bearerJsonClient.put("${Model.PATH}/$modelId${Group.PATH}/$groupId") {
        contentType(ContentType.Application.Json)
        setBody(group)
    }
    if (response.status != HttpStatusCode.OK) {
        throw Exception("Updating group failed ${response.status}")
    } else {
        return response.body()
    }
}

suspend fun deleteGroup(modelId: Int, groupId: Int) {
    val response: HttpResponse = bearerJsonClient.delete("${Model.PATH}/$modelId${Group.PATH}/$groupId")
    if (response.status != HttpStatusCode.OK) {
        throw Exception("Deleting group failed ${response.status}")
    }
}

suspend fun createRule(modelId: Int, groupId: Int, rule: Rule): Rule {
    val response: HttpResponse = bearerJsonClient.post("${Model.PATH}/$modelId${Group.PATH}/$groupId${Rule.PATH}") {
        contentType(ContentType.Application.Json)
        setBody(rule)
    }
    if (response.status != HttpStatusCode.Created) {
        throw Exception("Creating rule failed ${response.status}")
    } else {
        return response.body()
    }
}

suspend fun getRule(modelId: Int, groupId: Int, ruleId: Int): Rule {
    val response: HttpResponse =
        bearerJsonClient.get("${Model.PATH}/$modelId${Group.PATH}/$groupId${Rule.PATH}/$ruleId")
    if (response.status != HttpStatusCode.OK) {
        throw Exception("Getting rule failed ${response.status}")
    } else {
        return response.body()
    }
}

suspend fun updateRule(modelId: Int, groupId: Int, ruleId: Int, rule: Rule): Rule {
    val response: HttpResponse =
        bearerJsonClient.put("${Model.PATH}/$modelId${Group.PATH}/$groupId${Rule.PATH}/$ruleId") {
            contentType(ContentType.Application.Json)
            setBody(rule)
        }
    if (response.status != HttpStatusCode.OK) {
        throw Exception("Updating rule failed ${response.status}")
    } else {
        return response.body()
    }
}

suspend fun deleteRule(modelId: Int, groupId: Int, ruleId: Int) {
    val response: HttpResponse =
        bearerJsonClient.delete("${Model.PATH}/$modelId${Group.PATH}/$groupId${Rule.PATH}/$ruleId")
    if (response.status != HttpStatusCode.OK) {
        throw Exception("Deleting rule failed ${response.status}")
    }
}
