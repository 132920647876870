package components

import dev.fritz2.core.HtmlTag
import dev.fritz2.core.Store
import dev.fritz2.headless.components.listbox
import dev.fritz2.headless.foundation.utils.floatingui.core.middleware.flip
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import dev.fritz2.headless.foundation.utils.floatingui.utils.StrategyValues
import kotlinx.coroutines.flow.Flow
import org.w3c.dom.HTMLDivElement

fun HtmlTag<HTMLDivElement>.dropdownSelector(
    label: Flow<String>,
    valueStore: Store<String>,
    items: List<String>
) {

    listbox("dropdown-selector-container") {
        label("dropdown-selector-label") {
            label.renderText(this)
        }

        value(valueStore)

        listboxButton("dropdown-selector-button") {
            span { value.data.renderText() }
            svg ("dropdown-selector-icon"){

            }
        }

        listboxItems("dropdown-selector-item-container") {
            placement = PlacementValues.bottom
            strategy = StrategyValues.absolute
            addMiddleware(flip())

            items.forEach { entry ->
                listboxItem(entry, "dropdown-selector-item") {
                    attr("data-dropdown-selector-selected", selected, true.toString())
                    attr("data-dropdown-selector-active", active, true.toString())
                    attr("data-dropdown-selector-disabled", disabled, true.toString())
                    span { +entry }
                }
            }

        }
    }
}