package api

import domain.userManagement.Group
import domain.userManagement.GroupParameter
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun getGroups(parameter: GroupParameter): List<Group> {

    val response = bearerJsonClient.get(Group.PATH) {
        url {
            parameters.append("params", parameter.name)
        }
    }
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Retrieving groups failed ${response.status}")
    }
}

suspend fun getGroup(id: Long): Group {
    val response = bearerJsonClient.get(Group.PATH + "/${id}") {}
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Retrieving user failed ${response.status}")
    }
}

suspend fun putGroup(id: Long, group: Group): HttpResponse {
    return bearerJsonClient.put(Group.PATH + "/${id}") {
        contentType(ContentType.Application.Json)
        setBody(group)
    }
}

suspend fun postGroup(id: Long, group: Group): HttpResponse {
    return bearerJsonClient.post(Group.PATH + "/${id}") {
        contentType(ContentType.Application.Json)
        setBody(group)
    }
}

suspend fun groupNameDuplicateExists(id: Long, name: String): Boolean {
    val response = bearerJsonClient.get(Group.PATH + "/${id}" + "/duplicate") {
        url {
            parameters.append("name", name)
        }
    }
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Duplicate name failed ${response.status}")
    }
}