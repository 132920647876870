package policies

import com.tryformation.localization.Translatable
import components.DisclosureText
import components.keyspotDisclosure
import dev.fritz2.core.RenderContext
import koin
import localization.TranslationStore
import localization.policies.UiTermsOfUse

fun RenderContext.tou(styleClass: String) {
    val translationStore by koin.inject<TranslationStore>()

    article(styleClass) {
        h1("text-3xl font-semibold") {
            translationStore[UiTermsOfUse.Heading].renderText(this)
        }

        listOf(
            UiTermsOfUse.One to renderOne(),
            UiTermsOfUse.Two to renderTwo(),
            UiTermsOfUse.Three to renderThree(),
            UiTermsOfUse.Four to renderFour(),
            UiTermsOfUse.Five to renderFive(),
            UiTermsOfUse.Six to renderSix(),
            UiTermsOfUse.Seven to renderSeven(),
            UiTermsOfUse.Eight to renderEight(),
            UiTermsOfUse.Nine to renderNine(),
            UiTermsOfUse.Ten to renderTen(),
            UiTermsOfUse.ElevenHeading to renderEleven(),
            UiTermsOfUse.TwelveHeading to renderTwelve(),
            UiTermsOfUse.ThirteenHeading to renderThirteen(),
            UiTermsOfUse.Fourteen to renderFourteen()
        ).forEach { (heading, section) ->
            keyspotDisclosure(
                DisclosureText.TranslatableContent(heading),
                DisclosureText.StringContent(""),
                section
            )
        }
    }
}

private fun renderOne(): RenderContext.() -> Unit = {
    renderPharagraph(UiTermsOfUse.OneOneHeading, UiTermsOfUse.OneOneContent, "mt-4")
    renderPharagraph(UiTermsOfUse.OneTwoHeading, UiTermsOfUse.OneTwoContent, "mt-4")
    renderPharagraph(UiTermsOfUse.OneThreeHeading, UiTermsOfUse.OneThreeContent, "mt-4")
    renderPharagraph(UiTermsOfUse.OneFourHeading, UiTermsOfUse.OneFourContent, "my-4")
}

private fun renderTwo(): RenderContext.() -> Unit = {
    renderPharagraph(UiTermsOfUse.TwoOneHeading, UiTermsOfUse.TwoOneContent, "mt-4")
    renderPharagraph(UiTermsOfUse.TwoTwoHeading, UiTermsOfUse.TwoTwoContent, "mt-4")
    renderPharagraph(UiTermsOfUse.TwoThreeHeading, UiTermsOfUse.TwoThreeContent, "my-4")
}

private fun renderThree(): RenderContext.() -> Unit = {
    val translationStore by koin.inject<TranslationStore>()

    renderPharagraph(UiTermsOfUse.ThreeOneHeading, UiTermsOfUse.ThreeOneContent, "mt-4")
    div("font-semibold mt-4") { translationStore[UiTermsOfUse.ThreeTwoHeading].renderText(this) }
    renderPharagraph(UiTermsOfUse.ThreeTwoOneHeading, UiTermsOfUse.ThreeTwoOneContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.ThreeTwoTwoHeading, UiTermsOfUse.ThreeTwoTwoContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.ThreeTwoThreeHeading, UiTermsOfUse.ThreeTwoThreeContent, "mt-2", "sm:pl-4")
}

private fun renderFour(): RenderContext.() -> Unit = {
    renderPharagraph(UiTermsOfUse.FourOneHeading, UiTermsOfUse.FourOneContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FourTwoHeading, UiTermsOfUse.FourTwoContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FourThreeHeading, UiTermsOfUse.FourThreeContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FourFourHeading, UiTermsOfUse.FourFourContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FourFourOneHeading, UiTermsOfUse.FourFourOneContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.FourFourTwoHeading, UiTermsOfUse.FourFourTwoContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.FourFourThreeHeading, UiTermsOfUse.FourFourThreeContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.FourFourFourHeading, UiTermsOfUse.FourFourFourContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.FourFourFiveHeading, UiTermsOfUse.FourFourFiveContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.FourFiveHeading, UiTermsOfUse.FourFiveContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FourSixHeading, UiTermsOfUse.FourSixContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FourSevenHeading, UiTermsOfUse.FourSevenContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FourEightHeading, UiTermsOfUse.FourEightContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FourNineHeading, UiTermsOfUse.FourNineContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FourTenHeading, UiTermsOfUse.FourTenContent, "mt-4")
}

private fun renderFive(): RenderContext.() -> Unit = {
    renderPharagraph(UiTermsOfUse.FiveOneHeading, UiTermsOfUse.FiveOneContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FiveTwoHeading, UiTermsOfUse.FiveTwoContent, "mt-4")
    renderPharagraph(
        UiTermsOfUse.FiveThreeHeading, UiTermsOfUse.FiveThreeContent, "mt-4" +
                ""
    )
    renderPharagraph(UiTermsOfUse.FiveFourHeading, UiTermsOfUse.FiveFourContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FiveFourOneHeading, UiTermsOfUse.FiveFourOneContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.FiveFourTwoHeading, UiTermsOfUse.FiveFourTwoContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.FiveFourThreeHeading, UiTermsOfUse.FiveFourThreeContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.FiveFourFourHeading, UiTermsOfUse.FiveFourFourContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.FiveFourFiveHeading, UiTermsOfUse.FiveFourFiveContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.FiveFourSixHeading, UiTermsOfUse.FiveFourSixContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.FiveFourSevenHeading, UiTermsOfUse.FiveFourSevenContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.FiveFourEightHeading, UiTermsOfUse.FiveFourEightContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.FiveFourNineHeading, UiTermsOfUse.FiveFourNineContent, "mt-2", "sm:pl-4")

    renderPharagraph(UiTermsOfUse.FiveFiveHeading, UiTermsOfUse.FiveFiveContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FiveSixHeading, UiTermsOfUse.FiveSixContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FiveSevenHeading, UiTermsOfUse.FiveSevenContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FiveEightHeading, UiTermsOfUse.FiveEightContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FiveNineHeading, UiTermsOfUse.FiveNineContent, "mt-4")
}

private fun renderSix(): RenderContext.() -> Unit = {
    renderPharagraph(UiTermsOfUse.SixOneHeading, UiTermsOfUse.SixOneContent, "mt-4")
    renderPharagraph(UiTermsOfUse.SixTwoHeading, UiTermsOfUse.SixTwoContent, "mt-4")
    renderPharagraph(UiTermsOfUse.SixThreeHeading, UiTermsOfUse.SixThreeContent, "mt-4")
}

private fun renderSeven(): RenderContext.() -> Unit = {
    val translationStore by koin.inject<TranslationStore>()
    div("font-semibold mt-4") { translationStore[UiTermsOfUse.SevenOne].renderText(this) }
    renderPharagraph(UiTermsOfUse.SevenOneOneHeading, UiTermsOfUse.SevenOneOneContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.SevenOneTwoHeading, UiTermsOfUse.SevenOneTwoContent, "mt-2", "sm:pl-4")

    div("font-semibold mt-4") { translationStore[UiTermsOfUse.SevenTwo].renderText(this) }
    renderPharagraph(UiTermsOfUse.SevenTwoOneHeading, UiTermsOfUse.SevenTwoOneContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.SevenTwoTwoHeading, UiTermsOfUse.SevenTwoTwoContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.SevenTwoThreeHeading, UiTermsOfUse.SevenTwoThreeContent, "mt-2", "sm:pl-4")

    div("font-semibold mt-4") { translationStore[UiTermsOfUse.SevenThree].renderText(this) }
    renderPharagraph(UiTermsOfUse.SevenThreeOneHeading, UiTermsOfUse.SevenThreeOneContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.SevenThreeTwoHeading, UiTermsOfUse.SevenThreeTwoContent, "mt-2", "sm:pl-4")

    div("font-semibold mt-4") { translationStore[UiTermsOfUse.SevenFour].renderText(this) }
    renderPharagraph(UiTermsOfUse.SevenFourOneHeading, UiTermsOfUse.SevenFourOneContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.SevenFourTwoHeading, UiTermsOfUse.SevenFourTwoContent, "mt-2", "sm:pl-4")

    renderPharagraph(UiTermsOfUse.SevenFiveHeading, UiTermsOfUse.SevenFiveContent, "mt-4")
    renderPharagraph(UiTermsOfUse.SevenSixHeading, UiTermsOfUse.SevenSixContent, "mt-4")
}

private fun renderEight(): RenderContext.() -> Unit = {
    renderPharagraph(UiTermsOfUse.EightOneHeading, UiTermsOfUse.EightOneContent, "mt-4")
    renderPharagraph(UiTermsOfUse.EightTwoHeading, UiTermsOfUse.EightTwoContent, "mt-4")
    renderPharagraph(UiTermsOfUse.EightThreeHeading, UiTermsOfUse.EightThreeContent, "mt-4")
}

private fun renderNine(): RenderContext.() -> Unit = {
    renderPharagraph(UiTermsOfUse.NineOneHeading, UiTermsOfUse.NineOneContent, "mt-4")
    renderPharagraph(UiTermsOfUse.NineTwoHeading, UiTermsOfUse.NineTwoContent, "mt-4")
    renderPharagraph(UiTermsOfUse.NineTwoOneHeading, UiTermsOfUse.NineTwoOneContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.NineTwoTwoHeading, UiTermsOfUse.NineTwoTwoContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.NineTwoThreeHeading, UiTermsOfUse.NineTwoThreeContent, "mt-2", "sm:pl-4")
    renderPharagraph(UiTermsOfUse.NineTwoFourHeading, UiTermsOfUse.NineTwoFourContent, "mt-2", "sm:pl-4")

}

private fun renderTen(): RenderContext.() -> Unit = {
    renderPharagraph(UiTermsOfUse.TenOneHeading, UiTermsOfUse.TenOneContent, "mt-4")
    renderPharagraph(UiTermsOfUse.TenTwoHeading, UiTermsOfUse.TenTwoContent, "mt-4")
    renderPharagraph(UiTermsOfUse.TenThreeHeading, UiTermsOfUse.TenThreeContent, "mt-4")

}

private fun renderEleven(): RenderContext.() -> Unit = {
    val translationStore by koin.inject<TranslationStore>()
    div("mt-4") { translationStore[UiTermsOfUse.ElevenContent].renderText(this) }
}

private fun renderTwelve(): RenderContext.() -> Unit = {
    val translationStore by koin.inject<TranslationStore>()
    div("mt-4") { translationStore[UiTermsOfUse.TwelveContent].renderText(this) }
}

private fun renderThirteen(): RenderContext.() -> Unit = {
    val translationStore by koin.inject<TranslationStore>()
    div("mt-4") { translationStore[UiTermsOfUse.ThirteenContent].renderText(this) }
}

private fun renderFourteen(): RenderContext.() -> Unit = {
    renderPharagraph(UiTermsOfUse.FourteenOneHeading, UiTermsOfUse.FourteenOneContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FourteenTwoHeading, UiTermsOfUse.FourteenTwoContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FourteenThreeHeading, UiTermsOfUse.FourteenThreeContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FourteenFourHeading, UiTermsOfUse.FourteenFourContent, "mt-4")
    renderPharagraph(UiTermsOfUse.FourteenFiveHeading, UiTermsOfUse.FourteenFiveContent, "mt-4")
}

private fun RenderContext.renderPharagraph(heading: Translatable, content: Translatable, styleClass: String = "", headingPadding: String = "") {
    val translationStore by koin.inject<TranslationStore>()
    div("grid gap-4 sm:grid-cols-[20%_80%]  $styleClass") {
        div("font-semibold $headingPadding") { translationStore[heading].renderText(this) }
        div { translationStore[content].renderText(this) }
    }
}