package components

import dev.fritz2.core.*
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import domain.model.*
import icons.addIcon
import koin
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.filter
import localization.TranslationStore
import localization.components.UiTextareaRuleConverter
import util.Message

val orSymbols = listOf("||", "|")
val andSymbols = listOf("&&", "&", "+")

fun RenderContext.textareaRuleConverter(
    label: Flow<String>,
    messages: Flow<List<Message>>,
    initialRuleStore: Store<List<Rule>>,
    additionalStyles: String = ""
) {
    val translationStore by koin.inject<TranslationStore>()
    val languageStore: Store<String> = storeOf(Language.ENGLISH.representation)
    val valueStore: Store<String> = storeOf("")
    val ruleStore: Store<List<Rule>> = storeOf(emptyList())

    val updateConvertHandler = valueStore.handle<String> { _, newValue ->
        try {
            if (newValue.isNotBlank()) {
                val convertedRules = convertTextToRules(newValue, languageStore)
                val currentRules = ruleStore.current

                val newRulesToAdd = convertedRules.filter { newRule ->
                    currentRules.none { it.isContentEqual(newRule) }
                }

                if (newRulesToAdd.isNotEmpty()) {
                    ruleStore.update(currentRules + newRulesToAdd)
                }
            }
        } catch (e: Exception) {
            return@handle newValue
        }
        return@handle ""
    }
    valueStore.data handledBy updateConvertHandler

    div("bg-greyscale-70 rounded-lg py-4 mx-4 $additionalStyles") {
        div {
            dropdownSelector(
                translationStore[UiTextareaRuleConverter.LanguageLabel],
                languageStore,
                Language.entries.map { it.representation }.sorted()
            )
            textareaInput(label, valueStore, messages)
        }

        div("flex gap-4 flex-wrap items-stretch flex-1  px-4") {
            ruleStore.data.renderEach(into = this) { rule ->
                div("p-1 bg-greyscale-90 shadow rounded-lg items-stretch flex-grow flex flex-col justify-between") {
                    div {
                        val ruleSplits = rule.getPreview().split("<br>")
                        ruleSplits.forEach {
                            div("mb-1") { +it }
                        }
                    }
                    div("mt-2 flex justify-end") {
                        addButton(rule, ruleStore, initialRuleStore)
                    }
                }
            }
        }
        addAllButton(ruleStore, initialRuleStore)
    }
}

private fun RenderContext.addAllButton(ruleStore: Store<List<Rule>>, initialRuleStore: Store<List<Rule>>) {
    val translationStore by koin.inject<TranslationStore>()
    ruleStore.data.render { rules ->
        button(
            "flex items-center cursor-pointer rounded-lg " +
                    "hover:bg-greyscale-100 hover:rounded hover:shadow-[rgba(0,0,15,0.3)_0px_2px_2px_0px] " +
                    "focus-visible:outline-none focus-visible:bg-greyscale-100 p-4 " +
                    "disabled:text-disabled disabled:cursor-none disabled:pointer-events-none " +
                    "disabled:bg-none disabled:bg-disabled-12 disabled:text-disabled mt-4 mx-4"
        ) {
            disabled(rules.isEmpty())
            addIcon("w-6 h-6", "currentColor")
            span("pl-2") {
                translationStore[UiTextareaRuleConverter.AddAllLabel].renderText(this)
            }
            keydownsCaptured.filter { shortcutOf(it) == Keys.Enter } handledBy {
                initialRuleStore.update(initialRuleStore.current + ruleStore.current)
                ruleStore.update(emptyList())
            }
            clicks handledBy {
                initialRuleStore.update(initialRuleStore.current + ruleStore.current)
                ruleStore.update(emptyList())
            }
        }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
            placement = PlacementValues.right
            translationStore[UiTextareaRuleConverter.AddAllDescription].renderText(this)
            arrow()
        }
    }
}

private fun RenderContext.addButton(rule: Rule, ruleStore: Store<List<Rule>>, initialRuleStore: Store<List<Rule>>) {
    val translationStore by koin.inject<TranslationStore>()
    button(
        "p-2 bg-gradient-to-r from-darkest-0 to-primary-10 rounded-lg text-greyscale-100 " +
                "hover:bg-darkest-0 hover:shadow-lg hover:text-tertiary-50"
    ) {
        addIcon("w-4 h-4", "currentColor")
        keydownsCaptured.filter { shortcutOf(it) == Keys.Enter } handledBy {
            initialRuleStore.update(
                initialRuleStore.current.plus(rule)
            )
            ruleStore.update(ruleStore.current.minus(rule))
        }
        clicks handledBy {
            initialRuleStore.update(
                initialRuleStore.current.plus(rule)
            )
            ruleStore.update(ruleStore.current.minus(rule))
        }
    }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
        placement = PlacementValues.right
        translationStore[UiTextareaRuleConverter.AddDescription].renderText(this)
        arrow()
    }
}

private fun convertTextToRules(value: String, languageStore: Store<String>): Set<Rule> {
    val rules = mutableSetOf<Rule>()
    val ruleSplit = value.split(Regex("\\r?\\n|\\r"))

    ruleSplit.forEach { line ->
        if (line.isNotBlank()) {
            val createdRules = convertLineToRule(line, languageStore)
            createdRules.isNotEmpty().let {
                rules.addAll(createdRules)
            }
        }
    }
    return rules
}

private fun convertLineToRule(line: String, languageStore: Store<String>): List<Rule> {
    var words: List<String> = line.split(Regex("\\s+"))

    val andStopword = listOf(getAndStopword(languageStore))
    val orStopword = listOf(getOrStopword(languageStore))

    if (words.size == 1 && (andSymbols.any { words[0].contains(it) } || orSymbols.any { words[0].contains(it) })) {
        val word = words[0]
        words = addBlanksAroundSymbolsAndSplit(word, andSymbols, orSymbols)
    }

    return when {
        words.isEmpty() -> return emptyList()
        words.size == 1 && !containsStopwordOrSymbols(line, orStopword, orSymbols) && !containsStopwordOrSymbols(
            line,
            andStopword,
            andSymbols
        ) -> listOf(createTermRule(words[0], languageStore))

        words.size == 1 && containsStopwordOrSymbols(line, orStopword, orSymbols) && !containsStopwordOrSymbols(
            line,
            andStopword,
            andSymbols
        ) -> {
            val splitWords = splitByStopwords(line, orStopword, orSymbols)
            splitWords.map { createTermRule(it, languageStore) }
        }

        words.size in 2..8 && containsStopwordOrSymbols(line, orStopword, orSymbols) && !containsStopwordOrSymbols(
            line,
            andStopword,
            andSymbols
        ) -> {
            val splitWords = splitByStopwords(line, orStopword, orSymbols)
            splitWords.map { createTermRule(it, languageStore) }
        }

        words.size in 2..8 && containsStopwordOrSymbols(line, andStopword, andSymbols) -> {
            return listOf(
                createCombinationRule(
                    words,
                    languageStore
                )
            )
        }

        words.size in 2..5 && !containsStopwordOrSymbols(line, andStopword, andSymbols) && !containsStopwordOrSymbols(
            line,
            orStopword,
            orSymbols
        )
            -> listOf(createTermRule(removeStopwordsAndSymbols(line, languageStore), languageStore))

        words.size >= 5 && containsStopwords(line, languageStore) -> {
            listOf(createPhraseRule(words, languageStore))
        }

        else -> {
            listOf(createPhraseRule(words, languageStore))
        }
    }
}

private fun addBlanksAroundSymbolsAndSplit(
    word: String,
    andSymbols: List<String>,
    orSymbols: List<String>
): List<String> {
    var modifiedWord = word

    // Step 1: Replace each symbol with a temporary placeholder
    val placeholderMap = mutableMapOf<String, String>()

    for ((placeholderIndex, symbol) in (andSymbols + orSymbols).sortedByDescending { it.length }.withIndex()) {
        val placeholder = "___PLACEHOLDER_${placeholderIndex}___"
        placeholderMap[placeholder] = symbol
        modifiedWord = modifiedWord.replace(symbol, placeholder)
    }

    // Step 2: Replace placeholders with spaced symbols
    for ((placeholder, symbol) in placeholderMap) {
        modifiedWord = modifiedWord.replace(placeholder, " $symbol ")
    }

    // Step 3: Split the modified word by whitespace
    return modifiedWord.split("\\s+".toRegex()).filter { it.isNotBlank() }
}

private fun splitByStopwords(line: String, stopwords: List<String>, symbols: List<String>): List<String> {
    val stopwordsWithBlank = stopwords.map { " $it " }
    val sortedSymbols = symbols.sortedByDescending { it.length }
    val separator = stopwordsWithBlank + sortedSymbols
    val splitResult = line.split(*separator.toTypedArray()).filter { it.isNotBlank() }.map { it.trim() }
    return splitResult
}


private fun containsStopwordOrSymbols(line: String, stopwords: List<String>, symbols: List<String>): Boolean {
    val lineContain = symbols.any { line.contains(it) }
    if (lineContain) {
        val parts = line.split(*symbols.toTypedArray()).map { it.trim() }
        return parts.size > 1 && parts.any { it.isNotBlank() }
    }

    val words = line.lowercase().split("\\s+".toRegex())
    return words.any { it in stopwords + symbols }
}


private fun containsStopwords(line: String, languageStore: Store<String>): Boolean {
    val stopwords: Array<String> = getStopwords(languageStore)
    val words = line.lowercase().split("\\s+".toRegex())
    val hasStopword = words.any {
        it in stopwords
    }
    return hasStopword
}


private fun getOrStopword(languageStore: Store<String>): String {
    return when (languageStore.current.lowercase()) {
        "english" -> "or"
        "german" -> "oder"
        "french" -> "ou"
        "russian" -> "или"
        "spanish" -> "o"
        "danish" -> "eller"
        "dutch" -> "of"
        "finnish" -> "tai"
        "norwegian" -> "eller"
        "swedish" -> "eller"
        "portuguese" -> "ou"
        else -> "or"
    }
}

private fun getAndStopword(languageStore: Store<String>): String {
    return when (languageStore.current.lowercase()) {
        "english" -> "and"
        "german" -> "und"
        "french" -> "et"
        "russian" -> "и"
        "spanish" -> "y"
        "danish" -> "og"
        "dutch" -> "en"
        "finnish" -> "ja"
        "norwegian" -> "og"
        "swedish" -> "och"
        "portuguese" -> "e"
        else -> "and"
    }
}

private fun createTermRule(word: String, languageStore: Store<String>): Rule {
    return Rule(
        0,
        word,
        null,
        listOf(
            Combination(
                0,
                type = CombinationType.TERM,
                words = listOf(
                    Word(
                        0,
                        removeStopwordsAndSymbols(word, languageStore),
                        if (word.length in 2..5 && word.all { it.isUpperCase() || !it.isLetter() }) Language.fromRepresentation(
                            languageStore.current
                        ) else Language.NONE,
                        caseSensitive = false,
                        subStringSearch = false
                    )
                )
            )
        ),
        metadata = emptyList(),
        language = Language.fromRepresentation(languageStore.current),
        span = Span.NARROW
    )
}

private fun createCombinationRule(words: List<String>, languageStore: Store<String>): Rule {
    val line = words.joinToString(" ")
    val compoundElements = splitByStopwords(line, listOf(getAndStopword(languageStore)), andSymbols)
    val orStopword = getOrStopword(languageStore)

    return Rule(
        0,
        words[0],
        null,
        combinations = compoundElements.map {
            Combination(
                0,
                type = CombinationType.COMPOUND,
                words = splitByStopwords(it, listOf(orStopword), orSymbols).map { word ->
                    Word(
                        0,
                        removeStopwordsAndSymbols(word, languageStore),
                        if (word.length in 2..5 && word.all { c: Char -> c.isUpperCase() || !c.isLetter() }) Language.fromRepresentation(
                            languageStore.current
                        ) else Language.NONE,
                        caseSensitive = false,
                        subStringSearch = false,
                        type = WordType.TERM
                    )
                }
            )
        },
        metadata = emptyList(),
        language = Language.fromRepresentation(languageStore.current),
        span = Span.NARROW
    )
}

private fun createPhraseRule(words: List<String>, languageStore: Store<String>): Rule {
    return Rule(
        0,
        words[0],
        null,
        listOf(
            Combination(
                0,
                type = CombinationType.TERM,
                words = listOf(
                    Word(
                        0,
                        generateRandomString(),
                        stemming = Language.NONE,
                        caseSensitive = false,
                        subStringSearch = false,
                        type = WordType.TERM
                    )
                )
            ),
            Combination(
                0,
                type = CombinationType.PHRASE,
                words = listOf(
                    Word(
                        0,
                        words.joinToString(" "),
                        stemming = Language.fromRepresentation(languageStore.current),
                        caseSensitive = false,
                        subStringSearch = false,
                        type = WordType.PHRASE
                    )
                )
            )
        ),
        metadata = emptyList(),
        language = Language.fromRepresentation(languageStore.current),
        span = Span.NARROW
    )
}

private fun removeStopwordsAndSymbols(text: String, languageStore: Store<String>): String {
    val stopwords: Array<String> = getStopwords(languageStore)
    val cleanedText = text.replace(Regex("[+%|/\\\\=()\\[\\]{}]"), "")
    val words = cleanedText.split(Regex("\\s+")).toTypedArray()
    if (words.size < 5) {
        val filteredWords = Stopword.removeStopwords(words, stopwords)
        return filteredWords.joinToString(" ")
    } else {
        return words.joinToString(" ")
    }
}


private fun getStopwords(languageStore: Store<String>): Array<String> {
    return when (languageStore.current.lowercase()) {
        "english" -> Stopword.eng
        "german" -> Stopword.deu
        "french" -> Stopword.fra
        "russian" -> Stopword.rus
        "spanish" -> Stopword.spa
        "danish" -> Stopword.dan
        "dutch" -> Stopword.nld
        "finnish" -> Stopword.fin
        "norwegian" -> Stopword.nob
        "swedish" -> Stopword.swe
        "portuguese" -> Stopword.por
        "portuguese_br" -> Stopword.porBr
        else -> emptyArray()
    }
}

private fun generateRandomString(length: Int = 24): String {
    val allowedChars = ('A'..'Z') + ('a'..'z') + ('0'..'9')
    return (1..length)
        .map { allowedChars.random() }
        .joinToString("")
}