package domain.repository

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import dev.fritz2.validation.validation
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable
import localization.platform.UiScreening
import util.Message
import util.Severity

@Lenses
@Serializable
data class ScreeningHistory(
    val id: Int,
    val name: String,
    val state: ScreeningState,
    val userMail: String,
    val creationDateTime: LocalDateTime?,
    val startDateTime: LocalDateTime?,
    val deletionDateTime: LocalDateTime?,
    val downloadDateTime: LocalDateTime?,
    val numDocuments: Int?,
    val numPages: Int?,
    val numOcr: Int?,
    val slot: Int?,
    val models: List<String> = emptyList()
) {
    companion object {

        val validation: Validation<ScreeningHistory, Unit, Message> = validation { inspector ->
            val name = inspector.map(ScreeningHistory.name())
            if (!validateName(name.data)) {
                add(Message(name.path, Severity.Error, UiScreening.NameValidation))
            }
        }

        fun validate(screening: ScreeningHistory): Boolean = validateName(screening.name)

        private fun validateName(name: String): Boolean = name.length < 50
    }
}