package login

import api.entraLogin
import api.postLogin
import api.postPin
import api.postRegister
import components.defaultHeader
import components.textInput
import dev.fritz2.core.*
import dev.fritz2.headless.components.TabGroup
import dev.fritz2.headless.components.tabGroup
import dev.fritz2.routing.MapRouter
import dev.fritz2.routing.routerOf
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.valid
import domain.authentication.login.*
import domain.authentication.token.Pin
import domain.authentication.token.pin
import icons.entraIcon
import koin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import localization.login.UiLogin
import localization.login.UiRegister
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import react.FC
import react.Props
import util.Message
import utils.mainButton

external interface LoginProps : Props

object LoginPages {
    const val LOGIN = "Login"
    const val PIN = "Pin"
}

val LoginPage = FC<LoginProps> {
    val router = routerOf(mapOf("page" to LoginPages.LOGIN))

    render("#root") {
        defaultHeader()
        main(id = "main", baseClass = "flex justify-center") {
            router.select("page").render(into = this) { (value, _) ->
                when (value) {
                    LoginPages.LOGIN -> loginPage(router)
                    LoginPages.PIN -> pinPage()
                }
            }

        }
    }
}

private fun HtmlTag<HTMLElement>.loginPage(
    router: MapRouter
) {
    val translationStore by koin.inject<TranslationStore>()

    article("ta-ce") {
        val currentIndex = storeOf(0)

        currentIndex.data handledBy {
            responseMsgStore.update(Pair(flowOf(""), true))
        }

        tabGroup("tabGroup") {
            value(currentIndex)

            tabList("tabList") {
                tab("tabListBtn") { translationStore[UiLogin.Heading].renderText(this) }
                tab("tabListBtn") { translationStore[UiRegister.Heading].renderText(this) }
            }
            tabPanels("tabPanels") {
                loginPanel(router)
                registerPanel()
            }
        }
    }
}

private fun HtmlTag<HTMLElement>.pinPage() {
    val translationStore by koin.inject<TranslationStore>()
    article("ta-ce max-width") {
        h1("text-3xl font-semibold") {
            translationStore[UiLogin.PinHeading].renderText(this)
        }

        val pin = PinStore.map(Pin.pin())
        val msgs: Flow<List<Message>> = PinStore.messages

        div {
            renderMessages()

            textInput(translationStore[UiLogin.Pin], pin, msgs)

            button(mainButton, "pinButton") {
                translationStore[UiLogin.Button].renderText(this)
                clicks handledBy PinStore.save
            }
        }
    }
}


private val responseMsgStore: Store<Pair<Flow<String>, Boolean>> = storeOf(Pair(flowOf("test"), false), Job())

private object PinStore : ValidatingStore<Pin, Unit, Message>(
    Pin("", ""),
    Pin.validation,
    Unit, Job(),
    validateAfterUpdate = false
) {
    val save = PinStore.handle { pin ->
        if (PinStore.validate(pin).valid) {
            PinStore.resetMessages()
            val response = postPin(pin)
            responseMsgStore.update(response)
            Pin(pin.pin, pin.expirationTime)
        } else pin
    }
}

private object LoginStore : ValidatingStore<Login, Unit, Message>(
    Login("", ""),
    Login.validation,
    Unit, Job(),
    validateAfterUpdate = false
) {

    val save: SimpleHandler<MapRouter> = LoginStore.handle { login, router ->
        if (LoginStore.validate(login).valid) {
            LoginStore.resetMessages()
            val response = postLogin(Login(login.email, login.password), router)
            responseMsgStore.update(response)
            Login(login.email, login.password)
        } else login
    }
}

private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.loginPanel(router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()

    val msgs: Flow<List<Message>> = LoginStore.messages
    val email = LoginStore.map(Login.email())
    val password = LoginStore.map(Login.password())

    panel("tabPanel") {
        div("tabPanelHeading") {
            attr("aria-hidden", "true")
            h1("tabHeading") {
                translationStore[UiLogin.Heading].renderText(this)
            }
            div("tabPanelUnderline") {
                attr("aria-hidden", "true")
            }
        }

        renderMessages()

        div("tabPanelContent") {
            textInput(translationStore[UiLogin.Email], email, msgs)
            textInput(translationStore[UiLogin.Password], password, msgs, true)

            div("pad_btm") {
                a("textLinkOnBg") {
                    attr("role", "link")
                    tabIndex(0)
                    href(PasswordReset.PATH)
                    translationStore[UiLogin.PasswordForget].renderText(this)
                }
            }

            button(mainButton, "loginButton") {
                translationStore[UiLogin.Button].renderText(this)
                clicks.map { router } handledBy LoginStore.save
            }

            div("h-px bg-primary-10 w-full rounded-lg my-4") {}

            div {
                + "SSO"
            }

            button("border border-primary-30 bg-gradient-to-r from-darkest-0 to-primary-10 rounded-full aspect-square p-2 " +
                    "text-greyscale-100 hover:shadow-md hover:bg-none hover:bg-darkest-0 hover:pointer-cursor " +
                    "focus-visible:outline-none focus-visible:shadow-lg focus-visible:bg-none focus-visible:bg-darkest-0", "entraButton") {
                attr("aria-label", "EntraId Login")
                entraIcon("w-6 h-6", "#FFFFFF")
                clicks handledBy {
                    entraLogin()
                }
            }
        }
    }
}

private object RegisterStore : ValidatingStore<Register, Unit, Message>(
    Register("", "", "", ""),
    Register.validation,
    Unit, Job(),
    validateAfterUpdate = false
) {
    val save = RegisterStore.handle {
        if (RegisterStore.validate(it).valid) {
            RegisterStore.resetMessages()
            val response = postRegister(Register(it.email, it.username, it.password, it.passwordConfirmation))
            responseMsgStore.update(response)
            Register(it.email, it.username, it.password, it.passwordConfirmation)
        } else it
    }
}

private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.registerPanel() {
    val translationStore by koin.inject<TranslationStore>()

    val msgs: Flow<List<Message>> = RegisterStore.messages
    val username = RegisterStore.map(Register.username())
    val email = RegisterStore.map(Register.email())
    val password = RegisterStore.map(Register.password())
    val passwordConfirmation = RegisterStore.map(Register.passwordConfirmation())

    panel("tabPanel") {

        div("tabPanelHeading") {
            attr("aria-hidden", "true")
            h1("tabHeading") {
                translationStore[UiRegister.Heading].renderText(this)
            }
            div("tabPanelUnderline") {
                attr("aria-hidden", "true")
            }
        }

        renderMessages()

        div("tabPanelContent") {
            textInput(translationStore[UiRegister.Username], username, msgs)
            textInput(translationStore[UiRegister.Email], email, msgs)
            textInput(translationStore[UiRegister.Password], password, msgs, true)
            textInput(translationStore[UiRegister.PasswordConfirmation], passwordConfirmation, msgs, true)

            // checkbox for registration terms of use

            button(mainButton, "registerButton") {
                translationStore[UiRegister.Button].renderText(this)
                clicks handledBy RegisterStore.save
            }
        }
    }
}

private fun RenderContext.renderMessages() {
    val classNameStore = storeOf("tabPanelResponseError")
    div {
        className(classNameStore.data)
        responseMsgStore.data.render(into = this) { (message, isSuccessMessage) ->
            if (isSuccessMessage) {
                classNameStore.update("text-primary-10 tabPanelResponseError")
            } else {
                classNameStore.update("tabPanelResponseError")
            }
            message.renderText(this)
        }
    }
}
