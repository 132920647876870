package domain.userManagement

import kotlinx.serialization.Serializable

@Serializable
data class Role(
    val id: Int,
    val name: String
) {
    companion object {
        const val PATH = "/roles"
    }
}
