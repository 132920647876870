package components

import api.getModelTree
import dev.fritz2.core.*
import dev.fritz2.routing.MapRouter
import domain.model.ModelTree
import domain.repository.DocumentType
import koin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import localization.login.UiLogin
import localization.platform.UiModelSelector
import org.w3c.dom.HTMLLIElement
import platform.navigation.Pages
import util.Mode
import utils.toastError

data class ExtendedModelTree(
    val id: String,
    val name: String,
    val type: DocumentType,
    var isSelected: Boolean = false,
    val children: MutableList<ExtendedModelTree> = mutableListOf(),
    var version: Int = 0
) {

    private fun hasNodeWithId(nodeId: String): Boolean {
        return (id == nodeId) || children.any { it.hasNodeWithId(nodeId) }
    }


    fun deepCopyWithUpdatedState(
        nodeId: String,
        isSelected: Boolean,
        type: DocumentType,
        childState: Boolean = false
    ): ExtendedModelTree {
        if (!hasNodeWithId(nodeId) && !childState) {
            return this
        }

        val newState = if (this.id == nodeId || childState) isSelected else this.isSelected

        val newChildren = children.map { child ->
            child.deepCopyWithUpdatedState(nodeId, isSelected, type, this.id == nodeId || childState)
        }

        val childSelectedState = if (newChildren.isEmpty()) newState else newChildren.all { it.isSelected }
        val isActualChange = (childSelectedState != this.isSelected)

        return if (isActualChange) {
            this.copy(
                isSelected = childSelectedState,
                children = newChildren.toMutableList(),
                version = this.version + 1
            )
        } else {
            this.copy(
                children = newChildren.toMutableList()
            )
        }
    }
}

class ModelSelector(private val mode: Mode = Mode.SELECT) {

    private val collapsedStateStore = storeOf(mapOf<String, Boolean>(), Job())

    private class ExtendedModelTreeStore(
        mode: Mode,
        initialTree: ExtendedModelTree = ExtendedModelTree("0", "", DocumentType.FOLDER)
    ) : RootStore<ExtendedModelTree>(initialTree, Job()) {

        val initialize: SimpleHandler<Store<Map<String, Boolean>>> = handle { oldTree, collapsedStateStore ->
            try {
                val modelTree = getModelTree(mode)
                val extendedModelTree = mapToExtendedModelTree(modelTree)
                collapsedStateStore.update(createCollapsedStateMap(extendedModelTree))

                extendedModelTree
            } catch (e: Exception) {
                toastError("Failed to load ModelTree: ${e.message}")
                oldTree
            }
        }

        val collapseAll: SimpleHandler<Store<Map<String, Boolean>>> = handle { oldTree, collapsedStateStore ->
            collapsedStateStore.update(createCollapsedAllStateMap(oldTree))
            oldTree
        }

        val expandAll: SimpleHandler<Store<Map<String, Boolean>>> = handle { oldTree, collapsedStateStore ->
            collapsedStateStore.update(createExpandedAllStateMap(oldTree))
            oldTree
        }

        val updateIdSelected: SimpleHandler<Triple<String, Boolean, DocumentType>> =
            handle { extendedModelTree, (id, selected, type) ->
                val updatedTree = extendedModelTree.deepCopyWithUpdatedState(id, selected, type)
                updatedTree
            }

        private fun isDifferentModelTree(old: ExtendedModelTree, new: ExtendedModelTree): Boolean {
            when (new.type) {
                DocumentType.FOLDER -> if (old.name != new.name || old.type != new.type || old.children.size != new.children.size) return true
                DocumentType.FILE -> throw Exception()
                DocumentType.MODEL -> if (old.id != new.id || old.name != new.name || old.type != new.type || old.children.size != new.children.size) return true
            }

            new.children.forEachIndexed { index, child ->
                if (isDifferentModelTree(old.children[index], child)) {
                    return true
                }
            }

            return false
        }

        private fun mapToExtendedModelTree(original: ModelTree): ExtendedModelTree {
            val newID: String = if (original.type == DocumentType.FOLDER) Id.next(16) else original.id.toString()
            val extendedNode = ExtendedModelTree(newID, original.name, original.type)
            original.children?.forEach {
                extendedNode.children.add(mapToExtendedModelTree(it))
            }
            return extendedNode
        }

        private fun createCollapsedStateMap(tree: ExtendedModelTree): Map<String, Boolean> {
            val stateMap = mutableMapOf<String, Boolean>()
            if (tree.type == DocumentType.FOLDER) {
                stateMap[tree.id] = tree.children.none { it.type == DocumentType.FOLDER }
                tree.children.forEach { child -> stateMap.putAll(createCollapsedStateMap(child)) }
            }
            return stateMap
        }

        private fun createCollapsedAllStateMap(tree: ExtendedModelTree): Map<String, Boolean> {
            val stateMap = mutableMapOf<String, Boolean>()
            if (tree.type == DocumentType.FOLDER) {
                stateMap[tree.id] = true
                tree.children.forEach { child -> stateMap.putAll(createCollapsedAllStateMap(child)) }
            }
            return stateMap
        }

        private fun createExpandedAllStateMap(tree: ExtendedModelTree): Map<String, Boolean> {
            val stateMap = mutableMapOf<String, Boolean>()
            if (tree.type == DocumentType.FOLDER) {
                stateMap[tree.id] = false
                tree.children.forEach { child -> stateMap.putAll(createExpandedAllStateMap(child)) }
            }
            return stateMap
        }
    }

    private val extendedModelTreeStore = ExtendedModelTreeStore(mode)

    private val modelTreeListIdLens = lensOf<ExtendedModelTree, List<Int>>(
        "modelTreeListIdLens",
        getter = { extendedTree -> getSelectedIds(extendedTree) },
        setter = { extendedTree, ids ->
            clearSelection(extendedTree)
            val extendedTreeCopy = setSelectedIds(extendedTree, ids)
            extendedTreeCopy
        }
    )

    private val modelTreeIdStore: Store<List<Int>> = extendedModelTreeStore.map(modelTreeListIdLens)

    private fun clearSelection(extendedTree: ExtendedModelTree) {
        extendedTree.isSelected = false
        extendedTree.children.forEach { child -> clearSelection(child) }
    }

    private fun setSelectedIds(extendedTree: ExtendedModelTree, ids: List<Int>): ExtendedModelTree {
        // Set isSelected to true if id is in the list
        val newChildren = extendedTree.children.map { child ->
            setSelectedIds(child, ids)
        }.toMutableList()

        if (extendedTree.type == DocumentType.MODEL) {
            return extendedTree.copy(isSelected = ids.contains(extendedTree.id.toInt()))
        } else if (extendedTree.type == DocumentType.FOLDER) {
            return extendedTree.copy(isSelected = newChildren.all { it.isSelected }, children = newChildren)
        }
        return extendedTree
    }

    private fun getSelectedIds(tree: ExtendedModelTree): List<Int> {
        val ids = mutableListOf<Int>()
        if (tree.isSelected && tree.type == DocumentType.MODEL) ids.add(tree.id.toInt())
        tree.children.forEach { ids.addAll(getSelectedIds(it)) }
        return ids
    }

    private fun childStore(parentStore: Store<List<ExtendedModelTree>>, childId: String): Store<ExtendedModelTree?> {
        val childLens = lensOf<List<ExtendedModelTree>, ExtendedModelTree?>(
            "childLens",
            getter = { parent -> parent.find { it.id == childId } },
            setter = { parentList, updatedChild ->
                if (updatedChild != null) {
                    parentList.map { if (it.id == childId) updatedChild else it }
                } else {
                    parentList
                }
            }
        )
        return parentStore.map(childLens)
    }

    private val childrenLens = lensOf<ExtendedModelTree, List<ExtendedModelTree>>(
        "childrenLens",
        getter = { parent -> parent.children },
        setter = { parent, newChildren ->
            parent.copy(children = newChildren.map { it.copy() }.toMutableList(), version = parent.version + 1)
        }
    )

    private val selectedLens = lensOf<ExtendedModelTree, Boolean>(
        "selectedLens",
        getter = { model -> model.isSelected },
        setter = { model, isSelected -> model.copy(isSelected = isSelected) }
    )

    private fun childrenStore(parentStore: Store<ExtendedModelTree>): Store<List<ExtendedModelTree>> {
        return parentStore.map(childrenLens)
    }

    private fun childrenNullableStore(parentStore: Store<ExtendedModelTree?>): Store<List<ExtendedModelTree>> {
        return parentStore.map(childrenLens)
    }

    fun renderModelSelector(context: RenderContext, router: MapRouter? = null) {
        extendedModelTreeStore.initialize(collapsedStateStore)

        with(context) {
            div {
                renderCollapseAll()
                renderTree(router)
                if (mode == Mode.EDIT) {
                    renderAddLink(router)
                }
            }
        }
    }

    private fun RenderContext.renderAddLink(router: MapRouter?) {
        val translationStore by koin.inject<TranslationStore>()
        router?.let {
            div("mt-4") {
                a("flex items-center cursor-pointer " +
                        "hover:bg-greyscale-80 hover:rounded hover:shadow-[rgba(0,0,15,0.3)_0px_2px_2px_0px]") {
                    attr("role", "link")
                    tabIndex(0)
                    img(
                        "h-8 p-1 rounded aspect-square " +
                                "hover:bg-tertiary-50 hover:border hover:border-primary-10 focus-visible:bg-tertiary-50 " +
                                "focus-visible:border focus-visible:border-primary-10 focus-visible:outline-none"
                    ) {
                        src("../images/addGradient.svg")
                        alt("addLink to model customization")
                    }
                    span("pl-2") {
                        translationStore[UiModelSelector.Add].renderText(this)
                    }
                    keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }.map {
                        mapOf("page" to Pages.modelCustomization, "id" to "0", "init" to "true")
                    } handledBy it.navTo
                    clicks.map {
                        mapOf("page" to Pages.modelCustomization, "id" to "0", "init" to "true")
                    } handledBy it.navTo
                }
            }
        }
    }

    private fun RenderContext.renderCollapseAll() {
        val inlinePadding = if (mode == Mode.SELECT) "px-0" else "px-1"
        div("$inlinePadding mb-3") {
            button(
                "rounded-full bg-primary-10 aspect-square w-6 p-2 flex items-center justify-center relative " +
                        "mr-1 hover:shadow-hover hover:border-primary-10 hover:bg-darkest-0 hover:border " +
                        "focus-visible:shadow-hover focus-visible:border-primary-10 focus-visible:bg-darkest-0 " +
                        "focus-visible:border focus-visible:outline-none focus-visible:bg-darkest-0"
            ) {
                classMap(collapsedStateStore.data.map {
                    val isCollapsed = it.none { collapsePair -> !collapsePair.value }
                    mapOf(
                        "triangle-down" to !isCollapsed,
                        "triangle-right" to isCollapsed
                    )
                })
                clicks.map { collapsedStateStore.current } handledBy { collapsedMap ->
                    if (collapsedMap.any { !it.value }) {
                        extendedModelTreeStore.collapseAll(collapsedStateStore)
                    } else {
                        extendedModelTreeStore.expandAll(collapsedStateStore)
                    }
                }
            }
        }
    }


    private fun RenderContext.renderTree(router: MapRouter?) {
        ul {
            val childrenStore = childrenStore(extendedModelTreeStore)
            renderItem(childrenStore, router)
        }
    }

    private fun RenderContext.renderItem(store: Store<List<ExtendedModelTree>>, router: MapRouter?) {

        store.data.renderEach(ExtendedModelTree::id) { tree ->
            val childStore = childStore(store, tree.id)
            li {
                when (tree.type) {
                    DocumentType.FOLDER -> renderFolder(this, tree, childStore, router)
                    DocumentType.MODEL -> renderModel(this, tree, childStore, router)
                    else -> console.error("Unsupported type")
                }
            }
        }
    }


    private fun RenderContext.renderFolder(
        tag: HtmlTag<HTMLLIElement>,
        tree: ExtendedModelTree,
        treeStore: Store<ExtendedModelTree?>,
        router: MapRouter?
    ) {
        tag.classList(listOf("flex", "flex-col", "gap-2", "py-0.5", "item-center"))
        val inlinePadding = if (mode == Mode.SELECT) "px-0" else "px-1"

        div(
            "flex gap-2 item-center py-0.5 $inlinePadding hover:shadow-[rgba(0,0,15,0.3)_0px_2px_2px_0px] " +
                    "hover:rounded hover:bg-greyscale-80"
        ) {
            button(
                "rounded-full bg-primary-10 aspect-square w-6 p-2 flex items-center justify-center relative " +
                        "mr-1 hover:shadow-hover hover:border-primary-10 hover:bg-darkest-0 hover:border " +
                        "focus-visible:shadow-hover focus-visible:border-primary-10 focus-visible:bg-darkest-0 " +
                        "focus-visible:border focus-visible:outline-none focus-visible:bg-darkest-0"
            ) {
                classMap(collapsedStateStore.data.map {
                    val isCollapsed = it[tree.id] ?: false
                    mapOf(
                        "triangle-down" to !isCollapsed,
                        "triangle-right" to isCollapsed
                    )
                })
                clicks.map { tree.id } handledBy ::toggleCollapsed
            }
            if (mode == Mode.SELECT) {
                inputCheckbox(tree, treeStore)
            }
            +tree.name
        }


        if (tree.children.isNotEmpty()) {
            val isExpanded = collapsedStateStore.data.map {
                it[tree.id] ?: false
            }
            val marginLeft = if (mode == Mode.SELECT) "ml-16" else "ml-8"
            ul(marginLeft) {
                hidden(isExpanded)

                val childrenStore = childrenNullableStore(treeStore)
                renderItem(childrenStore, router)
            }
        }

    }

    private fun RenderContext.renderModel(
        tag: HtmlTag<HTMLLIElement>,
        tree: ExtendedModelTree,
        treeStore: Store<ExtendedModelTree?>,
        router: MapRouter?
    ) {
        tag.classList(
            listOf(
                "flex", "gap-2", "item-center", "py-0.5", "px-0", "items-center",
                "hover:shadow-[rgba(0,0,15,0.3)_0px_2px_2px_0px]", "hover:rounded", "hover:bg-greyscale-80"
            )
        )

        when (mode) {
            Mode.SELECT -> {
                inputCheckbox(tree, treeStore)
            }

            Mode.EDIT -> {
                editLink(tree, router!!)
            }
        }
        span {
            +tree.name
        }

    }

    private fun RenderContext.inputCheckbox(tree: ExtendedModelTree, treeStore: Store<ExtendedModelTree?>) {
        val selectedStore = treeStore.map(selectedLens)

        input(
            "relative aspect-square lowercase border border-primary-10 w-6 " +
                    "hover:bg-tertiary-50 hover:shadow-checkbox-inset " +
                    "focus-visible:bg-tertiary-50 focus-visible:shadow-checkbox-inset focus-visible:outline-none " +
                    "aria-checked:bg-tertiary-50 aria-checked:shadow-checkbox-inset " +
                    "aria-checked:after:content-[''] aria-checked:after:absolute aria-checked:after:h-8px " +
                    "aria-checked:after:w-14px aria-checked:after:border-l-2 aria-checked:after:border-b-2 " +
                    "aria-checked:after:top-1 aria-checked:after:left-1 aria-checked:after:text-primary-10 " +
                    "aria-checked:after:-rotate-45 aria-checked-hover-bg-transparent aria-checked-focus-visible-bg-transparent"
        ) {
            type("checkbox")
            checked(selectedStore.data)
            attr("aria-checked", selectedStore.data.map { it.toString() })

            changes.states().map { isSelected ->
                Triple(tree.id, isSelected, tree.type)
            } handledBy {
                extendedModelTreeStore.updateIdSelected(it)
            }
        }
    }

    private fun RenderContext.editLink(tree: ExtendedModelTree, router: MapRouter) {
        a("p-6 contents cursor-pointer") {
            attr("role", "link")
            tabIndex(0)
            img(
                "h-8 p-1 rounded aspect-square " +
                        "hover:bg-tertiary-50 hover:border hover:border-primary-10 focus-visible:bg-tertiary-50 " +
                        "focus-visible:border focus-visible:border-primary-10 focus-visible:outline-none"
            ) {
                src("../images/edit.svg")
                alt("editLink to model customization")
            }
            keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }.map {
                mapOf("page" to Pages.modelCustomization, "id" to tree.id, "init" to "true")
            } handledBy router.navTo
            clicks.map {
                mapOf("page" to Pages.modelCustomization, "id" to tree.id, "init" to "true")
            } handledBy router.navTo
        }
    }

    private suspend fun toggleCollapsed(id: String) {
        val currentState = collapsedStateStore.current
        val newState = currentState.toMutableMap().apply {
            this[id] = !(this[id] ?: false)
        }
        collapsedStateStore.enqueue { newState }
    }

    fun getIdStore(): Store<List<Int>> {
        return modelTreeIdStore
    }
}
