package admin.sides.stores

import api.deleteFeature
import api.getFeatures
import api.getPermissions
import api.putDocumentFolder
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.headless.components.toast
import domain.repository.Feature
import domain.repository.Permission
import kotlinx.coroutines.Job

object FeaturesStore : RootStore<List<Feature>>(emptyList(), Job()) {

    val initialize: SimpleHandler<Boolean> = handle { _, _ ->
        try {
            toast("success", 1000L, "successToast") {
                +"Retrieving of features successful"
            }
            getFeatures()
        } catch (e: Exception) {
            toast("error", classes = "errorToast") {
                e.message
            }
            emptyList()
        }
    }

    val delete: SimpleHandler<Feature> = handle { features, featureToDelete ->
        try {
            if(deleteFeature(featureToDelete)) {
                features.filterNot { it.id == featureToDelete.id }
            } else {
                toast("error", classes = "errorToast") {
                    + "Deletion of feature failed"
                }
                features
            }
        } catch (e: Exception) {
            toast("error", classes = "errorToast") {
                e.message
            }
            features
        }
    }
}