package api

import domain.userManagement.User
import domain.userManagement.UserApproval
import domain.userManagement.UserFilter
import domain.util.BooleanValue
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

suspend fun isAdmin(): Boolean {
    val response = bearerJsonClient.get(User.PATH + "/isAdmin") {}
    return if (response.status == HttpStatusCode.OK) {
        (response.body() as BooleanValue).boolValue
    } else {
        false
    }
}

suspend fun getPersonalId(): Int {
    val response = bearerJsonClient.get(User.PATH + "/personalId") {}
    return if (response.status == HttpStatusCode.OK) {
        (response.body() as Int).toInt()
    } else {
        0
    }
}

suspend fun getUsers(): List<User> {
    return bearerJsonClient.get(User.PATH) {}.body()
}

suspend fun getUsers(limit: Int, offset: Long): List<User> {
    return bearerJsonClient.get(User.PATH) {
        url {
            parameters.append("limit", limit.toString())
            parameters.append("offset", offset.toString())
        }
    }.body()
}

suspend fun getUser(id: Long): User {
    val response = bearerJsonClient.get(User.PATH + "/${id}") {}
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Retrieving user failed ${response.status}")
    }
}

suspend fun getUsersCount(): Long {
    return bearerJsonClient.get(User.PATH + "/count").body()
}

suspend fun getUsersFilter(limit: Int, offset: Long, userFilter: UserFilter): List<User> {
    return bearerJsonClient.get(User.PATH + UserFilter.PATH) {
        url {
            parameters.append("limit", limit.toString())
            parameters.append("offset", offset.toString())
            parameters.append("filter", userFilter.filter)
        }
    }.body()
}

suspend fun getUsersFilterCount(userFilter: UserFilter): Long {
    return bearerJsonClient.get(User.PATH + UserFilter.PATH + "/count") {
        url {
            parameters.append("filter", userFilter.filter)
        }
    }.body()
}

suspend fun approveUser(id: Int, approval: Boolean): HttpResponse {
    return bearerJsonClient.post(User.PATH + "/${id}" + UserApproval.PATH) {
        contentType(ContentType.Application.Json)
        setBody(UserApproval(id, approval))
    }
}

suspend fun putUser(id: Long, user: User): HttpResponse {
    return bearerJsonClient.put(User.PATH + "/${id}") {
        contentType(ContentType.Application.Json)
        setBody(user)
    }
}