package components

import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.placeholder
import dev.fritz2.headless.components.textArea
import koin
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import org.w3c.dom.HTMLDivElement
import util.Message

fun HtmlTag<HTMLDivElement>.textareaInput(label: Flow<String>, valueStore: Store<String>, msgs: Flow<List<Message>>) {
    val translationStore by koin.inject<TranslationStore>()
    textArea ("textareaContainer") {
        value(valueStore)
        textareaTextfield ("textareaTextfield") {
            placeholder(label)
        }
        textareaLabel("textareaLabel") {
            label.renderText(this)
        }
        textareaValidationMessages(tag = RenderContext::div) {
            className("textareaMessageContainer")
            msgs.map { it.filter { msg -> msg.path == valueStore.path } }.renderEach {
                div("textareaMessage") {
                    translationStore[it.text].renderText(this)
                }
            }
        }
    }
}