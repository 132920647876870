// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import domain.userManagement.User
import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.collections.List

public fun Rule.Companion.id(): Lens<Rule, Int> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v)}
  )

public fun <PARENT> Lens<PARENT, Rule>.id(): Lens<PARENT, Int> = this + Rule.id()

public fun Rule.Companion.label(): Lens<Rule, String> = lensOf(
    "label",
    { it.label },
    { p, v -> p.copy(label = v)}
  )

public fun <PARENT> Lens<PARENT, Rule>.label(): Lens<PARENT, String> = this + Rule.label()

public fun Rule.Companion.owner(): Lens<Rule, User?> = lensOf(
    "owner",
    { it.owner },
    { p, v -> p.copy(owner = v)}
  )

public fun <PARENT> Lens<PARENT, Rule>.owner(): Lens<PARENT, User?> = this + Rule.owner()

public fun Rule.Companion.combinations(): Lens<Rule, List<Combination>> = lensOf(
    "combinations",
    { it.combinations },
    { p, v -> p.copy(combinations = v)}
  )

public fun <PARENT> Lens<PARENT, Rule>.combinations(): Lens<PARENT, List<Combination>> = this +
    Rule.combinations()

public fun Rule.Companion.metadata(): Lens<Rule, List<String>> = lensOf(
    "metadata",
    { it.metadata },
    { p, v -> p.copy(metadata = v)}
  )

public fun <PARENT> Lens<PARENT, Rule>.metadata(): Lens<PARENT, List<String>> = this +
    Rule.metadata()

public fun Rule.Companion.language(): Lens<Rule, Language> = lensOf(
    "language",
    { it.language },
    { p, v -> p.copy(language = v)}
  )

public fun <PARENT> Lens<PARENT, Rule>.language(): Lens<PARENT, Language> = this + Rule.language()

public fun Rule.Companion.span(): Lens<Rule, Span> = lensOf(
    "span",
    { it.span },
    { p, v -> p.copy(span = v)}
  )

public fun <PARENT> Lens<PARENT, Rule>.span(): Lens<PARENT, Span> = this + Rule.span()

public fun Rule.Companion.headingSearch(): Lens<Rule, Boolean> = lensOf(
    "headingSearch",
    { it.headingSearch },
    { p, v -> p.copy(headingSearch = v)}
  )

public fun <PARENT> Lens<PARENT, Rule>.headingSearch(): Lens<PARENT, Boolean> = this +
    Rule.headingSearch()

public fun Rule.Companion.orderSensitivity(): Lens<Rule, Boolean> = lensOf(
    "orderSensitivity",
    { it.orderSensitivity },
    { p, v -> p.copy(orderSensitivity = v)}
  )

public fun <PARENT> Lens<PARENT, Rule>.orderSensitivity(): Lens<PARENT, Boolean> = this +
    Rule.orderSensitivity()

public fun Rule.Companion.connector(): Lens<Rule, Connector> = lensOf(
    "connector",
    { it.connector },
    { p, v -> p.copy(connector = v)}
  )

public fun <PARENT> Lens<PARENT, Rule>.connector(): Lens<PARENT, Connector> = this +
    Rule.connector()

public fun Rule.Companion.uniqueIdentifier(): Lens<Rule, String> = lensOf(
    "uniqueIdentifier",
    { it.uniqueIdentifier },
    { p, v -> p.copy(uniqueIdentifier = v)}
  )

public fun <PARENT> Lens<PARENT, Rule>.uniqueIdentifier(): Lens<PARENT, String> = this +
    Rule.uniqueIdentifier()
