package admin.sides.stores

import api.*
import com.tryformation.localization.Translatable
import components.textInput
import dev.fritz2.core.*
import dev.fritz2.headless.components.toast
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.valid
import domain.userManagement.User
import domain.userManagement.UserFilter
import io.ktor.http.*
import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.flow.*
import localization.TranslationStore
import org.w3c.dom.HTMLElement
import util.Message


const val LIMIT = 50

val userCountStore = RootStore(0L, Job())

object UsersStore : RootStore<List<User>>(emptyList(), Job()) {

    private val getInitialUsers: SimpleHandler<Unit> = handle {
        userCountStore.update(getUsersCount())
        fetchUsers(0L)
    }

    val extendUsers: Handler<Long> = handle { users, offset ->
        val userList = users.toMutableList()
        val userFilter: UserFilter = UsersFilterStore.data.first()
        userList += fetchUsers(offset, userFilter)
        userList
    }

    val approveUser: Handler<Pair<Int, Boolean>> = handle {users, pair ->
        val response = approveUser(pair.first, pair.second)
        if(response.status == HttpStatusCode.OK) {
            val updatedUsers = users.map { user ->
                if (user.id == pair.first) {
                    user.copy(approved = pair.second)
                } else {
                    user
                }
            }
            updatedUsers
        } else {
            users
        }
    }

    init {
        getInitialUsers()
    }
}

object UsersFilterStore : ValidatingStore<UserFilter, Unit, Message>(
    UserFilter(""),
    UserFilter.validation,
    Unit, Job(),
    validateAfterUpdate = false
) {
    val save = handle {
        if (validate(it).valid) {
            try {
                resetMessages()
                userCountStore.update(getUsersFilterCount(it))
                val userList: List<User> = getUsersFilter(LIMIT, 0, it)
                UsersStore.update(userList)
                toast("success", 1000L, "successToast") {
                    +"Filtering successful"
                }
            } catch (e: Exception) {
                toast("error", classes = "errorToast") {
                    +"Failed to filter users: ${e.message}"
                }
                console.error("Failed to filter users: ${e.message}")
            }
            it
        } else it
    }
}

object UsersOffsetStore : RootStore<Long>(0L, Job()) {
    override val update: SimpleHandler<Long> = handle { _, offset ->
        UsersStore.extendUsers(offset)
        offset
    }

    val increase = handle {
        var offset = 0
        if (UsersStore.data.first().size % LIMIT == 0) {
            offset = UsersStore.data.first().size
            update(offset.toLong())
        }
        offset.toLong()
    }

    val reset = handle {
        0
    }
}

object UsersButtonVisibilityStore : RootStore<Boolean>(true, Job()) {
    init {
        val usersCountFlow = UsersStore.data.map { it.size.toLong() }
        val userCount = userCountStore.data

        val combinedFlow = usersCountFlow.combine(userCount) { usersCount, totalUsersCount ->
            usersCount < totalUsersCount
        }

        combinedFlow.onEach { value -> update(value) }.launchIn(MainScope())
    }
}

fun RenderContext.userFiltering(
    filter: Store<String>,
    filterMsgs: Flow<List<Message>>,
    inputLabel: Translatable,
    buttonLabel: Translatable,
    translationStore: TranslationStore
) {
    div("grid grid-cols-[1fr_auto] gap-4 mt-8") {
        textInput(translationStore[inputLabel], filter, filterMsgs)
        button("bg-gradient-to-r from-darkest-0 to-primary-10 rounded-lg text-greyscale-100 border px-8 " +
                "mt-4 mr-4 mb-8 " +
                "border-primary-10 hover:bg-none hover:bg-darkest-0 hover:shadow-xl hover:cursor-pointer " +
                "focus-visible:bg-none focus-visible:bg-darkest-0 focus-visible:shadow-xl") {
            translationStore[buttonLabel].renderText(this)
            attr("aria-label", "filter user list")
            clicks handledBy UsersFilterStore.save
        }
    }
}

fun Tag<HTMLElement>.userLoadMoreButton(
    buttonLabel: Translatable,
    className: String,
    translationStore: TranslationStore
) {
    button(baseClass = className) {
        translationStore[buttonLabel].renderText(this)
        classMap(UsersButtonVisibilityStore.data.map { mapOf("display-none" to !it) })
        clicks handledBy UsersOffsetStore.increase
    }
}

private suspend fun fetchUsers(offset: Long, filter: UserFilter? = null): List<User> {
    return try {
        val users = if (filter?.filter?.trim()?.isNotBlank() == true) {
            getUsersFilter(LIMIT, offset, filter)
        } else {
            getUsers(LIMIT, offset)
        }
        toast("success", 1000L, "successToast") {
            +"User operation successful"
        }
        users
    } catch (e: Exception) {
        toast("error", classes = "errorToast") {
            +"Failed to fetch users: ${e.message}"
        }
        console.error("Failed to fetch users: ${e.message}")
        emptyList()
    }
}