package localization.policies

import com.tryformation.localization.Translatable

enum class UiTermsOfUse : Translatable {

    Heading,
    Description,
    One,
    OneOneHeading,
    OneOneContent,
    OneTwoHeading,
    OneTwoContent,
    OneThreeHeading,
    OneThreeContent,
    OneFourHeading,
    OneFourContent,
    Two,
    TwoOneHeading,
    TwoOneContent,
    TwoTwoHeading,
    TwoTwoContent,
    TwoThreeHeading,
    TwoThreeContent,
    Three,
    ThreeOneHeading,
    ThreeOneContent,
    ThreeTwoHeading,
    ThreeTwoOneHeading,
    ThreeTwoOneContent,
    ThreeTwoTwoHeading,
    ThreeTwoTwoContent,
    ThreeTwoThreeHeading,
    ThreeTwoThreeContent,
    Four,
    FourOneHeading,
    FourOneContent,
    FourTwoHeading,
    FourTwoContent,
    FourThreeHeading,
    FourThreeContent,
    FourFourHeading,
    FourFourContent,
    FourFourOneHeading,
    FourFourOneContent,
    FourFourTwoHeading,
    FourFourTwoContent,
    FourFourThreeHeading,
    FourFourThreeContent,
    FourFourFourHeading,
    FourFourFourContent,
    FourFourFiveHeading,
    FourFourFiveContent,
    FourFiveHeading,
    FourFiveContent,
    FourSixHeading,
    FourSixContent,
    FourSevenHeading,
    FourSevenContent,
    FourEightHeading,
    FourEightContent,
    FourNineHeading,
    FourNineContent,
    FourTenHeading,
    FourTenContent,
    Five,
    FiveOneHeading,
    FiveOneContent,
    FiveTwoHeading,
    FiveTwoContent,
    FiveThreeHeading,
    FiveThreeContent,
    FiveFourHeading,
    FiveFourContent,
    FiveFourOneHeading,
    FiveFourOneContent,
    FiveFourTwoHeading,
    FiveFourTwoContent,
    FiveFourThreeHeading,
    FiveFourThreeContent,
    FiveFourFourHeading,
    FiveFourFourContent,
    FiveFourFiveHeading,
    FiveFourFiveContent,
    FiveFourSixHeading,
    FiveFourSixContent,
    FiveFourSevenHeading,
    FiveFourSevenContent,
    FiveFourEightHeading,
    FiveFourEightContent,
    FiveFourNineHeading,
    FiveFourNineContent,
    FiveFiveHeading,
    FiveFiveContent,
    FiveSixHeading,
    FiveSixContent,
    FiveSevenHeading,
    FiveSevenContent,
    FiveEightHeading,
    FiveEightContent,
    FiveNineHeading,
    FiveNineContent,
    Six,
    SixOneHeading,
    SixOneContent,
    SixTwoHeading,
    SixTwoContent,
    SixThreeHeading,
    SixThreeContent,
    Seven,
    SevenOne,
    SevenOneOneHeading,
    SevenOneOneContent,
    SevenOneTwoHeading,
    SevenOneTwoContent,
    SevenTwo,
    SevenTwoOneHeading,
    SevenTwoOneContent,
    SevenTwoTwoHeading,
    SevenTwoTwoContent,
    SevenTwoThreeHeading,
    SevenTwoThreeContent,
    SevenThree,
    SevenThreeOneHeading,
    SevenThreeOneContent,
    SevenThreeTwoHeading,
    SevenThreeTwoContent,
    SevenFour,
    SevenFourOneHeading,
    SevenFourOneContent,
    SevenFourTwoHeading,
    SevenFourTwoContent,
    SevenFiveHeading,
    SevenFiveContent,
    SevenSixHeading,
    SevenSixContent,
    Eight,
    EightOneHeading,
    EightOneContent,
    EightTwoHeading,
    EightTwoContent,
    EightThreeHeading,
    EightThreeContent,
    Nine,
    NineOneHeading,
    NineOneContent,
    NineTwoHeading,
    NineTwoContent,
    NineTwoOneHeading,
    NineTwoOneContent,
    NineTwoTwoHeading,
    NineTwoTwoContent,
    NineTwoThreeHeading,
    NineTwoThreeContent,
    NineTwoFourHeading,
    NineTwoFourContent,
    Ten,
    TenOneHeading,
    TenOneContent,
    TenTwoHeading,
    TenTwoContent,
    TenThreeHeading,
    TenThreeContent,
    ElevenHeading,
    ElevenContent,
    TwelveHeading,
    TwelveContent,
    ThirteenHeading,
    ThirteenContent,
    Fourteen,
    FourteenOneHeading,
    FourteenOneContent,
    FourteenTwoHeading,
    FourteenTwoContent,
    FourteenThreeHeading,
    FourteenThreeContent,
    FourteenFourHeading,
    FourteenFourContent,
    FourteenFiveHeading,
    FourteenFiveContent;

    override val prefix: String = "tou"
}