package policies

import components.DisclosureText
import components.keyspotDisclosure
import dev.fritz2.core.RenderContext
import koin
import localization.TranslationStore
import localization.policies.UiCookie


fun RenderContext.cookiePolicy(styleClass: String) {
    val translationStore by koin.inject<TranslationStore>()

    article(styleClass) {
        h1("text-3xl font-semibold") {
            translationStore[UiCookie.Heading].renderText(this)
        }
        div {
            translationStore[UiCookie.Application].renderText(this)
        }

        listOf(
            UiCookie.IntroductionHeading to UiCookie.IntroductionContent,
            UiCookie.ExplainingHeading to UiCookie.ExplainingContent,
            UiCookie.UsageHeading to UiCookie.UsageForDescription,
            UiCookie.ManagingHeading to UiCookie.ManagingContent,
            UiCookie.ChangesHeading to UiCookie.ChangesContent
        ).forEach { (heading, content) ->
            if (heading == UiCookie.UsageHeading) {
                keyspotDisclosure(DisclosureText.TranslatableContent(heading), DisclosureText.TranslatableContent(content), usageContent())
            } else {
                keyspotDisclosure(DisclosureText.TranslatableContent(heading), DisclosureText.TranslatableContent(content))
            }
        }
    }
}


fun usageContent(): RenderContext.() -> Unit = {
    val translationStore by koin.inject<TranslationStore>()

    div {
        ul("list-disc px-6") {
            listOf(
                UiCookie.UsageForFirstHeading to UiCookie.UsageForFirstContent,
                UiCookie.UsageForSecondHeading to UiCookie.UsageForSecondContent
            ).forEach { (heading, content) ->
                li {
                    span("font-semibold pr-4") { translationStore[heading].renderText(this) }
                    span {  translationStore[content].renderText(this)  }
                }
            }
        }

        div("mt-4") { translationStore[UiCookie.UsageSpecialDescription].renderText(this) }
        ul("list-disc px-6") {
            listOf(
                UiCookie.UsageSpecialFirstHeading to UiCookie.UsageSpecialFirstContent,
                UiCookie.UsageSpecialSecondHeading to UiCookie.UsageSpecialSecondContent,
                UiCookie.UsageSpecialThirdHeading to UiCookie.UsageSpecialThirdContent
            ).forEach { (heading, content) ->
                li {
                    span("font-semibold pr-4") { translationStore[heading].renderText(this) }
                    span {  translationStore[content].renderText(this)  }
                }
            }
        }
        div("mt-4") {translationStore[UiCookie.NecessaryContent].renderText(this)}
    }
}


