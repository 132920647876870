package domain.model

enum class CompoundCombinatorics(val representation: String) {
    FULL("full"),
    NO_COVERAGE("noCoverage"),
    NO_OVERLAP("noOverlap");

    companion object {
        fun fromRepresentation(representation: String): CompoundCombinatorics {
            return entries.find { it.representation == representation } ?: NO_COVERAGE
        }
    }
}