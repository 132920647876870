package homepage

import com.tryformation.localization.Locale
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import homepage.sides.FaqStore
import kotlinx.browser.document
import kotlinx.coroutines.Job
import localization.Locales

class LanguageStore : RootStore<Locale>(Locales.EN_US, Job()) {

    override val update: SimpleHandler<Locale> = handle { _, newLocale ->
        document.getElementById("languageButton")?.setAttribute("selectedLang", newLocale.name)
        FaqStore.updateFaq(newLocale)
        newLocale
    }
}