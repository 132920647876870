package localization.homepage

import com.tryformation.localization.Translatable

enum class UiBenefit : Translatable {
    Heading,
    Desc,
    TimeHeading,
    TimeDesc,
    CompetitivenessHeading,
    CompetitivenessDesc,
    RiskHeading,
    RiskDesc;

    override val prefix: String = "benefit"
}