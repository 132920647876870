package platform.sides

import components.headingBanner
import dev.fritz2.core.RenderContext
import dev.fritz2.headless.components.tabGroup
import koin
import localization.TranslationStore
import localization.platform.UiImpressum
import localization.policies.*
import policies.*

fun RenderContext.impressum() {

    val translationStore by koin.inject<TranslationStore>()

    article("w-full text-primary-10") {

        headingBanner(
            translationStore[UiImpressum.Heading],
            translationStore[UiImpressum.Description],
            "bg-heading-banner-5"
        )

        corporateInformation("text-left px-8", "flex justify-start")

        tabGroup("mt-16") {
            tabList("bg-primary-90 rounded-lg flex justify-between mx-4") {
                tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                    translationStore[UiCookie.Heading].renderText(
                        this
                    )
                }
                tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                    translationStore[UiPrivacy.Heading].renderText(
                        this
                    )
                }
                tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                    translationStore[UiTermsOfUse.Heading].renderText(
                        this
                    )
                }
                tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                    translationStore[UiAup.Heading].renderText(
                        this
                    )
                }
                tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                    translationStore[UiLegal.Heading].renderText(
                        this
                    )
                }
            }
            tabPanels {
                panel { cookiePolicy("text-left px-8") }
                panel { privacyPolicy("text-left px-8", "flex justify-start") }
                panel { tou("text-left px-8") }
                panel { aup("text-left px-8") }
                panel { legal("text-left px-8") }
            }
        }


    }
}