package localization.login

import com.tryformation.localization.Translatable

enum class UiLogin :Translatable {

    Heading,
    PinHeading,
    Email,
    EmailInvalid,
    Pin,
    PinInvalid,
    Password,
    PasswordInvalid,
    PasswordForget,
    Button,
    SignIn,
    Unsuccessful,
    Successful,
    InvalidPin,
    WaitingForApproval;

    override val prefix: String = "login"

}