package domain.repository

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import dev.fritz2.validation.validation
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable
import localization.admin.UiDirectoryEdit
import util.Message
import util.Severity

@Lenses
@Serializable
data class Document(
    val id: Int,
    val name: String,
    val type: DocumentType,
    val folderType: SingletonFolderType?,
    val uri: String,
    val creationDateTime: LocalDateTime,
    val updateDateTime: LocalDateTime,
    val parent: Document?,
    val children: List<Document>  = emptyList()
) {
    companion object {
        const val FOLDER_PATH = "/folder"
        const val FILE_PATH = "/file"
        const val MODEL_PATH = "/model"
        const val DOWNLOAD_PATH = "/download"
        const val PATH = "/document"
        const val CHILDREN_PATH = "/children"

        val validation: Validation<Document, Unit, Message> = validation { inspector ->
            val name = inspector.map(Document.name())
            if (validateName(name.data)) {
                add(Message(name.path, Severity.Error, UiDirectoryEdit.NameValidation))
            }
        }

        fun validate(document: Document): Boolean = validateName(document.name)

        private fun validateName(name: String): Boolean = false
    }
}