package domain.model

import dev.fritz2.core.Id
import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import kotlinx.serialization.Serializable
import localization.platform.UiModelRule
import util.Message
import util.Severity

@Lenses
@Serializable
data class Combination(
    val id: Int,
    val connector: Connector = Connector.OR,
    val orderSensitivity: Boolean = false,
    val type: CombinationType,
    val words: List<Word>,

    val uniqueIdentifier: String = Id.next(16)
) {
    fun isContentEqual(other: Combination): Boolean {
        if (this === other) return true
        if (connector != other.connector) return false
        if (orderSensitivity != other.orderSensitivity) return false
        if (type != other.type) return false
        if (!words.isContentEqual(other.words)) return false
        return true
    }

    private fun List<Word>?.isContentEqual(other: List<Word>?): Boolean {
        if (this == null && other == null) return true
        if (this == null || other == null) return false
        if (this.size != other.size) return false

        return this.all { word ->
            val otherWord = other.find { it.id == word.id } ?: return false
            word.isContentEqual(otherWord)
        }
    }

    fun getPreview(): String {
        return when (connector) {
            Connector.OR -> getOrPreview()
            Connector.NOR -> getNegationPreview()
            Connector.POR -> getPageElementPreview()
            Connector.NPOR -> getNegativePageElementPreview()
            Connector.AND -> "There should be no and connector on this level"
        }
    }

    private fun getNegativePageElementPreview(): String {
        return "$~(${words.joinToString("||") { it.word }})~$"
    }

    private fun getPageElementPreview(): String {
        return "$(${words.joinToString("||") { it.word }})$"
    }

    private fun getNegationPreview(): String {
        return "!(${words.joinToString("||") { it.word }})"
    }

    private fun getOrPreview(): String {
        return "(${words.joinToString("||") { it.word }})"
    }

    companion object {
        val validation: Validation<Combination, Unit, Message> = dev.fritz2.validation.validation { inspector ->
        }
    }
}