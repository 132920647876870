package domain.repository

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import dev.fritz2.validation.validation
import domain.model.Rule
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable
import localization.platform.UiScreening
import util.Message
import util.Severity

@Lenses
@Serializable
data class Screening(
    val id: Int,
    val name: String,
    val state: ScreeningState,
    val userId: Int,
    val creationDateTime: LocalDateTime?,
    val startDateTime: LocalDateTime?,
    val deletionDateTime: LocalDateTime?,
    val downloadDateTime: LocalDateTime?,
    val numDocuments: Int?,
    val numPages: Int?,
    val numOcr: Int?,
    val slot: Int?,
    val modelIds: List<Int> = emptyList(),
    val rules: List<Rule> = emptyList()
) {
    fun areRulesContentEqual(ruleList: List<Rule>): Boolean {
        if (rules.size != ruleList.size) {
            return false
        }

        return rules.zip(ruleList).all { (rule1, rule2) ->
            rule1.isContentEqual(rule2)
        }
    }

    companion object {
        const val PATH = "/screening"

        const val FEATURE_MULTI_SLOT = 1
        const val FEATURE_IGNORE_HEADER_FOOTER = 1 shl 1
        const val FEATURE_PDF_INDEXES = 1 shl 2
        const val FEATURE_REQ_LIST = 1 shl 3
        const val FEATURE_RESULT_PROFILE = 1 shl 4
        const val FEATURE_ENUM_DETECTION = 1 shl 5
        const val FEATURE_CUSTOM = 1 shl 6
        const val FEATURE_CSV = 1 shl 7
        const val FEATURE_XYR = 1 shl 8
        const val FEATURE_ACOM = 1 shl 9
        const val FEATURE_OCR = 1 shl 10
        const val FEATURE_SUMMARY = 1 shl 11
        const val FEATURE_CUSTOM_TERMS = 1 shl 12
        const val FEATURE_PREDEFINED_TERMS = 1 shl 13
        const val FEATURE_CASE_SENSITIVITY = 1 shl 14
        const val FEATURE_REDUCED = 1 shl 15
        const val FEATURE_EXCEL = 1 shl 16
        const val FEATURE_JSON = 1 shl 17
        const val FEATURE_REQ_IF_OUTPUT = 1 shl 18
        const val FEATURE_TRANSLATE = 1 shl 19
        const val FEATURE_CONVERT_TO_PDF = 1 shl 20
        const val FEATURE_AI_SEARCH = 1 shl 21
        const val FEATURE_DEFAULT_SPONSOR = 1 shl 22
        const val FEATURE_IGNORE_TOC_ABBRIVIATION = 1 shl 23

        private var featureFlag = 0

        val validation: Validation<Screening, Unit, Message> = validation { inspector ->
            val name = inspector.map(Screening.name())
            if (!validateName(name.data)) {
                add(Message(name.path, Severity.Error, UiScreening.NameValidation))
            }
        }

        fun validate(screening: Screening): Boolean = validateName(screening.name)

        private fun validateName(name: String): Boolean = name.length < 50
    }

    fun enableFeatures(features: List<Int>) {
        features.forEach { feature ->
            featureFlag = featureFlag or feature
        }
    }

    fun disableFeatures(features: List<Int>) {
        features.forEach { feature ->
            featureFlag = featureFlag and feature.inv()
        }
    }

    fun enableFeature(feature: Int) {
        featureFlag = featureFlag or feature
    }

    fun disableFeature(feature: Int) {
        featureFlag = featureFlag and feature.inv()
    }

    fun toggleFeature(feature: Int) {
        featureFlag = featureFlag xor feature
    }

    fun isFeatureAllowed(feature: Int): Boolean {
        return featureFlag and feature == feature
    }

    fun isFeatureNotAllow(feature: Int): Boolean {
        return featureFlag and feature == 0
    }
}