package localization.admin

import com.tryformation.localization.Translatable

enum class UiFeatureManagement : Translatable {
    Heading,
    Description,
    FilterLabel,
    NewButton,
    EditButton,
    DeleteButton,
    DeleteModalTitle,
    DeleteModalDescription,
    DeleteModalDeleteButton,
    DeleteModalCancelButton;

    override val prefix: String = "feature-management"
}