package localization.login

import com.tryformation.localization.Translatable

enum class UiRegister :Translatable {

    Heading,
    Email,
    EmailInvalid,
    Username,
    UsernameInvalid,
    Password,
    PasswordInvalid,
    PasswordConfirmation,
    PasswordConfirmationInvalid,
    PasswordEquality,
    Button,
    MailTaken,
    Unsuccessful,
    Successful;

    override val prefix: String = "register"
}