package components

import dev.fritz2.core.*
import kotlinx.coroutines.flow.filter

fun RenderContext.skipToMain() {
    val skipToMainStore: Store<Boolean> = storeOf(false)

    a("skip") {
        attr("role", "link")
        tabIndex(0)
        +"Skip to main content"


        keydownsCaptured.filter { shortcutOf(it) == Keys.Enter } handledBy skipToMainStore.handle {
            val mainComponent = web.dom.document.getElementById("main")
            mainComponent?.scrollIntoView()
            mainComponent?.focus()
            true
        }
        clicks handledBy skipToMainStore.handle {
            val mainComponent = web.dom.document.getElementById("main")
            mainComponent?.scrollIntoView()
            mainComponent?.focus()
            true
        }
    }
}