package login

import api.postPasswordUpdateReset
import components.defaultHeader
import components.textInput
import dev.fritz2.core.render
import dev.fritz2.core.storeOf
import dev.fritz2.headless.components.TabGroup
import dev.fritz2.headless.components.tabGroup
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.valid
import domain.authentication.login.PasswordUpdateReset
import domain.authentication.login.password
import domain.authentication.login.passwordConfirmation
import koin
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.first
import localization.TranslationStore
import localization.login.UiPasswordUpdate
import org.w3c.dom.HTMLDivElement
import react.FC
import react.Props
import util.Message
import utils.mainButton
import web.url.URLSearchParams


external interface PasswordUpdateResetProps : Props

val PasswordUpdateResetPage = FC<PasswordUpdateResetProps> {
    val translationStore by koin.inject<TranslationStore>()

    render("#root") {
        defaultHeader()
        main(id = "main") {
            article("ta-ce") {
                tabGroup("tabGroup") {
                    tabList("tabList") {
                        tab("tabListBtn") { translationStore[UiPasswordUpdate.Heading].renderText(this) }
                    }
                    tabPanels("tabPanels") {
                        passwordUpdateResetPanel()
                    }
                }
            }
        }
    }
}

private val responseMsgStore = storeOf("", Job())

private object PasswordUpdateResetStore : ValidatingStore<PasswordUpdateReset, Unit, Message>(
    PasswordUpdateReset("", ""),
    PasswordUpdateReset.validation,
    Unit, Job(),
    validateAfterUpdate = false
) {
    val save = PasswordUpdateResetStore.handle {
        val translationStore by koin.inject<TranslationStore>()

        if (PasswordUpdateResetStore.validate(it).valid) {
            PasswordUpdateResetStore.resetMessages()

            val queryString = window.location.search
            val urlParams = URLSearchParams(queryString)
            val token = urlParams["token"]

            val response = token?.let { urlToken ->
                postPasswordUpdateReset(PasswordUpdateReset(it.password, it.passwordConfirmation), urlToken)
            } ?: translationStore[UiPasswordUpdate.MissingToken]
            responseMsgStore.update(response.first())
            PasswordUpdateReset(it.password, it.passwordConfirmation)
        } else it
    }
}

private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.passwordUpdateResetPanel() {
    val translationStore by koin.inject<TranslationStore>()

    val msgs: Flow<List<Message>> = PasswordUpdateResetStore.messages
    val password = PasswordUpdateResetStore.map(PasswordUpdateReset.password())
    val passwordConfirmation = PasswordUpdateResetStore.map(PasswordUpdateReset.passwordConfirmation())

    panel("tabPanel") {
        div("tabPanelHeading") {
            attr("aria-hidden", "true")
            h1("tabHeading") {
                translationStore[UiPasswordUpdate.Heading].renderText(this)
            }
            div("tabPanelUnderline") {
                attr("aria-hidden", "true")
            }
        }

        div("tabPanelResponseError") {
            responseMsgStore.data.renderText()
        }

        div("tabPanelContent") {
            textInput(translationStore[UiPasswordUpdate.Password], password, msgs, true)
            textInput(translationStore[UiPasswordUpdate.PasswordConfirmation], passwordConfirmation, msgs, true)

            button(mainButton, "loginButton") {
                translationStore[UiPasswordUpdate.Button].renderText(this)
                clicks handledBy PasswordUpdateResetStore.save
            }
        }
    }
}
