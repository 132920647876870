package api

import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.cookies.*
import io.ktor.client.request.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json

var csrfToken: String? = null

val jsonClient = HttpClient {
    install(ContentNegotiation) {
        json(Json {
            prettyPrint = true
            isLenient = true
        })
    }
    install(HttpCookies)
    defaultRequest {
        csrfToken?.let {
            header("X-CSRF-Token", it)
        }
    }
}

val bearerJsonClient = HttpClient {
    install(ContentNegotiation) {
        json(Json {
            prettyPrint = true
            isLenient = true
        })
    }
    install(HttpCookies)
    install(Auth) {
        bearer {
            loadTokens {
                BearerTokens("", "")
            }
            refreshTokens {
                postRefresh()
                BearerTokens("", "")
            }
        }
    }
    defaultRequest {
        csrfToken?.let {
            header("X-CSRF-Token", it)
        }
    }
}
