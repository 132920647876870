package localization.admin

import com.tryformation.localization.Translatable

enum class UiMonitoring : Translatable {
    Heading,
    Description,
    FilterLabel;

    override val prefix: String = "monitoring"
}