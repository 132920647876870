package localization.platform

import com.tryformation.localization.Translatable

enum class UiModelRule : Translatable {

    Heading,
    LabelLabel,
    LabelMessage,
    CombinationsMessage;

    override val prefix: String = "model-rule"
}