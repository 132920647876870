// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.repository

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import domain.model.Rule
import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlinx.datetime.LocalDateTime

public fun Screening.Companion.id(): Lens<Screening, Int> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v)}
  )

public fun <PARENT> Lens<PARENT, Screening>.id(): Lens<PARENT, Int> = this + Screening.id()

public fun Screening.Companion.name(): Lens<Screening, String> = lensOf(
    "name",
    { it.name },
    { p, v -> p.copy(name = v)}
  )

public fun <PARENT> Lens<PARENT, Screening>.name(): Lens<PARENT, String> = this + Screening.name()

public fun Screening.Companion.state(): Lens<Screening, ScreeningState> = lensOf(
    "state",
    { it.state },
    { p, v -> p.copy(state = v)}
  )

public fun <PARENT> Lens<PARENT, Screening>.state(): Lens<PARENT, ScreeningState> = this +
    Screening.state()

public fun Screening.Companion.userId(): Lens<Screening, Int> = lensOf(
    "userId",
    { it.userId },
    { p, v -> p.copy(userId = v)}
  )

public fun <PARENT> Lens<PARENT, Screening>.userId(): Lens<PARENT, Int> = this + Screening.userId()

public fun Screening.Companion.creationDateTime(): Lens<Screening, LocalDateTime?> = lensOf(
    "creationDateTime",
    { it.creationDateTime },
    { p, v -> p.copy(creationDateTime = v)}
  )

public fun <PARENT> Lens<PARENT, Screening>.creationDateTime(): Lens<PARENT, LocalDateTime?> =
    this + Screening.creationDateTime()

public fun Screening.Companion.startDateTime(): Lens<Screening, LocalDateTime?> = lensOf(
    "startDateTime",
    { it.startDateTime },
    { p, v -> p.copy(startDateTime = v)}
  )

public fun <PARENT> Lens<PARENT, Screening>.startDateTime(): Lens<PARENT, LocalDateTime?> = this +
    Screening.startDateTime()

public fun Screening.Companion.deletionDateTime(): Lens<Screening, LocalDateTime?> = lensOf(
    "deletionDateTime",
    { it.deletionDateTime },
    { p, v -> p.copy(deletionDateTime = v)}
  )

public fun <PARENT> Lens<PARENT, Screening>.deletionDateTime(): Lens<PARENT, LocalDateTime?> =
    this + Screening.deletionDateTime()

public fun Screening.Companion.downloadDateTime(): Lens<Screening, LocalDateTime?> = lensOf(
    "downloadDateTime",
    { it.downloadDateTime },
    { p, v -> p.copy(downloadDateTime = v)}
  )

public fun <PARENT> Lens<PARENT, Screening>.downloadDateTime(): Lens<PARENT, LocalDateTime?> =
    this + Screening.downloadDateTime()

public fun Screening.Companion.numDocuments(): Lens<Screening, Int?> = lensOf(
    "numDocuments",
    { it.numDocuments },
    { p, v -> p.copy(numDocuments = v)}
  )

public fun <PARENT> Lens<PARENT, Screening>.numDocuments(): Lens<PARENT, Int?> = this +
    Screening.numDocuments()

public fun Screening.Companion.numPages(): Lens<Screening, Int?> = lensOf(
    "numPages",
    { it.numPages },
    { p, v -> p.copy(numPages = v)}
  )

public fun <PARENT> Lens<PARENT, Screening>.numPages(): Lens<PARENT, Int?> = this +
    Screening.numPages()

public fun Screening.Companion.numOcr(): Lens<Screening, Int?> = lensOf(
    "numOcr",
    { it.numOcr },
    { p, v -> p.copy(numOcr = v)}
  )

public fun <PARENT> Lens<PARENT, Screening>.numOcr(): Lens<PARENT, Int?> = this + Screening.numOcr()

public fun Screening.Companion.slot(): Lens<Screening, Int?> = lensOf(
    "slot",
    { it.slot },
    { p, v -> p.copy(slot = v)}
  )

public fun <PARENT> Lens<PARENT, Screening>.slot(): Lens<PARENT, Int?> = this + Screening.slot()

public fun Screening.Companion.modelIds(): Lens<Screening, List<Int>> = lensOf(
    "modelIds",
    { it.modelIds },
    { p, v -> p.copy(modelIds = v)}
  )

public fun <PARENT> Lens<PARENT, Screening>.modelIds(): Lens<PARENT, List<Int>> = this +
    Screening.modelIds()

public fun Screening.Companion.rules(): Lens<Screening, List<Rule>> = lensOf(
    "rules",
    { it.rules },
    { p, v -> p.copy(rules = v)}
  )

public fun <PARENT> Lens<PARENT, Screening>.rules(): Lens<PARENT, List<Rule>> = this +
    Screening.rules()
