// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.contact

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.String

public fun ContactTrial.Companion.firstname(): Lens<ContactTrial, String> = lensOf(
    "firstname",
    { it.firstname },
    { p, v -> p.copy(firstname = v)}
  )

public fun <PARENT> Lens<PARENT, ContactTrial>.firstname(): Lens<PARENT, String> = this +
    ContactTrial.firstname()

public fun ContactTrial.Companion.surname(): Lens<ContactTrial, String> = lensOf(
    "surname",
    { it.surname },
    { p, v -> p.copy(surname = v)}
  )

public fun <PARENT> Lens<PARENT, ContactTrial>.surname(): Lens<PARENT, String> = this +
    ContactTrial.surname()

public fun ContactTrial.Companion.email(): Lens<ContactTrial, String> = lensOf(
    "email",
    { it.email },
    { p, v -> p.copy(email = v)}
  )

public fun <PARENT> Lens<PARENT, ContactTrial>.email(): Lens<PARENT, String> = this +
    ContactTrial.email()

public fun ContactTrial.Companion.company(): Lens<ContactTrial, String> = lensOf(
    "company",
    { it.company },
    { p, v -> p.copy(company = v)}
  )

public fun <PARENT> Lens<PARENT, ContactTrial>.company(): Lens<PARENT, String> = this +
    ContactTrial.company()

public fun ContactTrial.Companion.attentiveBy(): Lens<ContactTrial, String> = lensOf(
    "attentiveBy",
    { it.attentiveBy },
    { p, v -> p.copy(attentiveBy = v)}
  )

public fun <PARENT> Lens<PARENT, ContactTrial>.attentiveBy(): Lens<PARENT, String> = this +
    ContactTrial.attentiveBy()
