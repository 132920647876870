package homepage.sides

import api.postContact
import components.dropdownSelector
import components.policyCheckbox
import components.textInput
import components.textareaInput
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.routing.MapRouter
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.valid
import domain.contact.*
import homepage.LanguageStore
import homepage.navigation.Pages
import koin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import localization.TranslationStore
import localization.homepage.UiContact
import localization.homepage.UiFaq
import util.Message
import utils.mainButton
import web.dom.document
import web.html.HTMLButtonElement

//validationStore used for validation of inputs for ContactTrial
object ContactStore : ValidatingStore<Contact, Unit, Message>(
    Contact("", "", "", "", "", ""),
    Contact.validation,
    Unit, Job(),
    validateAfterUpdate = false
) {
    private val languageStore by koin.inject<LanguageStore>()

    val save = handle {
        if (validate(it).valid) {
            resetMessages()
            val contact = Contact(it.firstname, it.surname, it.email, it.company, it.comment, it.attentiveBy)
            postContact(contact, languageStore.current)
            navRouter?.navTo?.let { it1 -> it1(mapOf("page" to Pages.home)) }
            Contact("", "", "", "", "", "")
        } else it
    }
}

fun RenderContext.contact(router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()
    val policyCheckboxStore: Store<Boolean> = object : RootStore<Boolean>(false, Job()) {
        override val update: SimpleHandler<Boolean> = handle { _, newValue ->
            val trialButton: HTMLButtonElement = document.getElementById("contactButton") as HTMLButtonElement
            trialButton.disabled = !newValue
            trialButton.ariaDisabled = (!newValue).toString()
            newValue
        }
    }
    navRouter = router

    //textInput stores
    val msgs: Flow<List<Message>> = ContactStore.messages
    val firstname = ContactStore.map(Contact.firstname())
    val surname = ContactStore.map(Contact.surname())
    val company = ContactStore.map(Contact.company())
    val email = ContactStore.map(Contact.email())
    val comment = ContactStore.map(Contact.comment())
    val attentiveBy = ContactStore.map(Contact.attentiveBy())

    article {
        h1("text-3xl font-semibold") {
            translationStore[UiContact.Heading].renderText(this)
        }

        div ("contactInputContainer") {
            textInput(translationStore[UiContact.Name], firstname, msgs)
            textInput(translationStore[UiContact.Surname], surname, msgs)
            textInput(translationStore[UiContact.Email], email, msgs)
            textInput(translationStore[UiContact.Company], company, msgs)
            textareaInput(translationStore[UiContact.Comment], comment, msgs)

            attentiveBy.update(translationStore.getString(UiContact.AttentiveByOptionOne))

            val dropdownList = listOf(
                translationStore.getString(UiContact.AttentiveByOptionOne),
                translationStore.getString(UiContact.AttentiveByOptionTwo),
                translationStore.getString(UiContact.AttentiveByOptionThree),
                translationStore.getString(UiContact.AttentiveByOptionFour),
                translationStore.getString(UiContact.AttentiveByOptionFive)
            )

            dropdownSelector(translationStore[UiContact.AttentiveBy], attentiveBy, dropdownList)

            policyCheckbox(policyCheckboxStore, router, "policyCheckboxContact")

            button(mainButton, "contactButton") {
                attr("disabled", "true")
                attr("aria-disabled", "true")
                translationStore[UiContact.Button].renderText(this)
                clicks handledBy ContactStore.save
            }
        }
    }
}
