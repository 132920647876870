package localization.homepage

import com.tryformation.localization.Translatable

enum class UiSuccessStories : Translatable {
    Heading,
    Challenge,
    Solution,
    Benefit,
    PressureHeading,
    PressureClient,
    PressureChallenge,
    PressureSolution,
    PressureBenefit,
    PressureCitationFirst,
    PressureCitationSecond,
    PressureCitationThird,
    PressureCitationName,
    PressureCitationJob,
    PressureBenefitNumber,
    PressureBenefitDesc,
    PowerHeading,
    PowerClient,
    PowerChallenge,
    PowerSolution,
    PowerBenefit,
    PowerBenefitNumber,
    PowerBenefitDesc,
    TransHeading,
    TransClient,
    TransChallenge,
    TransSolution,
    TransBenefit,
    TransBenefitNumber,
    TransBenefitDesc,
    BuildingHeading,
    BuildingClient,
    BuildingChallenge,
    BuildingSolution,
    BuildingBenefit,
    BuildingBenefitNumber,
    BuildingBenefitDesc;

    override val prefix: String = "success"
}