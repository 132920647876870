package domain.repository

import dev.fritz2.core.Lenses
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Lenses
@Serializable
data class ScreeningDocument(
    val id: Int,
    val name: String,
    val type: ScreeningDocumentType,
    val creationDateTime: LocalDateTime,
    val updateDateTime: LocalDateTime
) {
    companion object {
        const val PATH = "/screeningDocument"
        const val MULTIPLE_PATH = "/multiple"
        const val RESULTS_PATH = "/results"

        // TODO: Validation part
    }
}