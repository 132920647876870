// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.userManagement

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.String

public fun UserFilter.Companion.filter(): Lens<UserFilter, String> = lensOf(
    "filter",
    { it.filter },
    { p, v -> p.copy(filter = v)}
  )

public fun <PARENT> Lens<PARENT, UserFilter>.filter(): Lens<PARENT, String> = this +
    UserFilter.filter()
