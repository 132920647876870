package components

import dev.fritz2.core.*
import dev.fritz2.headless.components.inputField
import koin
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import org.w3c.dom.HTMLDivElement
import util.Message
import web.dom.document
import web.html.HTMLInputElement
import web.html.InputType

private enum class ShowState {
    Show,
    Hide;
}

fun HtmlTag<HTMLDivElement>.textInput(
    label: Flow<String>,
    valueStore: Store<String>,
    msgs: Flow<List<Message>>?,
    password: Boolean = false
) {
    val translationStore by koin.inject<TranslationStore>()

    val inputId = storeOf("")

    inputField("inputContainer") {
        value(valueStore)
        inputTextfield("inputTextfield") {
            this.id?.let {
                inputId.update(it)
            }
            if (password) type("password")
            placeholder(label)
        }
        inputLabel("inputLabel") {
            label.renderText(this)
        }
        if (password) {
            val btnId = Id.next()
            button("inputShow", btnId) {
                attr("aria-label", ShowState.Hide)

                clicks handledBy {
                    val input = document.getElementById(inputId.current) as HTMLInputElement
                    when (input.type) {
                        InputType.password -> input.type = InputType.text
                        else -> input.type = InputType.password
                    }

                    val button = document.getElementById(btnId)
                    when (button?.ariaLabel) {
                        ShowState.Hide.name -> button.ariaLabel = ShowState.Show.name
                        else -> button?.ariaLabel = ShowState.Hide.name
                    }

                }
            }
        }
        inputValidationMessages(tag = RenderContext::div) {
            className("inputMessageContainer")
            msgs?.map { it.filter { msg -> msg.path == valueStore.path } }?.renderEach {
                div("inputMessage") {
                    translationStore[it.text].renderText(this)
                }
            }
        }
    }
}