package homepage.navigation

import components.skipToMain
import dev.fritz2.core.*
import dev.fritz2.headless.components.toastContainer
import dev.fritz2.headless.foundation.portalRoot
import dev.fritz2.routing.MapRouter
import dev.fritz2.routing.routerOf
import homepage.LanguageStore
import homepage.sides.*
import koin
import kotlinx.browser.document
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import localization.components.UiRouting
import org.w3c.dom.AddEventListenerOptions
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventListener
import policies.*
import react.FC
import react.Props

external interface RouterProps : Props

object Pages {
    const val home = "Home"
    const val faq = "FAQ"
    const val contact = "Contact"
    const val trial = "Get free trial"
    const val team = "Team"

    const val corporateInformation = "Corporate Information"
    const val privacyPolicy = "Privacy Policy"
    const val cookiePolicy = "Cookie Policy"
    const val tou = "Terms of Use"
    const val aup = "Acceptable Use Policy"

    const val legal = "Legal"
}


val Router = FC<RouterProps> {


    val router = routerOf(mapOf("page" to Pages.home))

    val menuToggleAriaExpandedStore: Store<String> = storeOf("false", Job())
    val primaryNavigationStateStore: Store<String> = storeOf("closed", Job())
    val floaterInScrollStore: Store<Boolean> = storeOf(false, Job())

    val translationStore by koin.inject<TranslationStore>()
    val languageStore by koin.inject<LanguageStore>()



    render("#root") {

        val headerClassStore = storeOf("transparentHeader")
        router.data handledBy {
            if (it["page"] != Pages.home) {
                headerClassStore.update("bg-darkest-0")
            } else {
                headerClassStore.update("transparentHeader")
            }
        }

        header(baseClass = headerClassStore.current, id = "header") {
            skipToMain()
            nav("navbar", id = "navbar") {
                attr("aria-expanded", "true")

                /*
                button("languageButton", "languageButton") {
                    attr("aria-label", "languageSwitchButton")
                    attr("selectedLang", "EN_US")

                    clicks.map {
                        var localesId = Locales.EN_US.id
                        when(languageStore.current.name) {
                            Locales.EN_US.name -> {
                                languageStore.update(Locales.DE_DE)
                                localesId = Locales.DE_DE.id
                            }
                            Locales.DE_DE.name -> {
                                languageStore.update(Locales.EN_US)
                                localesId = Locales.EN_US.id
                            }
                        }
                        localesId
                    } handledBy translationStore.updateLocale
                }
                 */

                a("headerLogo") {
                    attr("role", "link")
                    tabIndex(0)
                    img("headerLogoImage") {
                        src("../images/logo_white.svg")
                        alt("keyspot Logo")
                    }

                    keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }.map {
                        handleTransparentHeader(Pages.home)
                        mapOf("page" to Pages.home)
                    } handledBy router.navTo
                    clicks.map {
                        handleTransparentHeader(Pages.home)
                        mapOf("page" to Pages.home)
                    } handledBy router.navTo
                }

                div("inlineNavigation") {
                    aLink("", Pages.home, router, translationStore[UiRouting.Home])
                    aLink("", Pages.faq, router, translationStore[UiRouting.Faq])
                    aLink("", Pages.contact, router, translationStore[UiRouting.Contact])
                    aLink("", Pages.team, router, translationStore[UiRouting.Team])

                    /*
                    a("loginButton") {
                        attr("role", "link")
                        tabIndex(0)
                        href("/login")
                        translationStore[UiRouting.Login].renderText(this)
                    }

                     */
                }

                div("trialContainer") {
                    aLink("trial trialHeader", Pages.trial, router, translationStore[UiRouting.Trial])
                }


                div("burgerContainer") {
                    button("menu-toggle") {
                        attr("aria-controls", "primary-navigation")
                        attr("aria-expanded", menuToggleAriaExpandedStore.data)

                        val primaryNavigationHandler: EventListener = object : EventListener {
                            override fun handleEvent(event: Event) {
                                primaryNavigationStateStore.update("closed")
                            }
                        }

                        clicks handledBy menuToggleAriaExpandedStore.handle {
                            if (it == "true") {
                                val nav = document.getElementById("primary-navigation")
                                primaryNavigationStateStore.update("closing")
                                nav?.addEventListener(
                                    "animationend", primaryNavigationHandler, AddEventListenerOptions(once = true)
                                )
                                "false"
                            } else {
                                primaryNavigationStateStore.update("opened")
                                "true"
                            }
                        }
                        span("visually-hidden") {
                            +"Menu"
                        }
                        div("hamburger") {
                            attr("aria-hidden", "true")
                        }
                    }
                    ul("primaryNavigation", id = "primary-navigation") {
                        attr("data-state", primaryNavigationStateStore.data)
                        li {
                            className(router.data.map {
                                if (it.containsValue(Pages.home)) "active" else ""
                            })
                            aLink("", Pages.home, router, translationStore[UiRouting.Home])
                        }
                        li {
                            className(router.data.map {
                                if (it.containsValue(Pages.faq)) "active" else ""
                            })
                            aLink("", Pages.faq, router, translationStore[UiRouting.Faq])
                        }
                        li {
                            className(router.data.map {
                                if (it.containsValue(Pages.contact)) "active" else ""
                            })
                            aLink("", Pages.contact, router, translationStore[UiRouting.Contact])
                        }
                        li {
                            className(router.data.map {
                                if (it.containsValue(Pages.team)) "active" else ""
                            })
                            aLink("", Pages.team, router, translationStore[UiRouting.Team])
                        }
                        /*
                        li {
                            a("loginButton") {
                                attr("role", "link")
                                tabIndex(0)
                                href("/login")
                                translationStore[UiRouting.Login].renderText(this)
                            }
                        }

                         */
                        li {
                            aLink("", Pages.trial, router, translationStore[UiRouting.TrialNav])
                        }
                    }
                }
            }
        }
        main("card", id = "main") {

            router.select("page").render(into = this) { (value, _) ->
                when (value) {
                    Pages.home -> index(router)
                    Pages.faq -> faq()
                    Pages.contact -> contact(router)
                    Pages.team -> team()
                    Pages.trial -> trial(router)
                    Pages.corporateInformation -> corporateInformation("text-center px-4", "flex justify-center")
                    Pages.privacyPolicy -> privacyPolicy("text-center px-4", "flex justify-center")
                    Pages.cookiePolicy -> cookiePolicy("text-center px-4")
                    Pages.tou -> tou("text-center px-4")
                    Pages.aup -> aup("text-center px-4")
                    Pages.legal -> legal("text-center px-4")
                    else -> section {
                        h5 {
                            +"Page not found"
                        }
                    }
                }
            }
        }
        button("scrollTopBtn", id = "scrollTopBtn") {
            clicks handledBy floaterInScrollStore.handle {
                val rootElement = document.documentElement
                rootElement?.scrollTo(
                    ScrollToOptions(
                        top = 0.0, behavior = ScrollBehavior.SMOOTH
                    )
                )
                false
            }
            div("scrollTopIcon filter-tertiary50") {}
        }
        footer("footer", id = "footer") {
            div("footerInformation") {
                p {
                    +"(c) keyspot GmbH 2024"
                }
                p {
                    div("flex gap_2") {
                        a("socialButton linkedInFooter") {
                            attr("role", "link")
                            attr("aria-label", "Link to keyspot linkedIn page")
                            tabIndex(0)
                            target("_blank")
                            href("https://www.linkedin.com/company/keyspot-platform/")
                        }

                        a("contactEmailButton") {
                            attr("role", "link")
                            tabIndex(0)
                            href("mailto:info@keyspot.ai")
                            translationStore[UiRouting.ContactEmail].renderText(this)
                        }
                    }
                }
            }
            nav("footerNavigation") {
                aLink(
                    "text-greyscale-100 pointer no-underline border-b-2 border-transparent hover:outline-none " +
                            "hover:border-greyscale-100 focus-visible:outline-none focus-visible:border-greyscale-100",
                    Pages.corporateInformation,
                    router,
                    translationStore[UiRouting.CorporateInformation]
                )
                aLink(
                    "text-greyscale-100 pointer no-underline border-b-2 border-transparent hover:outline-none " +
                            "hover:border-greyscale-100 focus-visible:outline-none focus-visible:border-greyscale-100",
                    Pages.privacyPolicy,
                    router,
                    translationStore[UiRouting.PrivacyPolicy]
                )
                aLink(
                    "text-greyscale-100 pointer no-underline border-b-2 border-transparent hover:outline-none " +
                            "hover:border-greyscale-100 focus-visible:outline-none focus-visible:border-greyscale-100",
                    Pages.cookiePolicy,
                    router,
                    translationStore[UiRouting.CookiePolicy]
                )
                aLink(
                    "text-greyscale-100 pointer no-underline border-b-2 border-transparent hover:outline-none " +
                            "hover:border-greyscale-100 focus-visible:outline-none focus-visible:border-greyscale-100",
                    Pages.tou,
                    router,
                    translationStore[UiRouting.Tou]
                )
                aLink(
                    "text-greyscale-100 pointer no-underline border-b-2 border-transparent hover:outline-none " +
                            "hover:border-greyscale-100 focus-visible:outline-none focus-visible:border-greyscale-100",
                    Pages.aup,
                    router,
                    translationStore[UiRouting.Aup]
                )
                aLink(
                    "text-greyscale-100 pointer no-underline border-b-2 border-transparent hover:outline-none " +
                            "hover:border-greyscale-100 focus-visible:outline-none focus-visible:border-greyscale-100",
                    Pages.faq,
                    router,
                    translationStore[UiRouting.Faq]
                )
                aLink(
                    "text-greyscale-100 pointer no-underline border-b-2 border-transparent hover:outline-none " +
                            "hover:border-greyscale-100 focus-visible:outline-none focus-visible:border-greyscale-100",
                    Pages.legal,
                    router,
                    translationStore[UiRouting.Legal]
                )
            }


        }

        val scrollHandler: EventListener = object : EventListener {
            override fun handleEvent(event: Event) {
                val navbar = document.getElementById("navbar")
                val scrollTopBtn = document.getElementById("scrollTopBtn")

                if (kotlinx.browser.window.pageYOffset > navbar?.scrollTop!! && !navbar.classList.contains("stickyHeader")) {
                    navbar.classList.add("stickyHeader")
                } else if (kotlinx.browser.window.pageYOffset <= navbar.scrollTop && navbar.classList.contains("stickyHeader")) {
                    navbar.classList.remove("stickyHeader")
                }

                if (kotlinx.browser.window.pageYOffset > navbar.scrollTop && !scrollTopBtn?.classList?.contains("phaseIn")!!) {
                    scrollTopBtn.classList.add("phaseIn")
                } else if (kotlinx.browser.window.pageYOffset <= navbar.scrollTop && scrollTopBtn?.classList?.contains("phaseIn")!!) {
                    scrollTopBtn.classList.remove("phaseIn")
                }
            }
        }
        kotlinx.browser.window.addEventListener("scroll", scrollHandler, false)

        toastContainer("success", "toastContainer")
        toastContainer("error", "toastContainer")
        portalRoot()
    }

}

fun RenderContext.aLink(className: String, routeTo: String, router: MapRouter, name: Flow<String>?) {
    a(className) {
        attr("role", "link")
        tabIndex(0)
        if (name != null) {
            name.renderText(this)
        } else {
            +routeTo
        }

        keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }.map {
            handleTransparentHeader(routeTo)
            mapOf("page" to routeTo)
        } handledBy router.navTo
        clicks.map {
            handleTransparentHeader(routeTo)
            mapOf("page" to routeTo)
        } handledBy router.navTo
    }
}

private fun handleTransparentHeader(routeTo: String) {
    val header = web.dom.document.getElementById("header")
    if (routeTo == Pages.home) {
        if (header?.classList?.contains("transparentHeader") != true) {
            header?.classList?.add("transparentHeader")
        }
    } else {
        if (header?.classList?.contains("transparentHeader") == true) {
            header.classList.remove("transparentHeader")
        }
    }

}
