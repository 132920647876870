package platform.sides

import components.*
import components.modal.modalAnimationOverlay
import dev.fritz2.core.*
import dev.fritz2.headless.components.modal
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.InitialFocus
import dev.fritz2.routing.MapRouter
import domain.model.*
import koin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.emptyFlow
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import localization.platform.UiCustomizationGroupEdit
import platform.navigation.AdminStore
import platform.navigation.Pages
import platform.navigation.PersonalIdStore
import util.Message
import utils.*

fun RenderContext.modelCustomizationGroupEdit(modelId: String, groupUniqueIdentifier: String, router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()
    val groupStore: ModelGroupValidationStore = ModelValidationStore.getGroupStore(groupUniqueIdentifier)

    div("text-primary-10") {

        headingBanner(
            translationStore[UiCustomizationGroupEdit.Heading],
            translationStore[UiCustomizationGroupEdit.Description],
            "bg-heading-banner-4"
        )

        val allEditable =
            AdminStore.data.combine(groupStore.data.map { (it.owner != null && it.owner.id == PersonalIdStore.current) }) { admin, isOwnGroup ->
                admin || isOwnGroup
            }

        val color = groupStore.map(Group.color())
        val rules = groupStore.map(Group.rules())

        val ruleSetLens: Lens<Set<Rule>?, List<Rule>> = lensOf(
            "ruleSetToList",
            getter = { ruleSet: Set<Rule>? -> (ruleSet?.toList() ?: emptyList()) },
            setter = {_, ruleList: List<Rule> -> ruleList.toSet()}
        )
        val ruleList = rules.map(ruleSetLens)

        div {
            allEditable.render { isAllEditable ->
                renderDeleteButton(groupStore, isAllEditable, router)

                div("flex gap-4 justify-between items-center pt-4 pr-4") {
                    renderGroupName(groupStore, isAllEditable)
                    renderColorPicker(color)
                }

                textareaRuleConverter(translationStore[UiCustomizationGroupEdit.QuickRules], emptyFlow(), ruleList)

                groupStore.data.render { group: Group ->
                    div("text-left p-4 rounded-lg bg-greyscale-90 shadow-lg mt-2") {
                        renderRules(group, router)
                    }
                }

                div("flex gap-4 justify-center") {
                    renderBackButton(groupStore, modelId, isAllEditable, router)
                    renderSaveButton(groupStore, modelId, isAllEditable, router)
                }
            }
        }
    }
}


private fun RenderContext.renderGroupName(group: ModelGroupValidationStore, allEditable: Boolean) {
    val translationStore by koin.inject<TranslationStore>()
    val name = group.map(Group.name())
    val messages: Flow<List<Message>> = group.messages

    div("flex-1") {
        if (allEditable) {
            textInput(translationStore[UiCustomizationGroupEdit.NameLabel], name, messages)
        } else {
            textDisplay(translationStore[UiCustomizationGroupEdit.NameLabel], name)
        }
    }
}

private fun RenderContext.renderBackButton(
    groupStore: ModelGroupValidationStore,
    modelId: String,
    allEditable: Boolean,
    router: MapRouter
) {
    val translationStore by koin.inject<TranslationStore>()

    groupStore.data.render { group ->
        if (allEditable && modelId.toInt() != 0) {
            div("flex justify-center items-center") {
                button(mainButton) {
                    translationStore[UiCustomizationGroupEdit.BackGroup].renderText(this)
                    attr("aria-label", "return to model without saving")
                    clicks.map { router } handledBy groupStore.resetChanges
                }
            }
        }
    }
}

private fun RenderContext.renderSaveButton(
    groupStore: ModelGroupValidationStore,
    modelId: String,
    allEditable: Boolean,
    router: MapRouter
) {
    val translationStore by koin.inject<TranslationStore>()

    groupStore.data.render { group ->
        val disabled = !Group.isValid(group)
        div("flex justify-center items-center") {
            button(mainButton) {
                disabled(disabled)
                if (allEditable && modelId.toInt() != 0 && group.id == 0) {
                    translationStore[UiCustomizationGroupEdit.SaveGroup].renderText(this)
                } else if (allEditable && modelId.toInt() != 0 && group.id != 0) {
                    translationStore[UiCustomizationGroupEdit.UpdateGroup].renderText(this)
                } else {
                    translationStore[UiCustomizationGroupEdit.BackGroup].renderText(this)
                }

                attr("aria-label", "save, update or validate model")
                clicks.map { router } handledBy groupStore.saveGroup
            }
        }.tooltip(tooltip) {
            hidden(!disabled)
            translationStore[UiCustomizationGroupEdit.DisabledMessage].renderText(this)
            arrow()
            placement = "top"
        }
    }
}

private fun RenderContext.renderDeleteButton(
    groupStore: ModelGroupValidationStore,
    allEditable: Boolean,
    router: MapRouter
) {
    val translationStore by koin.inject<TranslationStore>()

    groupStore.data.render { group ->
        //models with only one group cannot delete group and have to be removed completely
        val disabled =
            group.id == 0 || !allEditable || groupStore.modelValidationStore.current.groups.filter { it.id != 0 }.size <= 1

        val showModal = storeOf(false, Job())
        modal {
            router.data handledBy {
                if (it["page"] != Pages.groupCustomization) {
                    showModal.update(false)
                }
            }
            this.openState(showModal)
            modalPanel(modalDark) {
                modalAnimationOverlay()
                modalTitle(modalDarkTitle) { translationStore[UiCustomizationGroupEdit.DeleteModalTitle].renderText(this) }
                modalDescription("my-2") {
                    translationStore[UiCustomizationGroupEdit.DeleteModalDescription].renderText(
                        this
                    )
                }
                div(modalDarkButtonContainer) {
                    button(modalDarkOk) {
                        setInitialFocus = InitialFocus.TryToSet
                        type("button")
                        translationStore[UiCustomizationGroupEdit.DeleteModalOk].renderText(this)
                        clicks.map { router } handledBy groupStore.deleteGroup
                        clicks.map { false } handledBy showModal.update
                    }

                    button(modalDarkCancel) {
                        type("button")
                        translationStore[UiCustomizationGroupEdit.DeleteModalCancel].renderText(this)
                        clicks.map { false } handledBy showModal.update
                    }
                }
            }
        }

        div("flex justify-end mr-4") {
            div {
                button(iconTextButtonUnderlined) {
                    disabled(disabled)
                    attr("aria-disabled", disabled.toString())
                    trashCanSvg(disabled)
                    clicks.map { true } handledBy showModal.update
                    translationStore[UiCustomizationGroupEdit.DeleteGroup].renderText()
                }
            }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
                hidden(!disabled)
                translationStore[UiCustomizationGroupEdit.DeleteDisabledMessage].renderText(this)
                arrow()
                placement = "left"
            }
        }
    }
}




