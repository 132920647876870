package utils

import dev.fritz2.routing.MapRouter
import kotlinx.coroutines.*

class PollingUtility(private val scope: CoroutineScope) {
    private var pollingJob: Job? = null

    fun startPolling(router: MapRouter, interval: Long, targetRoute: String, action: suspend CoroutineScope.() -> Unit) {
        pollingJob?.cancel()
        pollingJob = scope.launch {
            var currentRoute = ""

            val routeJob = launch {
                router.data.collect { routeData ->
                    currentRoute = routeData["page"].toString()
                }
            }

            while (isActive) {
                if (currentRoute == targetRoute) {
                    action()
                }
                delay(interval)
            }

            routeJob.cancel()
        }
    }
}