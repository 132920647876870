package domain.authentication.login

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import dev.fritz2.validation.validation
import kotlinx.serialization.Serializable
import localization.login.UiPasswordReset
import util.EmailValidator
import util.Message
import util.Severity

@Lenses
@Serializable
data class PasswordReset(val email: String) {

    companion object {

        const val PATH = "/reset-password"

        val validation: Validation<PasswordReset, Unit, Message> = validation { inspector ->
            val email = inspector.map(PasswordReset.email())
            if (validateEmail(email.data)) {
                add(Message(email.path, Severity.Error, UiPasswordReset.EmailInvalid))
            }
        }

        fun validate(passwordReset: PasswordReset): Boolean = !validateEmail(passwordReset.email)

        private fun validateEmail(email: String): Boolean = !EmailValidator.isEmailValid(email.trim())
    }
}
