// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.model

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.Boolean
import kotlin.Int
import kotlin.String

public fun Word.Companion.id(): Lens<Word, Int> = lensOf(
    "id",
    { it.id },
    { p, v -> p.copy(id = v)}
  )

public fun <PARENT> Lens<PARENT, Word>.id(): Lens<PARENT, Int> = this + Word.id()

public fun Word.Companion.word(): Lens<Word, String> = lensOf(
    "word",
    { it.word },
    { p, v -> p.copy(word = v)}
  )

public fun <PARENT> Lens<PARENT, Word>.word(): Lens<PARENT, String> = this + Word.word()

public fun Word.Companion.stemming(): Lens<Word, Language> = lensOf(
    "stemming",
    { it.stemming },
    { p, v -> p.copy(stemming = v)}
  )

public fun <PARENT> Lens<PARENT, Word>.stemming(): Lens<PARENT, Language> = this + Word.stemming()

public fun Word.Companion.caseSensitive(): Lens<Word, Boolean> = lensOf(
    "caseSensitive",
    { it.caseSensitive },
    { p, v -> p.copy(caseSensitive = v)}
  )

public fun <PARENT> Lens<PARENT, Word>.caseSensitive(): Lens<PARENT, Boolean> = this +
    Word.caseSensitive()

public fun Word.Companion.subStringSearch(): Lens<Word, Boolean> = lensOf(
    "subStringSearch",
    { it.subStringSearch },
    { p, v -> p.copy(subStringSearch = v)}
  )

public fun <PARENT> Lens<PARENT, Word>.subStringSearch(): Lens<PARENT, Boolean> = this +
    Word.subStringSearch()

public fun Word.Companion.type(): Lens<Word, WordType> = lensOf(
    "type",
    { it.type },
    { p, v -> p.copy(type = v)}
  )

public fun <PARENT> Lens<PARENT, Word>.type(): Lens<PARENT, WordType> = this + Word.type()

public fun Word.Companion.uniqueIdentifier(): Lens<Word, String> = lensOf(
    "uniqueIdentifier",
    { it.uniqueIdentifier },
    { p, v -> p.copy(uniqueIdentifier = v)}
  )

public fun <PARENT> Lens<PARENT, Word>.uniqueIdentifier(): Lens<PARENT, String> = this +
    Word.uniqueIdentifier()
