package icons

import dev.fritz2.core.*


fun RenderContext.toTopIcon(styleClass: String, fill: String): SvgTag {
    return svg(styleClass) {
        xmlns("http://www.w3.org/2000/svg")
        viewBox("0 0 64 64")
        fill(fill)

        path {
            attr("transform", "matrix(1,0,0,1,0,6)")
            d("M11,38L1,38L32,4L63,38L53,38L32,15")
        }
        path {
            attr("transform", "matrix(1,0,0,1,-0.25403,14)")
            d("M6.254,41.238L32.254,13L58.254,41.238L48.254,41.2L32.254,24L16.254,41.238L6.254,41.238Z")
        }
    }
}

fun RenderContext.platformIcon(styleClass: String, fill: String): SvgTag {
    return svg(styleClass) {
        xmlns("http://www.w3.org/2000/svg")
        viewBox("0 0 256 256")
        fill(fill)
        path {
            d("M125.445,4.518c1.575,-0.936 3.535,-0.936 5.11,0c14.529,8.635 86.921,51.661 110.213,65.504c1.516,0.901 2.446,2.535 2.446,4.298c-0,1.764 -0.93,3.397 -2.446,4.298c-23.292,13.844 -95.684,56.869 -110.213,65.504c-1.575,0.936 -3.535,0.936 -5.11,0c-14.529,-8.635 -86.921,-51.66 -110.213,-65.504c-1.516,-0.901 -2.446,-2.534 -2.446,-4.298c0,-1.763 0.93,-3.397 2.446,-4.298c23.292,-13.843 95.684,-56.869 110.213,-65.504Z")
        }
        path {
            d("M211.532,109.363c1.575,-0.936 3.535,-0.936 5.11,-0c5.066,3.011 16.552,9.837 24.126,14.339c1.516,0.901 2.446,2.534 2.446,4.298c-0,1.764 -0.93,3.397 -2.446,4.298c-23.292,13.844 -95.684,56.869 -110.213,65.504c-1.575,0.936 -3.535,0.936 -5.11,0c-14.529,-8.635 -86.921,-51.66 -110.213,-65.504c-1.516,-0.901 -2.446,-2.534 -2.446,-4.298c0,-1.764 0.93,-3.397 2.446,-4.298c7.574,-4.502 19.06,-11.328 24.126,-14.339c1.575,-0.936 3.535,-0.936 5.11,-0c12.635,7.509 68.343,40.618 80.977,48.128c1.575,0.935 3.535,0.935 5.11,-0c12.634,-7.51 68.342,-40.619 80.977,-48.128Z")
        }
        path {
            d("M211.532,163.042c1.575,-0.936 3.535,-0.936 5.11,0c5.066,3.012 16.552,9.838 24.126,14.34c1.516,0.901 2.446,2.534 2.446,4.298c-0,1.763 -0.93,3.397 -2.446,4.298c-23.292,13.843 -95.684,56.869 -110.213,65.504c-1.575,0.936 -3.535,0.936 -5.11,-0c-14.529,-8.635 -86.921,-51.661 -110.213,-65.504c-1.516,-0.901 -2.446,-2.535 -2.446,-4.298c0,-1.764 0.93,-3.397 2.446,-4.298c7.574,-4.502 19.06,-11.328 24.126,-14.34c1.575,-0.936 3.535,-0.936 5.11,0c12.635,7.51 68.343,40.619 80.977,48.128c1.575,0.936 3.535,0.936 5.11,0c12.634,-7.509 68.342,-40.618 80.977,-48.128Z")
        }
    }
}

fun RenderContext.userIcon(styleClass: String, fill: String): SvgTag {
    return svg(styleClass) {
        xmlns("http://www.w3.org/2000/svg")
        viewBox("0 0 64 64")
        fill(fill)
        path {
            attr("transform", "matrix(1.19794,0,0,1.11953,-6.22076,-2.78803)")
            d("M28.985,45.735C28.34,48.287 27.946,51.671 27.946,55.382C27.946,56.526 27.985,57.661 28.059,58.764C19.398,58.344 11.845,56.501 6.946,53.847C6.915,53.426 6.807,52.993 6.899,52.58C8.33,46.167 7.73,42.604 13.005,38.572C13.447,38.234 16.15,37.664 19.196,36.284C20.701,35.603 22.043,34.823 23.727,33.63L25.686,44.303L26.118,44.303C26.689,42.775 27.644,41.508 28.832,40.689C28.947,41.575 29.366,42.354 29.97,42.897C29.769,43.308 29.58,43.779 29.403,44.303C29.254,44.744 29.115,45.223 28.985,45.735Z")
        }
        path {
            attr("transform", "matrix(1.19794,0,0,1.11953,-6.22076,-2.78803)")
            d("M34.519,44.303C34.349,43.799 34.167,43.345 33.976,42.945C34.605,42.404 35.045,41.61 35.166,40.704C36.346,41.523 37.298,42.784 37.871,44.303L38.241,44.303L40.196,33.656C41.444,34.632 43.063,35.36 44.521,36.162C47.074,37.565 49.854,38.257 50.122,38.382C53.592,40 56.753,46.557 56.945,52.58C56.958,52.973 56.932,53.366 56.905,53.758C52.054,56.436 44.522,58.306 35.864,58.752C35.938,57.653 35.976,56.522 35.976,55.382C35.976,51.645 35.577,48.24 34.924,45.682C34.798,45.19 34.663,44.729 34.519,44.303Z")
        }
        path {
            attr("transform", "matrix(1.19794,0,0,1.11953,-6.22076,-2.78803)")
            d("M38.342,30.076C38.375,30.352 38.048,30.789 38.002,31.065C37.511,34.063 34.995,36.806 32.409,37.547C32.229,37.598 32.034,37.574 31.844,37.574C31.655,37.574 31.462,37.594 31.282,37.547C29.023,36.954 26.68,34.125 25.686,31.065C25.599,30.799 25.703,30.506 25.737,30.229C22.609,25.746 20.625,20.351 20.464,17.18C20.094,9.876 24.554,4.99 31.845,5.175C39.003,5.357 43.62,9.45 43.304,16.712C43.166,19.885 41.47,25.592 38.342,30.076Z" )
        }
    }
}

fun RenderContext.groupIcon(styleClass: String, fill: String): SvgTag {
    return svg(styleClass) {
        xmlns("http://www.w3.org/2000/svg")
        viewBox("0 0 256 256")
        fill(fill)
        path {
            d("M252.937,140.716c0.348,1.794 -0.556,3.598 -2.201,4.394c-8.749,4.175 -18.076,7.366 -27.908,9.747c-0.322,0.078 -0.644,0.151 -0.968,0.22c-10.825,2.295 -21.785,3.672 -32.851,4.331c-0.518,0.03 -1.025,-0.152 -1.405,-0.504c-0.38,-0.352 -0.6,-0.844 -0.609,-1.362c-0.113,-6.179 -0.45,-24.725 -0.45,-24.725c-0.432,-7.508 -2.836,-14.726 -5.753,-21.087c2.096,-1.462 3.698,-3.438 4.414,-6.334c2.669,2.215 5.259,5.16 7.582,8.448c0.232,0.332 0.641,0.492 1.037,0.408c0.396,-0.085 0.703,-0.399 0.779,-0.797c1.715,-8.89 3.729,-18.303 6.101,-28.334c0.057,-0.242 0.222,-0.443 0.448,-0.546c0.226,-0.103 0.486,-0.096 0.706,0.019c2.607,1.364 10.099,5.284 13.474,7.05c1.114,0.582 2.283,1.054 3.489,1.407c3.708,1.107 7.883,2.573 12.514,4.165c2.138,0.731 4.129,1.836 5.881,3.264c2.735,2.259 5.231,4.954 7.445,8.152c1.859,2.653 3.138,5.668 3.754,8.849c1.19,6.044 3.453,17.725 4.521,23.235Z")
        }
        path {
            d("M176.945,96.676c4.485,-27.984 -6.965,-47.61 -36.742,-53.703c-4.585,-24.232 8.668,-39.394 35.192,-39.973c40.547,3.156 44.513,34.151 19.712,76.076c-0.931,2.573 -1.916,4.937 -2.98,7.068c-3.01,6.022 -8.767,9.961 -15.182,10.532Z")
        }
        path {
            d("M3.074,140.717c-0.348,1.794 0.555,3.597 2.2,4.393c8.746,4.174 18.069,7.365 27.897,9.747c0.321,0.077 0.644,0.151 0.968,0.22c10.82,2.294 21.776,3.672 32.837,4.331c0.518,0.03 1.025,-0.152 1.405,-0.504c0.38,-0.352 0.6,-0.844 0.609,-1.362c0.113,-6.18 0.45,-24.726 0.45,-24.726c0.432,-7.507 2.834,-14.725 5.75,-21.086c-2.095,-1.463 -3.696,-3.439 -4.411,-6.334c-2.669,2.215 -5.257,5.159 -7.579,8.447c-0.232,0.332 -0.641,0.492 -1.037,0.408c-0.396,-0.085 -0.703,-0.399 -0.779,-0.797c-1.714,-8.89 -3.727,-18.302 -6.098,-28.333c-0.057,-0.242 -0.223,-0.443 -0.449,-0.546c-0.225,-0.103 -0.486,-0.096 -0.706,0.019c-2.606,1.364 -10.093,5.283 -13.467,7.049c-1.114,0.583 -2.283,1.055 -3.49,1.408c-3.706,1.107 -7.878,2.573 -12.506,4.164c-2.139,0.732 -4.131,1.838 -5.883,3.267c-2.734,2.258 -5.228,4.954 -7.441,8.151c-1.857,2.653 -3.135,5.667 -3.75,8.846c-1.19,6.044 -3.453,17.727 -4.52,23.238Z")
        }
        path {
            d("M115.984,42.461c-28.691,4.807 -42.6,24.541 -37.461,54.054c-6.201,-1.002 -11.588,-5.156 -14.269,-11.172c-0.993,-2.307 -1.82,-4.848 -2.49,-7.611c-28.795,-43.757 -17.87,-73.911 19.746,-74.732c27.459,2.137 38.141,17.041 34.474,39.461Z")
        }
        path {
            d("M24.021,229.155c-0.346,1.786 0.547,3.582 2.181,4.384c11.912,5.781 24.643,10.168 38.083,13.415c0.405,0.098 0.811,0.19 1.219,0.277c14.543,3.077 29.269,4.908 44.138,5.766c0.517,0.029 1.024,-0.154 1.403,-0.506c0.379,-0.352 0.599,-0.843 0.609,-1.361c0.135,-7.453 0.607,-33.451 0.607,-33.451c0.573,-9.96 3.761,-19.537 7.63,-27.975c-2.78,-1.941 -4.904,-4.563 -5.853,-8.404c-3.71,3.079 -7.303,7.222 -10.496,11.838c-0.23,0.336 -0.64,0.5 -1.039,0.417c-0.398,-0.084 -0.708,-0.399 -0.784,-0.799c-2.323,-12.117 -5.066,-24.964 -8.314,-38.684c-0.057,-0.242 -0.222,-0.443 -0.448,-0.546c-0.226,-0.103 -0.486,-0.096 -0.706,0.019c-3.148,1.648 -13.583,7.11 -18.187,9.52c-1.465,0.766 -3.001,1.385 -4.588,1.85c-5.019,1.499 -10.684,3.494 -16.98,5.657c-2.435,0.834 -4.715,2.069 -6.743,3.654c-4.061,3.226 -7.731,7.162 -10.942,11.915c-2.236,3.276 -3.779,6.974 -4.533,10.867c-1.637,8.292 -4.923,25.258 -6.257,32.147Z")
        }
        path {
            d("M231.37,229.154c0.346,1.787 -0.548,3.584 -2.181,4.385c-11.918,5.781 -24.653,10.168 -38.1,13.416c-0.404,0.097 -0.811,0.189 -1.218,0.276c-14.549,3.077 -29.281,4.908 -44.157,5.766c-0.517,0.029 -1.023,-0.153 -1.403,-0.506c-0.379,-0.352 -0.599,-0.843 -0.608,-1.361c-0.136,-7.452 -0.608,-33.451 -0.608,-33.451c-0.574,-9.96 -3.762,-19.536 -7.633,-27.975c2.781,-1.941 4.906,-4.562 5.856,-8.404c3.711,3.08 7.306,7.223 10.5,11.839c0.231,0.336 0.64,0.5 1.039,0.417c0.398,-0.084 0.708,-0.399 0.784,-0.799c2.324,-12.117 5.068,-24.965 8.317,-38.685c0.058,-0.242 0.223,-0.443 0.449,-0.546c0.225,-0.103 0.486,-0.096 0.706,0.019c3.148,1.648 13.59,7.111 18.195,9.52c1.465,0.766 3.001,1.385 4.587,1.849c5.022,1.5 10.691,3.496 16.99,5.659c2.435,0.833 4.713,2.067 6.741,3.65c4.064,3.227 7.736,7.163 10.948,11.917c2.238,3.276 3.782,6.975 4.537,10.87c1.638,8.292 4.924,25.256 6.259,32.144Z")
        }
        path {
            d("M102.078,144.598c3.084,12.722 8.688,21.902 17.279,26.885c7.364,4.24 16.658,2.997 22.649,-3.029c4.785,-4.848 8.895,-12.326 12.422,-22.074c32.902,-55.621 27.641,-96.743 -26.153,-100.93c-49.906,1.089 -64.4,41.095 -26.197,99.148Z")
        }
    }
}

fun RenderContext.directoryIcon(styleClass: String, fill: String): SvgTag {
    return svg(styleClass) {
        xmlns("http://www.w3.org/2000/svg")
        viewBox("0 0 256 256")
        fill(fill)
        path {
            d("M47.809,74.121l178.772,0.707c16.218,2.447 23.941,14.982 25.085,35.33l-3.533,87.619c-5.319,23.187 -18.822,32.522 -36.391,34.978l-175.945,0.706c-22.867,-6.339 -33.662,-24.751 -31.091,-56.528l0.226,-15.749l3.957,-47.201c2.47,-22.108 14.593,-36.598 38.92,-39.862Zm-0.077,-2.558c-36.426,5.972 -42.075,25.19 -43.026,66.059l0,-93.885c1.985,-12.781 8.614,-19.37 19.079,-20.845l130.016,-0.353c13.167,1.49 19.689,8.519 21.551,19.432l34.624,0.706c8.825,0.832 13.668,5.646 15.899,13.072l0.61,16.473l-10.559,0l-0.297,-11.88c-0.895,-3.073 -3.816,-5.641 -8.48,-7.772l-150.86,-0c-5.203,0.587 -8.341,3.652 -8.833,9.892l0.276,9.101Z")
        }
    }
}

fun RenderContext.featureIcon(styleClass: String, fill: String): SvgTag {
    return svg(styleClass) {
        xmlns("http://www.w3.org/2000/svg")
        viewBox("0 0 256 256")
        fill(fill)
        path {
            d("M125.025,16.774c0.388,-1.257 1.599,-2.12 2.975,-2.12c1.376,0 2.587,0.863 2.975,2.12l26.092,84.367c0.026,0.085 0.081,0.16 0.157,0.212c0.075,0.053 0.167,0.08 0.26,0.077l92.328,-2.442c1.375,-0.037 2.611,0.794 3.036,2.039c0.425,1.246 -0.062,2.61 -1.197,3.35l-76.203,49.699c-0.077,0.05 -0.134,0.123 -0.163,0.208c-0.029,0.085 -0.027,0.176 0.004,0.26l30.97,82.858c0.462,1.234 0.014,2.61 -1.099,3.38c-1.113,0.769 -2.625,0.749 -3.715,-0.05l-73.187,-53.651c-0.074,-0.055 -0.165,-0.084 -0.258,-0.084c-0.093,0 -0.184,0.029 -0.258,0.084l-73.187,53.651c-1.09,0.799 -2.602,0.819 -3.715,0.05c-1.113,-0.77 -1.561,-2.146 -1.099,-3.38l30.97,-82.858c0.031,-0.084 0.033,-0.175 0.004,-0.26c-0.029,-0.085 -0.086,-0.158 -0.163,-0.208l-76.203,-49.699c-1.135,-0.74 -1.622,-2.104 -1.197,-3.35c0.425,-1.245 1.661,-2.076 3.036,-2.039l92.328,2.442c0.093,0.003 0.185,-0.024 0.26,-0.077c0.076,-0.052 0.131,-0.127 0.157,-0.212l26.092,-84.367Zm-0.22,151.107c1.063,0.274 2.237,-0.112 2.877,-1.034l33.951,-48.867c0.8,-1.151 0.468,-2.704 -0.741,-3.465l-4.379,-2.759c-1.209,-0.761 -2.839,-0.445 -3.639,0.706l-29.839,42.949l-21.822,-15.233c-1.168,-0.816 -2.813,-0.575 -3.67,0.538l-3.104,4.032c-0.857,1.113 -0.603,2.679 0.565,3.495l27.564,19.241c0.669,0.467 1.493,0.587 2.237,0.397Z")
        }
    }
}

fun RenderContext.entraIcon(styleClass: String, fill: String): SvgTag {
    return svg(styleClass) {
        xmlns("http://www.w3.org/2000/svg")
        viewBox("0 0 256 256")
        fill(fill)
        path {
            d("M30.892,113.233l23.814,-75.411c-0,-0 21.168,-17.199 48.422,-15.612c27.253,1.588 46.834,10.584 46.834,10.584l-24.608,82.291c2.091,1.748 -13.157,-12.974 -46.04,-12.966c-30.975,0.009 -46.714,8.032 -48.422,11.114Z")
        }
        path {
            d("M226.07,127.421l-23.814,75.41c-0,0 -21.168,17.199 -48.422,15.612c-27.254,-1.588 -46.834,-10.584 -46.834,-10.584l24.608,-82.291c-2.091,-1.748 13.156,12.975 46.04,12.966c30.975,-0.009 46.714,-8.032 48.422,-11.113Z")
        }
        path {
            d("M4.432,200.021l22.491,-73.558c-0,-0 21.697,-15.876 48.951,-14.289c27.254,1.588 46.04,10.849 46.04,10.849l-24.608,81.761c2.091,1.749 -10.51,-13.503 -43.394,-13.495c-30.975,0.009 -47.772,5.651 -49.48,8.732Z")
        }
        path {
            d("M251.568,40.774l-22.491,73.558c0,0 -21.697,15.876 -48.951,14.289c-27.254,-1.588 -46.04,-10.849 -46.04,-10.849l24.608,-81.761c-2.091,-1.749 10.51,13.503 43.394,13.495c30.975,-0.009 47.772,-5.651 49.48,-8.732Z")
        }
    }
}

fun RenderContext.closeIcon(styleClass: String, fill: String): SvgTag {
    return svg(styleClass) {
        xmlns("http://www.w3.org/2000/svg")
        viewBox("0 0 64 64")
        fill(fill)
        path {
            d("M26.343,32l-22.274,-22.274c-0.78,-0.78 -0.78,-2.048 0,-2.828l2.829,-2.829c0.78,-0.78 2.048,-0.78 2.828,0l22.274,22.274l22.274,-22.274c0.78,-0.78 2.048,-0.78 2.828,0l2.829,2.829c0.78,0.78 0.78,2.048 -0,2.828l-22.274,22.274l22.274,22.274c0.78,0.78 0.78,2.048 -0,2.828l-2.829,2.829c-0.78,0.78 -2.048,0.78 -2.828,-0l-22.274,-22.274l-22.274,22.274c-0.78,0.78 -2.048,0.78 -2.828,-0l-2.829,-2.829c-0.78,-0.78 -0.78,-2.048 0,-2.828l22.274,-22.274Z")
        }
    }
}


fun RenderContext.addIcon(styleClass: String, fill: String): SvgTag {
    return svg(styleClass) {
        xmlns("http://www.w3.org/2000/svg")
        viewBox("0 0 64 64")
        fill(fill)
        path {
            d("M23.617,23.617L23.617,5.71C23.617,3.663 25.28,2 27.327,2L36.673,2C38.72,2 40.383,3.663 40.383,5.71L40.383,23.617L58.29,23.617C60.337,23.617 62,25.28 62,27.327L62,36.673C62,38.72 60.337,40.383 58.29,40.383L40.383,40.383L40.383,58.29C40.383,60.337 38.72,62 36.673,62L27.327,62C25.28,62 23.617,60.337 23.617,58.29L23.617,40.383L5.71,40.383C3.663,40.383 2,38.72 2,36.673L2,27.327C2,25.28 3.663,23.617 5.71,23.617L23.617,23.617Z")
        }
    }
}
