// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.authentication.login

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.String

public fun PasswordReset.Companion.email(): Lens<PasswordReset, String> = lensOf(
    "email",
    { it.email },
    { p, v -> p.copy(email = v)}
  )

public fun <PARENT> Lens<PARENT, PasswordReset>.email(): Lens<PARENT, String> = this +
    PasswordReset.email()
