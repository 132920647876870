package api

import dev.fritz2.headless.components.toast
import domain.repository.Document
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.browser.document
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLDivElement
import org.w3c.files.File
import utils.updateAction
import utils.updateProgress
import web.buffer.Blob
import web.url.URL


suspend fun postDocumentFolder(id: Int, document: Document): HttpResponse {
    return bearerJsonClient.post(Document.PATH + "/${id}" + Document.CHILDREN_PATH + Document.FOLDER_PATH) {
        contentType(ContentType.Application.Json)
        setBody(document)
    }
}

suspend fun putDocumentFolder(document: Document):HttpResponse {
    return bearerJsonClient.put(Document.PATH + "/${document.id}" + Document.FOLDER_PATH) {
        contentType(ContentType.Application.Json)
        setBody(document)
    }
}

suspend fun postDocumentFile(id: Int, document: Document, file: File, progressContainer: HTMLDivElement): HttpResponse {
    val multipartContent = createMultipartFormDataContentPost(file, id, document)

    return bearerJsonClient.post(Document.PATH + "/${id}" + Document.CHILDREN_PATH + Document.FILE_PATH) {
        setBody(multipartContent)
        onUpload { bytesSentTotal, contentLength ->
            if(contentLength > 0) {
                val progress = bytesSentTotal.toDouble() / contentLength * 100
                updateProgress(progressContainer, progress)
            }
        }
    }
}

suspend fun putDocumentFile(document: Document, file: File?, progressContainer: HTMLDivElement?):HttpResponse {
    val multipartContent = createMultipartFormDataContentPut(file, document)

    return bearerJsonClient.put(Document.PATH + "/${document.id}" + Document.FILE_PATH)  {
        setBody(multipartContent)
        progressContainer?.let {
            onUpload { bytesSentTotal, contentLength ->
                if(contentLength > 0) {
                    val progress = bytesSentTotal.toDouble() / contentLength * 100
                    updateProgress(progressContainer, progress)
                }
            }
        }
    }
}

suspend fun postDocumentModel(id: Int, document: Document, file: File, progressContainer: HTMLDivElement): HttpResponse {
    val multipartContent = createMultipartFormDataContentPost(file, id, document)

    return bearerJsonClient.post(Document.PATH + "/${id}" + Document.CHILDREN_PATH + Document.MODEL_PATH) {
        setBody(multipartContent)
        onUpload { bytesSentTotal, contentLength ->
            if(contentLength > 0) {
                val progress = bytesSentTotal.toDouble() / contentLength * 100
                updateProgress(progressContainer, progress)

                if(contentLength == bytesSentTotal) {
                    updateAction(progressContainer, "Database inserting")
                }
            }
        }
    }
}

suspend fun putDocumentModel(document: Document, file: File?, progressContainer: HTMLDivElement?):HttpResponse {
    val multipartContent = createMultipartFormDataContentPut(file, document)

    return bearerJsonClient.put(Document.PATH + "/${document.id}" + Document.MODEL_PATH)  {
        setBody(multipartContent)
        progressContainer?.let {
            onUpload { bytesSentTotal, contentLength ->
                if(contentLength > 0) {
                    val progress = bytesSentTotal.toDouble() / contentLength * 100
                    updateProgress(progressContainer, progress)

                    if(contentLength == bytesSentTotal) {
                        updateAction(progressContainer, "Database inserting")
                    }
                }
            }
        }
    }
}

suspend fun documentNameDuplicateExists(parentId: Int, name: String, id: Int = 0): Boolean {
    val response = bearerJsonClient.get(Document.PATH + "/${parentId}" + "/duplicate") {
        url {
            parameters.append("documentId", id.toString())
            parameters.append("name", name)
        }
    }
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Duplicate name failed ${response.status}")
    }
}


suspend fun getDocumentChildren(id: Int): List<Document> {
    val response = bearerJsonClient.get(Document.PATH + "/${id}" + Document.CHILDREN_PATH) {}
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Retrieving user failed ${response.status}")
    }
}

suspend fun getDocument(id: Int): Document? {
    val response = bearerJsonClient.get(Document.PATH + "/${id}") {}
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Retrieving document failed ${response.status}")
    }
}

suspend fun deleteDocument(id: Int) {
    val response = bearerJsonClient.delete(Document.PATH + "/${id}") {}
    if (response.status != HttpStatusCode.OK) throw Exception("Retrieving document failed ${response.status}")
}

suspend fun downloadDocument(id: Int) {
    toast("progress", 2000L, "progressToast") {
        +"Downloading a folder zips the structure. This can take a while to start downloading"
    }
    val response = bearerJsonClient.get(Document.PATH + "/${id}" + Document.DOWNLOAD_PATH) {
        onDownload { bytesSentTotal, contentLength ->
            if (contentLength != -1L) {
                if ((bytesSentTotal % (contentLength * 0.01)).toInt() == 0)
                    toast("progress", 100L, "progressToast") {
                        +"Downloading $bytesSentTotal of $contentLength"
                    }
            } else {
                if ((bytesSentTotal % 1024).toInt() == 0) {
                    toast("progress", 100L, "progressToast") {
                        +"Downloading $bytesSentTotal of $contentLength"
                    }
                }
            }
        }
    }
    val contentDisposition = response.headers[HttpHeaders.ContentDisposition]
    val filename = contentDisposition?.let {
        ContentDisposition.parse(it).parameter(ContentDisposition.Parameters.FileName)
    }

    if (filename != null) saveFileToUserDevice(filename, response.body())
}

private fun saveFileToUserDevice(fileName: String, data: ByteArray) {
    val blob = Blob(arrayOf(data))
    val a = document.createElement("a") as HTMLAnchorElement
    a.href = URL.createObjectURL(blob)
    a.download = fileName
    a.click()
}

private suspend fun createMultipartFormDataContentPost(
    file: File,
    id: Int,
    document: Document
): MultiPartFormDataContent {
    val fileBytes = readFile(file)

    val multipartContent = MultiPartFormDataContent(
        formData {
            append("parentId", id)
            append("name", document.name)
            append("file", fileBytes, Headers.build {
                append(HttpHeaders.ContentType, ContentType.Application.OctetStream)
                append(HttpHeaders.ContentDisposition, "filename=\"${file.name}\"")
            })
        }
    )
    return multipartContent
}

private suspend fun createMultipartFormDataContentPut(
    file: File?,
    document: Document
): MultiPartFormDataContent {
    val fileBytes = file?.let { readFile(file) }

    val multipartContent = MultiPartFormDataContent(
        formData {
            append("id", document.id)
            append("name", document.name)
            fileBytes?.let {
                append("file", fileBytes, Headers.build {
                    append(HttpHeaders.ContentType, ContentType.Application.OctetStream)
                    append(HttpHeaders.ContentDisposition, "filename=\"${file.name}\"")
                })
            }
        }
    )
    return multipartContent
}