package components.modal

import dev.fritz2.core.transition
import dev.fritz2.headless.components.Modal
import org.w3c.dom.HTMLDivElement

fun Modal.ModalPanel<HTMLDivElement>.modalAnimationOverlay() {
    modalOverlay("fixed") {
        transition(
            enter = "ease-out duration-300",
            enterStart = "opacity-0",
            enterEnd = "opacity-100",
            leave = "ease-in duration-200",
            leaveStart = "opacity-100",
            leaveEnd = "opacity-0"
        )
    }
}