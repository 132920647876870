package admin.sides

import admin.navigation.Pages
import api.getActiveScreenings
import api.getScreeningHistory
import api.getScreeningServiceLog
import api.getScreeningServiceStatus
import components.headingBanner
import components.textInput
import dev.fritz2.core.*
import dev.fritz2.headless.components.dataCollection
import dev.fritz2.headless.components.toast
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import dev.fritz2.headless.foundation.utils.scrollintoview.ScrollBehavior
import dev.fritz2.routing.MapRouter
import domain.repository.ScreeningHistory
import domain.repository.ScreeningServiceStatus
import domain.util.ServiceStatus
import icons.directoryIcon
import icons.featureIcon
import icons.platformIcon
import koin
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.launch
import kotlinx.datetime.format
import localization.TranslationStore
import localization.admin.UiMonitoring
import utils.PollingUtility
import utils.dateTimeFormat

class ScreeningHistoryService(scope: CoroutineScope, val pollingUtility: PollingUtility) {
    val activeScreeningStore = RootStore<List<ScreeningHistory>>(emptyList(), Job())

    init {
        scope.launch {
            refreshData()
        }
    }

    suspend fun refreshData() {
        try {
            val activeScreenings = getActiveScreenings()
            activeScreeningStore.update(activeScreenings)

        } catch (e: Exception) {
            toast("error", classes = "errorToast") { e.message }
        }
    }
}

fun RenderContext.monitoring(router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()

    val windowWidthStore = storeOf(1680)
    val updateLayout = {
        windowWidthStore.update(kotlinx.browser.window.innerWidth)
    }
    kotlinx.browser.window.addEventListener("resize", {
        updateLayout()
    })
    updateLayout()

    div("text-primary-10") {
        headingBanner(
            translationStore[UiMonitoring.Heading],
            translationStore[UiMonitoring.Description],
            "bg-heading-banner-admin-3"
        )

        screeningService()
        screeningMonitor(router, windowWidthStore)
    }
}

private fun RenderContext.screeningService() {
    div("px-4 pt-4") {
        div("flex justify-between pt-4") {
            screeningServiceStatus()
            screeningServiceLog()
        }
    }
}

private fun RenderContext.screeningServiceStatus() {
    val statusStore = storeOf(ServiceStatus(ScreeningServiceStatus.IDLE))
    MainScope().launch {
        statusStore.update(getScreeningServiceStatus())
    }

    button(
        "p-2 bg-gradient-to-r from-darkest-0 to-primary-10 rounded-lg text-greyscale-100 " +
                "hover:bg-darkest-0 hover:shadow-lg hover:text-tertiary-50"
    ) {
        directoryIcon("w-6 h-6", "currentColor")
        keydownsCaptured.filter { shortcutOf(it) == Keys.Enter } handledBy {
            getScreeningServiceStatus()
        }
        clicks handledBy {
            getScreeningServiceStatus()
        }
    }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
        placement = PlacementValues.right
        +"Refresh screening service status"
        arrow()
    }

    div("flex gap-4 items-center") {
        statusStore.data.render { status ->
            div {
                +"Screening service status: "

                val color = when (status.status) {
                    ScreeningServiceStatus.IDLE -> "text-contrast-green"
                    ScreeningServiceStatus.BUSY -> "text-primary-10"
                    ScreeningServiceStatus.STOPPED -> "text-error-red"
                    ScreeningServiceStatus.ERROR -> "text-error-red"
                    ScreeningServiceStatus.STOPPING -> "text-error-red"
                }
                span("mx-2 text-lg font-semibold $color") {
                    +status.status.toString()
                }
            }
        }
    }
}

private fun RenderContext.screeningServiceLog() {
    button(
        "p-2 bg-gradient-to-r from-darkest-0 to-primary-10 rounded-lg text-greyscale-100 " +
                "hover:bg-darkest-0 hover:shadow-lg hover:text-tertiary-50"
    ) {
        featureIcon("w-6 h-6", "currentColor")
        keydownsCaptured.filter { shortcutOf(it) == Keys.Enter } handledBy {
            getScreeningServiceLog()
        }
        clicks handledBy {
            getScreeningServiceLog()
        }
    }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
        placement = PlacementValues.right
        +"get screening service log"
        arrow()
    }
}

private fun RenderContext.screeningMonitor(router: MapRouter, windowWidthStore: Store<Int>) {
    val translationStore by koin.inject<TranslationStore>()
    val screeningHistoryScreening by koin.inject<ScreeningHistoryService>()
    val filterStore = storeOf("")

    MainScope().launch {
        screeningHistoryScreening.refreshData()
    }

    screeningHistoryScreening.pollingUtility.startPolling(router, 5000L, Pages.screeningMonitor) {
        screeningHistoryScreening.refreshData()
    }

    dataCollection("mt-4") {
        data(screeningHistoryScreening.activeScreeningStore.data, ScreeningHistory::id)

        div("flex gap-4 mr-4") {
            filterStore.data handledBy filterByText { screening: ScreeningHistory -> screening.userMail }
            div("grow") {
                textInput(translationStore[UiMonitoring.FilterLabel], filterStore, null)
            }

            button(
                "mt-4 mb-8 p-2 bg-gradient-to-r from-darkest-0 to-primary-10 rounded-lg text-greyscale-100 " +
                        "hover:bg-darkest-0 hover:shadow-lg hover:text-tertiary-50"
            ) {
                platformIcon("w-6 h-6", "currentColor")
                keydownsCaptured.filter { shortcutOf(it) == Keys.Enter } handledBy {
                    getScreeningHistory()
                }
                clicks handledBy {
                    getScreeningHistory()
                }
            }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
                placement = PlacementValues.right
                +"Download screening history"
                arrow()
            }
        }

        windowWidthStore.data.render { width ->
            div("px-4") {
                table("w-full") {
                    thead("bg-darkest-0 text-greyscale-100 rounded-lg") {
                        th("py-1 px-2 rounded-l-lg") { +"ID" }
                        th("py-1 px-2") { +"E-Mail" }
                        th(if (width <= 640) "py-1 px-2 rounded-r-lg" else "py-1 px-2") { +"State" }
                        if (width > 640) th("py-1 px-2") { +"Name" }
                        if (width > 640) th("py-1 px-2") { +"Creation Time" }
                        if (width > 1000) th("py-1 px-2") { +"Start Time" }
                        if (width > 1000) th("py-1 px-2") { +"Download Time" }
                        if (width > 640) th((if (width <= 1500) "py-1 px-2 rounded-r-lg" else "py-1 px-2")) { +"Deletion Time" }
                        if (width > 1500) th("py-1 px-2") { +"Number of documents" }
                        if (width > 1500) th("py-1 px-2") { +"Number of pages" }
                        if (width > 1500) th("py-1 px-2") { +"Number of OCR pages" }
                        if (width > 1500) th("py-1 px-2") { +"Slot" }
                        if (width > 1500) th("py-1 px-2 rounded-r-lg") { +"Model ID's" }
                    }
                    dataCollectionItems(tag = RenderContext::tbody) {
                        scrollIntoView(ScrollBehavior.smooth)
                        items.renderEach(ScreeningHistory::id) { screening: ScreeningHistory ->
                            dataCollectionItem(item = screening, tag = RenderContext::tr) {
                                td("py-1 px-2") { +"${screening.id}" }
                                td("py-1 px-2") { +screening.userMail }
                                td("py-1 px-2") { +"${screening.state}" }
                                if (width > 640) td("py-1 px-2") { +screening.name }
                                if (width > 640) td("py-1 px-2") {
                                    +(screening.creationDateTime?.format(dateTimeFormat) ?: "")
                                }
                                if (width > 1000) td("py-1 px-2") {
                                    +(screening.startDateTime?.format(dateTimeFormat) ?: "")
                                }
                                if (width > 1000) td("py-1 px-2") {
                                    +(screening.downloadDateTime?.format(dateTimeFormat) ?: "")
                                }
                                if (width > 640) td("py-1 px-2") {
                                    +(screening.deletionDateTime?.format(dateTimeFormat) ?: "")
                                }
                                if (width > 1500) td("py-1 px-2") { +(screening.numDocuments?.toString() ?: "") }
                                if (width > 1500) td("py-1 px-2") { +(screening.numPages?.toString() ?: "") }
                                if (width > 1500) td("py-1 px-2") { +(screening.numOcr?.toString() ?: "") }
                                if (width > 1500) td("py-1 px-2") { +(screening.slot?.toString() ?: "") }
                                if (width > 1500) td("py-1 px-2") { +screening.models.joinToString(", ") }
                            }
                        }
                    }
                }
            }
        }
    }
}

