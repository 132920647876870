package components

import dev.fritz2.core.RenderContext
import kotlinx.coroutines.flow.Flow

fun RenderContext.headingBanner(heading: Flow<String>, description: Flow<String>, bannerClass: String) {
    div ("$bannerClass w-full px-8 py-12 rounded-lg"){
        div ("text-5xl font-semibold") {
            heading.renderText(this)
        }
        div ("text-lg p-1 w-6/12"){
            description.renderText(this)
        }
    }
}