package domain.authentication.login

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import dev.fritz2.validation.validation
import kotlinx.serialization.Serializable
import localization.login.UiRegister
import util.EmailValidator
import util.Message
import util.Severity

@Lenses
@Serializable
data class Register(
    val email: String,
    val username: String,
    val password: String,
    val passwordConfirmation: String
) {
    companion object {

        const val PATH = "/register"
        const val MAIL_TAKEN = "Email is already registered!"
        const val WAITING_APPROVAL = "Waiting for approval!"

        val validation: Validation<Register, Unit, Message> = validation { inspector ->
            val email = inspector.map(Register.email())
            if (validateEmail(email.data)) {
                add(Message(email.path, Severity.Error, UiRegister.EmailInvalid))
            }

            val username = inspector.map(Register.username())
            if (validateUsername(username.data)) {
                add(Message(username.path, Severity.Error, UiRegister.UsernameInvalid))
            }

            val password = inspector.map(Register.password())
            if (validatePassword(password.data)) {
                add(Message(password.path, Severity.Error, UiRegister.PasswordInvalid))
            }

            val passwordConfirmation = inspector.map(Register.passwordConfirmation())
            if (validatePasswordConfirmation(passwordConfirmation.data)) {
                add(Message(passwordConfirmation.path, Severity.Error, UiRegister.PasswordConfirmationInvalid))
            }

            if (validateEquality(password.data, passwordConfirmation.data)) {
                add(Message(passwordConfirmation.path, Severity.Error, UiRegister.PasswordEquality))
            }
        }

        fun validate(register: Register): Boolean = !validateEmail(register.email) &&
                !validateUsername(register.username) &&
                !validatePassword(register.password) &&
                !validatePasswordConfirmation(register.passwordConfirmation) &&
                !validateEquality(register.password, register.passwordConfirmation)

        private fun validateEmail(email: String): Boolean = !EmailValidator.isEmailValid(email.trim())
        private fun validateUsername(username: String): Boolean = username.trim().isBlank()
        private fun validatePassword(password: String): Boolean = password.trim().isBlank()
        private fun validatePasswordConfirmation(passwordConfirmation: String): Boolean =
            passwordConfirmation.trim().isBlank()

        private fun validateEquality(password: String, passwordConfirmation: String): Boolean =
            password != passwordConfirmation
    }
}
