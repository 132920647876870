package localization.policies

import com.tryformation.localization.Translatable

enum class UiCookie  : Translatable {
    Heading,
    Application,
    IntroductionHeading,
    IntroductionContent,
    ExplainingHeading,
    ExplainingContent,
    UsageHeading,
    UsageForDescription,
    UsageForFirstHeading,
    UsageForFirstContent,
    UsageForSecondHeading,
    UsageForSecondContent,
    UsageSpecialDescription,
    UsageSpecialFirstHeading,
    UsageSpecialFirstContent,
    UsageSpecialSecondHeading,
    UsageSpecialSecondContent,
    UsageSpecialThirdHeading,
    UsageSpecialThirdContent,
    NecessaryHeading,
    NecessaryContent,
    ManagingHeading,
    ManagingContent,
    ChangesHeading,
    ChangesContent;

    override val prefix: String = "cookie"
}