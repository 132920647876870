package localization.admin

import com.tryformation.localization.Translatable

enum class UiDirectoryEdit : Translatable {
    Heading,
    Description,
    NameLabel,
    NameValidation,
    GroupsValidation,
    NameDuplicate,
    SingletonLabel,
    TabFolder,
    TabFile,
    TabModel,
    SaveButton;

    override val prefix: String = "directory-edit"
}