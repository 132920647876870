// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.userManagement

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import domain.model.ModelPermission
import domain.repository.Feature
import domain.repository.Permission
import kotlin.Boolean
import kotlin.String
import kotlin.collections.List

public fun User.Companion.email(): Lens<User, String> = lensOf(
    "email",
    { it.email },
    { p, v -> p.copy(email = v)}
  )

public fun <PARENT> Lens<PARENT, User>.email(): Lens<PARENT, String> = this + User.email()

public fun User.Companion.username(): Lens<User, String> = lensOf(
    "username",
    { it.username },
    { p, v -> p.copy(username = v)}
  )

public fun <PARENT> Lens<PARENT, User>.username(): Lens<PARENT, String> = this + User.username()

public fun User.Companion.approved(): Lens<User, Boolean> = lensOf(
    "approved",
    { it.approved },
    { p, v -> p.copy(approved = v)}
  )

public fun <PARENT> Lens<PARENT, User>.approved(): Lens<PARENT, Boolean> = this + User.approved()

public fun User.Companion.password(): Lens<User, String?> = lensOf(
    "password",
    { it.password },
    { p, v -> p.copy(password = v)}
  )

public fun <PARENT> Lens<PARENT, User>.password(): Lens<PARENT, String?> = this + User.password()

public fun User.Companion.groups(): Lens<User, List<Group>> = lensOf(
    "groups",
    { it.groups },
    { p, v -> p.copy(groups = v)}
  )

public fun <PARENT> Lens<PARENT, User>.groups(): Lens<PARENT, List<Group>> = this + User.groups()

public fun User.Companion.roles(): Lens<User, List<Role>> = lensOf(
    "roles",
    { it.roles },
    { p, v -> p.copy(roles = v)}
  )

public fun <PARENT> Lens<PARENT, User>.roles(): Lens<PARENT, List<Role>> = this + User.roles()

public fun User.Companion.permissions(): Lens<User, List<Permission>> = lensOf(
    "permissions",
    { it.permissions },
    { p, v -> p.copy(permissions = v)}
  )

public fun <PARENT> Lens<PARENT, User>.permissions(): Lens<PARENT, List<Permission>> = this +
    User.permissions()

public fun User.Companion.modelPermissions(): Lens<User, List<ModelPermission>> = lensOf(
    "modelPermissions",
    { it.modelPermissions },
    { p, v -> p.copy(modelPermissions = v)}
  )

public fun <PARENT> Lens<PARENT, User>.modelPermissions(): Lens<PARENT, List<ModelPermission>> =
    this + User.modelPermissions()

public fun User.Companion.features(): Lens<User, List<Feature>> = lensOf(
    "features",
    { it.features },
    { p, v -> p.copy(features = v)}
  )

public fun <PARENT> Lens<PARENT, User>.features(): Lens<PARENT, List<Feature>> = this +
    User.features()
