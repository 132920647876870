package homepage.sides

import api.postContactTrial
import components.dropdownSelector
import components.policyCheckbox
import components.textInput
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.routing.MapRouter
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.valid
import domain.contact.*
import homepage.LanguageStore
import homepage.navigation.Pages
import koin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import localization.TranslationStore
import localization.homepage.UiTeam
import localization.homepage.UiTrial
import util.Message
import utils.mainButton
import web.dom.document
import web.html.HTMLButtonElement

var navRouter: MapRouter? = null

//validationStore used for validation of inputs for ContactTrial
object ContactTrialStore : ValidatingStore<ContactTrial, Unit, Message>(
    ContactTrial("", "", "", "", ""),
    ContactTrial.validation,
    Unit, Job(),
    validateAfterUpdate = false
) {
    private val languageStore by koin.inject<LanguageStore>()

    val save = handle {
        if (validate(it).valid) {
            resetMessages()
            val trial = ContactTrial(it.firstname, it.surname, it.email, it.company, it.attentiveBy)
            postContactTrial(trial, languageStore.current)
            navRouter?.navTo?.let { it1 -> it1(mapOf("page" to Pages.home)) }
            ContactTrial("", "", "", "", "")
        } else it
    }
}

fun RenderContext.trial(router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()
    val policyCheckboxStore: Store<Boolean> = object : RootStore<Boolean>(false, Job()) {
        override val update: SimpleHandler<Boolean> = handle { _, newValue ->
            val trialButton: HTMLButtonElement = document.getElementById("trialButton") as HTMLButtonElement
            trialButton.disabled = !newValue
            trialButton.ariaDisabled = (!newValue).toString()
            newValue
        }
    }
    navRouter = router

    //textInput stores
    val msgs: Flow<List<Message>> = ContactTrialStore.messages
    val firstname = ContactTrialStore.map(ContactTrial.firstname())
    val surname = ContactTrialStore.map(ContactTrial.surname())
    val company = ContactTrialStore.map(ContactTrial.company())
    val email = ContactTrialStore.map(ContactTrial.email())
    val attentiveBy = ContactTrialStore.map(ContactTrial.attentiveBy())


    article {
        h1("text-3xl font-semibold") {
            translationStore[UiTrial.Heading].renderText(this)
        }

        div("trialInputContainer") {
            textInput(translationStore[UiTrial.Name], firstname, msgs)
            textInput(translationStore[UiTrial.Surname], surname, msgs)
            textInput(translationStore[UiTrial.Email], email, msgs)
            textInput(translationStore[UiTrial.Company], company, msgs)

            attentiveBy.update(translationStore.getString(UiTrial.AttentiveByOptionOne))

            val dropdownList = listOf(
                translationStore.getString(UiTrial.AttentiveByOptionOne),
                translationStore.getString(UiTrial.AttentiveByOptionTwo),
                translationStore.getString(UiTrial.AttentiveByOptionThree),
                translationStore.getString(UiTrial.AttentiveByOptionFour),
                translationStore.getString(UiTrial.AttentiveByOptionFive)
            )

            dropdownSelector(translationStore[UiTrial.AttentiveBy], attentiveBy, dropdownList)


            policyCheckbox(policyCheckboxStore, router, "policyCheckboxTrial")

            button(mainButton, "trialButton") {
                attr("disabled", "true")
                attr("aria-disabled", "true")
                translationStore[UiTrial.Button].renderText(this)
                clicks handledBy ContactTrialStore.save
            }
        }
    }
}