package homepage.sides

import api.getFaqs
import com.tryformation.localization.Locale
import components.DisclosureText
import components.keyspotDisclosure
import dev.fritz2.core.*
import dev.fritz2.headless.components.disclosure
import domain.util.Faq
import koin
import kotlinx.coroutines.Job
import localization.Locales
import localization.TranslationStore
import localization.homepage.UiFaq
import localization.policies.UiLegal

var language: Locale? = null

object FaqStore : RootStore<List<Faq>>(emptyList(), Job()) {

    private val getAllFaq: SimpleHandler<Unit> = handle {
        getFaqs(language ?: Locales.EN_US)
    }

    val updateFaq: Handler<Locale> = handle { _, locale ->
        getFaqs(locale)
    }

    init {
        getAllFaq()
    }
}

fun RenderContext.faq() {
    val translationStore by koin.inject<TranslationStore>()

    article {
        h1("text-3xl font-semibold") {
            translationStore[UiFaq.Heading].renderText(this)
        }

        FaqStore.data.renderEach { faq: Faq ->
            div {
                keyspotDisclosure(DisclosureText.StringContent(faq.question), DisclosureText.StringContent(faq.answer))
            }
        }
    }
}