package admin.sides

import admin.navigation.Pages
import api.*
import components.headingBanner
import components.textInput
import dev.fritz2.core.Handler
import dev.fritz2.core.RenderContext
import dev.fritz2.headless.components.toast
import dev.fritz2.routing.MapRouter
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.valid
import domain.repository.Feature
import domain.repository.display
import domain.repository.name
import io.ktor.http.*
import koin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import localization.admin.UiFeatureEdit
import login.LoginPages
import util.Message
import utils.mainButton
import utils.toastError
import web.http.fetch


object FeatureStore : ValidatingStore<Feature, Unit, Message>(
    Feature(0, "", ""),
    Feature.validation,
    Unit, Job(),
    validateAfterUpdate = false
) {
    val initialize: Handler<String> = FeatureStore.handle { old, newId ->
        try {
            if(newId.toInt() == 0) return@handle Feature(0, "", "")
            val feature = getFeature(newId)
            toast("success", 1000L, "successToast") {
                +"Retrieving of feature successful"
            }
            feature
        } catch (e: Exception) {
            toastError(e.message ?: "Retrieving feature failed")
            old
        }
    }
    val save: Handler<Pair<String, MapRouter>> = FeatureStore.handle { feature, idRouterPair ->

        val newId = idRouterPair.first
        val router = idRouterPair.second
        if (FeatureStore.validate(feature).valid) {
            FeatureStore.resetMessages()
            try {
                if (duplicateFeature(feature)) {
                    toastError("Duplicate feature name")
                    return@handle feature
                }

                val response: Feature = when (newId) {
                    "0" -> postFeature(feature)
                    else -> putFeature(feature)
                }
                router.navTo(mapOf("page" to Pages.featureManagement))
                return@handle response
            } catch (e: Exception) {
                toastError(e.message ?: "Saving feature failed")
            }
            feature
        } else feature
    }

}

fun RenderContext.featureEdit(router: MapRouter, id: String?) {
    val translationStore by koin.inject<TranslationStore>()

    val msgs: Flow<List<Message>> = FeatureStore.messages
    val name = FeatureStore.map(Feature.name())
    val display = FeatureStore.map(Feature.display())

    if (id != null) {
        FeatureStore.initialize(id)
    }

    headingBanner(
        translationStore[UiFeatureEdit.Heading],
        translationStore[UiFeatureEdit.Description],
        "bg-heading-banner-admin-2"
    )

    div("w-full mt-8") {
        textInput(translationStore[UiFeatureEdit.NameLabel], name, msgs)
        textInput(translationStore[UiFeatureEdit.DisplayLabel], display, msgs)
    }
    div("w-full flex items-center justify-center") {
        button(mainButton) {
            clicks.map { Pair(id ?: "", router) } handledBy FeatureStore.save
            translationStore[UiFeatureEdit.SaveButton].renderText(this)
        }
    }

}