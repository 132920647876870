package homepage.sides.indexComponents

import dev.fritz2.core.*
import koin
import kotlinx.coroutines.flow.Flow
import localization.TranslationStore
import localization.homepage.UiSolution

data class BulletinPoint(val iconUrl: String, val heading: Flow<String>, val description: Flow<String>)

data class BulletinItem(val iconUrl: String, val heading: Flow<String>, val points: List<BulletinPoint>)

fun RenderContext.solution() {
    val translationStore by koin.inject<TranslationStore>()

    val bulletinItems = mapOf(
        1 to BulletinItem("../images/docHeap.svg", translationStore[UiSolution.InputHeading], listOf(
            BulletinPoint("../images/document.svg", translationStore[UiSolution.InputFormatHeading],
                translationStore[UiSolution.InputFormatDesc]),
            BulletinPoint("../images/wrench.svg", translationStore[UiSolution.InputEngineeringHeading],
                translationStore[UiSolution.InputEngineeringDesc])
        )),
        2 to BulletinItem("../images/gearAi.svg", translationStore[UiSolution.PipelineHeading], listOf(

            BulletinPoint("../images/gear.svg", translationStore[UiSolution.PipelineAdvancedHeading],
                translationStore[UiSolution.PipelineAdvancedDesc]),
            BulletinPoint("../images/treeList.svg", translationStore[UiSolution.PipelineRepositoryHeading],
                translationStore[UiSolution.PipelineRepositoryDesc]),
            BulletinPoint("../images/edit.svg", translationStore[UiSolution.PipelineModelsHeading],
                translationStore[UiSolution.PipelineModelsDesc])
        )),
        3 to BulletinItem("../images/docStructured.svg", translationStore[UiSolution.OutputHeading], listOf(

            BulletinPoint("../images/docHighlighted.svg", translationStore[UiSolution.OutputHighlightedHeading],
                translationStore[UiSolution.OutputHighlightedDesc]),
            BulletinPoint("../images/table.svg", translationStore[UiSolution.OutputRequirementHeading],
                translationStore[UiSolution.OutputRequirementDesc]),
            BulletinPoint("../images/list.svg", translationStore[UiSolution.OutputCommentingHeading],
                translationStore[UiSolution.OutputCommentingDesc])
        ))
    )


    article {
        div ("headingArticle"){
            attr("aria-hidden", "true")
            h1 {
                translationStore[UiSolution.Heading].renderText(this)
            }
            div ("headingArticleUnderline"){
                attr("aria-hidden", "true")
            }
        }


        //solutionVideo()

        bulletinItems.forEach { item ->
            solutionItem(item.key, item.value)
        }
    }
}

private fun RenderContext.solutionVideo() {
    div("videoContainer") {
        iframe("videoFrame") {
            src("https://player.vimeo.com/video/821191112?h=5ad71fcc62&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479")
            allowFullscreen(true)
            frameBorder("0")
            title("keyspot - smart document screening")
        }
    }
}

private fun RenderContext.solutionItem(position: Int, bulletinItem: BulletinItem) {

    if(position%2 == 0) {
        div ("bulletinItem"){
            attr("aria-hidden", "true")
            bulletinItemHeading(bulletinItem, "bulletinItemHeadingBgRight")
            bulletinItemPoints(bulletinItem, "bulletinWidthLeft")
        }
    } else {
        div ("bulletinItem") {
            attr("aria-hidden", "true")
            bulletinItemHeading(bulletinItem, "bulletinItemHeadingBgLeft")
            bulletinItemPoints(bulletinItem, "")
        }
    }

}

private fun RenderContext.bulletinItemHeading(bulletinItem: BulletinItem, bgClass: String?) {
    div (classes("bulletinGrid", bgClass)){
        h2 {
            bulletinItem.heading.renderText(this)
        }
        div("bulletinItemIcon") {
            attr("aria-hidden", "true")
            img("icon filter-tertiary50") {
                attr("aria-hidden", "true")
                attr("role", "img")
                src(bulletinItem.iconUrl)
                alt("")
            }
        }
    }
}

private fun RenderContext.bulletinItemPoints(bulletinItem: BulletinItem, bgClass: String) {
    div (classes("bulletinWidth", bgClass)){
        attr("aria-hidden", "true")
        bulletinItem.points.forEach { solutionPoint ->
            div("bulletinPoint") {
                attr("aria-hidden", "true")
                div("bulletinPointIcon") {
                    attr("aria-hidden", "true")
                    img("icon") {
                        attr("aria-hidden", "true")
                        attr("role", "img")
                        src(solutionPoint.iconUrl)
                        alt("")
                    }
                }
                div {
                    h3("bulletinPointHeader") {
                        solutionPoint.heading.renderText(this)
                    }
                    p("bulletinPointDescription") {
                        solutionPoint.description.renderText(this)
                    }
                }
            }
        }
    }
}