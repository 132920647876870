package domain.model

import dev.fritz2.core.Id
import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import domain.userManagement.User
import kotlinx.serialization.Serializable
import localization.platform.UiModelRule
import util.Message
import util.Severity

@Lenses
@Serializable
data class Rule(
    val id: Int,
    val label: String,

    val owner: User?,

    val combinations: List<Combination>,
    val metadata: List<String>,

    val language: Language = Language.ENGLISH,
    val span: Span = Span.MEDIUM,
    val headingSearch: Boolean = false,
    val orderSensitivity: Boolean = false,
    val connector: Connector = Connector.AND,

    val uniqueIdentifier: String = Id.next(16)
) {
    fun isContentEqual(other: Rule): Boolean {
        if (this === other) return true
        if (label != other.label) return false
        if (owner != other.owner) return false
        if (!combinations.isContentEqual(other.combinations)) return false
        if (metadata != other.metadata) return false
        if (language != other.language) return false
        if (span != other.span) return false
        if (headingSearch != other.headingSearch) return false
        if (orderSensitivity != other.orderSensitivity) return false
        if (connector != other.connector) return false
        return true
    }

    private fun List<Combination>?.isContentEqual(other: List<Combination>?): Boolean {
        if (this == null && other == null) return true
        if (this == null || other == null) return false
        if (this.size != other.size) return false

        return this.all { combination ->
            val otherCombination = other.find { it.id == combination.id } ?: return false
            combination.isContentEqual(otherCombination)
        }
    }

    fun getPreview(): String {
        if (combinations.firstOrNull { it.type == CombinationType.PHRASE } != null) return getPhrasePreview()

        return when (connector) {
            Connector.AND -> {
                if (combinations.size == 1) {
                    getTermPreview()
                } else {
                    getCompoundPreview()
                }
            }

            else -> getTermsPreview()
        }
    }

    private fun getCompoundPreview(): String {
        return combinations.joinToString("&&") { it.getPreview() }
    }

    private fun getTermPreview(): String {
        if (combinations[0].words.size > 1) return "single terms should only consist of one word"
        return combinations[0].words[0].word
    }

    private fun getTermsPreview(): String {
        combinations.forEach { if (it.words.size > 1) return@getTermsPreview "synonym listing should only consist of one word per combination" }
        return combinations.map { it.words[0].word }.joinToString { "; " }
    }

    private fun getPhrasePreview(): String {
        val phrases = combinations.filter { it.type == CombinationType.PHRASE }
        phrases.forEach { if (it.words.size > 1) return@getPhrasePreview "phrases should only consist of one word per combination" }
        return phrases.joinToString("<br>") { it.words[0].word }
    }

    companion object {
        const val PATH = "/modelRule"

        val validation: Validation<Rule, Unit, Message> = dev.fritz2.validation.validation { inspector ->
            val label = inspector.map(Rule.label())
            if (!isValidLabel(label.data)) {
                add(Message(label.path, Severity.Error, UiModelRule.LabelMessage))
            }

            val connector = inspector.map(Rule.connector())
            val combinations = inspector.map(Rule.combinations())

            if (!areValidCombinations(combinations.data, connector.data)) {
                add(Message(combinations.path, Severity.Error, UiModelRule.CombinationsMessage))
            }

        }

        fun isValid(rule: Rule): Boolean =
            isValidLabel(rule.label) && areValidCombinations(rule.combinations, rule.connector)

        private fun isValidLabel(label: String): Boolean = label.length < 255

        private fun areValidCombinations(combinations: List<Combination>, connector: Connector): Boolean {
            if (connector == Connector.NOR || connector == Connector.POR || connector == Connector.NPOR) return false

            return when (connector) {
                Connector.AND -> isValidCompound(combinations)
                else -> areValidSeperatedTerms(combinations)
            }
        }

        private fun isValidCompound(combinations: List<Combination>): Boolean {
            if (combinations.size == 1) return isValidSingleTermOrRegex(combinations[0])
            if (combinations.find { combination: Combination -> combination.type == CombinationType.PHRASE } != null) {
                return isValidPhraseCombination(combinations)
            }

            if (combinations.find { it.connector == Connector.AND } != null) return false

            return true
        }

        private fun isValidPhraseCombination(combinations: List<Combination>): Boolean {
            val termCombinations = combinations.filter { it.type == CombinationType.TERM }
            if (termCombinations.isEmpty() || (termCombinations.size > 1 && termCombinations[0].words.size != 1)) return false

            val phraseCombinations = combinations.filter { it.type == CombinationType.PHRASE }
            return !(phraseCombinations.size > 5 && !(phraseCombinations.all { phraseCombination ->
                phraseCombination.words.size == 1 && phraseCombination.words[0].type == WordType.PHRASE && phraseCombination.words[0].word != ""
            }))
        }

        private fun isValidSingleTermOrRegex(combination: Combination): Boolean =
            (combination.type == CombinationType.TERM || combination.type == CombinationType.REGEX) && combination.words.size == 1

        private fun areValidSeperatedTerms(combinations: List<Combination>): Boolean {
            return combinations.isNotEmpty() && combinations.all { combination ->
                combination.type == CombinationType.TERM &&
                        combination.words.size == 1 &&
                        combination.words[0].type == WordType.TERM
            }
        }
    }
}