package domain.util

import kotlinx.serialization.Serializable

@Serializable
data class Faq (val question: String, val answer:String) {
    val id: Int = question.hashCode()

    companion object {
        const val PATH = "/faq"
    }
}