package platform.navigation

import api.*
import dev.fritz2.core.*
import dev.fritz2.headless.components.toast
import dev.fritz2.headless.components.toastContainer
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.portalRoot
import dev.fritz2.routing.MapRouter
import dev.fritz2.routing.routerOf
import domain.wpn.VapidKeys
import koin
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.await
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import org.w3c.workers.ServiceWorkerRegistration
import platform.sides.*
import react.FC
import react.Props

external interface RouterProps : Props

object Pages {
    const val home = "Home"
    const val create = "Create"
    const val screenings = "Screenings"
    const val customization = "Customize"
    const val modelCustomization = "Customization"
    const val groupCustomization = "Group Customization"
    const val phraseCustomization = "Phrase Customization"
    const val criteriumCustomization = "Criterium Customization"
    const val legal = "Legal"
    const val admin = "Admin"
    const val logout = "Logout"
}

object AdminStore : RootStore<Boolean>(false, Job()) {
    val initialize: Handler<Unit> = handle {
        try {
            isAdmin()
        } catch (e: Exception) {
            false
        }
    }
}

object PersonalIdStore : RootStore<Int>(0, Job()) {
    val initialize: Handler<Unit> = handle {
        try {
            getPersonalId()
        } catch (e: Exception) {
            0
        }
    }
}

object VapidKeyStore : RootStore<VapidKeys?>(null, Job()) {
    val initialize: Handler<Unit> = handle {
        try {
            getVapidKey()
        } catch (e: Exception) {
            null
        }
    }
}

var serviceWorkerScope: String? = null

val PlatformRouter = FC<RouterProps> {

    val router = routerOf(mapOf("page" to Pages.home, "id" to ""))

    val menuItems = mapOf(
        Pages.home to mutableMapOf(
            "svgCode" to "M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z",
            "viewBox" to "0 0 576 512"
        ),
        Pages.create to mutableMapOf(
            "svgCode" to "M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z",
            "viewBox" to "0 0 448 512"
        ),
        Pages.screenings to mutableMapOf(
            "svgCode" to "M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z",
            "viewBox" to "0 0 384 512"
        ),
        Pages.customization to mutableMapOf(
            "svgCode" to "M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z",
            "viewBox" to "0 0 512 512"
        ),
        Pages.legal to mutableMapOf(
            "svgCode" to "M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z",
            "viewBox" to "0 0 192 512"
        ),
        Pages.admin to mutableMapOf(
            "svgCode" to "M459.1 52.4L442.6 6.5C440.7 2.6 436.5 0 432.1 0s-8.5 2.6-10.4 6.5L405.2 52.4l-46 16.8c-4.3 1.6-7.3 5.9-7.2 10.4c0 4.5 3 8.7 7.2 10.2l45.7 16.8 16.8 45.8c1.5 4.4 5.8 7.5 10.4 7.5s8.9-3.1 10.4-7.5l16.5-45.8 45.7-16.8c4.2-1.5 7.2-5.7 7.2-10.2c0-4.6-3-8.9-7.2-10.4L459.1 52.4zm-132.4 53c-12.5-12.5-32.8-12.5-45.3 0l-2.9 2.9C256.5 100.3 232.7 96 208 96C93.1 96 0 189.1 0 304S93.1 512 208 512s208-93.1 208-208c0-24.7-4.3-48.5-12.2-70.5l2.9-2.9c12.5-12.5 12.5-32.8 0-45.3l-80-80zM200 192c-57.4 0-104 46.6-104 104v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-75.1 60.9-136 136-136h8c8.8 0 16 7.2 16 16s-7.2 16-16 16h-8z",
            "viewBox" to "0 0 512 512"
        ),
        Pages.logout to mutableMapOf(
            "svgCode" to "M459.1 52.4L442.6 6.5C440.7 2.6 436.5 0 432.1 0s-8.5 2.6-10.4 6.5L405.2 52.4l-46 16.8c-4.3 1.6-7.3 5.9-7.2 10.4c0 4.5 3 8.7 7.2 10.2l45.7 16.8 16.8 45.8c1.5 4.4 5.8 7.5 10.4 7.5s8.9-3.1 10.4-7.5l16.5-45.8 45.7-16.8c4.2-1.5 7.2-5.7 7.2-10.2c0-4.6-3-8.9-7.2-10.4L459.1 52.4zm-132.4 53c-12.5-12.5-32.8-12.5-45.3 0l-2.9 2.9C256.5 100.3 232.7 96 208 96C93.1 96 0 189.1 0 304S93.1 512 208 512s208-93.1 208-208c0-24.7-4.3-48.5-12.2-70.5l2.9-2.9c12.5-12.5 12.5-32.8 0-45.3l-80-80zM200 192c-57.4 0-104 46.6-104 104v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-75.1 60.9-136 136-136h8c8.8 0 16 7.2 16 16s-7.2 16-16 16h-8z",
            "viewBox" to "0 0 512 512"
        )
    )
    val selectedMenuItem = storeOf(Pages.home, Job())

    val screeningService by koin.inject<ScreeningService>()

    render("#root") {
        AdminStore.initialize()
        PersonalIdStore.initialize()
        VapidKeyStore.initialize()

        MainScope().launch {
            screeningService.refreshData()
            if (window.navigator.serviceWorker != undefined) {
                try {
                    window.navigator.serviceWorker
                        .register("/service-worker.js")
                        .await()

                    val readyRegistration: ServiceWorkerRegistration = window.navigator.serviceWorker
                        .ready
                        .await()
                    serviceWorkerScope = readyRegistration.scope
                } catch (e: Exception) {
                    console.log("Service Worker registration failed: ${e.message}")
                }
            }
        }

        div("flex h-screen antialiased text-greyscale-10") {
            // Sidebar for larger screens
            nav("sm:block hidden") {
                sidebar(router, menuItems, selectedMenuItem)
            }

            // Mobile header for smaller screens
            div("sm:hidden") {
                mobileHeader(router, selectedMenuItem)
            }

            // Main content
            main(router, selectedMenuItem)

            // Bottom bar for smaller screens
            div("sm:hidden") {
                bottombar(router, menuItems, selectedMenuItem)
            }
        }

        toastContainer("success", "toastContainer")
        toastContainer("progress", "toastContainer")
        toastContainer("error", "toastContainer")
        portalRoot()
    }
}

fun RenderContext.main(router: MapRouter, selectedMenuItem: Store<String>) {

    main("flex grow h-screen flex-col gap-0 sm:ml-20 xl:ml-56 py-4 sm:py-4 px-0 sm:px-4 text-primary-10") {
        router.select("page").render(into = this) { (page, other) ->
            when (page) {
                Pages.home -> index()
                Pages.create -> create(other["id"], router)
                Pages.screenings -> screenings(router, selectedMenuItem)
                Pages.customization -> modelCustomizationManagement(router)
                Pages.modelCustomization -> modelCustomizationEdit(other["id"] ?: "0", other["init"] ?: "false", router)
                Pages.groupCustomization -> modelCustomizationGroupEdit(
                    other["modelId"] ?: "0",
                    other["groupUniqueIdentifier"] ?: "0",
                    router
                )

                Pages.phraseCustomization -> modelCustomizationPhraseEdit(
                    other["modelId"] ?: "0",
                    other["groupUniqueIdentifier"] ?: "0",
                    other["ruleUniqueIdentifier"] ?: "0",
                    router
                )

                Pages.criteriumCustomization -> modelCustomizationCriteriumEdit(
                    other["modelId"] ?: "0",
                    other["groupUniqueIdentifier"] ?: "0",
                    other["ruleUniqueIdentifier"] ?: "0",
                    router
                )

                Pages.legal -> impressum()
                else -> section {
                    h5 {
                        +"Page not found"
                    }
                }
            }
        }
    }
}

fun RenderContext.sidebar(
    router: MapRouter,
    menuItems: Map<String, MutableMap<String, String>>,
    selectedMenuItem: Store<String>
) {
    aside("fixed flex flex-col top-0 left-0 w-20 xl:w-56 bg-darkest-0 h-full border-r") {
        sideBarLogo(router, selectedMenuItem)
        sideBarMenuItems(router, menuItems, selectedMenuItem)
        sideBarFooter(router, selectedMenuItem)
    }
}

fun RenderContext.mobileHeader(router: MapRouter, selectedMenuItem: Store<String>) {
    header("flex fixed inset-x-0 top-0 border-b border-greyscale-60 h-16 sm:hidden") {
        div("flex items-center cursor-pointer") {
            logo(
                logo = "logo_white.svg",
                width = "",
                height = "h-8",
                scale = "mt-0.5 ml-0.5",
                padding = "p-2",
                displayOptions = "sm:hidden",
                router = router
            )
        }.clicks.map { Pages.home } handledBy selectedMenuItem.update
    }
}

fun RenderContext.logo(
    logo: String = "logo_white.svg",
    height: String = "w-150",
    width: String = "h-20",
    scale: String = "",
    padding: String = "p-6",
    displayOptions: String = "xl:block hidden",
    router: MapRouter
) {
    a("$padding $displayOptions") {
        attr("role", "link")
        tabIndex(0)
        img("$height $width $scale") {
            src("../images/$logo")
            alt("keyspot Logo")
        }
        keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }.map {
            mapOf("page" to Pages.home)
        } handledBy router.navTo
        clicks.map {
            mapOf("page" to Pages.home)
        } handledBy router.navTo
    }
}

fun RenderContext.bottombar(
    router: MapRouter,
    menuItems: Map<String, MutableMap<String, String>>,
    selectedMenuItem: Store<String>
) {
    div("block fixed inset-x-0 bottom-0 z-10 bg-white border-t border-greyscale-60 shadow sm:hidden") {
        div("justify-between") {


            AdminStore.data.render(this) { boolValue ->
                menuItems.forEach { menuItem ->
                    if (menuItem.key != Pages.legal && menuItem.key != Pages.customization && menuItem.key != Pages.logout && (menuItem.key != Pages.admin || boolValue)) {
                        a("w-1/5 hover:cursor-pointer justify-center inline-block text-center pt-2 pb-1") {
                            attr("role", "link")
                            tabIndex(0)

                            keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }.map {
                                mapOf("page" to menuItem.key)
                            } handledBy router.navTo

                            clicks.map {
                                selectedMenuItem.update(menuItem.key)
                                mapOf("page" to menuItem.key)
                            } handledBy router.navTo


                            className(selectedMenuItem.data.map { if (it == menuItem.key) "bg-slate-300 bg-rounded-lg" else "" })

                            svg("inline-block mb-1 text-darkest-0") {
                                className(selectedMenuItem.data.map { if (it == menuItem.key) "scale-110" else "scale-90" })
                                attr("width", "25")
                                attr("height", "25")
                                attr("fill", "currentColor")
                                attr("viewBox", menuItem.value["viewBox"])
                                path {
                                    attr("d", menuItem.value["svgCode"])
                                }
                            }
                            span("tab block text-xs") {
                                +menuItem.key
                            }
                        }
                    }
                }
            }
        }
    }

}

fun RenderContext.sideBarMenuItems(
    router: MapRouter,
    menuItems: Map<String, MutableMap<String, String>>,
    selectedMenuItem: Store<String>
) {
    div("overflow-y-auto overflow-x-hidden flex-grow") {
        ul("flex flex-col py-4 space-y-2 text-xl") {

            AdminStore.data.render(this) { boolValue ->
                menuItems.forEach { menuItem ->
                    if (menuItem.key != Pages.legal && menuItem.key != Pages.customization && menuItem.key != Pages.logout && (menuItem.key != Pages.admin || boolValue)) {
                        li("cursor-pointer") {
                            menuItem(menuItem, selectedMenuItem, router, null)
                        }.tooltip("xl:hidden text-primary-100 bg-primary-10 rounded-md p-1.5") {
                            +menuItem.key
                            arrow()
                            placement = "right"
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.menuItem(
    menuItem: Map.Entry<String, MutableMap<String, String>>,
    selectedMenuItem: Store<String>,
    router: MapRouter,
    name: Flow<String>?
) {
    a(
        "group/item relative flex items-center justify-center rounded-lg xl:justify-start space-x-6 m-1.5 " +
                "xl:mx-3 xl:px-4 py-6 focus:outline-none focus-visible:bg-primary-10 focus-visible:text-primary-80 " +
                "focus:visible:duration-500 hover:bg-primary-10 text-primary-100 hover:text-primary-80 " +
                "border-darkest-0 hover:duration-500"
    ) {
        attr("role", "link")

        when (menuItem.key) {
            Pages.admin -> {
                val coroutineScope = MainScope()
                domNode.onclick = {
                    coroutineScope.launch {
                        navigateToAdmin()
                    }
                }
            }

            Pages.logout -> {
                val coroutineScope = MainScope()
                domNode.onclick = {
                    coroutineScope.launch {
                        try {
                            postLogout()
                        } catch (e: Exception) {
                            toast("error", classes = "errorToast") { +"Logout failed" }
                        }
                    }
                }
            }

            else -> {
                keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }.map {
                    mapOf("page" to menuItem.key)
                } handledBy router.navTo

                clicks.map {
                    selectedMenuItem.update(menuItem.key)
                    mapOf("page" to menuItem.key)
                } handledBy router.navTo
            }
        }

        div("flex-shrink-0 group-hover/item:scale-125") {
            className(selectedMenuItem.data.map { if (it == menuItem.key) "scale-125" else "" })
            svg("w-5 h-5") {
                attr("fill", "#DFF2F6")
                attr("stroke", "currentColor")
                attr("viewBox", menuItem.value["viewBox"])
                path {
                    attr("stroke-linecap", "round")
                    attr("stroke-linejoin", "round")
                    attr("stroke-width", "2")
                    attr("d", menuItem.value["svgCode"])
                }
            }
        }

        div("grow tracking-wide truncate xl:flex hidden transform-none") {
            if (name != null) {
                name.renderText(this)
            } else {
                +menuItem.key
            }
        }

        //styling for focus to be done
        tabIndex(0)
    }
}

fun RenderContext.sideBarLogo(router: MapRouter, selectedMenuItem: Store<String>) {
    div("flex place-items-center cursor-pointer") {
        // small screens -> k logo
        logo("logo_k.svg", "h-20", "w-20", "", "sm:block xl:hidden hidden", "p-2", router)

        // large screens -> large logo
        logo(router = router)
    }.clicks.map { Pages.home } handledBy selectedMenuItem.update
}

fun RenderContext.sideBarFooter(router: MapRouter, selectedMenuItem: Store<String>) {
    // Footer container
    div("flex flex-col xl:flex-row justify-around items-center py-1 border-t border-greyscale-70") { // This makes the footer items align horizontally

        // Imprint link
        div("text-greyscale-70 text-sm cursor-pointer") {
            attr("role", "link")
            keydownsCaptured.filter { shortcutOf(it) == Keys.Enter }.map {
                mapOf("page" to Pages.legal)
            } handledBy router.navTo

            clicks.map {
                selectedMenuItem.update(Pages.legal)
                mapOf("page" to Pages.legal)
            } handledBy router.navTo
            +"Imprint"
        }

        // Logout link
        div("text-greyscale-70 text-sm cursor-pointer") {
            val coroutineScope = MainScope()
            domNode.onclick = {
                coroutineScope.launch {
                    try {
                        postLogout()
                    } catch (e: Exception) {
                        toast("error", classes = "errorToast") { +"Logout failed" }
                    }
                }
            }
            +"Logout"
        }
    }
}
