package domain.model

import kotlinx.serialization.Serializable

@Serializable
data class ModelMetadata(
    val id: Int,
    val compiledByName: String,
    val compiledByVersion: String,
    val queryId: String,
    val crDate: String,
    val lastEdit: String,
    val useDate: String,
    val minScreenerVersion: String,
    val queryLanguage: String,
    val ownerBu: String,
    val businessField: String,
    val region: String,
    val country: String,
    val customer: String,
    val project: String,
    val product: String,
    val queryAlias: String
) {
    constructor() : this(0,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "")
}
