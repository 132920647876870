package localization.admin

import com.tryformation.localization.Translatable

enum class UiDirectoryManagement : Translatable {
    Heading,
    Description,
    UpwardsTooltip,
    AddTooltip,
    DeleteEmptyTooltip,
    TableHeadingType,
    TableHeadingName,
    TableHeadingEdit,
    TableHeadingDownload,
    TableHeadingDelete,
    DeleteModalTitle,
    DeleteModalDescription,
    DeleteModalDeleteButton,
    DeleteModalCancelButton,
    DownloadSuccessToast;

    override val prefix: String = "directory-management"
}