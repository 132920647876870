package domain.repository

import kotlinx.serialization.Serializable

@Serializable
data class Permission(
    val id: Int,
    val name: String,
    val path: String,
    val permissionType: PermissionType
) {
    companion object {
        const val PATH = "/permissions"
    }
}
