package localization.platform

import com.tryformation.localization.Translatable

enum class UiModelGroup : Translatable {

    Heading,
    NameLabel,
    NameMessage,
    ColorLabel,
    ColorMessage,
    RulesLabel,
    RulesMessage;

    override val prefix: String = "model-group"
}