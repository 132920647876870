package domain.repository

enum class ScreeningDocumentType {
    FOLDER,
    PDF,
    WORD,
    EXCEL,
    TXT,
    MSG,
    EML;
}