package api

import dev.fritz2.routing.MapRouter
import domain.authentication.AuthenticationClass
import domain.authentication.login.Login
import domain.authentication.login.PasswordReset
import domain.authentication.login.PasswordUpdateReset
import domain.authentication.login.Register
import domain.authentication.token.CsrfToken
import domain.authentication.token.Pin
import domain.authentication.token.RefreshToken
import domain.authentication.token.Token
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import io.ktor.utils.io.charsets.*
import koin
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.json.Json
import localization.TranslationStore
import localization.login.UiLogin
import localization.login.UiPasswordReset
import localization.login.UiPasswordUpdate
import localization.login.UiRegister
import login.LoginPages

private enum class ServerResponseMessage {
    SUCCESSFUL,
    UNSUCCESSFUL,
    WAITING_FOR_APPROVAL
}

suspend fun postLogin(login: Login, router: MapRouter): Pair<Flow<String>, Boolean> {
    val translationStore by koin.inject<TranslationStore>()

    val response = jsonClient.post(AuthenticationClass.PATH + Login.PATH) {
        contentType(ContentType.Application.Json)
        setBody(login)
    }
    when (saveTokenAndRedirectToPinElse(response, router)) {
        ServerResponseMessage.UNSUCCESSFUL -> return Pair(translationStore[UiLogin.Unsuccessful], false)
        ServerResponseMessage.WAITING_FOR_APPROVAL -> return Pair(translationStore[UiLogin.WaitingForApproval], false)
        else -> Pair(translationStore[UiLogin.Successful], true)
    }
    return Pair(translationStore[UiLogin.Successful], true)
}

suspend fun postPin(pin: Pin): Pair<Flow<String>, Boolean> {
    val translationStore by koin.inject<TranslationStore>()

    val response = bearerJsonClient.post(AuthenticationClass.PATH + Pin.PATH) {
        contentType(ContentType.Application.Json)
        setBody(pin)
    }
    when (saveTokenAndRedirectToPlatformElse(response)) {
        ServerResponseMessage.UNSUCCESSFUL -> return Pair(translationStore[UiLogin.InvalidPin], false)
        ServerResponseMessage.WAITING_FOR_APPROVAL -> return Pair(translationStore[UiLogin.WaitingForApproval], false)
        else -> Pair(translationStore[UiLogin.Successful], true)
    }
    return Pair(translationStore[UiLogin.Successful], true)
}

suspend fun postRefresh() {
    val response = jsonClient.post(AuthenticationClass.PATH + Token.PATH + RefreshToken.PATH)
    csrfToken = (response.body() as CsrfToken).token
}

suspend fun postLogout() {
    val response = bearerJsonClient.post(AuthenticationClass.PATH + Login.LOGOUT_PATH)
    if (response.status == HttpStatusCode.OK) {
        window.location.replace("/login")
    } else {
        throw Exception("Logout failed ${response.status}")
    }
}

suspend fun postRegister(register: Register): Pair<Flow<String>, Boolean> {
    val translationStore by koin.inject<TranslationStore>()
    val response = jsonClient.post(AuthenticationClass.PATH + Register.PATH) {
        contentType(ContentType.Application.Json)
        setBody(register)
    }
    return when (response.status) {
        HttpStatusCode.OK -> Pair(translationStore[UiRegister.MailTaken], false)
        HttpStatusCode.Created -> Pair(translationStore[UiRegister.Successful], true)
        else -> Pair(translationStore[UiRegister.Unsuccessful], false)
    }
}

suspend fun postPasswordReset(passwordReset: PasswordReset): Flow<String> {
    val translationStore by koin.inject<TranslationStore>()
    val response = jsonClient.post(AuthenticationClass.PATH + PasswordReset.PATH) {
        contentType(ContentType.Application.Json)
        setBody(passwordReset)
    }
    return if (response.status == HttpStatusCode.OK) {
        translationStore[UiPasswordReset.Successful]
    } else {
        translationStore[UiPasswordReset.Unsuccessful]
    }
}

suspend fun postPasswordUpdateReset(passwordUpdateReset: PasswordUpdateReset, token: String): Flow<String> {
    val translationStore by koin.inject<TranslationStore>()
    val pathBearerClient = HttpClient {
        install(ContentNegotiation) {
            json(Json {
                prettyPrint = true
                isLenient = true
            })
        }
        install(Auth) {
            bearer {
                loadTokens {
                    BearerTokens(token, "")
                }
            }
        }
    }

    val response = pathBearerClient.post(AuthenticationClass.PATH + PasswordUpdateReset.PATH) {
        contentType(ContentType.Application.Json)
        setBody(passwordUpdateReset)
    }

    return if (response.status == HttpStatusCode.OK) {
        navigateToLogin()
        translationStore[UiPasswordUpdate.Successful]
    } else {
        translationStore[UiPasswordUpdate.Unsuccessful]
    }
}

private suspend fun saveTokenAndRedirectToPinElse(response: HttpResponse, router: MapRouter): ServerResponseMessage {
    if (response.status == HttpStatusCode.OK) {
        csrfToken = (response.body() as CsrfToken).token
        router.navTo(mapOf("page" to LoginPages.PIN))
        return ServerResponseMessage.SUCCESSFUL
    } else if (response.status == HttpStatusCode.Unauthorized) {
        if (response.bodyAsText(Charsets.UTF_8) == Login.APPROVAL_MESSAGE) {
            return ServerResponseMessage.WAITING_FOR_APPROVAL
        }
    }
    return ServerResponseMessage.UNSUCCESSFUL
}

private suspend fun saveTokenAndRedirectToPlatformElse(response: HttpResponse): ServerResponseMessage {
    if (response.status == HttpStatusCode.OK) {
        navigateToPlatformAfterLogin()
        return ServerResponseMessage.SUCCESSFUL
    } else if (response.status == HttpStatusCode.Unauthorized) {
        if (response.bodyAsText(Charsets.UTF_8) == Login.APPROVAL_MESSAGE) {
            return ServerResponseMessage.WAITING_FOR_APPROVAL
        }
    }
    return ServerResponseMessage.UNSUCCESSFUL
}

suspend fun navigateToAdmin() {
    postRefresh()
    window.location.replace("/admin")
}

fun navigateToPlatformAfterLogin() {
    window.location.replace("/platform")
}

suspend fun navigateToPlatform() {
    postRefresh()
    window.location.replace("/platform")
}

private fun navigateToLogin() {
    window.location.replace(Login.PATH)
}

fun getCookie(name: String): String? {
    val value = "; " + document.cookie
    val parts = value.split("; $name=")
    if (parts.size == 2) return parts.last().split(";").first()
    return null
}