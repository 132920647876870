package homepage.sides.indexComponents

import dev.fritz2.core.RenderContext
import dev.fritz2.routing.MapRouter
import homepage.navigation.Pages
import homepage.navigation.aLink
import koin
import localization.TranslationStore
import localization.components.UiRouting
import localization.homepage.UiBanner
import localization.homepage.UiMainDescription

fun RenderContext.heroSection(router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()

    div("heroContainer") {
        article ("heroSection") {
            h1 {
                translationStore[UiBanner.Content].renderText(this)
            }

            p("heroSectionDesc") {
                translationStore[UiMainDescription.Desc].renderText(this)
            }

            div("heroSectionBtns") {
                aLink("heroSectionTrial trial", Pages.trial, router, translationStore[UiRouting.TrialHero])
            }
        }
    }
}