@file:Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")

package components

import dev.fritz2.core.*
import domain.util.FileType
import koin
import kotlinx.browser.document
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import localization.components.UiFileInput
import org.w3c.dom.*
import org.w3c.files.File
import kotlin.js.Promise


fun RenderContext.fileInput(
    store: RootStore<MutableList<File>>,
    allowMultiple: Boolean,
    allowedFileTypes: List<FileType>,
    extractZip: Boolean,
    classNames: String = ""
) {
    val translationStore by koin.inject<TranslationStore>()

    div("file-input-container") {
        val progressBarContainerId = Id.next()
        val fileInputId = Id.next()
        div("file-input-drag-drop-area") {
            input(id = fileInputId, baseClass = "hidden") {
                tabIndex(-1)
                type("file")
                multiple(allowMultiple)
                accept(FileType.mimeTypes(allowedFileTypes))
                clicks handledBy {
                    this.value("")
                }
                changes.files().map { fileList ->
                    extractAndAddToFiles(
                        extractZip,
                        fileList?.asList() ?: emptyList(),
                        allowMultiple,
                        allowedFileTypes,
                        progressBarContainerId
                    )
                } handledBy store.update
            }

            button(classNames) {

                svg("h-16 w-full pointer-events-none") {
                    xmlns("http://www.w3.org/2000/svg")
                    viewBox("0 0 24 24")
                    fill("currentColor")
                    path {
                        d("M18.944 11.112C18.507 7.67 15.56 5 12 5 9.244 5 6.85 6.611 5.757 9.15 3.609 9.792 2 11.82 2 14c0 2.757 2.243 5 5 5h11c2.206 0 4-1.794 4-4a4.01 4.01 0 0 0-3.056-3.888zM13 14v3h-2v-3H8l4-5 4 5h-3z")
                    }
                }

                val textField = div ("pointer-events-none", id=Id.next()) { + "test" }
                translationStore[UiFileInput.Label].renderText(textField)


                attr("aria-label", "Dropzone and click button")

                keydownsCaptured.filter { shortcutOf(it) == Keys.Enter } handledBy {
                    (document.getElementById(fileInputId) as? HTMLInputElement)?.click()
                }
                clicks handledBy {
                    (document.getElementById(fileInputId) as? HTMLInputElement)?.click()
                }

                dragenters handledBy {
                    it.preventDefault()
                    (it.target as? HTMLElement)?.classList?.add("file-input-drag-over")
                }

                dragleaves handledBy {
                    it.preventDefault()
                    (it.target as? HTMLElement)?.classList?.remove("file-input-drag-over")

                    translationStore[UiFileInput.Label].renderText(textField)
                }

                dragovers handledBy { dragEvent ->
                    dragEvent.preventDefault()
                    dragEvent.dataTransfer?.dropEffect = "copy"
                }

                dragoversCaptured handledBy { dragEvent ->
                    dragEvent.preventDefault()

                    val dataTransferItemList = dragEvent.dataTransfer?.items

                    if (dataTransferItemList != null) {

                        if (!allowMultiple && dataTransferItemList.length > 1) {
                            translationStore[UiFileInput.DragoverSize].renderText(textField)
                            dragEvent.dataTransfer?.dropEffect = "copy"
                        } else {
                            var notAllowedTypesOnly = true
                            for (i in 0 until dataTransferItemList.length) {
                                val item = dataTransferItemList[i]
                                if (allowedFileTypes.contains(FileType.ALL) || allowedFileTypes.map { file -> file.mimeType }
                                        .contains(item?.type)) {
                                    notAllowedTypesOnly = false
                                }
                            }

                            if (notAllowedTypesOnly) {
                                translationStore[UiFileInput.DragoverType].renderText(textField)
                                dragEvent.dataTransfer?.dropEffect = "none"
                            } else {
                                if (extractZip) {
                                    translationStore[UiFileInput.DragoverExtract].renderText(textField)
                                } else {
                                    translationStore[UiFileInput.DragoverDefault].renderText(textField)
                                }
                                dragEvent.dataTransfer?.dropEffect = "copy"
                            }
                        }
                    }

                    dragEvent.dataTransfer?.dropEffect = "copy"
                }

                this.dropsCaptured handledBy { dropEvent ->
                    dropEvent.preventDefault()
                    dropEvent.stopPropagation()

                    val eventTarget = (dropEvent.target as? HTMLElement)
                    eventTarget?.classList?.remove("file-input-drag-over")

                    translationStore[UiFileInput.Label].renderText(textField)

                    CoroutineScope(Dispatchers.Default).launch {
                        try {
                            val items = dropEvent.dataTransfer?.items ?: return@launch
                            val dataTransferItems = List(items.length) { index -> items[index] as DataTransferItem }

                            val entries = dataTransferItems.mapNotNull { it.webkitGetAsEntry() }
                            val promises = entries.map { entry ->
                                async {
                                    processEntry(
                                        entry,
                                        mutableListOf(),
                                        extractZip,
                                        allowedFileTypes,
                                        document.getElementById(progressBarContainerId) as HTMLDivElement
                                    ).await()
                                }
                            }
                            (document.getElementById(progressBarContainerId) as HTMLDivElement).hidden = true
                            val resolvedLists = promises.map { it.await() }
                            val fileList = resolvedLists.flatten()

                            store.update(
                                fileList.toMutableList().takeIf { allowMultiple || it.isNotEmpty() }?.let {
                                    if (allowMultiple) it.map { file ->
                                        file.name.replace("/", "_")
                                        file
                                    }.toMutableList() else mutableListOf(it.first())
                                } ?: mutableListOf()
                            )
                        } catch (e: Exception) {
                            console.error("An error occurred:", e)
                        }
                    }

                }
            }
        }

        div("file-input-progress-bar-container", id = progressBarContainerId) {
            hidden(true)
        }
    }

}

suspend fun extractAndAddToFiles(
    extractZip: Boolean,
    files: List<File>,
    allowMultiple: Boolean,
    allowedFileTypes: List<FileType>,
    progressBarContainerId: String
): MutableList<File> {
    val mutableFiles = files.toMutableList()
    if (extractZip) {
        mutableFiles.filter { it.type == FileType.ZIP.mimeType || it.type == FileType.X_ZIP.mimeType }
            .forEach { file ->
                val extractedFiles = extractZip(
                    file,
                    allowedFileTypes,
                    document.getElementById(progressBarContainerId) as HTMLDivElement
                )
                mutableFiles.addAll(extractedFiles)
            }
    }

    val rtnFiles: MutableList<File> = if (!allowMultiple) {
        mutableListOf(mutableFiles.first {
            allowedFileTypes.contains(FileType.ALL) ||
                    allowedFileTypes.map { fileType -> fileType.fileEnding }.contains(it.name.substringAfterLast("."))
        })
    } else {
        mutableFiles.filter {
            allowedFileTypes.contains(FileType.ALL) ||
                    allowedFileTypes.map { fileType -> fileType.fileEnding }.contains(it.name.substringAfterLast("."))
        }
        mutableFiles
    }

    return rtnFiles
}


fun processEntry(
    entry: FileSystemEntry,
    fileList: MutableList<File>,
    extractZip: Boolean,
    allowedFileTypes: List<FileType>,
    progressBarContainer: HTMLDivElement?
): Promise<List<File>> {
    return Promise { resolve, _ ->
        when {
            entry.isFile -> {
                val fileEntry = entry as FileSystemFileEntry
                fileEntry.filePromise().then { file ->
                    CoroutineScope(Dispatchers.Default).launch {
                        try {
                            if (extractZip && (FileType.ZIP.mimeType == file.type || FileType.X_ZIP.mimeType == file.type)) {
                                val extractedFiles = extractZip(file, allowedFileTypes, progressBarContainer)
                                fileList.addAll(extractedFiles)
                            } else if (allowedFileTypes.contains(FileType.ALL) ||
                                allowedFileTypes.map { fileType -> fileType.fileEnding }
                                    .contains(file.name.substringAfterLast("."))
                            ) {
                                fileList.add(file)
                            }
                            resolve(fileList)
                        } catch (e: Exception) {
                            console.error("An error occurred:", e)
                        }
                    }
                }
            }

            entry.isDirectory -> {
                val directoryEntry = entry as FileSystemDirectoryEntry
                val reader = directoryEntry.createReader()
                reader.readEntries { entries ->
                    val promises = entries.map { subEntry ->
                        processEntry(subEntry, fileList, extractZip, allowedFileTypes, progressBarContainer)
                    }
                    Promise.all(promises.toTypedArray()).then {
                        resolve(fileList)
                    }
                }
            }
        }
    }
}