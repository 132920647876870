package admin.sides

import admin.navigation.Pages
import api.*
import components.fileInput
import components.headingBanner
import components.textInput
import dev.fritz2.core.*
import dev.fritz2.headless.components.TabGroup
import dev.fritz2.headless.components.listbox
import dev.fritz2.headless.components.tabGroup
import dev.fritz2.headless.components.toast
import dev.fritz2.headless.foundation.utils.floatingui.core.middleware.flip
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import dev.fritz2.headless.foundation.utils.floatingui.utils.StrategyValues
import dev.fritz2.routing.MapRouter
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.valid
import domain.repository.Document
import domain.repository.DocumentType
import domain.repository.SingletonFolderType
import domain.repository.name
import domain.util.FileType
import io.ktor.client.statement.*
import io.ktor.http.*
import koin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import localization.TranslationStore
import localization.admin.UiDirectoryEdit
import localization.admin.UiDirectoryManagement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.files.File
import util.Message
import utils.createProgressBar
import utils.mainButton
import utils.toastError

object DocumentValidationStore : ValidatingStore<Document, Unit, Message>(
    Document(
        0,
        "",
        DocumentType.FOLDER,
        null,
        "",
        Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()),
        Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()),
        null
    ),
    Document.validation,
    Unit,
    job = Job(),
    validateAfterUpdate = false
) {
    val initialize: Handler<Int> = DocumentValidationStore.handle { _, id ->
        if (id != 0 && id != -1) {
            val document = getDocument(id)
                ?: Document(
                    0,
                    "",
                    DocumentType.FOLDER,
                    null,
                    "",
                    Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()),
                    Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()),
                    null
                )

            when (document.type) {
                DocumentType.FOLDER -> {
                    fileTabDisable.update(true)
                    modelTabDisable.update(true)
                    tabIndex.update(0)
                }

                DocumentType.FILE -> {
                    folderTabDisable.update(true)
                    modelTabDisable.update(true)
                    tabIndex.update(1)
                }

                DocumentType.MODEL -> {
                    folderTabDisable.update(true)
                    fileTabDisable.update(true)
                    tabIndex.update(2)
                }
            }

            folderTypeStore.update(document.folderType)
            document
        } else {
            Document(
                0,
                "",
                DocumentType.FOLDER,
                null,
                "",
                Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()),
                Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()),
                null
            )
        }
    }

    val updateFolderType: Handler<SingletonFolderType> = handle { document, type ->
        val updatedDocument = document.copy(folderType = type)
        updatedDocument
    }

    val save = DocumentValidationStore.handle {
        if (DocumentValidationStore.validate(it).valid) {
            DocumentValidationStore.resetMessages()
            it
        } else it
    }

    val saveFolder: Handler<Pair<Int, Int>> = handle { document, idPair ->
        if (!validate(document).valid) return@handle document

        resetMessages()
        try {
            val (id, parentId) = idPair
            if (documentNameDuplicateExists(parentId, document.name, id)) {
                toastError("Duplicate document name")
                return@handle document
            }

            val response = when (id) {
                0 -> postDocumentFolder(parentId, document)
                else -> {
                    putDocumentFolder(document)
                }
            }
            handleResponse(response, parentId)
        } catch (e: Exception) {
            toastError("Failed to check for duplicate")
        }

        document
    }

    val saveFile: Handler<SaveFileParameter> = handle { document, saveFileParameter ->
        if (!validate(document).valid) return@handle document

        resetMessages()
        try {
            with(saveFileParameter) {
                if (isDuplicateName(document)) return@handle document
                if (isPostAndFileEmpty()) return@handle document

                val response = handleFileUpload(ids.first, document, fileList.firstOrNull(), ids.second, element)
                handleResponse(response, ids.second)
            }
        } catch (e: Exception) {
            toastError("Failed to check for duplicate")
        }

        document
    }


    val saveModel: Handler<SaveFileParameter> = handle { document, saveFileParameter ->
        if (!validate(document).valid) return@handle document

        resetMessages()

        try {
            with(saveFileParameter) {
                if (isDuplicateName(document)) return@handle document
                if (isPostAndFileEmpty()) return@handle document

                val response = handleModelUpload(ids.first, document, fileList.firstOrNull(), ids.second, element)
                handleResponse(response, ids.second)
            }
        } catch (e: Exception) {
            toastError("Failed to check for duplicate")
        }

        document
    }

    private fun SaveFileParameter.isPostAndFileEmpty(): Boolean {
        if (ids.first == 0 && fileList.isEmpty()) {
            toastError("No file uploaded")
            return true
        }
        return false
    }

    private suspend fun SaveFileParameter.isDuplicateName(document: Document): Boolean {
        if (documentNameDuplicateExists(ids.second, document.name, ids.first)) {
            toastError("Duplicate document name")
            return true
        }
        return false
    }

    private suspend fun handleFileUpload(
        id: Int,
        document: Document,
        file: File?,
        parentId: Int,
        container: HTMLElement
    ): HttpResponse {
        return if (id == 0 && file != null) {
            val progressContainer = createProgressBar(file.name)
            container.append(progressContainer)
            try {
                postDocumentFile(parentId, document, file, progressContainer)
            } finally {
                progressContainer.remove()
            }
        } else {
            val progressContainer = file?.let { createProgressBar(it.name) }
            container.append(progressContainer)
            try {
                putDocumentFile(document, file, progressContainer)
            } finally {
                progressContainer?.remove()
            }
        }
    }

    private suspend fun handleModelUpload(
        id: Int,
        document: Document,
        file: File?,
        parentId: Int,
        container: HTMLElement
    ): HttpResponse {
        return if (id == 0 && file != null) {
            val progressContainer = createProgressBar(file.name, "Upload model:")
            container.append(progressContainer)
            try {
                postDocumentModel(parentId, document, file, progressContainer)
            } finally {
                progressContainer.remove()
            }
        } else {
            val progressContainer = file?.let { createProgressBar(it.name, "Upload model:") }
            container.append(progressContainer)
            try {
                putDocumentModel(document, file, progressContainer)
            } finally {
                progressContainer?.remove()
            }
        }
    }


    private fun handleResponse(response: HttpResponse, parentId: Int) {
        if (response.status == HttpStatusCode.OK) {
            toast("success", 1000L, "successToast") { +"Document operation successful" }
            updateDocumentValidationStore()
            fileTabDisable.update(false)
            modelTabDisable.update(false)
            folderTabDisable.update(false)
            globalRouter?.navTo?.let { it(mapOf("page" to Pages.directoryManagement, "id" to parentId.toString())) }
        } else {
            toastError("Failed to update/create document: ${response.status}")
        }
    }

    private fun updateDocumentValidationStore() {
        DocumentValidationStore.update(
            Document(
                -1, "",
                DocumentType.FOLDER,
                null,
                "",
                Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()),
                Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()),
                null
            )
        )
    }


}


private var globalRouter: MapRouter? = null
private val msgs: Flow<List<Message>> = DocumentValidationStore.messages
private val translationStore by koin.inject<TranslationStore>()

private val tabIndex = storeOf(0, Job())
private val folderTabDisable = storeOf(false, Job())
private val fileTabDisable = storeOf(false, Job())
private val modelTabDisable = storeOf(false, Job())
private val folderTypeStore = storeOf<SingletonFolderType?>(SingletonFolderType.NONE, Job())

data class SaveFileParameter(val fileList: MutableList<File>, val ids: Pair<Int, Int>, val element: HTMLElement)

fun RenderContext.directoryEdit(router: MapRouter, id: String?, parentId: String?) {
    val translationStore by koin.inject<TranslationStore>()
    globalRouter = router

    val idInt = id?.toInt() ?: 0
    val parentIdInt = parentId?.toInt() ?: 0

    if (idInt != 0 && idInt != -1) {
        DocumentValidationStore.initialize(idInt)
    }

    headingBanner(
        translationStore[UiDirectoryEdit.Heading],
        translationStore[UiDirectoryEdit.Description],
        "bg-heading-banner-admin-3"
    )

    tabGroup("mt-8") {
        value(tabIndex)
        tabList("bg-primary-100 rounded-lg flex justify-between") {
            tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                folderTabDisable.data handledBy disable
                disabled(disabled)
                translationStore[UiDirectoryEdit.TabFolder].renderText(this)
            }
            tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                fileTabDisable.data handledBy disable
                disabled(disabled)
                translationStore[UiDirectoryEdit.TabFile].renderText(this)
            }
            tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                modelTabDisable.data handledBy disable
                disabled(disabled)
                translationStore[UiDirectoryEdit.TabModel].renderText(this)
            }
        }
        tabPanels("mt-8") {
            folderPanel(idInt, parentIdInt)
            filePanel(idInt, parentIdInt)
            modelPanel(idInt, parentIdInt)
        }
    }
}

private fun singletonFolderTypeToString(folderType: SingletonFolderType?): String {
    return when (folderType) {
        null -> "Select an option"
        else -> folderType.name
    }
}


private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.folderPanel(id: Int, parentId: Int) {
    val documentName = DocumentValidationStore.map(Document.name())

    panel("tab-panel") {
        div("input-card") {
            textInput(translationStore[UiDirectoryEdit.NameLabel], documentName, msgs)

            folderTypeStore.data.map { newFolderType ->
                newFolderType ?: SingletonFolderType.NONE
            }.handledBy { newType -> DocumentValidationStore.updateFolderType(newType) }

            listbox("dropdown-selector-container") {
                label("dropdown-selector-label") {
                    translationStore[UiDirectoryEdit.SingletonLabel].renderText(this)
                }
                value(folderTypeStore)

                listboxButton("dropdown-selector-button") {
                    span { value.data.map { singletonFolderTypeToString(it) }.renderText() }
                    svg("dropdown-selector-icon") {

                    }
                }

                listboxItems("dropdown-selector-item-container") {
                    placement = PlacementValues.bottom
                    strategy = StrategyValues.absolute
                    addMiddleware(flip())

                    for (option in SingletonFolderType.values()) {
                        listboxItem(option, "dropdown-selector-item") {
                            attr("data-dropdown-selector-selected", selected, true.toString())
                            attr("data-dropdown-selector-active", active, true.toString())
                            attr("data-dropdown-selector-disabled", disabled, true.toString())
                            +option.name
                        }
                    }
                }
            }

            div("w-full flex items-center justify-center") {
                button(mainButton) {
                    clicks.map { Pair(id, parentId) } handledBy DocumentValidationStore.saveFolder
                    translationStore[UiDirectoryEdit.SaveButton].renderText(this)
                }
            }
        }
    }
}

private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.filePanel(id: Int, parentId: Int) {
    val documentName = DocumentValidationStore.map(Document.name())
    val fileStore = fileListStore()

    panel("tab-panel") {
        div("input-card") {
            fileListDragAndDrop(fileStore, false, listOf(FileType.ALL), false, fileStore.remove)
            textInput(translationStore[UiDirectoryEdit.NameLabel], documentName, msgs)

            div("flex justify-center") {
                button(mainButton) {
                    clicks.map {
                        try {
                            SaveFileParameter(
                                fileStore.data.first(),
                                Pair(id, parentId),
                                (it.target as? HTMLElement)?.parentElement!! as HTMLElement
                            )
                        } catch (e: Exception) {
                            SaveFileParameter(
                                mutableListOf(),
                                Pair(id, parentId),
                                (it.target as? HTMLElement)?.parentElement!! as HTMLElement
                            )
                        }
                    } handledBy DocumentValidationStore.saveFile
                    translationStore[UiDirectoryEdit.SaveButton].renderText(this)
                }
            }
        }
    }
}

private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.modelPanel(id: Int, parentId: Int) {
    val documentName = DocumentValidationStore.map(Document.name())
    val modelStore = fileListStore()

    panel("tab-panel") {
        div("input-card") {
            fileListDragAndDrop(modelStore, false, FileType.modelFileTypes(), false, modelStore.remove)
            textInput(translationStore[UiDirectoryEdit.NameLabel], documentName, msgs)

            div("flex justify-center") {
                button(mainButton) {
                    translationStore[UiDirectoryEdit.SaveButton].renderText(this)
                    clicks.map {
                        try {
                            SaveFileParameter(
                                modelStore.data.first(),
                                Pair(id, parentId),
                                (it.target as? HTMLElement)?.parentElement!! as HTMLElement
                            )
                        } catch (e: Exception) {
                            SaveFileParameter(
                                mutableListOf(),
                                Pair(id, parentId),
                                (it.target as? HTMLElement)?.parentElement!! as HTMLElement
                            )
                        }
                    } handledBy DocumentValidationStore.saveModel
                }
            }
        }
    }
}

private fun fileListStore() = object : RootStore<MutableList<File>>(mutableListOf(), Job()) {
    override val update = handle<MutableList<File>> { _, newFiles ->
        newFiles
    }

    val remove = handle<File> { files, fileToRemove ->
        files.filter { it != fileToRemove }.toMutableList()
    }
}

private fun RenderContext.fileListDragAndDrop(
    fileStore: RootStore<MutableList<File>>,
    allowMultiple: Boolean,
    allowedFileTypes: List<FileType>,
    extractZip: Boolean,
    removeHandler: SimpleHandler<File>
) {
    fileInput(fileStore, allowMultiple, allowedFileTypes, extractZip)

    div("file-store-container") {
        fileStore.data.renderEach(into = this) { file ->
            div("file-container") {
                +file.name

                button("icon-secondary-button ma-in-auto delete-secondary") {
                    attr("aria-label", "delete")
                    clicks.map { file } handledBy removeHandler
                    span { }
                }
            }
        }
    }
}
