package api

import com.tryformation.localization.Locale
import domain.contact.Contact
import domain.contact.ContactTrial
import domain.util.Faq
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*

suspend fun getFaqs(locale: Locale): List<Faq> {
    return jsonClient.get(Faq.PATH) {
        url {
            parameters.append("lang", locale.id)
        }
    }.body()
}

suspend fun postContactTrial(contactTrial: ContactTrial, locale: Locale) {
    jsonClient.post(ContactTrial.PATH) {
        contentType(ContentType.Application.Json)
        setBody(contactTrial)
        url {
            parameters.append("lang", locale.id)
        }
    }
}

suspend fun postContact(contact: Contact, locale: Locale) {
    jsonClient.post(Contact.PATH) {
        contentType(ContentType.Application.Json)
        setBody(contact)
        url {
            parameters.append("lang", locale.id)
        }
    }
}