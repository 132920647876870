package localization.policies

import com.tryformation.localization.Translatable

enum class UiLegal  : Translatable {
    Heading,
    First,
    Second;

    override val prefix: String = "third-party"
}