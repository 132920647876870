package localization.platform

import com.tryformation.localization.Translatable

enum class UiModel : Translatable {

    NameMessage,
    GroupsMessage;

    override val prefix: String = "model"
}