package homepage.sides.indexComponents

import dev.fritz2.core.Id
import dev.fritz2.core.RenderContext
import dev.fritz2.core.alt
import dev.fritz2.core.src
import koin
import localization.TranslationStore
import localization.homepage.UiSuccessStories
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.asList


data class Citation(
    val citation: List<UiSuccessStories> = emptyList(),
    val name: UiSuccessStories,
    val job: UiSuccessStories
)

fun RenderContext.successStories() {
    val translationStore by koin.inject<TranslationStore>()


    article {
        div("headingArticle") {
            attr("aria-hidden", "true")
            h1 {
                translationStore[UiSuccessStories.Heading].renderText(this)
            }
            div("headingArticleUnderline") {
                attr("aria-hidden", "true")
            }
        }

        div {
            accordion()
        }

    }
}

fun RenderContext.accordion() {

    div("accordion-wrapper") {
        div("accordion") {
            clicks handledBy {
                val target = it.target as HTMLElement
                val activePanel = target.closest(".accordion-panel")
                if (activePanel != null) {

                    val buttons = activePanel.parentElement?.querySelectorAll("button")
                    val contents = activePanel.parentElement?.querySelectorAll(".accordion-content")

                    buttons?.asList()?.forEach { node ->
                        val button = node as HTMLElement
                        button.setAttribute("aria-expanded", false.toString())
                    }

                    contents?.asList()?.forEach { node ->
                        val content = node as HTMLDivElement
                        content.setAttribute("aria-hidden", true.toString())
                    }

                    activePanel.querySelector("button")?.setAttribute("aria-expanded", true.toString())
                    activePanel.querySelector(".accordion-content")?.setAttribute("aria-hidden", false.toString())
                }
            }

            accordionPanel(
                UiSuccessStories.PressureClient,
                "../images/wikaBg.webp",
                "Wika",
                "pressureGauge",
                UiSuccessStories.Challenge,
                UiSuccessStories.PressureChallenge,
                UiSuccessStories.Solution,
                UiSuccessStories.PressureSolution,
                UiSuccessStories.Benefit,
                UiSuccessStories.PressureBenefit,
                UiSuccessStories.PressureHeading,

                citation = Citation(
                    listOf(
                        UiSuccessStories.PressureCitationFirst,
                        UiSuccessStories.PressureCitationSecond,
                        UiSuccessStories.PressureCitationThird
                    ),
                    UiSuccessStories.PressureCitationName,
                    UiSuccessStories.PressureCitationJob
                ),

                false
            )
            accordionPanel(
                UiSuccessStories.PowerClient,
                "../images/turbine.webp",
                "Turbine",
                "turbine",
                UiSuccessStories.Challenge,
                UiSuccessStories.PowerChallenge,
                UiSuccessStories.Solution,
                UiSuccessStories.PowerSolution,
                UiSuccessStories.Benefit,
                UiSuccessStories.PowerBenefit,
                UiSuccessStories.PowerHeading,
                null
            )
            accordionPanel(
                UiSuccessStories.TransClient,
                "../images/powerPlant.webp",
                "PowerPlant",
                "powerPole",
                UiSuccessStories.Challenge,
                UiSuccessStories.TransChallenge,
                UiSuccessStories.Solution,
                UiSuccessStories.TransSolution,
                UiSuccessStories.Benefit,
                UiSuccessStories.TransBenefit,
                UiSuccessStories.TransHeading,
                null
            )
            accordionPanel(
                UiSuccessStories.BuildingClient,
                "../images/building.webp",
                "Building",
                "skyscraper",
                UiSuccessStories.Challenge,
                UiSuccessStories.BuildingChallenge,
                UiSuccessStories.Solution,
                UiSuccessStories.BuildingSolution,
                UiSuccessStories.Benefit,
                UiSuccessStories.BuildingBenefit,
                UiSuccessStories.BuildingHeading,
                null
            )
        }
    }
}

private fun RenderContext.accordionPanel(
    title: UiSuccessStories,
    imgSrc: String,
    imgAlt: String,
    iconClass: String,

    situationHeading: UiSuccessStories,
    situationDescription: UiSuccessStories,

    solutionHeading: UiSuccessStories,
    solutionDescription: UiSuccessStories,

    benefitHeading: UiSuccessStories,
    benefitDescription: UiSuccessStories,

    improvement: UiSuccessStories,
    citation: Citation?,

    hidden: Boolean = true,
) {
    val translationStore by koin.inject<TranslationStore>()

    val id = Id.next()

    div("accordion-panel") {
        h2("accordion-panel-heading", id = id + "heading") {
            button("accordion-trigger") {
                attr("aria-controls", id + "content")
                attr("aria-expanded", (!hidden).toString())
                span("accordion-title", id = id + "title") {
                    translationStore[title].renderText(this)
                }
                svg("accordion-icon $iconClass") {
                    attr("aria-hidden", true)
                }
            }
        }

        div("accordion-content", id = id + "content") {
            attr("aria-labelledby", id + "heading")
            attr("aria-hidden", hidden)
            attr("role", "region")


            div("accordion-content-container") {
                if (citation != null) {
                    citation(citation)
                } else {
                    scs(situationHeading, situationDescription, "../images/challenge.svg")
                    scs(solutionHeading, solutionDescription, "../images/solution.svg")
                    scs(benefitHeading, benefitDescription, "../images/benefit.svg")

                    div("scs-improvement") {
                        translationStore[improvement].renderText(this)
                    }
                }
            }
            img("accordion-image") {
                src(imgSrc)
                alt(imgAlt)
            }
        }
    }
}

private fun RenderContext.citation(citation: Citation) {
    val translationStore by koin.inject<TranslationStore>()

    div("citation-container") {
        p("citation-cite") {
            for (uiSuccessStories in citation.citation) {
                div("citation-cite-line") {
                    translationStore[uiSuccessStories].renderText()
                }
            }
        }
        p("citation-name") {
            translationStore[citation.name].renderText(this)
        }
        p("citation-job") {
            translationStore[citation.job].renderText(this)
        }
    }
}

private fun RenderContext.scs(heading: UiSuccessStories, desc: UiSuccessStories, iconUrl: String) {
    val translationStore by koin.inject<TranslationStore>()

    div("scs-container") {
        div("scs-icon") {
            img("icon") {
                attr("aria-hidden", "true")
                attr("role", "img")
                src(iconUrl)
                alt("")
            }
            attr("aria-hidden", "true")
        }
        div("scs-content") {
            h3("scs-heading") {
                translationStore[heading].renderText(this)
            }
            p("scs-description") {
                translationStore[desc].renderText(this)
            }
        }
    }
}