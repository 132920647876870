package domain.userManagement

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import dev.fritz2.validation.validation
import domain.model.ModelPermission
import domain.repository.Feature
import domain.repository.Permission
import kotlinx.serialization.Serializable
import localization.admin.UiUserEdit
import util.Message
import util.Severity

@Lenses
@Serializable
data class User(
    var id: Int,
    val email: String,
    val username: String,
    val approved: Boolean,
    val password: String? = null,
    val groups: List<Group> = emptyList(),
    val roles: List<Role> = emptyList(),
    val permissions: List<Permission> = emptyList(),
    val modelPermissions: List<ModelPermission> = emptyList(),
    val features: List<Feature> = emptyList()
) {
    companion object {
        const val PATH = "/users"

        val validation: Validation<User, Unit, Message> = validation { inspector ->
            val username = inspector.map(User.username())
            if (validateUsername(username.data)) {
                add(Message(username.path, Severity.Error, UiUserEdit.UsernameValidation))
            }
        }

        fun validate(user: User): Boolean = validateUsername(user.username)

        private fun validateUsername(username: String): Boolean = false
    }
}
