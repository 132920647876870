package api

import domain.util.ServiceStatus
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.browser.window
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag
import web.file.File

suspend fun getScreeningServiceStatus(): ServiceStatus {
    val response = bearerJsonClient.get("/monitoring/status")
    if (response.status == HttpStatusCode.OK) {
        return response.body()
    } else {
        throw Exception("Retrieving features failed ${response.status}")
    }
}

suspend fun getScreeningServiceLog() {
    val response = bearerJsonClient.get("/monitoring/log") {
        accept(ContentType.Text.Plain)
    }
    if (response.status == HttpStatusCode.OK) {
        val bytes = response.readBytes()
        val blob = Blob(arrayOf(bytes), BlobPropertyBag("text/plain"))

        val url = URL.createObjectURL(blob)
        val a = window.document.createElement("a") as HTMLAnchorElement
        a.href = url
        a.download = "screeningServiceLog.log"
        a.click()
        URL.revokeObjectURL(url)
    } else {
        // Handle error response
        console.log("Error: ${response.status}")
    }
}