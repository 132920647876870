package domain.userManagement

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import dev.fritz2.validation.validation
import kotlinx.serialization.Serializable
import localization.admin.UiUserManagement
import util.Message
import util.Severity

@Lenses
@Serializable
data class UserFilter(
    val filter: String
) {
    companion object {
        const val PATH = "/filter"

        val validation: Validation<UserFilter, Unit, Message> = validation { inspector ->
            val filter = inspector.map(UserFilter.filter())
            if (validateFilter(filter.data)) {
                add(Message(filter.path, Severity.Error, UiUserManagement.FilterValidation))
            }
        }

        fun validate(filter: UserFilter): Boolean = validateFilter(filter.filter)

        private fun validateFilter(filter: String): Boolean = filter == "Filter" // TODO: only for testing
    }
}

