package admin.sides

import admin.navigation.Pages
import api.deleteDocument
import api.downloadDocument
import api.getDocument
import api.getDocumentChildren
import components.headingBanner
import components.modal.modalAnimationOverlay
import dev.fritz2.core.*
import dev.fritz2.headless.components.dataCollection
import dev.fritz2.headless.components.modal
import dev.fritz2.headless.components.toast
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.utils.floatingui.core.middleware.offset
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import dev.fritz2.routing.MapRouter
import domain.repository.Document
import domain.repository.DocumentType
import koin
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import localization.TranslationStore
import localization.admin.UiDirectoryManagement
import localization.admin.UiGroupManagement
import platform.sides.showModal
import utils.*

private object DocumentStore : RootStore<Document?>(null, Job()) {
    val initialize: Handler<Int> = DocumentStore.handle { _, id ->
        try {
            var document: Document? = null
            if (id != 0) {
                document = getDocument(id)
            }
            DocumentsStore.update(getDocumentChildren(id))

            toast("success", 1000L, "successToast") {
                +"Retrieving of document information successful"
            }

            document
        } catch (e: Exception) {
            toast("error", classes = "errorToast") {
                e.message
            }
            null
        }
    }

}

private object DocumentsStore : RootStore<List<Document>>(emptyList(), Job()) {
    val delete: Handler<Document> = DocumentsStore.handle { documents, item ->
        try {
            deleteDocument(item.id)
            val removedMutableList = documents.toMutableList()
            removedMutableList.remove(item)

            toast("success", 1000L, "successToast") {
                +"Document deletion successful"
            }

            removedMutableList
        } catch (e: Exception) {
            toast("error", classes = "errorToast") {
                e.message
            }
            documents
        }

    }
}

@OptIn(DelicateCoroutinesApi::class)
fun RenderContext.directoryManagement(router: MapRouter, id: String?) {
    val translationStore by koin.inject<TranslationStore>()
    DocumentStore.initialize(id?.let { id.toInt() } ?: 0)

    headingBanner(
        translationStore[UiDirectoryManagement.Heading],
        translationStore[UiDirectoryManagement.Description],
        "bg-heading-banner-admin-3"
    )

    //trigger re-render on name change
    val documentIdProvider: (Document) -> String = { document -> "${document.updateDateTime}-${document.id}" }
    dataCollection<Document>("document-data-collection mt-8") {

        data(DocumentsStore.data, documentIdProvider)

        div("document-data-button-container") {
            DocumentStore.data.render {
                val document = it
                a("icon-secondary-button upwards-secondary") {
                    attr("role", "link")
                    tabIndex(0)
                    attr("aria-label", "up")
                    clicks.map {
                        mapOf(
                            "page" to Pages.directoryManagement,
                            "id" to if (document?.parent != null) document.parent.id.toString() else "0"
                        )
                    } handledBy router.navTo
                    span {}
                }.tooltip {
                    placement = PlacementValues.right
                    addMiddleware(offset(4))
                    translationStore[UiDirectoryManagement.UpwardsTooltip].renderText(this)
                }

                button("icon-primary-button add-primary") {
                    clicks.map {
                        mapOf(
                            "page" to Pages.directoryEdit,
                            "id" to "0",
                            "parentId" to (document?.id?.toString() ?: "0")
                        )
                    } handledBy router.navTo
                    span {}
                }.tooltip {
                    placement = PlacementValues.left
                    addMiddleware(offset(4))
                    translationStore[UiDirectoryManagement.AddTooltip].renderText(this)
                }
            }
        }
        table("collection-table") {
            thead("collection-table-thead") {
                tr("collection-table-tr") {
                    td("collection-table-td") { translationStore[UiDirectoryManagement.TableHeadingType].renderText(this) }
                    td("collection-table-td collection-table-grow") {
                        translationStore[UiDirectoryManagement.TableHeadingName].renderText(
                            this
                        )
                    }
                    td("collection-table-td") { translationStore[UiDirectoryManagement.TableHeadingEdit].renderText(this) }
                    td("collection-table-td") {
                        translationStore[UiDirectoryManagement.TableHeadingDownload].renderText(
                            this
                        )
                    }
                    td("collection-table-td") {
                        translationStore[UiDirectoryManagement.TableHeadingDelete].renderText(
                            this
                        )
                    }
                }
            }
            dataCollectionItems(tag = RenderContext::tbody, classes = "collection-table-tbody") {
                items.renderEach(documentIdProvider, into = this) { item ->
                    val toggle = storeOf(false)

                    modal {
                        router.data handledBy {
                            if(it["page"] != Pages.directoryManagement) {
                                toggle.update(false)
                            }
                        }
                        openState(toggle)
                        modalPanel(modalDark) {
                            modalAnimationOverlay()

                            modalTitle(modalDarkTitle) {
                                translationStore[UiDirectoryManagement.DeleteModalTitle].renderText(
                                    this
                                )
                            }
                            modalDescription(modalDarkDescription) {
                                translationStore[UiDirectoryManagement.DeleteModalDescription].renderText(this)
                            }

                            div(modalDarkButtonContainer) {
                                button(modalDarkOk) {
                                    type("button")
                                    translationStore[UiDirectoryManagement.DeleteModalDeleteButton].renderText(this)
                                    clicks.map { item } handledBy DocumentsStore.delete
                                    clicks.map { false } handledBy toggle.update
                                }

                                button(modalDarkCancel) {
                                    type("button")
                                    translationStore[UiDirectoryManagement.DeleteModalCancelButton].renderText(this)
                                    clicks.map { false } handledBy toggle.update
                                }
                            }
                        }
                    }

                    dataCollectionItem(
                        item,
                        tag = RenderContext::tr,
                        classes = "document-data-collection-item collection-table-tr"
                    ) {
                        td("collection-table-td") {
                            when (item.type) {
                                DocumentType.FOLDER -> {
                                    a("icon-secondary-button ma-in-auto folder-secondary") {
                                        tabIndex(0)
                                        attr("role", "link")
                                        attr("aria-label", "go into folder")
                                        clicks.map {
                                            mapOf("page" to Pages.directoryManagement, "id" to item.id.toString())
                                        } handledBy router.navTo
                                        span {}
                                    }
                                }

                                DocumentType.FILE -> {
                                    div("icon-container-secondary ma-in-auto file-secondary") {
                                    }
                                }

                                DocumentType.MODEL -> {
                                    div("icon-container-secondary ma-in-auto model-secondary") {
                                    }
                                }
                            }
                        }
                        td("collection-table-td") {
                            +item.name
                        }
                        td("collection-table-td") {
                            a("icon-secondary-button ma-in-auto edit-secondary") {
                                attr("role", "link")
                                tabIndex(0)
                                attr("aria-label", "edit")
                                clicks.map {
                                    mapOf(
                                        "page" to Pages.directoryEdit,
                                        "id" to item.id.toString(),
                                        "parentId" to (item.parent?.id?.toString() ?: "0")
                                    )
                                } handledBy router.navTo
                                span {}
                            }
                        }
                        td("collection-table-td") {
                            val loadingStore = object : RootStore<Boolean>(false, Job()) {
                                val download: Handler<Int> = handle { loadingState, id ->

                                    GlobalScope.launch {
                                        try {
                                            downloadDocument(id)
                                            toast("success", 1000L, "successToast") {
                                                translationStore[UiDirectoryManagement.DownloadSuccessToast].renderText(
                                                    this
                                                )
                                            }
                                        } catch (e: Exception) {
                                            toast("error", classes = "errorToast") {
                                                e.message
                                            }
                                        }
                                        update(loadingState)
                                    }
                                    !loadingState
                                }
                            }
                            button("icon-secondary-button ma-in-auto download-secondary") {
                                disabled(loadingStore.data)
                                attr("aria-label", "download")
                                attr("aria-busy", loadingStore.data.map { it.toString() })
                                clicks.map {
                                    item.id
                                } handledBy loadingStore.download
                                span {}
                            }
                        }
                        td("collection-table-td") {
                            button("icon-secondary-button ma-in-auto delete-secondary") {
                                disabled(item.children.isNotEmpty())
                                attr("aria-label", "delete")
                                clicks.map { true } handledBy toggle.update
                                span { }
                            }
                        }.tooltip {
                            if (item.children.isNotEmpty()) {
                                translationStore[UiDirectoryManagement.DeleteEmptyTooltip].renderText(this)
                            }
                        }
                    }
                }
            }

        }
    }
}

