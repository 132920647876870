package utils

import dev.fritz2.core.RenderContext
import dev.fritz2.core.d
import dev.fritz2.core.fill
import dev.fritz2.core.viewBox
import dev.fritz2.headless.components.toast
import icons.closeIcon
import kotlinx.browser.document
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.format.Padding
import kotlinx.datetime.format.char
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLProgressElement

inline fun <T, K> Flow<Iterable<T>>.groupIterableBy(crossinline keySelector: (T) -> K): Flow<Map<K, List<T>>> =
    map { it.groupBy(keySelector) }


fun createProgressBar(fileName: String, action: String = "Extracting:"): HTMLDivElement {
    val fileProgress = document.createElement("div") as HTMLDivElement
    fileProgress.classList.add("zip-file-progress")

    val fileProgressAction = document.createElement("div") as HTMLDivElement
    fileProgressAction.classList.add("zip-file-progress-action")
    fileProgressAction.textContent = action

    val fileProgressName = document.createElement("div") as HTMLDivElement
    fileProgressName.classList.add("zip-file-progress-name")
    fileProgressName.textContent = fileName

    val fileProgressBar = document.createElement("progress") as HTMLProgressElement
    fileProgressBar.classList.add("zip-file-progress-bar")
    fileProgressBar.value = 0.0
    fileProgressBar.max = 100.0

    fileProgress.appendChild(fileProgressAction)
    fileProgress.appendChild(fileProgressName)
    fileProgress.appendChild(fileProgressBar)

    return fileProgress
}

fun updateProgress(progressBar: HTMLDivElement, value: Double) {
    (progressBar.querySelector(".zip-file-progress-bar") as? HTMLProgressElement)?.value = value
}

fun updateAction(progressBar: HTMLDivElement, action: String) {
    (progressBar.querySelector(".zip-file-progress-action") as? HTMLProgressElement)?.textContent = action
}

fun toEuropeanReadableFormat(dateTime: LocalDateTime?): String {
    return if (dateTime != null) {
        val year = dateTime.year.toString()
        val month = dateTime.monthNumber.toString().padStart(2, '0')
        val day = dateTime.dayOfMonth.toString().padStart(2, '0')
        val hour = dateTime.hour.toString().padStart(2, '0')
        val minute = dateTime.minute.toString().padStart(2, '0')
        val second = dateTime.second.toString().padStart(2, '0')

        "$year-$month-$day $hour:$minute:$second"
    } else ""
}

fun toEuropeanReadableDateFormat(dateTime: LocalDateTime?): String {
    return if (dateTime != null) {
        val year = dateTime.year.toString()
        val month = dateTime.monthNumber.toString().padStart(2, '0')
        val day = dateTime.dayOfMonth.toString().padStart(2, '0')
        "$year-$month-$day"
    } else ""
}

fun RenderContext.chevron(it: Boolean) {
    val svgClasses = if (it) "h-4" else "transition rotate-180 duration-300 h-4"
    svg(svgClasses) {
        xmlns("http://www.w3.org/2000/svg")
        viewBox("0 0 512 512")
        fill("#004669FF")
        path {
            d("M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z")
        }
    }
}

fun convertToHex(color: String): String {
    val rgbValues = color.split(",").map { it.trim().toInt() }
    return "#" + rgbValues.joinToString("") {
        it.toString(16).padStart(2, '0')
    }
}

fun toastError(message: String) {
    toast("error", duration = 0L, classes = "errorToast") {
        +message
        button {
            closeIcon("h-5 w-5", "#FFFFFF")
            clicks handledBy close
        }
    }
}

fun generateRandomString(length: Int = 24): String {
    val allowedChars = ('A'..'Z') + ('a'..'z') + ('0'..'9')
    return (1..length)
        .map { allowedChars.random() }
        .joinToString("")
}

val dateTimeFormat = LocalDateTime.Format {
    dayOfMonth()
    char('/')
    monthNumber()
    char('/')
    year()
    char(' ')
    hour()
    char(':')
    minute()
    char(':')
    second()
}