package admin.sides

import admin.sides.stores.*
import api.getUser
import api.putUser
import components.headingBanner
import components.textInput
import dev.fritz2.core.Handler
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.storeOf
import dev.fritz2.headless.components.TabGroup
import dev.fritz2.headless.components.checkboxGroup
import dev.fritz2.headless.components.tabGroup
import dev.fritz2.headless.components.toast
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.valid
import domain.model.ModelPermission
import domain.repository.Feature
import domain.repository.Permission
import domain.userManagement.*
import io.ktor.http.*
import koin
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flattenMerge
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import localization.admin.UiUserEdit
import org.w3c.dom.HTMLDivElement
import util.Message
import utils.groupIterableBy
import utils.mainButton

private val userStore = object : ValidatingStore<User, Unit, Message>(
    User(0, "", "", false, "", emptyList(), emptyList(), emptyList()),
    User.validation,
    Unit, Job(),
    validateAfterUpdate = false
) {

    val initialize: Handler<Long> = handle { _, id ->

        try {
            toast("success", 1000L, "successToast") {
                +"Retrieving of user successful"
            }
            getUser(id)
        } catch (e: Exception) {
            toast("error", classes = "errorToast") {
                e.message
            }
            User(0, "", "", false, "", emptyList(), emptyList(), emptyList())
        }
    }

    val save: Handler<Long> = handle { user, id ->
        if (validate(user).valid) {
            resetMessages()
            val response = putUser(id, user)
            if (response.status == HttpStatusCode.OK) {
                toast("success", 1000L, "successToast") {
                    +"User operation successful"
                }
            } else {
                toast("error", classes = "errorToast") {
                    +"Failed to update user: ${response.status}"
                }
            }
            user
        } else user
    }
}

private val msgs: Flow<List<Message>> = userStore.messages

private val translationStore by koin.inject<TranslationStore>()

fun RenderContext.userEdit(id: String?) {

    val idLong = id?.toLong() ?: 0

    if (id != null) {
        userStore.initialize(id.toLong())
        PermissionsStore.initialize(true)
        ModelPermissionsStore.initialize(true)
        GroupsStore.initialize(true)
        RolesStore.initialize(true)
        FeaturesStore.initialize(true)
    }

    headingBanner(
        translationStore[UiUserEdit.Heading],
        translationStore[UiUserEdit.Description],
        "bg-heading-banner-admin-1"
    )

    tabGroup("mt-8") {
        tabList("bg-primary-100 rounded-lg flex justify-between") {
            tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                translationStore[UiUserEdit.TabUser].renderText(
                    this
                )
            }
            tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                translationStore[UiUserEdit.TabGroup].renderText(
                    this
                )
            }
            tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                translationStore[UiUserEdit.TabPermission].renderText(
                    this
                )
            }
            tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                translationStore[UiUserEdit.TabModelPermission].renderText(
                    this
                )
            }
            tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                translationStore[UiUserEdit.TabRole].renderText(
                    this
                )
            }
            tab("hover:bg-tertiary-50 rounded-lg px-8 py-4 font-semibold grow aria-selected:bg-primary-10 aria-selected:text-greyscale-100") {
                translationStore[UiUserEdit.TabFeature].renderText(
                    this
                )
            }
        }
        tabPanels("mt-8") {
            userPanel()
            groupPanel()
            permissionPanel()
            modelPermissionPanel()
            rolePanel()
            featurePanel()
        }

        div("w-full flex items-center justify-center") {
            button(mainButton) {
                clicks.map { idLong } handledBy userStore.save
                translationStore[UiUserEdit.SaveButton].renderText(this)
            }
        }
    }
}

private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.userPanel() {
    val username = userStore.map(User.username())

    panel("tab-panel") {
        div("input-card") {
            textInput(translationStore[UiUserEdit.UsernameLabel], username, msgs)
        }
    }
}


private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.groupPanel() {

    val userGroups: Store<List<Group>> = userStore.map(User.groups())

    panel("tab-panel") {
        div("card-container") {
            GroupsStore.data.renderEach { group ->
                div("group-card") {
                    div("group-card-name") {
                        +group.name
                    }

                    checkboxGroup("checkbox-group") {
                        value(userGroups)
                        checkboxGroupLabel("checkbox-group-label") {}

                        checkboxGroupOption(group) {
                            checkboxGroupOptionToggle("checkbox-option-toggle") {}
                        }
                    }
                }
            }
        }
    }
}

@OptIn(ExperimentalCoroutinesApi::class)
private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.permissionPanel() {

    val groupedFlow = PermissionsStore.data.groupIterableBy { it.path }
    val userPermissions: Store<List<Permission>> = userStore.map(User.permissions())
    val userGroups: Store<List<Group>> = userStore.map(User.groups())

    panel("tab-panel") {
        div("card-container") {
            groupedFlow.render(into = this) { permissionMap ->
                permissionMap.forEach { (path, permissionList) ->
                    div("permission-card") {
                        div("permission-card-name") {
                            +path
                        }

                        checkboxGroup("checkbox-group") {
                            value(userPermissions)
                            checkboxGroupLabel("checkbox-group-label") { }
                            permissionList.forEach { permission ->
                                checkboxGroupOption(permission, classes = "checkbox-option") {

                                    val isGroupPermission: Flow<Boolean> = userGroups.data.map {
                                        it.any { group ->
                                            group.permissions.contains(permission)
                                        }
                                    }

                                    checkboxGroupOptionLabel("checkbox-option-label") {
                                        +permission.permissionType.name
                                    }
                                    checkboxGroupOptionToggle("checkbox-option-toggle") {
                                        className(isGroupPermission.map { if (it) "checkbox-option-disabled-selected" else "" })
                                    }
                                    checkboxGroupOptionDescription("checkbox-option-description") {

                                        val textStore = storeOf(flowOf(""))

                                        isGroupPermission.map {
                                            if (it) {
                                                getPermissionGroup(permission).map { group -> group.name }
                                            } else flowOf("")
                                        } handledBy textStore.update

                                        textStore.data.flattenMerge().renderText(this)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@OptIn(ExperimentalCoroutinesApi::class)
private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.modelPermissionPanel() {

    val groupedFlow = ModelPermissionsStore.data.groupIterableBy { it.path }
    val userModelPermissions: Store<List<ModelPermission>> = userStore.map(User.modelPermissions())
    val userGroups: Store<List<Group>> = userStore.map(User.groups())

    panel("tab-panel") {
        div("card-container") {
            groupedFlow.render(into = this) { permissionMap ->
                permissionMap.forEach { (path, permissionList) ->
                    div("permission-card") {
                        div("permission-card-name") {
                            +path
                        }

                        checkboxGroup("checkbox-group") {
                            value(userModelPermissions)
                            checkboxGroupLabel("checkbox-group-label") { }
                            permissionList.forEach { permission ->
                                checkboxGroupOption(permission, classes = "checkbox-option") {

                                    val isGroupPermission: Flow<Boolean> = userGroups.data.map {
                                        it.any { group ->
                                            group.modelPermissions.contains(permission)
                                        }
                                    }

                                    checkboxGroupOptionLabel("checkbox-option-label") {
                                        +permission.permissionType.name
                                    }
                                    checkboxGroupOptionToggle("checkbox-option-toggle") {
                                        className(isGroupPermission.map { if (it) "checkbox-option-disabled-selected" else "" })
                                    }
                                    checkboxGroupOptionDescription("checkbox-option-description") {

                                        val textStore = storeOf(flowOf(""))

                                        isGroupPermission.map {
                                            if (it) {
                                                getModelPermissionGroup(permission).map { group -> group.name }
                                            } else flowOf("")
                                        } handledBy textStore.update

                                        textStore.data.flattenMerge().renderText(this)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@OptIn(ExperimentalCoroutinesApi::class)
private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.featurePanel() {

    val groupedFlow = FeaturesStore.data.groupIterableBy { it.display }
    val userFeatures: Store<List<Feature>> = userStore.map(User.features())
    val userGroups: Store<List<Group>> = userStore.map(User.groups())

    panel("tab-panel") {
        div("card-container") {
            groupedFlow.render(into = this) { featureMap ->
                featureMap.forEach { (display, featureList) ->
                    div("shadow-md border border-primary-10 rounded-lg bg-greyscale-100 text-left " +
                            "transition-all duration-300 hover:shadow-xl") {
                        div("permission-card-name") {
                            +display
                        }

                        checkboxGroup("flex justify-center items-center border-t border-primary-10 pt-1") {
                            value(userFeatures)
                            checkboxGroupLabel("checkbox-group-label") { }
                            featureList.forEach { feature ->
                                checkboxGroupOption(feature, classes = "checkbox-option") {

                                    val isGroupFeature: Flow<Boolean> = userGroups.data.map {
                                        it.any { group ->
                                            group.features.contains(feature)
                                        }
                                    }

                                    checkboxGroupOptionLabel("checkbox-option-label") {
                                        + feature.name
                                    }
                                    checkboxGroupOptionToggle("checkbox-option-toggle") {
                                        className(isGroupFeature.map { if (it) "checkbox-option-disabled-selected" else "" })
                                    }
                                    checkboxGroupOptionDescription("checkbox-option-description") {

                                        val textStore = storeOf(flowOf(""))

                                        isGroupFeature.map {
                                            if (it) {
                                                getFeatureGroup(feature).map { group -> group.name }
                                            } else flowOf("")
                                        } handledBy textStore.update

                                        textStore.data.flattenMerge().renderText(this)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


private fun getPermissionGroup(permission: Permission): Flow<Group> {
    return GroupsStore.data.map {
        it.firstNotNullOf { group ->
            if (group.permissions.contains(permission)) {
                group
            } else {
                null
            }
        }
    }
}

private fun getFeatureGroup(feature: Feature): Flow<Group> {
    return GroupsStore.data.map {
        it.firstNotNullOf { group ->
            if (group.features.contains(feature)) {
                group
            } else {
                null
            }
        }
    }
}

private fun getModelPermissionGroup(modelPermission: ModelPermission): Flow<Group> {
    return GroupsStore.data.map {
        it.firstNotNullOf { group ->
            if (group.modelPermissions.contains(modelPermission)) {
                group
            } else {
                null
            }
        }
    }
}


private fun TabGroup<HTMLDivElement>.TabPanels<HTMLDivElement>.rolePanel() {

    val userRoles: Store<List<Role>> = userStore.map(User.roles())

    panel("tab-panel") {
        div("card-container") {
            RolesStore.data.renderEach { role ->
                div("role-card") {
                    div("role-card-name") {
                        +role.name
                    }

                    checkboxGroup("checkbox-group") {
                        value(userRoles)
                        checkboxGroupLabel("checkbox-group-label") {}

                        checkboxGroupOption(role) {
                            checkboxGroupOptionToggle("checkbox-option-toggle") {}
                        }
                    }
                }
            }
        }
    }
}
