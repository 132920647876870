package domain.wpn

import kotlinx.serialization.Serializable


@Serializable
data class PushSubscription(val endpoint: String, val keys: Map<String, String>) {
    companion object {
        const val PATH = "/wpn"
        const val SUBSCRIBE = "/subscribe"
        const val UNSUBSCRIBE = "/unsubscribe"
        const val IS_SUBSCRIBE = "/isSubscribed"
    }
}