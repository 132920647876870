package localization.homepage

import com.tryformation.localization.Translatable

enum class UiContact : Translatable {
    Heading,
    Name,
    NameInvalid,
    Surname,
    SurnameInvalid,
    Email,
    EmailInvalid,
    Company,
    CompanyInvalid,
    Comment,
    CommentInvalid,
    AttentiveBy,
    AttentiveByOptionOne,
    AttentiveByOptionTwo,
    AttentiveByOptionThree,
    AttentiveByOptionFour,
    AttentiveByOptionFive,
    AttentiveByMissing,
    Checkbox,
    Button;

    override val prefix: String = "contact"
}