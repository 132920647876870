package policies

import components.DisclosureText
import components.keyspotDisclosure
import dev.fritz2.core.RenderContext
import dev.fritz2.core.href
import koin
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.launch
import localization.TranslationStore
import localization.policies.UiCorporateInformation
import localization.policies.UiPrivacy

fun RenderContext.privacyPolicy(styleClass: String, emailFlexClass: String) {
    val translationStore by koin.inject<TranslationStore>()

    article(styleClass) {
        h1("text-3xl font-semibold") {
            translationStore[UiPrivacy.Heading].renderText(this)
        }
        div {
            translationStore[UiPrivacy.Description].renderText(this)
        }

        listOf(
            UiPrivacy.GeneralHeading to UiPrivacy.GeneralDescription,
            UiPrivacy.CollectingHeading to UiPrivacy.CollectingDescription,
            UiPrivacy.Storage to UiPrivacy.StorageDescription,
            UiPrivacy.Rights to UiPrivacy.RightsDescription,
            UiPrivacy.ControllerProcessor to UiPrivacy.ControllerProcessorDescription,
            UiPrivacy.DataProtection to UiPrivacy.DataProtectionDescription,
            UiPrivacy.Amendment to UiPrivacy.AmendmentDescription
        ).forEach { (heading, description) ->
            when (heading) {
                UiPrivacy.GeneralHeading -> {
                    keyspotDisclosure(DisclosureText.TranslatableContent(heading), DisclosureText.TranslatableContent(description), generalContent())
                }
                UiPrivacy.CollectingHeading -> {
                    keyspotDisclosure(
                        DisclosureText.TranslatableContent(heading),
                        DisclosureText.TranslatableContent(description),
                        collectingContent()
                    )
                }
                UiPrivacy.Storage -> {
                    keyspotDisclosure(
                        DisclosureText.TranslatableContent(heading),
                        DisclosureText.TranslatableContent(description)
                    )
                }
                UiPrivacy.Rights -> {
                    keyspotDisclosure(
                        DisclosureText.TranslatableContent(heading),
                        DisclosureText.TranslatableContent(description),
                        rightsContent()
                    )
                }
                UiPrivacy.ControllerProcessor -> {
                    keyspotDisclosure(
                        DisclosureText.TranslatableContent(heading),
                        DisclosureText.TranslatableContent(description),
                    )
                }
                UiPrivacy.DataProtection -> {
                    keyspotDisclosure(
                        DisclosureText.TranslatableContent(heading),
                        DisclosureText.TranslatableContent(description),
                    )
                }
                else -> keyspotDisclosure(
                    DisclosureText.TranslatableContent(heading),
                    DisclosureText.TranslatableContent(description),
                    amendmentContent()
                )
            }
        }

        div("mt-4") { translationStore[UiPrivacy.RightsSub].renderText(this) }
        div { translationStore[UiCorporateInformation.CooPhone].renderText(this) }
        div (emailFlexClass) {
            div { +"✉" }
            a(
                "text-primary-50 font-bold underline visited:text-primary-10 " +
                        "hover:text-primary-50 hover:no-underline hover:outline-offset-2 hover:outline-primary-50"
            ) {
                val contactEmail: Flow<String> = translationStore[UiCorporateInformation.ContactEmail]
                MainScope().launch {
                    contactEmail.collect {
                        href("mailto:${it}")
                    }
                }
                translationStore[UiCorporateInformation.ContactEmail].renderText(this)
            }
        }
    }
}

private fun amendmentContent(): RenderContext.() -> Unit = {
    val translationStore by koin.inject<TranslationStore>()
    div("mt-4") { translationStore[UiPrivacy.AmendmentDisclosure].renderText(this) }
}

private fun generalContent(): RenderContext.() -> Unit = {
    val translationStore by koin.inject<TranslationStore>()

    div("mt-4") { translationStore[UiPrivacy.GeneralCompliance].renderText(this) }
    div("mt-4") { translationStore[UiPrivacy.GeneralResponsibleParty].renderText(this) }
    div("mt-4") { translationStore[UiPrivacy.GeneralCompany].renderText(this) }
    div { translationStore[UiPrivacy.GeneralCompanyStreet].renderText(this) }
    div { translationStore[UiPrivacy.GeneralCompanyBlz].renderText(this) }
    div { translationStore[UiPrivacy.GeneralCompanyCountry].renderText(this) }
    div("mt-4") { translationStore[UiPrivacy.GeneralCompanyEmail].renderText(this) }
    div("mt-4") { translationStore[UiPrivacy.GeneralDpo].renderText(this) }
    div { translationStore[UiPrivacy.GeneralDpoName].renderText(this) }
    div("mt-4") { translationStore[UiPrivacy.GeneralContactUs].renderText(this) }
}

private fun collectingContent(): RenderContext.() -> Unit = {
    val translationStore by koin.inject<TranslationStore>()

    div("mt-4") { translationStore[UiPrivacy.CollectingDataCheck].renderText(this) }
    div("mt-4") { translationStore[UiPrivacy.CollectingInformationRequirement].renderText(this) }
    ul("list-disc px-6") {
        li { translationStore[UiPrivacy.CollectingInformationRequirementCreate].renderText(this) }
        li { translationStore[UiPrivacy.CollectingInformationRequirementDemo].renderText(this) }
        li { translationStore[UiPrivacy.CollectingInformationRequirementTrial].renderText(this) }
        li { translationStore[UiPrivacy.CollectingInformationRequirementContact].renderText(this) }
        li { translationStore[UiPrivacy.CollectingInformationRequirementEmail].renderText(this) }
    }

    div("mt-4") { translationStore[UiPrivacy.CollectingCollected].renderText(this) }
    ul("list-disc px-6") {
        li { translationStore[UiPrivacy.CollectingCollectedName].renderText(this) }
        li { translationStore[UiPrivacy.CollectingCollectedAccountName].renderText(this) }
        li { translationStore[UiPrivacy.CollectingCollectedCompany].renderText(this) }
        li { translationStore[UiPrivacy.CollectingCollectedDepartment].renderText(this) }
        li { translationStore[UiPrivacy.CollectingCollectedEmail].renderText(this) }
    }

    div("mt-4") { translationStore[UiPrivacy.CollectingUsage].renderText(this) }
    ul("list-disc px-6") {
        li { translationStore[UiPrivacy.CollectingUsageSecurity].renderText(this) }
        li { translationStore[UiPrivacy.CollectingUsageVerify].renderText(this) }
        li { translationStore[UiPrivacy.CollectingUsageResponse].renderText(this) }
        li { translationStore[UiPrivacy.CollectingUsageSupport].renderText(this) }
        li { translationStore[UiPrivacy.CollectingUsageManage].renderText(this) }
        li { translationStore[UiPrivacy.CollectingUsageEvaluate].renderText(this) }
        li { translationStore[UiPrivacy.CollectingUsageCommunication].renderText(this) }
    }

    div("mt-4") { translationStore[UiPrivacy.CollectingProcess].renderText(this) }
    ul("list-disc px-6") {
        li { translationStore[UiPrivacy.CollectingProcessEnhance].renderText(this) }
        li { translationStore[UiPrivacy.CollectingProcessProtection].renderText(this) }
        li { translationStore[UiPrivacy.CollectingProcessAnalysis].renderText(this) }
    }

    div("mt-4") { translationStore[UiPrivacy.CollectingDisclosure].renderText(this) }
}


private fun rightsContent(): RenderContext.() -> Unit = {
    val translationStore by koin.inject<TranslationStore>()

    ul("list-disc px-6") {
        li { translationStore[UiPrivacy.RightsInformation].renderText(this) }
        li { translationStore[UiPrivacy.RightsRectification].renderText(this) }
        li { translationStore[UiPrivacy.RightsErasure].renderText(this) }
        li { translationStore[UiPrivacy.RightsRestriction].renderText(this) }
        li { translationStore[UiPrivacy.RightsObject].renderText(this) }
        li { translationStore[UiPrivacy.RightsData].renderText(this) }
    }

    div("mt-4") { translationStore[UiPrivacy.RightsDisclosure].renderText(this) }
}

private fun singleList(items: List<UiPrivacy>): RenderContext.() -> Unit = {
    val translationStore by koin.inject<TranslationStore>()
    if (items.isNotEmpty()) {
        ul("list-disc px-6") {
            items.forEach { item ->
                li { translationStore[item].renderText(this) }
            }
        }
    }
}