package platform.sides

import api.*
import components.headingBanner
import components.modal.modalAnimationOverlay
import dev.fritz2.core.*
import dev.fritz2.headless.components.modal
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.InitialFocus
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import dev.fritz2.routing.MapRouter
import domain.repository.Screening
import domain.repository.ScreeningDocument
import domain.repository.ScreeningState
import icons.closeIcon
import js.promise.await
import koin
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import localization.platform.UiScreening
import org.w3c.dom.HTMLDivElement
import platform.navigation.Pages
import platform.navigation.VapidKeyStore
import platform.navigation.serviceWorkerScope
import utils.*
import web.push.PushSubscription
import web.serviceworker.ServiceWorkerRegistration

class ScreeningService(scope: CoroutineScope, val pollingUtility: PollingUtility) {
    val screeningDocumentStore = RootStore<Map<Screening, MutableList<ScreeningDocument>>>(emptyMap(), Job())
    val screeningExtendedStore = RootStore<List<Int>>(emptyList(), Job())

    init {
        scope.launch {
            refreshData()
        }
    }

    suspend fun refreshData() {
        val screenings = getScreenings()
        val documents = getDocuments(screenings.map { it.id })
        val oldDocumentMap = screeningDocumentStore.current

        val documentMap: MutableMap<Screening, MutableList<ScreeningDocument>> = mutableMapOf()
        screenings.forEach { screening ->
            documents[screening.id]?.let {
                documentMap[screening] = it.toMutableList()
            }
        }
        checkStateChangedToFinished(oldDocumentMap, documentMap)
        screeningDocumentStore.update(documentMap)
    }

    private suspend fun checkStateChangedToFinished(
        oldDocumentMap: Map<Screening, List<ScreeningDocument>>,
        documentMap: Map<Screening, MutableList<ScreeningDocument>>
    ) {
        for ((oldScreening, _) in oldDocumentMap) {
            val newScreeningEntry = documentMap.entries.find { it.key.id == oldScreening.id }

            if (newScreeningEntry != null) {
                val newScreening = newScreeningEntry.key

                if (oldScreening.state != newScreening.state && newScreening.state == ScreeningState.FINISHED) {
                    try {
                        postPush()
                    } catch (e: Exception) {
                        e.message?.let {
                            toastError(it)
                        }
                    }
                }
            }
        }
    }

    val remove = screeningDocumentStore.handle<Screening> { screenings, screeningToRemove ->
        if (deleteScreening(screeningToRemove)) {
            val updatedScreenings = screenings.filterKeys { it.id != screeningToRemove.id }
            resetRelatedStores(screeningToRemove)
            updatedScreenings
        } else {
            screenings
        }
    }


    val addScreeningId = screeningExtendedStore.handle<Int> { screeningIds, screeningId ->
        screeningIds.plus(screeningId)
    }

    val removeScreeningId = screeningExtendedStore.handle<Int> { screeningIds, screeningId ->
        screeningIds.filter { it != screeningId }
    }

    private fun resetRelatedStores(screeningToRemove: Screening) {
        if (ScreeningValidationStore.current.id == screeningToRemove.id) {
            NameStore.reset()
            ScreeningValidationStore.reset()
            ScreeningFileStore.reset()
        }
    }
}


val showModal = RootStore(false, Job())
var screeningToDelete: Screening? = null
val updateDeleteModalScreening: SimpleHandler<Screening> = showModal.handle { toggle, screening ->
    screeningToDelete = screening
    !toggle
}

fun RenderContext.screenings(router: MapRouter, selectedMenuItem: Store<String>) {

    val translationStore by koin.inject<TranslationStore>()
    val screeningService by koin.inject<ScreeningService>()

    MainScope().launch {
        screeningService.refreshData()
    }

    screeningService.pollingUtility.startPolling(router, 5000L, Pages.screenings) {
        screeningService.refreshData()
    }

    // Header
    headingBanner(
        translationStore[UiScreening.Heading],
        translationStore[UiScreening.Description],
        "bg-heading-banner-2"
    )

    val cardLayoutStore = storeOf(false)
    val updateLayout = {
        if (window.innerWidth <= 1100) {
            cardLayoutStore.update(true)
        } else {
            cardLayoutStore.update(false)
        }
    }
    window.addEventListener("resize", {
        updateLayout()
    })
    updateLayout()

    subscribeModal(router)
    deleteModal(screeningService, router)

    // Table
    screeningService.screeningDocumentStore.data.render { documentMap ->
        if (documentMap.isNotEmpty()) {
            cardLayoutStore.data.render { cardLayout ->

                val headerClasses =
                    if (cardLayout) "flex flex-col mt-4 gap-4" else "grid grid-cols-[auto_auto_1fr_auto_auto_auto_auto] mt-4"
                div(headerClasses) {

                    if (!cardLayout) renderHeader(translationStore)

                    //render extended Store to check for data changes
                    screeningService.screeningExtendedStore.data.render {
                        documentMap.forEach { (screening, documents) ->

                            renderScreeningRow(
                                documents,
                                screening,
                                screeningService,
                                router,
                                selectedMenuItem,
                                cardLayout
                            )

                            if (!cardLayout && documents.isNotEmpty() && screeningService.screeningExtendedStore.current.contains(
                                    screening.id
                                )
                            ) {
                                documents.forEach {
                                    renderDocumentRow(it)
                                }
                            }
                        }
                    }
                }
            }
        } else {
            renderNoScreeningsCard()
        }
    }
}

private fun HtmlTag<HTMLDivElement>.renderHeader(translationStore: TranslationStore) {
    val defaultHeaderClass = "text-center font-semibold bg-primary-100 mb-2 p-1"
    div("$defaultHeaderClass rounded-l-lg") { }
    div(defaultHeaderClass) { translationStore[UiScreening.HeaderId].renderText(this) }
    div(defaultHeaderClass) { translationStore[UiScreening.HeaderName].renderText(this) }
    div(defaultHeaderClass) { translationStore[UiScreening.HeaderCreationDate].renderText(this) }
    div(defaultHeaderClass) { translationStore[UiScreening.HeaderDeletionDate].renderText(this) }
    div(defaultHeaderClass) { translationStore[UiScreening.HeaderState].renderText(this) }
    div("$defaultHeaderClass rounded-r-lg") { translationStore[UiScreening.HeaderAction].renderText(this) }
}

private fun RenderContext.renderNoScreeningsCard() {
    div("flex h-full") {
        div("my-auto mx-4 p-4 rounded-lg shadow-2xl bg-greyscale-90 sm:m-auto") {
            span("text-greyscale-10") {
                +"Your screening dashboard is empty now, but it's just waiting for your first creation. Start your journey by going to the Create tab!"
            }
        }
    }
}

private fun RenderContext.renderDocumentRow(it: ScreeningDocument) {
    div("col-start-2 col-end-8 p-1 bg-greyscale-90 rounded-lg pl-12") { +it.name }
}

private fun RenderContext.renderScreeningRow(
    documents: MutableList<ScreeningDocument>,
    screening: Screening,
    screeningService: ScreeningService,
    router: MapRouter,
    selectedMenuItem: Store<String>,
    cardLayout: Boolean
) {
    if (cardLayout) {
        renderRowCard(screening, router, selectedMenuItem)
    } else {
        renderRowGrid(documents, screening, screeningService, router, selectedMenuItem)
    }
}

private fun RenderContext.renderRowCard(
    screening: Screening,
    router: MapRouter,
    selectedMenuItem: Store<String>
) {
    val translationStore by koin.inject<TranslationStore>()
    div("bg-primary-100 p-4 hover:shadow-hover rounded-lg mx-4 sm:mx-0") {
        div("grid grid-cols-[auto_1fr] gap-1 gap-x-4") {
            //id
            div("font-semibold") { translationStore[UiScreening.HeaderId].renderText(this) }
            div { +screening.id.toString() }

            //name
            div("font-semibold") { translationStore[UiScreening.HeaderName].renderText(this) }
            div { +screening.name }

            //creationDate
            div("font-semibold") { translationStore[UiScreening.HeaderCreationDate].renderText(this) }
            div { +toEuropeanReadableFormat(screening.creationDateTime) }

            //deletionDate
            div("font-semibold") { translationStore[UiScreening.HeaderDeletionDate].renderText(this) }
            div { +toEuropeanReadableFormat(screening.deletionDateTime) }

            //id
            div("font-semibold") { translationStore[UiScreening.HeaderState].renderText(this) }
            div { +screening.state.toString() }
        }

        div("flex justify-end") {
            downloadButton(screening)
            editButton(screening, router, selectedMenuItem)
            deleteButton(screening)
        }

    }
}

private fun RenderContext.renderRowGrid(
    documents: MutableList<ScreeningDocument>,
    screening: Screening,
    screeningService: ScreeningService,
    router: MapRouter,
    selectedMenuItem: Store<String>
) {
    div("p-1") {
        if (documents.isNotEmpty()) {
            toggleButton(
                screening.id,
                screeningService.screeningExtendedStore,
                screeningService.removeScreeningId,
                screeningService.addScreeningId
            )
        }
    }
    div("p-1") { +screening.id.toString() }
    div("p-1") { +screening.name }
    div("p-1") { +toEuropeanReadableFormat(screening.creationDateTime) }
    div("p-1") { +toEuropeanReadableFormat(screening.deletionDateTime) }
    div("p-1 text-right") { +screening.state.toString() }
    div("p-1") {
        div("flex justify-between") {
            downloadButton(screening)
            editButton(screening, router, selectedMenuItem)
            deleteButton(screening)
        }
    }
}

private fun RenderContext.toggleButton(
    screeningId: Int,
    screeningExtendedStore: RootStore<List<Int>>,
    removeHandler: SimpleHandler<Int>,
    addHandler: SimpleHandler<Int>
) {
    button("p-1 rounded-lg hover:bg-primary-100 focus-visible:bg-primary-100 focus-visible:outline-none") {
        attr("aria-label", "toggle screening to show/hide documents")
        val isExpanded = screeningExtendedStore.current.contains(screeningId)
        if (isExpanded) clicks.map { screeningId } handledBy removeHandler else clicks.map { screeningId } handledBy addHandler
        clicks.map { screeningId } handledBy removeHandler
        chevron(isExpanded)
    }
}

private fun RenderContext.editButton(screening: Screening, router: MapRouter, selectedMenuItem: Store<String>) {
    val translationStore by koin.inject<TranslationStore>()

    button("p-1 rounded-lg hover:bg-primary-100 focus-visible:bg-primary-100 focus-visible:outline-none") {
        attr("aria-label", "edit screening")
        if (screening.state == ScreeningState.FINISHED || screening.state == ScreeningState.QUEUED || screening.state == ScreeningState.IN_PROGRESS) {
            attr("disabled", "true")
            className("invisible")
        }
        svg("h-4") {
            xmlns("http://www.w3.org/2000/svg")
            viewBox("0 0 448 512")
            fill("#004669FF")
            path {
                d("M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z")
            }
        }
        clicks.map { screening } handledBy { updateScreening ->
            router.navTo(mapOf("page" to Pages.create, "id" to updateScreening.id.toString()))
            selectedMenuItem.update(Pages.create)
        }
    }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
        placement = PlacementValues.left
        translationStore[UiScreening.EditDescription].renderText(this)
        arrow()
    }
}

private fun RenderContext.deleteButton(screening: Screening) {
    val translationStore by koin.inject<TranslationStore>()
    button("p-1 rounded-lg hover:bg-primary-100 focus-visible:bg-primary-100 focus-visible:outline-none") {
        attr("aria-label", "delete screening")
        if (screening.state == ScreeningState.QUEUED || screening.state == ScreeningState.IN_PROGRESS) {
            attr("disabled", "true")
            className("invisible")
        }
        svg("h-4") {
            xmlns("http://www.w3.org/2000/svg")
            viewBox("0 0 448 512")
            fill("#004669FF")
            path {
                d("M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z")
            }
        }
        clicks.map { screening } handledBy updateDeleteModalScreening
    }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
        placement = PlacementValues.left
        translationStore[UiScreening.DeleteDescription].renderText(this)
        arrow()
    }
}


private fun RenderContext.downloadButton(screening: Screening) {
    val translationStore by koin.inject<TranslationStore>()
    val coroutineScope = MainScope()

    button("p-1 rounded-lg hover:bg-primary-100 focus-visible:bg-primary-100 focus-visible:outline-none") {
        attr("aria-label", "download result documents")
        if (screening.state != ScreeningState.FINISHED) {
            attr("disabled", "true")
            className("invisible")
        }
        svg("h-4") {
            xmlns("http://www.w3.org/2000/svg")
            viewBox("0 0 512 512")
            fill("#004669FF")
            path {
                d("M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z")
            }
        }
        domNode.onclick = {
            coroutineScope.launch {
                getResultsZip(screening.id)
            }
        }
    }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
        placement = PlacementValues.left
        translationStore[UiScreening.DownloadDescription].renderText(this)
        arrow()
    }
}

private fun deleteModal(screeningService: ScreeningService, router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()

    modal {
        router.data handledBy {
            if (it["page"] != Pages.screenings) {
                showModal.update(false)
            }
        }

        this.openState(showModal)
        modalPanel(modalDark) {
            modalAnimationOverlay()
            modalTitle(modalDarkTitle) { translationStore[UiScreening.DeleteModalTitle].renderText(this) }
            modalDescription(modalDarkDescription) {
                translationStore[UiScreening.DeleteModalDescription].renderText(this)
            }

            div(modalDarkButtonContainer) {
                button(modalDarkOk) {
                    setInitialFocus = InitialFocus.TryToSet
                    type("button")
                    translationStore[UiScreening.DeleteModalOk].renderText(this)
                    clicks.map { screeningToDelete!! } handledBy screeningService.remove
                    clicks.map { false } handledBy showModal.update
                }

                button(modalDarkCancel) {
                    type("button")
                    translationStore[UiScreening.DeleteModalCancel].renderText(this)
                    clicks.map { false } handledBy showModal.update
                }
            }
        }
    }
}

@OptIn(DelicateCoroutinesApi::class)
private fun RenderContext.subscribeModal(router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()

    val modalOpen = storeOf(true, Job())
    val subscribed = storeOf(false, Job())
    val progress = storeOf(0.0, Job())

    GlobalScope.launch {
        val subscription = getSubscription()
        if (subscription != null) {
            val isBackendSubscribed = isSubscribed(subscription as PushSubscription)
            if (isBackendSubscribed) {
                subscribed.update(true)
            } else {
                unsubscribeUser()
                subscribed.update(false)
            }
        } else {
            subscribed.update(false)
        }
    }

    GlobalScope.launch {
        val steps = 1000
        val duration = 20000L
        val delayTime = duration / steps

        for (i in steps downTo 0) {
            delay(delayTime)
            val progressValue = 100.0 - ((i * 100.0) / steps)
            progress.update(progressValue)
            if (i == 0) modalOpen.update(false)
        }
    }

    serviceWorkerScope?.let {
        modal {
            router.data handledBy {
                if (it["page"] != Pages.screenings) {
                    modalOpen.update(false)
                }
            }
            openState(modalOpen)

            // Banner content
            modalPanel(modalBanner) {
                modalAnimationOverlay()
                modalDescription(modalBannerDescription) {
                    //Subscribe checkbox
                    div("flex gap-4") {
                        div { +"Notify me when screening is finished" }
                        input(
                            "relative aspect-square lowercase border border-primary-10 w-6 " +
                                    "hover:bg-tertiary-50 hover:shadow-checkbox-inset " +
                                    "focus-visible:bg-tertiary-50 focus-visible:shadow-checkbox-inset focus-visible:outline-none " +
                                    "focus:bg-tertiary-50 focus:shadow-checkbox-inset focus:outline-none " +
                                    "aria-checked:bg-tertiary-50 aria-checked:shadow-checkbox-inset " +
                                    "aria-checked:after:content-[''] aria-checked:after:absolute aria-checked:after:h-8px " +
                                    "aria-checked:after:w-14px aria-checked:after:border-l-2 aria-checked:after:border-b-2 " +
                                    "aria-checked:after:top-1 aria-checked:after:left-1 aria-checked:after:text-primary-10 " +
                                    "aria-checked:after:-rotate-45 aria-checked-hover-bg-greyscale-100 aria-checked-focus-visible-bg-greyscale-100"
                        ) {
                            setInitialFocus = InitialFocus.TryToSet
                            type("checkbox")
                            checked(subscribed.data)
                            attr("aria-checked", subscribed.data.map { it.toString() })
                            changes.states() handledBy { checked ->
                                if (checked) {
                                    val vapidKey = VapidKeyStore.current
                                    vapidKey?.let {
                                        it.applicationServerKey?.let { applicationServerKey ->
                                            subscribeUser(
                                                applicationServerKey
                                            )
                                        }
                                    }
                                    subscribed.update(true)
                                } else {
                                    unsubscribeUser()
                                    subscribed.update(false)
                                }
                            }
                        }
                    }

                    // Close button
                    button(modalBannerClose) {
                        closeIcon("h-5 w-5", "currentColor")
                        clicks.map { false } handledBy modalOpen.update
                    }

                    // Progress bar
                    div("absolute w-full rounded-lg h-1 bottom-0 left-0") {
                        div("h-1 bg-tertiary-50 group-hover:bg-primary-10") {
                            progress.data.render(this) {
                                inlineStyle("width: $it%")
                            }
                        }
                    }
                }
            }
        }
    }
}

private suspend fun getSubscription(): PushSubscription? {
    return if (serviceWorkerScope != null) {
        val registration: ServiceWorkerRegistration =
            window.navigator.serviceWorker.getRegistration(serviceWorkerScope!!).await() as ServiceWorkerRegistration
        val pushManager = registration.pushManager
        val subscription = pushManager.getSubscription().await()
        subscription
    } else {
        val registration: dynamic = window.navigator.serviceWorker.ready.await()
        val subscription = registration.pushManager.getSubscription().await()
        subscription as? PushSubscription
    }
}

private suspend fun subscribeUser(applicationServerKeyByteArray: String) {
    var subscription: PushSubscription? = null
    serviceWorkerScope?.let {
        val registration =
            window.navigator.serviceWorker.getRegistration(serviceWorkerScope!!).await() as ServiceWorkerRegistration
        registration.let {
            try {

                subscription = getSubscription() as? PushSubscription
                if (subscription != null) {
                    subscription?.unsubscribe()?.await()
                }

                val options = js("({})")
                options["userVisibleOnly"] = true
                options["applicationServerKey"] = applicationServerKeyByteArray

                subscription = registration.pushManager.subscribe(options).await()
                subscription?.let {
                    subscribeToPush(it)
                }
            } catch (e: Throwable) {
                console.error("Subscription failed", e)
                subscription?.unsubscribe()
                null
            }
        }
    }
}

private suspend fun unsubscribeUser() {
    val subscription = getSubscription()
    subscription?.unsubscribe()?.await()
    unsubscribeFromPush()
}
