// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package domain.contact

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.String

public fun Contact.Companion.firstname(): Lens<Contact, String> = lensOf(
    "firstname",
    { it.firstname },
    { p, v -> p.copy(firstname = v)}
  )

public fun <PARENT> Lens<PARENT, Contact>.firstname(): Lens<PARENT, String> = this +
    Contact.firstname()

public fun Contact.Companion.surname(): Lens<Contact, String> = lensOf(
    "surname",
    { it.surname },
    { p, v -> p.copy(surname = v)}
  )

public fun <PARENT> Lens<PARENT, Contact>.surname(): Lens<PARENT, String> = this + Contact.surname()

public fun Contact.Companion.email(): Lens<Contact, String> = lensOf(
    "email",
    { it.email },
    { p, v -> p.copy(email = v)}
  )

public fun <PARENT> Lens<PARENT, Contact>.email(): Lens<PARENT, String> = this + Contact.email()

public fun Contact.Companion.company(): Lens<Contact, String> = lensOf(
    "company",
    { it.company },
    { p, v -> p.copy(company = v)}
  )

public fun <PARENT> Lens<PARENT, Contact>.company(): Lens<PARENT, String> = this + Contact.company()

public fun Contact.Companion.comment(): Lens<Contact, String> = lensOf(
    "comment",
    { it.comment },
    { p, v -> p.copy(comment = v)}
  )

public fun <PARENT> Lens<PARENT, Contact>.comment(): Lens<PARENT, String> = this + Contact.comment()

public fun Contact.Companion.attentiveBy(): Lens<Contact, String> = lensOf(
    "attentiveBy",
    { it.attentiveBy },
    { p, v -> p.copy(attentiveBy = v)}
  )

public fun <PARENT> Lens<PARENT, Contact>.attentiveBy(): Lens<PARENT, String> = this +
    Contact.attentiveBy()
