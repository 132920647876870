package platform.sides

import api.*
import components.*
import dev.fritz2.core.*
import dev.fritz2.headless.components.toast
import dev.fritz2.headless.components.tooltip
import dev.fritz2.headless.foundation.utils.floatingui.utils.PlacementValues
import dev.fritz2.routing.MapRouter
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.messages
import dev.fritz2.validation.valid
import domain.model.Rule
import domain.repository.Screening
import domain.repository.ScreeningState
import domain.util.FileType
import icons.*
import io.ktor.http.*
import io.ktor.utils.io.errors.*
import koin
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.*
import localization.TranslationStore
import localization.platform.UiCreate
import org.w3c.files.File
import platform.navigation.Pages
import util.Message
import util.Mode
import utils.iconTextButtonUnderlined
import utils.mainButton


fun emptyScreening(): Screening {
    return Screening(
        0,
        "",
        ScreeningState.FINISHED,
        0,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
    )
}

object ScreeningValidationStore : ValidatingStore<Screening, Unit, Message>(
    emptyScreening(),
    Screening.validation,
    Unit, Job()
) {
    val updateName = handle<String> { oldScreening, newName ->
        val newScreening = this.current.copy(name = newName)
        if (!validate(newScreening).valid) return@handle oldScreening
        if (validate(newScreening).valid) {
            if (oldScreening.id == 0) {
                createScreening(newScreening)
            } else {
                updateScreening(newScreening)
            }
        } else {
            oldScreening
        }
    }

    val updateIdList = handle<List<Int>> { oldScreening, newIds ->
        val newScreening = this.current.copy(modelIds = newIds)
        if (oldScreening.modelIds != newIds) {
            updateScreening(newScreening)
        } else {
            oldScreening
        }
    }

    val updateScreeningRules = handle<List<Rule>> { _, screeningRules ->
        val newScreening = this.current.copy(rules = screeningRules)
        updateScreening(newScreening)
    }

    val reset = handle {
        emptyScreening()
    }

    val initialise = handle<String?> { _, screeningId ->
        if (screeningId == null) {
            emptyScreening()
        } else {
            getScreening(screeningId)?.let { screening ->
                NameStore.update(screening.name)
                ScreeningFileStore.initialise(screening)

                modelSelector.getIdStore().update(screening.modelIds)
                modelSelector.getIdStore().data.onEach { modelList -> updateIdList(modelList) }.launchIn(MainScope())

                screening
            } ?: emptyScreening()
        }
    }

    val startScreening = handle<MapRouter> { screening, router ->
        // get all upload complete files from ScreeningFileStore
        val screeningFileIds =
            ScreeningFileStore.current.filter { it.state == DocumentState.FINISHED }
                .map { it.documentId.toInt() }
        // get all selected model Ids from ModelIdStore
        val modelIds = modelSelector.getIdStore().current
        // call backend with ScreeningFile list and modelId list
        if (screeningFileIds.isNotEmpty()) {
            try {
                startScreening(screening, screeningFileIds, modelIds)
                toast("success", classes = "successToast", duration = 5000L) {
                    +"Screening started. Check progress in Screenings tab."
                }
                // OK: reset create page
                ScreeningValidationStore.reset()
                NameStore.reset()
                ScreeningFileStore.reset()
                FileStore.reset()
                router.navTo(mapOf("page" to Pages.screenings))
                emptyScreening()
            } catch (e: IOException) {
                toast("error", classes = "errorToast", duration = 5000L) {
                    +"Failed to start screening. Model validation failed, maybe you are using an empty model."
                }
                screening
            } catch (e: Exception) {
                console.log("attachmentException $e")
                toast("error", classes = "errorToast", duration = 5000L) {
                    +"Failed to start screening. Check if all uploads are completed."
                }
                screening
            }
        } else {
            toast("error", classes = "errorToast", duration = 5000L) {
                +"Failed to start screening. Check if all uploads are completed. (ScreeningFileStore)"
            }
            screening
        }
    }
}

object NameStore : RootStore<String>("", Job()) {
    override val update = handle<String> { _, newName ->
        ScreeningValidationStore.updateName(newName)
        newName
    }
    val reset = handle { "" }
}

object FileStore : RootStore<MutableList<File>>(mutableListOf(), Job()) {
    override val update = handle<MutableList<File>> { oldFiles, newFiles ->
        var screeningId = ScreeningValidationStore.current.id
        if (screeningId == 0) {
            val screening = createScreening(ScreeningValidationStore.current)
            screeningId = screening.id
            ScreeningValidationStore.update(screening)
        }

        val screeningFiles =
            newFiles.map { ScreeningFile(it.name.replace("/", "_"), Id.next(), DocumentState.IN_PROGRESS) }
                .toMutableList()
        ScreeningFileStore.addFiles(screeningFiles)

        CoroutineScope(Dispatchers.Default).launch {
            try {
                newFiles.forEachIndexed { index, file ->
                    val screeningDocumentListPair = postScreeningDocument(screeningId, file)

                    // check if screening was changed by the user in the meantime.
                    // In this case continue uploads but don't put them in file progress store
                    if (screeningId == ScreeningValidationStore.current.id) {
                        val screeningFile = screeningFiles[index]

                        if (screeningDocumentListPair.first == HttpStatusCode.OK) {
                            var foundAndProcessedScreeningFile = false
                            screeningDocumentListPair.second.forEach { screeningDocument ->
                                when (screeningDocument.name) {
                                    screeningFile.name -> {
                                        foundAndProcessedScreeningFile = true
                                        ScreeningFileStore.updateFile(
                                            screeningFile.documentId to screeningFile.copy(
                                                documentId = screeningDocument.id.toString(),
                                                state = DocumentState.FINISHED
                                            )
                                        )

                                    }

                                    else -> {
                                        ScreeningFileStore.addFile(
                                            ScreeningFile(
                                                name = screeningDocument.name,
                                                documentId = screeningDocument.id.toString(),
                                                state = DocumentState.FINISHED
                                            )
                                        )
                                    }
                                }
                            }
                            if (!foundAndProcessedScreeningFile) {
                                ScreeningFileStore.updateFile(
                                    screeningFile.documentId to screeningFile.copy(
                                        documentId = screeningFile.documentId,
                                        state = DocumentState.REPLACED
                                    )
                                )
                            }
                        } else {
                            val screeningFileCopy = when (screeningDocumentListPair.first) {
                                HttpStatusCode.Forbidden -> screeningFile.copy(state = DocumentState.VIRUS)
                                HttpStatusCode.NotAcceptable -> screeningFile.copy(state = DocumentState.INVALID)
                                HttpStatusCode.Conflict -> screeningFile.copy(state = DocumentState.DUPLICATE)
                                else -> screeningFile.copy(state = DocumentState.FAILED)
                            }
                            ScreeningFileStore.updateFile(
                                Pair(screeningFile.documentId, screeningFileCopy)
                            )
                        }
                    }
                }
            } catch (e: Exception) {
                console.error("An error occurred:", e)
            }
        }
        (oldFiles + newFiles).toMutableList()
    }

    val remove = handle<String> { files, fileToRemove ->
        deleteScreeningDocument(ScreeningValidationStore.current.id, fileToRemove)
        files.filter { it.name != fileToRemove }.toMutableList()
    }

    val reset = handle<Unit> { _, _ ->
        mutableListOf()
    }
}

object ScreeningFileStore : RootStore<MutableList<ScreeningFile>>(mutableListOf(), Job()) {

    val updateFile = handle<Pair<String, ScreeningFile>> { files, (tempId, fileToUpdate) ->
        files.map { if (it.documentId == tempId) fileToUpdate else it }.toMutableList()
    }

    val addFile = handle<ScreeningFile> { oldFiles, newFile ->
        (oldFiles + newFile).toMutableList()
    }

    val addFiles = handle<MutableList<ScreeningFile>> { oldFiles, newFiles ->
        (oldFiles + newFiles).toMutableList()
    }

    val initialise = handle<Screening> { _, screening ->
        val screeningDocuments = getAllScreeningDocuments(screening.id)
        val screeningFiles =
            screeningDocuments.map { ScreeningFile(it.name, it.id.toString(), DocumentState.FINISHED) }.toMutableList()
        screeningFiles.toMutableList()
    }

    val remove = handle<Pair<String, Int?>> { files, (fileToRemove, screeningId) ->
        if (screeningId == null) {
            FileStore.remove(fileToRemove)
        } else {
            deleteScreeningDocument(screeningId, fileToRemove)
        }
        files.filter { it.name != fileToRemove }.toMutableList()
    }

    val reset = handle { mutableListOf() }
}

enum class DocumentState {
    IN_PROGRESS,
    REPLACED,
    DUPLICATE,
    VIRUS,
    INVALID,
    FINISHED,
    FAILED
}

data class ScreeningFile(val name: String, val documentId: String, val state: DocumentState)

private val modelSelector = ModelSelector(mode = Mode.SELECT)

fun RenderContext.create(id: String?, router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()

    // When id is null this means there is no Screening to load, User just clicked on Create page.
    // When id is not null there is a Screening to load. If the Screening in ScreeningValidationStore then has id 0,
    // this means the id was not found in the DB.
    if (id != null) {
        ScreeningValidationStore.initialise(id)
    } else {
        modelSelector.getIdStore().data.onEach { modelList -> ScreeningValidationStore.updateIdList(modelList) }
            .launchIn(MainScope())
    }
    headingBanner(translationStore[UiCreate.Heading], translationStore[UiCreate.Description], "bg-heading-banner-1")
    createNewScreening(router)
}

fun RenderContext.createNewScreening(router: MapRouter) {
    val translationStore by koin.inject<TranslationStore>()

    // Create state stores here
    val isFileStoreEmpty = FileStore.data.map { it.isEmpty() }
    val isScreeningFileStoreEmpty = ScreeningFileStore.data.map { it.isEmpty() }
    val isModelSelected = modelSelector.getIdStore().data.map { it.isNotEmpty() }
    val isNameStoreEmpty = NameStore.data.map { it.isEmpty() }
    val isCorrectlyUploaded =
        ScreeningFileStore.data.map { files -> files.isNotEmpty() && files.none { it.state == DocumentState.IN_PROGRESS } }
    val areFileStoresEmpty =
        isFileStoreEmpty.combine(isScreeningFileStoreEmpty) { fileEmpty, screeningFileEmpty -> fileEmpty && screeningFileEmpty }
    val isScreeningCreated =
        areFileStoresEmpty.combine(isNameStoreEmpty) { fileStoresEmpty, nameEmpty -> !fileStoresEmpty || !nameEmpty }
    val isScreeningReady =
        isCorrectlyUploaded.combine(isModelSelected) { correctlyUploaded, modelSelected -> correctlyUploaded && modelSelected }

    div("flex flex-1 flex-col items-center space-y-4") {
        // optionButtons
        optionButtons(isScreeningCreated)

        // enter screening name
        screeningNameInput()


        areFileStoresEmpty.render { fileStoresEmpty ->
            screeningDocumentsInput()

            if (!fileStoresEmpty) {
                fileList()

                div("px-4 w-9/12 my-8") {
                    div("bg-greyscale-90 rounded-lg p-4 mb-4") {
                        processLabel(translationStore[UiCreate.ProcessModel])
                        modelSelector(router)
                    }
                }

                var shouldUpdateScreening = false
                val msgs = ScreeningValidationStore.messages
                val screeningRulesLens: Lens<Screening, List<Rule>> = lensOf(
                    "screeningRuleLens",
                    getter = { screening -> screening.rules },
                    setter = { screening, ruleList ->
                        if (screening.areRulesContentEqual(ruleList)) {
                            screening
                        } else {
                            shouldUpdateScreening = true
                            screening.copy(rules = ruleList)
                        }
                    }
                )
                val rules = ScreeningValidationStore.map(screeningRulesLens)
                val updateScreeningRules = rules.handle<List<Rule>> { _, ruleList ->
                    if (shouldUpdateScreening) {
                        ScreeningValidationStore.updateScreeningRules(ruleList)
                        shouldUpdateScreening = false
                    }
                    return@handle ruleList
                }
                rules.data handledBy updateScreeningRules
                textareaRuleConverter(translationStore[UiCreate.QuickRules], msgs, rules, "w-9/12")

                div("flex w-9/12 py-4 gap-4 flex-wrap items-stretch flex-1") {
                    rules.data.render { ruleList: List<Rule> ->
                        if (ruleList.isNotEmpty()) {
                            ruleList.forEach { rule ->
                                rule(rule, rules)
                            }
                        }
                    }
                }
                startScreeningButton(isScreeningReady, router)
            }
        }
    }
}

fun RenderContext.rule(rule: Rule, ruleStore: Store<List<Rule>>) {
    div("p-1 bg-greyscale-70 shadow rounded-lg items-stretch flex flex-1 flex-col justify-between") {
        div {
            val ruleSplits = rule.getPreview().split("<br>")
            ruleSplits.forEach {
                div("mb-1") { +it }
            }
        }
        div("mt-2 flex justify-end") {
            removeButton(rule, ruleStore)
        }
    }
}

private fun RenderContext.removeButton(rule: Rule, ruleStore: Store<List<Rule>>) {
    val translationStore by koin.inject<TranslationStore>()
    button(
        "p-2 bg-gradient-to-r from-darkest-0 to-primary-10 rounded-lg text-greyscale-100 " +
                "hover:bg-darkest-0 hover:shadow-lg hover:text-tertiary-50"
    ) {
        trashCanSvg(false, "currentColor")
        keydownsCaptured.filter { shortcutOf(it) == Keys.Enter } handledBy {
            ruleStore.update(ruleStore.current.minus(rule))
        }
        clicks handledBy {
            ruleStore.update(ruleStore.current.minus(rule))

        }
    }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
        placement = PlacementValues.right
        +"Remove rule"
        arrow()
    }
}

fun RenderContext.processLabel(label: Flow<String>) {
    div("w-full text-left text-3xl font-semibold mb-4 ") {
        label.renderText(this)
    }
}

fun RenderContext.screeningDocumentsInput() {
    div("w-9/12") {
        fileInput(
            FileStore,
            true,
            FileType.screeningFileTypes(),
            true,
            "w-full block text-white text-lg py-24 rounded-md font-bold bg-primary-10 outline-none focus-visible:outline-none"
        )
    }
}

fun RenderContext.screeningNameInput() {
    val translationStore by koin.inject<TranslationStore>()
    div("text-base mt-0 w-9/12") {
        textInput(translationStore[UiCreate.Name], NameStore, NameStore.messages())
    }
}

fun RenderContext.fileList() {
    val translationStore by koin.inject<TranslationStore>()

    // Scrollable list of files
    div("flex flex-col w-9/12 gap-1 px-4 mb-4 max-h-40 overflow-y-auto") {

        ScreeningFileStore.data.renderEach(into = this) { file ->
            div("flex flex-row items-center justify-between text-lg") {
                div("line-clamp-1") {
                    +file.name
                }
                div("flex items-center gap-2") {
                    // progress
                    when (file.state) {
                        DocumentState.FINISHED -> successIcon("h-5 w-5", "#599900")
                        DocumentState.IN_PROGRESS -> processIcon("animate-spin h-4 w-5", "#599900")
                        DocumentState.VIRUS -> virusIcon("h-5 w-5", "#A51310")
                        DocumentState.REPLACED -> replaceIcon("h-5 w-5", "#004669")
                        DocumentState.DUPLICATE -> duplicateIcon("h-5 w-5", "#004669")
                        DocumentState.INVALID -> invalidIcon("h-5 w-5", "#A51310")
                        else -> failureIcon("h-4 w-5", "#A51310")
                    }
                    deleteButton(Pair(file.name, null), ScreeningFileStore.remove, file.state)
                }.tooltip("text-primary-100 bg-primary-10 rounded-md p-4") {
                    when (file.state) {
                        DocumentState.FINISHED -> translationStore[UiCreate.StateFinished].renderText(this)
                        DocumentState.IN_PROGRESS -> translationStore[UiCreate.StateInProgress].renderText(this)
                        DocumentState.VIRUS -> translationStore[UiCreate.StateVirus].renderText(this)
                        DocumentState.REPLACED -> translationStore[UiCreate.StateReplaced].renderText(this)
                        DocumentState.DUPLICATE -> translationStore[UiCreate.StateDuplicate].renderText(this)
                        DocumentState.INVALID -> translationStore[UiCreate.StateInvalid].renderText(this)
                        else -> translationStore[UiCreate.StateFailure].renderText(this)
                    }
                    arrow()
                    placement = "left"
                }
            }
        }
    }
}

fun RenderContext.modelSelector(router: MapRouter) {
    // Model tree
    modelSelector.renderModelSelector(this, router)
    modelSelector.getIdStore().update(ScreeningValidationStore.current.modelIds)
}

fun RenderContext.startScreeningButton(isScreeningReady: Flow<Boolean>, router: MapRouter) {
    val tooltipText =
        "The screening can be started if at least one file is correctly uploaded and a model is selected"

    div {
        button(mainButton) {
            disabled(isScreeningReady.map { !it })
            +"Start Screening"
        }
        clicks.map { router } handledBy ScreeningValidationStore.startScreening
    }.tooltip("text-primary-100 bg-primary-10 rounded-md p-1.5") {
        hidden(isScreeningReady)
        +tooltipText
        arrow()
        placement = "top"
    }
}

inline fun <reified T> RenderContext.deleteButton(
    item: T,
    removeHandler: SimpleHandler<T>,
    documentState: DocumentState
) {
    if (documentState == DocumentState.IN_PROGRESS) {
        val disabled = true
        button("p-1 rounded-lg disabled:text-disabled disabled:cursor-none disabled:pointer-events-none") {
            attr("disabled", disabled)
            trashCanSvg(disabled)
        }
    } else {
        button("p-1 rounded-lg hover:bg-primary-100 focus-visible:bg-primary-100 focus-visible:outline-none") {
            trashCanSvg(false)
            clicks.map { item } handledBy removeHandler
        }
    }
}

fun RenderContext.trashCanSvg(disabled: Boolean, fill: String = "#004669FF") {
    svg("h-4") {
        xmlns("http://www.w3.org/2000/svg")
        viewBox("0 0 448 512")
        fill(if (!disabled) fill else "#00000061")
        path {
            d("M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z")
        }
    }
}

fun RenderContext.optionButtons(isScreeningCreated: Flow<Boolean>) {
    val disabled = isScreeningCreated.map { !it }

    div("flex w-full justify-end space-x-2 mt-1") {
        // clear button
        button(
            "flex items-center justify-center p-1 gap-2 border-solid border-transparent border-b-2 " +
                    "hover:border-primary-10 focus-visible:border-primary-10 focus-visible:outline-none " +
                    "disabled:text-disabled disabled:cursor-none disabled:pointer-events-none"
        ) {
            disabled(disabled)
            attr("aria-disabled", disabled.map { it.toString() })
            svg("h-4") {
                xmlns("http://www.w3.org/2000/svg")
                viewBox("0 0 448 512")
                fill(isScreeningCreated.map { if (it) "#004669FF" else "#00000061" })
                path {
                    d("M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z")
                }
            }
            clicks.map { ScreeningValidationStore.current } handledBy {
                ScreeningValidationStore.reset()
                NameStore.reset()
                ScreeningFileStore.reset()
                FileStore.reset()
                modelSelector.getIdStore().update(emptyList())
            }
            +"create another screening"
        }
        // delete button
        button(iconTextButtonUnderlined) {
            disabled(disabled)
            attr("aria-disabled", disabled.map { it.toString() })
            svg("h-4") {
                xmlns("http://www.w3.org/2000/svg")
                viewBox("0 0 448 512")
                fill(isScreeningCreated.map { if (it) "#004669FF" else "#00000061" })
                path {
                    d("M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z")
                }
            }
            clicks.map { ScreeningValidationStore.current } handledBy {
                ScreeningValidationStore.reset()
                NameStore.reset()
                ScreeningFileStore.reset()
                FileStore.reset()
                modelSelector.getIdStore().update(emptyList())
                deleteScreening(it)
            }
            +"delete screening"
        }
    }
}
