package domain.authentication.login

import dev.fritz2.core.Lenses
import dev.fritz2.validation.Validation
import dev.fritz2.validation.validation
import kotlinx.serialization.Serializable
import localization.login.UiLogin
import util.EmailValidator
import util.Message
import util.Severity

@Lenses
@Serializable
data class Login(
    val email: String,
    val password: String
) {

    companion object {

        const val PATH = "/login"
        const val LOGOUT_PATH = "/logout"

        const val APPROVAL_MESSAGE = "Authentication failed: User login not approved"

        val validation: Validation<Login, Unit, Message> = validation { inspector ->
            val email = inspector.map(Login.email())
            if (validateEmail(email.data)) {
                add(Message(email.path, Severity.Error, UiLogin.EmailInvalid))
            }

            val password = inspector.map(Login.password())
            if (validatePassword(password.data)) {
                add(Message(password.path, Severity.Error, UiLogin.PasswordInvalid))
            }
        }

        fun validate(login: Login): Boolean = !validateEmail(login.email) && !validatePassword(login.password)

        private fun validatePassword(password: String): Boolean = password.trim().isBlank()
        private fun validateEmail(email: String): Boolean = !EmailValidator.isEmailValid(email.trim())
    }
}
