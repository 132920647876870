package localization.admin

import com.tryformation.localization.Translatable

enum class UiGroupManagement : Translatable {
    Heading,
    Description,
    FilterLabel,
    NewButton,
    EditButton;

    override val prefix: String = "group-management"
}