package components

import dev.fritz2.core.*
import dev.fritz2.headless.components.inputField
import koin
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import localization.TranslationStore
import org.w3c.dom.HTMLDivElement
import util.Message
import web.dom.document
import web.html.HTMLInputElement
import web.html.InputType


fun HtmlTag<HTMLDivElement>.textDisplay(
    label: Flow<String>,
    valueStore: Store<String>
) {
    val translationStore by koin.inject<TranslationStore>()

    div("relative p-4 mb-4 mt-2 text-left text-primary-10") {
        div ("w-full p-3 rounded border border-primary-10 text-primary-10") {
            valueStore.data.renderText(this)
        }
        div ("absolute -top-2 block font-medium ml-1 text-sm leading-4") {
            label.renderText(this)
        }
    }
}