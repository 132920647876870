package domain.model

enum class HighlightingSetting(val representation: String) {
    SOLID("solid"),
    TRANSPARENT("transparent");

    companion object {
        fun fromRepresentation(representation: String): HighlightingSetting {
            return HighlightingSetting.values()
                .find { it.representation == representation } ?: TRANSPARENT
        }
    }
}