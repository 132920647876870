package components

import dev.fritz2.core.*

fun RenderContext.defaultHeader() {
    header(id = "header") {
        skipToMain()
        nav("navbar", id = "navbar") {
            a("headerLogo mg-lf-1") {
                attr("role", "link")
                tabIndex(0)
                img("headerLogoImage") {
                    src("../images/logo_white.svg")
                    alt("keyspot Logo")
                }
                href("/")
            }
        }
    }
}