package admin.sides.stores

import api.getGroups
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.headless.components.toast
import domain.userManagement.Group
import domain.userManagement.GroupParameter
import kotlinx.coroutines.Job

object GroupsStore: RootStore<List<Group>>(emptyList(), Job()) {
    val initialize: SimpleHandler<Boolean> = handle {_, _ ->
        try {
            toast("success", 1000L, "successToast") {
                +"Retrieving of groups successful"
            }
            getGroups(GroupParameter.PERMISSION)
        } catch (e: Exception) {
            toast("error", classes = "errorToast") {
                e.message
            }
            emptyList()
        }
    }
}